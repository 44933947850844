.billinginfor_backtoclr {
    color: #fc7d72;
    font-size: 12px;
    font-weight: 500;
    float: left;
    top: 6px;
    position: relative;
    cursor: pointer;
}
span.billinginfortitlefs {
    font-size: 1.2rem;
}
.billinginforform label {
    font-size: 12px;
    color: #777171;
}
.billinginforform input.form-control {
    font-size: 12px;
    font-weight: 500;
}
.red_text {
    color: #ff2c39;
}
.billinginforform select.form-control {
    font-size: 12px;
    font-weight: 500;
    height: calc(1.5em + 0.75rem + 2px );
}
button.btn.btn-default.makepaybtn {
    background-color: #31719b;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
}
.card.rightsummbox {
    border: 1px solid rgba(0,0,0,.125);
    padding: 0px;
}
.summinnerbox {
	background-color: #f3f3f3;
    border-bottom: 1px solid #dddada;
}
h4.billingsumtextfs {
    font-size: 1rem;
    padding: 2px 0px 0px 8px;
}
.summinnerbodybgclr {
    background-color: #f8f8f8;
}
p.planseltextbox {
    margin: 0px;
    padding: 5px 5px;
    font-size: 12px;
    border-bottom: 1px solid #ddd;
}
span.plansheltextfs {
    font-size: 11px;
    color: #9d9d9d;
}
.plansheltextfs {
    font-size: 11px;
    color: #9d9d9d;
}
span.plannotexts {
    color: #31719b;
    font-size: 12px;
    font-weight: 700;
}
.compannameovertooltip {
    color: #31719b;
    font-size: 12px;
    max-width: 68%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: pointer;
}
span.howtovouch {
    font-size: 11px;
    color: #31719b;
    cursor: pointer;
    text-decoration: underline;
}
table.table.table-borderless.billsummboxtb tr td {
    font-size: 11px;
    font-weight: 500;
    padding: 0.25rem 0.75rem;
}
tr.trrowtopbor {
    border-top: 1px solid #ddd;
    padding: 5px 0px 1px 0px;
}
td.totaltextfs {
    color: #31719b;
    font-weight: 700 !important;
    font-size: 12px !important;
}
.overflowtexthtovoch {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}
.apply_btn {cursor: pointer; color: #fc7d72;}
.cancel_btn {cursor: pointer;}

::placeholder {
  font-size: 10px;
    color: #d3d3d3 !important;
}


button.btn.btn-default.Subscriptionplan_whatsapp_btnbgclr {
    padding: 3px 10px 5px 10px;
    border: 0px;
    background-color: #41c452;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    color: #fff;
  }
  button.btn.btn-default.Subscriptionplan_whatsapp_btnbgclr span.Subscriptionplan_whatsapp_textline {
    color: #d2d2d2;
    font-size: 13px;
    padding: 0px 2px;
  }
  
  button.btn.btn-default.Subscriptionplan_whatsapp_btnbgclr:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
  }
  
  ::-moz-placeholder {
    color: #ced4da !important;
  }
  
  ::placeholder {
    color: #ced4da !important;
  }
  
  .subscript_whatsappicon_hidebox {
    /* display: none; */
    position: absolute;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 4px 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 11;
    margin-top: 4px;
    min-width: 150px;
    width: 250px;
    max-width: -moz-max-content;
    max-width: max-content;
    margin-left: 300px;
  }
  .subscript_whatsappicon_hidebox .form-group.subscript_whatsappicon_brpdiv {
    margin-bottom: 0px;
  }
  .subscript_whatsappicon_hidebox .form-group.subscript_whatsappicon_brpdiv .input-group-prepend.subscript_whatsappicon_apprendleft {
    padding: 7px 0px 2px 3px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem 0rem 0 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #31719b;
    border-right: 0px;
    cursor: pointer;
  }
  .subscript_whatsappicon_hidebox .form-group.subscript_whatsappicon_brpdiv input.form-control.subscript_whatsappicon_inputbox {
    font-size: 12px;
    font-weight: 500;
    border-right: 0px;
    border-left: 0px;
  }
  .subscript_whatsappicon_hidebox .form-group.subscript_whatsappicon_brpdiv input.form-control.subscript_whatsappicon_inputbox:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
  }
  .subscript_whatsappicon_hidebox .form-group.subscript_whatsappicon_brpdiv .input-group-prepend.subscript_whatsappicon_apprendright {
    padding: 6px 4px 6px 2px;
    border: 1px solid #ced4da;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #31719b;
    border-left: 0px;
    position: relative;
    top: 0px;
    cursor: pointer;
  }
  .subscript_whatsappicon_hidebox button.btn.btn-default.subscript_whatsappicon_sharebtn {
    padding: 3px 10px 5px 10px;
    border: 0px;
    background-color: #31719b;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    color: #fff;
    position: relative;
    top: 2px;
  }
  .subscript_whatsappicon_hidebox button.btn.btn-default.subscript_whatsappicon_sharebtn:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
  }
  
body {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

.fade.returndash_videodemo_popup.modal.show .modal-dialog {
    margin: 3rem auto auto auto;
}

.returndash_videodemo_header.modal-header {
    background-color: #eee;
    padding: 0px 15px;
}
.returndash_videodemo_title.modal-title.h4 {
    padding: 4px 0px;
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    display: inline-flex;
}
.returndash_videodemo_header.modal-header button.close {
    position: relative;
    top: 5px;
}
.form-check.returndash_videodemo_lable {
    font-size: 12px;
    font-weight: 500;

    & span.returndash_videodemo_textfs {
        vertical-align: -webkit-baseline-middle;
        color: #000;
        font-size: 12px;
        font-weight: 500;
    }
}
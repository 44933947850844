h4.h4bold {
    font-size: 1rem;
    font-weight: 500;
    background: #f1e8e8;
    padding: 4px 10px;
}
span.fsizeicon {
    font-size: 15px;
    font-weight: 500;
}
input.onlyNumber {
    text-align: right;
}
table.table.table-striped.tilst2latedptb thead tr th {
    font-size: 0.7rem;
    padding: 10px 10px;
    text-align: center;
    font-weight: 500;
}
table.table.table-striped.tilst2latedptb tr td {
    font-size: 10.9px;
    padding: 5px 10px;
}

.latefeeheadbgclr.modal-header {
    padding: 0px 15px;
    background-color: #eee;
}
.latefeeheadbgclr.modal-header h5.modal-title {

padding: 3px 0px 3px 0px;
font-size: 1rem !important;
font-weight: 500;
}
.latefeeheadbgclr.modal-header  button.close {
top: 9px;
position: relative;
}

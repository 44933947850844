body {
  background-color: #ffffff !important;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.returnfilingTdsMappingscss {
  .card.mapping_deducttrans_card {
    padding: 0px;

    & .mapping_deducttrans_texfsbox {
      background-color: #33729b;
      padding: 2px 5px 1px 5px;
      color: #fff;
    }

    h4.mapping_deducttrans_titlefs {
      font-size: 1rem;
      font-weight: 500;
      position: relative;
      top: 1px;
    }

    & span.iconflipright {
      float: right;
      cursor: pointer;
      background: #fc7d72;
      border-radius: 30px;
      padding: 3px 10px 5px 10px;
      font-size: 13px;
      position: relative;
      right: 20px;
    }

    & span.iconflipleft {
      float: right;
      padding: 1px 2px;
      cursor: pointer;
    }

    & .hide {
      display: none;
    }

    & .monthwisetexcard {
      perspective: 1000px;
    }

    & .mapping_deducttrans_tb {
      min-height: 538px;

      position: relative;
      width: 100%;
      transition: transform 2.5s;
      transform-style: preserve-3d;
      animation: 0.5s elase;
    }

    & .roate_div {
      transform: rotateY(180deg);
    }

    & .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    & .flip-card-back {
      transform: rotateY(180deg);
    }

    .table.table-striped.mapping_deducttrans_table {
      thead tr th {
        font-size: 12px;
        font-weight: 500;
        border-top: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
      }

      & tr {
        &:hover {
          background-color: #9b9fa3;
        }

        td {
          font-size: 11px;
          padding: 0.25rem 0.75rem;
          font-weight: 500;

        }
      }
    }

    .table.table-striped.mapping_deducttrans_table tbody {
      display: block;
      max-height: 490px;
      overflow-x: auto;
    }

    //   table.table.table-striped.mapping_deducttrans_table thead, tbody tr {
    //   display:table;
    //   width:100%;
    //   table-layout:fixed;
    //   }
    table.table.table-striped.mapping_deducttrans_table thead {
      padding: 0px 10px 0px 0px;
    }
  }

  /*---------Duble errow collapse css ------------*/

  .expand-button.Interest_feescollapse_icon {
    position: relative;
  }

  .accordion-toggle.expand-button.Interest_feescollapse_icon:after {
    position: absolute;
    left: 0.2rem;
    top: 60%;
    transform: translate(0, -50%);
    content: url(../../../images/dangleup_icon.png);
    cursor: pointer;
  }

  .accordion-toggle.collapsed.expand-button.Interest_feescollapse_icon:after {
    content: url(../../../images/dangledown_icon.png);
  }

  /*---------Duble errow collapse css ------------*/

  /*========= collapse tbale tr css =========*/

  tr.hide-table-padding td {
    padding: 0.25rem 0.75rem;
  }

  .expand-button {
    position: relative;
    cursor: pointer;
  }

  .accordion-toggle.expand-button.collapsed.mapping_deducttrans_collicon:after {
    position: relative;
    left: 0.1rem;
    top: 0%;
    transform: translate(0, -50%);
    content: url(../../../images/angldownicon.png);
  }

  span.accordion-toggle.expand-button.mapping_deducttrans_collicon:after {
    content: url(../../../images/anglupicon.png);
    left: 0.1rem;
    position: relative;
  }

  /*========= collapse tbale tr css =========*/

  tr.mapping_deducttrans_row_bgclr_grey td {
    // border-bottom: 1px solid #c7c7c7;
    border-bottom: 1px solid #fff;
  }

  tr.mapping_deducttrans_row_bgclr_grey {
    border-bottom: 1px solid #c7c7c7;
    // background-color: #e0e0e0 !important;
    background-color: #f2f2f2 !important;
  }

  .mapping_deducttrans_row_bgclr_white {
    background-color: #fff !important;
  }
  td.mapping_bottom_borderW {
    border-bottom: 1px solid #fff !important;
}
  td.mapping_deducttrans_row_bgclr_innerlightgrey {
    background-color: rgb(243 243 243) !important;
  }

  td.textclr_lightblue {
    color: #545454;
  }

  fieldset {
    display: block;
    margin: 9px 2px 2px 2px !important;
    background-color: #fff;
    padding: 0.35em 0.75em 0.625em !important;
    border: 2px groove (internal value);
  }

  fieldset.mapping_deducttrans_rightuncon_fs {
    border: 1px solid rgba(0, 0, 0, 0.125);
    text-align: right;
    padding: 0px 30px !important;

    & legend.mapping_deducttrans_rightuncon_leg {
      width: auto;

      h4.mapping_deducttrans_unconsum {
        top: -14px;
        position: relative;
      }
    }

    /*----- rang slider  ---*/
  }

  /*----- rang slider  ---*/
  .mappingdt_rangesliderbox {
    text-align: left;
    top: -8px;
    position: relative;

    & p.mapped_alluncon_textfs {
      font-weight: 500;
      font-size: 12px;
      text-align: left;
      padding-left: 30px;
    }

    .double_range_slider_box {
      position: relative;
      width: 100%;
      height: auto;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
    }

    .double_range_slider {
      width: 100%;
      height: 2px;
      position: relative;
      // background-color: #dddddd;
      border-radius: 20px;
    }

    .range_track {
      height: 100%;
      position: absolute;
      border-radius: 20px;
      background-color: #95d564;
    }

    input {

      &.min,
      &.max {
        position: absolute;
        width: 100%;
        height: 1px;
        background: none;
        pointer-events: none;
        // -webkit-appearance: none;
        // -moz-appearance: none;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    input::-webkit-slider-thumb {
      height: 12px;
      width: 13px;
      border-radius: 50%;
      border: 3px solid #cbffa3;
      background-color: #95d564;
      pointer-events: auto;
      -webkit-appearance: none;
      cursor: pointer;
      margin-bottom: 1px;
    }

    input::-moz-range-thumb {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 3px solid #cbffa3;
      background-color: #95d564;
      pointer-events: auto;
      -moz-appearance: none;
      cursor: pointer;
      margin-top: 30%;
    }

    .minvalue {
      position: relative;
      padding: 0px 5px;
      background: #0e5f59;
      border-radius: 1rem;
      color: white;
      top: -8px;
      bottom: 0;
      width: auto;
      min-width: auto;
      max-width: fit-content;
      transform: translate(0, -100%);
      left: 0;
      font-size: 12px;
      transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      will-change: left, transform;
    }

    .maxvalue {
      position: absolute;
      padding: 0px 5px;
      background: #0e5f59;
      border-radius: 1rem;
      color: white;
      width: auto;
      min-width: auto;
      max-width: fit-content;
      top: -9px;
      transform: translate(0, 100%);
      right: 0;
      font-size: 12px;
      transition: right 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      will-change: right, transform;
      z-index: 2;
    }
  }

  /* Inconsum card box css  */
  .card.mapping_uncons_card {
    border: 1px solid #786e6e;
    border-radius: 0px;
    padding: 0;

    & .card-header.mapping_uncons_cardheader {
      background-color: #31719b;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      border-radius: 0px;
      padding: 2px 5px 2px 5px;

      & span.mapping_uncons_rightamt {
        font-weight: 700;
        top: 0px;
        position: relative;
      }
    }

    & .card-body.mapping_uncons_body {
      padding: 5px 5px 5px 5px;

      & p.mapping_uncons_innertextfs {
        font-size: 12px;
        font-weight: 500;

        & span.rightside_amtfs {
          font-weight: 700;
        }

        & span.rightside_textbold {
          font-weight: 500;
        }
      }
    }

    & .card-body.mapping_uncons_bodybgxlr_lightblue {
      background-color: #bbdefb;
    }
  }

  .cursor-default {
    cursor: default;
  }

  span.tds_unlinked_img {
    vertical-align: text-bottom;
  }

  .cursor-pointer {
    cursor: pointer;
  }



  .spborder_aftertenrow {
    border-bottom: 4px dashed #d35146;
    position: relative;
    padding: 6px 0px 10px 0px;
    margin-bottom: 17px;
  }

  // table.mapping_deducttrans_trtd_innertablefixed {
  //     width: 100%;

  //     & tbody{
  //         display: block;
  //         max-height: 128px;
  //         overflow-x: auto;

  //         & tr{
  //             display: table;
  //             width: 100%;
  //             table-layout: fixed;
  //         }

  //         & tr.mapping_deducttrans_trtd_bgclr_yell {
  //             background-color: #fff !important;
  //         }
  //     }
  // }
  .card.tdsmapping_deducttrans_card {
    padding: 3px 3px;
    margin-top: 6px;
  }

  .mapping_unconsum_boxfixed {
    overflow-y: auto;
    height: 477px;
    overflow-x: hidden;
  }

  span.showtrans_textfsclr {
    font-size: 12px;
    font-weight: 500;
    color: #31719b;
    text-decoration: underline;
    text-align: right;
    cursor: pointer;
    float: right;
    position: relative;
    top: 4px;
    padding: 0px 3px 0px 5px;
  }

  tr.mapping_deducttrans_rowgradienteffect {
    background-color: #d7faae;
    background-image: linear-gradient(180deg, #fff, #d7faae);
  }

  tr.mapping_deducttrans_2row th {
    color: #00f;
    font-size: 9px !important;
    padding-top: 0px !important;
  }

  tr.mapping_deducttrans_toprow th {
    padding-bottom: 0px !important;
    border-bottom: 0px !important;
  }

  span.mapping_uncons_leftside_text {
    padding: 1px 1px;
    font-weight: 700;
  }

  span.editiconbgclr {
    padding: 2px 1px;
    top: 0px;
    position: relative;
    cursor: pointer;
  }

  /*----plus collpase icon -----*/

  .expand-button.mapping_dt_inner2_collapseicon {
    position: relative;
  }

  .expand-button.mapping_dt_inner2_collapseicon:after {
    position: absolute;
    left: 0.2rem;
    top: 60%;
    transform: translate(0, -50%);
    content: url(../../../images/accor_mins_icon.png);
    cursor: pointer;
  }

  .collapsed.expand-button.mapping_dt_inner2_collapseicon:after {
    content: url(../../../images/accor_plus_icon.png);
  }

  /*----plus collpase icon -----*/

  .table.table-striped.mapping_dt_innertb thead tr th {
    background-color: #fffdea;
    font-size: 12px;
    font-weight: 500;
    border-top: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
  }

  table.table.table-striped.mapping_dt_innertb tr td {
    font-size: 11px;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    // background-color: #e8e4e4;
  }

  td.tdsmapping_rowbgclr_innertb_whitebg {
    background-color: #fff !important;
  }

  td.tdsmapping_rowbgclr_innertb_whitebg.borderleftside {
    border-left: 1px solid #ddd;
  }

  .svg-inline--fa.fa-w-16 {
    width: 1em;
  }

  .svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }

  input.form-control.tdsmapping_searchtext {
    font-weight: 500;
    padding: 14px 3px 14px 3px !important;
    font-size: 11px;
    border-radius: 0.25rem 0 0 0.25rem;
    border-right: 0 solid #ced4da;
    min-width: 160px;
  }

  span.input-group-text.tdsmapping_search_icon {
    background-color: #fff;
    color: #31719b;
    font-size: 11px;
    border: 1px solid #ced4da;
    border-left-width: 0;
    cursor: pointer;
    padding: 0.375rem 0.2rem;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
  }

  ::placeholder {
    font-size: 11px !important;
    font-weight: 500;
    color: #ddd !important;
  }

  input.form-control.tdsmapping_searchtext:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
  }

  .tdsmapping_cardbox_modeview_active:hover {

    .card.mapping_uncons_card,
    .card.mapping_uncons_card.activehighlight {
      box-shadow: 1px 16px 15px -7px rgba(0, 0, 0, 0.46);
      outline-color: #e6db55;
      border: 1px solid #e6db55;
      transition: all 0.3s ease 0s;
      border-width: 1.9px;
      background-color: #ffffe0;
      border-color: #e6db55;
    }
  }

  .tdsmapping_cardbox_modeview_active {
    .card.mapping_uncons_card.activehighlight {
      box-shadow: 1px 16px 15px -7px rgba(0, 0, 0, 0.46);
      outline-color: #e6db55;
      border: 1px solid #e6db55;
      transition: all 0.3s ease 0s;
      border-width: 1.9px;
      background-color: #ffffe0;
      border-color: #e6db55;
    }
  }

  .tdsmapping_cardbox_modeview_disabledcard .card-header.mapping_uncons_cardheader {
    background-color: #d2d2d2 !important;
    color: #ffffff !important;
    cursor: grabbing;
  }

  .tdsmapping_cardbox_modeview_disabledcard .card-body.mapping_uncons_body {
    background-color: #d2d2d2 !important;
    cursor: grabbing;
  }


  .tdsmapping_cardbox_modeview_disabledcard span.editiconbgclr {
    cursor: none;
  }

  .tdsmapping_cardbox_modeview_deniededimgbox {
    width: 100%;
    height: 100%;
    background-position: center;
    position: absolute;
    top: 0px;
    background-size: contain;
    background-color: rgba(0, 0, 0, 0.32);
    cursor: pointer;
  }

  // .tdsmapping_cardbox_modeview_deniededimg{
  //     // background-image: url(./../../../images/denided.png);
  //     content: 'Consumed';
  //     width: 100%;
  //     height: 100%;
  //     position: absolute;
  //     top: 0px;
  //     background-repeat: no-repeat;
  //     background-size: contain;
  //     background-position: left 50% bottom 50%;
  //     background-position-x: center;
  //     background-position-y: center;
  //     cursor: pointer;
  // }

  .tdsmapping_cardbox_modeview_deniededimg {
    position: relative;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    top: 37%;
    transform: rotate(327deg);
    cursor: pointer;
  }

  &.adjustable_amt_against_modalbox.modal.show {
    background-color: #22222294;
  }

  &.adjustable_amt_against_modalbox.modal.show .modal-dialog {
    max-width: 750px !important;
    margin: 3rem auto auto auto;
  }

  .modal-header.adjustable_amt_against_header {
    background-color: #eee;
    padding: 0px 15px;
  }

  .modal-header.adjustable_amt_against_header {
    .modal-title.h4 {
      font-size: 1rem;
      font-weight: 500;
      padding: 4px 0px 4px 0px;
      width: 100%;
      margin: 0px;
    }

    button.close {
      padding-top: 20px;
    }
  }

  .modal-header.adjustable_amt_against_header button:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }

  .row.adjustableamt_frmrow label {
    font-size: 12px;
    color: #777171;
    font-weight: 500;
  }

  .row.adjustableamt_frmrow input.form-control {
    font-size: 12px;
    font-weight: 500;
  }

  .row.adjustableamt_frmrow input.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
  }

  button.btn.btn-default.adjustableamt_donebtnbgclr {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 40px;
    color: #fff;
    border: 0px;
    padding: 5px 22px 6px 22px;
    font-size: 12px;
    font-weight: 500;
  }

  .modal.tdsmappconsume_modalbox.show {
    background-color: #000000a8;
  }

  .modal.tdsmappconsume_modalbox.show .modal-dialog {
    max-width: 391px;
    margin: 15rem auto 0rem auto;
  }

  p.consume_textfs {
    font-size: 12px;
    font-weight: 500;
  }

  button.btn.btn-default.consum_yesbtnbgclr {
    background-color: #31719b;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
    border: 0px;
    padding: 4px 20px 6px 20px;
  }

  button.btn.btn-default.consum_nobtnbgclr {
    background-color: #31719b;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
    border: 0px;
    padding: 4px 20px 6px 20px;
  }

  /*------ right side certifbox css-----------*/
  .Computation_summary_leftsidemodal {
    position: fixed;
    padding: 5px 12px 5px 12px;
    background-color: #31719b;
    z-index: 2;
    top: 44%;
    transform: rotate(270deg);
    border-radius: 0px 0px 5px 5px;
    overflow: hidden;
    left: -4.7rem;
    cursor: pointer;
    -webkit-animation: instmappsummanimat;
    animation: instmappsummanimat;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    animation-direction: alternate-reverse;

    font-size: 15px;
    font-weight: 500;
    color: #fff;
  }

  .Computation_summary_leftsidemodal:hover {
    left: -4.8rem !important;
    transition: all 1.25s ease-in-out;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
    cursor: pointer;
  }

  @keyframes instmappsummanimat {
    0% {
      left: -6rem;
    }
  }

  .modal.comput_summmodal.show .modal-dialog {
    margin: 3rem auto auto 0rem;
    max-width: 400px;
  }

  .modal-header.comput_summmodal_header {
    background-color: #eee;
    padding: 0px 15px;
  }

  .modal-header.comput_summmodal_header h4.modal-title {
    font-size: 1rem;
    font-weight: 500;
    padding: 4px 0px 4px 0px;
  }

  table.table.table-striped.ComputationSummary_tb thead tr th {
    font-size: 12px;
    font-weight: 500;
    border-top: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    vertical-align: baseline;
  }

  table.table.table-striped.ComputationSummary_tb tr td {
    font-weight: 500;
    font-size: 11px;
    padding: 0.25rem 0.3rem;
  }

  tr.totlerow_shadoweffect {
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
      0 0 6px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 0 rgba(60, 64, 67, 0.15);
    border-bottom: 1px inset rgba(60, 64, 67, 0.15);
    position: relative;
  }

  tr.totlerow_shadoweffect td {
    font-weight: 700 !important;
    color: #31719b;
    background-color: #ebf5e0;
  }

  button.btn.btn-default.Paybtnbgclr {
    background-color: #31719b;
    color: #fff;
    padding: 4px 20px 6px 20px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
    cursor: pointer;
    position: relative;
    border: 0px;
  }

  .compsummary_cellbgclr_yell {
    background-color: #fffce1 !important;
  }

  .compsummary_cellbgclr_lightgrey {
    background-color: #ffe8e8;
  }

  td.textclr_starred {
    color: red;
  }

  &.tdsmapping_flipviewtable_popup.modal.show .modal-dialog {
    max-width: 650px !important;
    margin: 3rem auto auto auto;

    & .modal-header.tdsmapping_flipviewtable_header {
      background-color: #eee;
      padding: 0px 15px;

      & .modal-title.h4 {
        font-size: 1rem;
        font-weight: 500;
        padding: 4px 0px 4px 0px;
        margin: 0px;
      }

      & button.close {
        top: 6px;
        position: relative;
      }

      button:focus {
        outline: 1px dotted;
        outline: 0px auto -webkit-focus-ring-color;
      }
    }

    table.table.table-striped.tdsmapping_flipviewtable_tb {
      & thead tr th {
        font-size: 12px;
        font-weight: 500;
        border-top: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
        vertical-align: baseline;
      }

      & tr td {
        padding: 0.25rem 0.75rem;
        font-weight: 500;
        font-size: 11px;
      }
    }

  }

  .eyeicon_poit {
    cursor: pointer !important;
  }

  /* =================== Start media code -----------======*/
  @media (min-width: 320px) and (max-width: 767px) {
    span.showtrans_textfsclr {
      right: 0px;
      position: relative;
      top: 6px;
    }
  }

  @media (min-width: 768px) and (max-width: 821px) {
    span.showtrans_textfsclr {
      right: 0px;
      position: relative;
      top: 6px;
    }
  }

  @media (min-width: 822px) and (max-width: 913px) {
    span.showtrans_textfsclr {
      right: 0px;
      position: relative;
      top: 6px;
    }
  }

  @media (min-width: 914px) and (max-width: 1025px) {
    span.showtrans_textfsclr {
      right: 0px;
      position: relative;
      top: 6px;
    }

    fieldset.mapping_deducttrans_rightuncon_fs {
      padding: 0px 10px !important;
    }
  }

  @media (min-width: 1026px) and (max-width: 1171px) {
    fieldset.mapping_deducttrans_rightuncon_fs {
      padding: 0px 10px !important;
    }

    span.showtrans_textfsclr {
      display: block;
      position: relative;
    }

    .dedcuteetrans_mapping_left_colwidth {
      max-width: 50% !important;
      min-width: 50% !important;
    }

    .dedcuteetrans_mapping_right_colwidth {
      max-width: 50% !important;
      min-width: 50% !important;
    }
  }

  @media (min-width: 1171px) and (max-width: 1281px) {
    fieldset.mapping_deducttrans_rightuncon_fs {
      padding: 0px 10px !important;
    }

    .dedcuteetrans_mapping_left_colwidth {
      max-width: 50% !important;
      min-width: 50% !important;
    }

    .dedcuteetrans_mapping_right_colwidth {
      max-width: 50% !important;
      min-width: 50% !important;
    }
  }

  @media (min-width: 1282px) and (max-width: 1400px) {
    fieldset.mapping_deducttrans_rightuncon_fs {
      padding: 0px 10px !important;
    }

    .dedcuteetrans_mapping_left_colwidth {
      max-width: 55% !important;
      min-width: 55% !important;
    }

    .dedcuteetrans_mapping_right_colwidth {
      max-width: 45% !important;
      min-width: 45% !important;
    }
  }

  @media (min-width: 1401px) and (max-width: 1520px) {
    .dedcuteetrans_mapping_left_colwidth {
      max-width: 60% !important;
      min-width: 60% !important;
    }

    .dedcuteetrans_mapping_right_colwidth {
      max-width: 40% !important;
      min-width: 40% !important;
    }
  }

  .parentranswidth {
    padding-right: 1rem;
  }

  .gridmaxheight {
    .k-grid-content.k-virtual-content {
      overflow: auto;
      max-height: 400px;
    }
  }

  #drag-element-tooltip-main {
    position: absolute;
    left: -999em;
    opacity: 1;
  }

  #drag-element-tooltip {
    width: 200px;
    height: 60px;
    border: 1px solid #333333b8;
    box-shadow: 1px #333333b8;
    background-color: #fff;
    color: #212121;
    font-size: 12px;
    text-align: center;
    padding: 5px;
    border-radius: 19px 20px 20px 19px;
    position: absolute;
    z-index: 1000;
    opacity: 1;
  }

  .challanstitlepos {
    position: absolute;
    right: 26px;
    color: #00f;

    &>span {
      font-size: 11px;
    }

    &.mapedtrans {
      right: 57%;
    }

    &.challanpost {
      right: 50%;
    }
  }

  .b2btbtrthtextclr {
    position: absolute;
    font-size: 11px;
    line-height: 10px;
    padding-left: 10px !important;
  }

  .disablefiltercrsr {
    .k-cell-inner .k-link {
      cursor: default;
    }
  }

  .faGripVerticaltds {
    color: #9b9fa3;
  }

  .paddpos {
    padding-right: 30px !important;
  }

  .scrollbar {
    padding-right: 1.7rem;
  }

  //   .k-grid tbody tr:not(.k-detail-row):hover, .k-grid tbody tr:not(.k-detail-row).k-state-hover, .sectiontoggletrans table tbody tr:focus-visible {
  //     background-color: #ededed !important;
  //     border-color: black;
  // }


  .cardview_denidedview {
    width: 100%;
    height: 100%;
    background-position: center;
    position: absolute;
    top: 0px;
    background-size: contain;
    background-color: rgba(0, 0, 0, 0.13);
    right: 0px;
  }


  .ribbon {
    position: absolute;
    right: -7px;
    top: 10px;
    z-index: 1;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
  }

  .ribbon span {
    font-size: 9px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 18px;
    transform: rotate(320deg);
    -webkit-transform: rotate(320deg);
    width: 100px;
    display: block;
    background: #ff0000;
    background: linear-gradient(#df2f20 0%, #df2f20 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 40px;
    right: -21px;
    cursor: default;
  }

  .ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: -5px;
    z-index: -1;
    border-left: 3px solid #df2f20;
    border-right: 3px solid transparent;
    border-bottom: 3px solid #df2f20;
    border-top: 3px solid transparent;
  }

  .ribbon span::after {
    content: "";
    position: absolute;
    right: 2px;
    top: -58%;
    z-index: -1;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 7px solid #df2f20;
    border-top: 5px solid transparent;
  }

  .sumskelton {
    margin-left: 20px;
  }


}

.selected-drag-row {
  background-color: #ff635840 !important;

}

.k-overlay {
  z-index: 100
}


.row.tdsmapping_cardpaddbox {
  padding: 0px 10px 0px 14px;
}
.card.tdsmapping_cardbox {
  border: 1px solid #ddd;
  padding: 4px 5px;

  & p.tdsmapping_textfs_clr {
    font-size: 12px;
    font-weight: 500;
    color: red;
    text-decoration: underline;
    cursor: pointer;
  }

  & .form-check-inline.tdsmapping_textfs_checkboxinline {
    & label.form-check-label {
      font-size: 12px;
      color: #000000;
      font-weight: 500;
      cursor: pointer;
    }

    & span.checkbox_textbgclr {
      background-color: #ededed;
      padding: 3px 4px;
    }
  }

  & button.btn.btn-default.tdsmapping_proceedbtnbgclr {
    background-color: #31719b;
    border: 0px;
    color: #fff;
    padding: 1px 10px 3px 10px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 500;
  }


}

.monthwisedeductee {
  .k-widget.k-window.windowWrapper .k-window-title {
    font-size: 18px;
    color: #000000;
    line-height: 3.4;
  }

  &.transpopup {
    .k-grid-content.k-virtual-content {
      max-height: 120px;
      overflow: auto;
    }
  }

  .k-grid-content.k-virtual-content {
    max-height: 360px;
    overflow: auto;
  }

  .k-widget.k-window.windowWrapper.k-window-focused {
    z-index: 200 !important;
  }
}


fieldset.tdsmapping_deducteetrans_fs {
  border: 1px solid #fc7d72;

  & legend.tdsmapping_deducteetrans_leg {
      & .tdsmapping_deducteetrans_inndev {
        display: block;

        & .tdsmapping_deducteetrans_lefts {
          float: left;

            & h4.tdsmapping_deducteetrans_title {
                font-size: 1.2rem;
                font-weight: 500;
                padding: 0px 5px;
                position: relative;
                top: 4px;
            }
        }

        & .tdsmapping_deducteetrans_inner {
          height: 1px;
          width: 100%;
          position: relative;
      }

      & .tdsmapping_deducteetrans_right {
          float: right;

          span.tdsmapping_deducteetrans_monthwisebtn {
            background-color: #31719b;
            color: #fff;
            border: 0px;
            padding: 5px 10px 7px 10px;
            font-size: 12px;
            font-weight: 500;
            border-radius: 40px;
            position: relative;
            top: -7px;
        }

        .tdsmapping_deducteetrans_filtewricon {
          font-size: 12px;
          font-weight: 500;
          color: #31719b;
          text-decoration: underline;
          text-align: right;
          cursor: pointer;
          float: right;
          position: relative;
          top: 9px;
          padding: 0px 3px 0px 5px;
      }


      }

      & .tdsmapping_deducteetrans_middleborder {
          display: flex;
          position: relative;
          margin-top: 17px;
          border-top: 1px solid #fc7d72;
      }
    }
  }

  
}
 .tdsmapping_mapp_unmapped_cardcolorbox {
  display: inline-flex;

  & span.card.tdsmapping_mapp_compunmappedbox {
    width: 55px;
    height: 20px;
    padding: 5px 5px;
    background-color: #f2f2f2 !important;
  }

  & span.tdsmapping_mapp_titlefs {
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
  }

  & span.card.tdsmapping_mapp_compmappedbox {
    width: 55px;
    height: 20px;
    padding: 5px 5px;
    background-color: #d7faae;
    background-image: linear-gradient(180deg, #fff, #d7faae);
}
}
 .tdsmapping_mapp_unmapped_cardcolorbox {
  display: inline-flex;

  & span.card.tdsmapping_mapp_compunmappedbox {
    width: 55px;
    height: 20px;
    padding: 5px 5px;
    background-color: #f2f2f2 !important;
  }

  & span.tdsmapping_mapp_titlefs {
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
  }

  & span.card.tdsmapping_mapp_compmappedbox {
    width: 55px;
    height: 20px;
    padding: 5px 5px;
    background-color: #d7faae;
    background-image: linear-gradient(180deg, #fff, #d7faae);
}
}


fieldset.tdsmapping_challan_rightbox_fs {
  border: 1px solid #31719b;
  position: relative;
  top: 3px;

    & legend.tdsmapping_challan_rightbox_fs {
      width: auto;

      & h4 {
        font-size: 1.2rem;
        font-weight: 500;
        padding: 0px 5px;
        position: relative;
        top: 1px;
    }
  }
}

/* -------------- add new css totaltb -------------------------*/
table.table.table-striped.mapping_deducttrans_totle_tb {
  tr th {
    font-size: 12px;
    font-weight: 500;
    padding: 0.25rem 0.75rem;
    border-top: 1px solid #dee2e6 !important;
}

  & .mapping_totle_cellbox_bgclr_blue {
    background-color: #31719b;
    color: #fff;
  }

    & .mapping_totle_cellbox_bgclr_white {
      background-color: #fff;
  }



}
.textclr_red {
  color: red;
}
.textclr_green {
color: green;
}
/* update total table css*/
.card.tdsmapping_totalstriknotebox {
  box-shadow: 1px 1px 6px 1px #333;

  & table.table.table-borderless.tdsmapping_totalstriknote_tb {
      background: linear-gradient(357deg, #fefda9 25%, #fff);
      box-shadow: 0 2px 9px #333;

      &   tr th {
        font-size: 11.5px;
        font-weight: 500;
        padding: 0.25rem 0.75rem;
    }

    & .tdsmapping_totalstriknote_pin {
        --pin-color: #d02627;
        --pin-dark: #9e0608;
        --pin-light: #fc7e7d;
        position: absolute;
        width: 60px;
        height: 50px;
        right: 50%;
        top: -1.8rem;
        right: -46px;


        & .tdsmapping_totalstriknote_metal {
            position: absolute;
            width: 4px;
            height: 16px;
            background: linear-gradient(90deg, grey, 40%, #eae8e8, 50%, grey);
            border-radius: 0 0 30% 30%;
            transform: rotate(50deg);
            transform-origin: bottom left;
            top: 18px;
            right: 3.3rem;
            border-bottom: 1px solid grey;
        }

        & .tdsmapping_totalstriknote_bottom_circle {
          position: absolute;
          right: 34px;
          width: 15px;
          top: 1rem;
          height: 15px;
          border-radius: 50%;
          background-color: var(--pin-color);
          background: radial-gradient(circle at bottom right, var(--pin-light), 25%, var(--pin-dark), 90%, var(--pin-color));
      }

      & .tdsmapping_totalstriknote_bottom_circle:before {
          content: "";
          position: absolute;
          top: 3px;
          left: 3px;
          width: 5px;
          height: 10px;
          transform: rotate(55deg);
          transform-origin: 100% 100%;
          border-radius: 0 0 40% 40%;
          background: linear-gradient(90deg, var(--pin-dark), 30%, var(--pin-color), 90%, var(--pin-light));
      }
      & .tdsmapping_totalstriknote_bottom_circle:after {
          content: "";
          position: absolute;
          right: -4px;
          top: -2px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: radial-gradient(circle at right, var(--pin-light), 30%, var(--pin-color), var(--pin-dark) 80%);
      }
    }
  }
}



/*----- challan left box css----------*/


fieldset.tdsmapping_challanbox_fs {
  border: 1px solid #31719b;


    & legend.tdsmapping_challanbox_leg {

      & .tdsmapping_challanbox_inndev {
          display: block;

          & .tdsmapping_challanbox_lefts {
              float: left;

              & h4 {
                  font-size: 1.2rem;
                  font-weight: 500;
                  padding: 0 5px;
                  position: relative;
                  top: 4px;
              }
          }

          & .tdsmapping_challanbox_inner {
              height: 1px;
              width: 100%;
              position: relative;
          }

          & .tdsmapping_challanbox_right {
              float: right;
              width: 40%;
              position: relative;
              top: 3.4px;
              padding: 0px 10px;
          }

      
          
         
         


          & .tdsmapping_challanbox_middleborder {
              display: flex;
              position: relative;
              margin-top: 17px;
              border-top: 1px solid #31719b;
          }
        
      }
    }

    /* range slider box*/
    .tdsmapping_rightsearchboxtop {
      padding-top: 12px;

    /*----- rang slider  ---*/
    .tdsmapping_double_range_slider_box {
        padding: 0px 10px 0px 10px;
        position: relative;
        top: 3px;
    }
    .mappingdt_rangesliderbox {
      text-align: left;
      top: 3px;
      position: relative;

      & p.mapped_alluncon_textfs {
        font-weight: 500;
        font-size: 12px;
        text-align: left;
        padding-left: 30px;
      }

      .double_range_slider_box {
        position: relative;
        width: 100%;
        height: auto;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
      }

      .double_range_slider {
        width: 150px;
        height: auto;
        position: relative;
        // background-color: #dddddd;
        border-radius: 20px;
      }

      .range_track {
        height: 100%;
        position: absolute;
        border-radius: 20px;
        background-color: #95d564;
      }

      input {

        &.min,
        &.max {
          position: absolute;
          width: 100%;
          height: 1px;
          background: none;
          pointer-events: none;
          // -webkit-appearance: none;
          // -moz-appearance: none;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      input::-webkit-slider-thumb {
        height: 12px;
        width: 13px;
        border-radius: 50%;
        border: 3px solid #cbffa3;
        background-color: #95d564;
        pointer-events: auto;
        -webkit-appearance: none;
        cursor: pointer;
        margin-bottom: 1px;
      }

      input::-moz-range-thumb {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        border: 3px solid #cbffa3;
        background-color: #95d564;
        pointer-events: auto;
        -moz-appearance: none;
        cursor: pointer;
        margin-top: 30%;
      }

      .minvalue {
        position: relative;
        padding: 0px 5px;
        background: #0e5f59;
        border-radius: 1rem;
        color: white;
        top: -8px;
        bottom: 0;
        width: auto;
        min-width: auto;
        max-width: fit-content;
        transform: translate(0, -100%);
        left: 0;
        font-size: 12px;
        transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        will-change: left, transform;
      }

      .maxvalue {
        position: absolute;
        padding: 0px 5px;
        background: #0e5f59;
        border-radius: 1rem;
        color: white;
        width: auto;
        min-width: auto;
        max-width: fit-content;
        top: -9px;
        transform: translate(0, 100%);
        right: 0;
        font-size: 12px;
        transition: right 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        will-change: right, transform;
        z-index: 2;
      }
    }


      .tdsmapping_paynowbtn {
        padding-top: 5px;

        &   button.btn.btn-default.tdsmapping_challanbox_paynowbtn {
            border: 0px;
            background-color: #31719b;
            color: #fff;
            border-radius: 40px;
            padding: 3px 10px 5px 10px;
            font-size: 12px;
            font-weight: 500;
        }
      }
    }



}

span.MappFiltericonBlue {
  background-image: url('../../../images/filtericon_blue.png');
  width: 100%;
  position: relative;
  background-position: center;
}

span.MappFiltericoncoral {
  background-image: url('../../../images/filtericon_coral.png');
  width: 100%;
  position: relative;
  background-position: center;
}
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #ffffff !important;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

fieldset {
  display: block;
  margin: 9px 2px 2px !important;
  background-color: #fff;
  padding: 0.35em 0.75em 0.625em !important;
  border: 2px groove (internal value);
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: -0.4rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

::placeholder {
  font-size: 12px;
  font-weight: 500;
  color: #ced4da !important;
}

.deductee_master_searchbox {
  & input.form-control {
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 11px !important;
    font-weight: 500;
  }

  & .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
  }
}

h4.deductee_master_titlefs {
  font-size: 1.2rem;
  font-weight: 500;
  // text-align: center;
}
button.btn.btn-defulat.deduct_master_compliance_checkbtnbox {
  font-size: 12px;
  font-weight: 500;
  background-color: #31719b;
  color: #fff;
  border: 0px;
  border-radius: 40px;
  padding: 3px 10px 5px 10px;
}

a.btn.btn-defulat.deduct_master_compliance_checkbtnbox1 {
  font-size: 12px;
  font-weight: 500;
  background-color: #31719b;
  color: #fff;
  border: 0px;
  border-radius: 40px;
  padding: 5px 10px 6px 10px;
}

button.btn.btn-defulat.deduct_master_compliance_panverificatbtnbox {
  font-size: 12px;
  font-weight: 500;
  background-color: #31719b;
  color: #fff;
  border: 0px;
  border-radius: 40px;
  padding: 5px 10px 6px 10px;
}

table.table.table-striped.deduct_master_tb {
  & thead tr th {
    font-size: 12px;
    text-align: center;
    border-top: 0px;
    font-weight: 500;
    padding-top: 0px;
  }

  & tr td {
    font-size: 11px;
    font-weight: 500;
    padding: 0.25rem 0.75rem;
  }

  & tr.deduct_master_tbtoprow th {
    padding-top: 5px;
    border-bottom: 0px !important;
    padding-bottom: 0px;
  }
  & tr.deduct_master_tb2row th {
    padding-top: 0px;
    padding-bottom: 5px;
    border-top: 0px;
    font-size: 9px;
    font-weight: 500;
  }
  & .deduct_master_textclr_red {
    color: red;
  }
  & .deduct_master_textclr_blue {
    color: rgb(0, 0, 255);
  }

  td.doticonclr_green {
    color: green;
  }

  /*============ switch btn toggle css ===========*/

  label.switch.deduct_master_togglebtnbox {
    position: relative;
    top: 0.21rem;
    width: 23px;
    height: 11px;

    .slider.round {
      border-radius: 7px;
      background-color: #ccc;
    }
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: green;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  label.switch.deduct_master_togglebtnbox {
    .slider:before {
      position: absolute;
      content: "";
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      left: 4px;
      height: 7.3px;
      width: 4px;
      top: 2px;
      bottom: 2.1px;
    }

    input:checked + .slider:before {
      left: -10px;
    }
  }

  input {
    &:checked + .slider {
      background-color: #28a745 !important;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  /* Rounded sliders */

  .slider.round {
    border-radius: 34px;

    &:before {
      border-radius: 34px;
    }
  }

  td.textclrblue {
    color: #31719b;
  }

  /*============ end switch btn toggle css ===========*/

  & span.doticonclr_green {
    color: green;
  }
  & span.doticonclr_yell {
    color: #ff9802;
  }

  & span.doticonclr_skyblue {
    color: #c9ccd1;
  }

  & span.exiocn_padd {
    cursor: pointer;
  }

  & input.inputbox_width100 {
    width: 100%;
  }
}

.dedcuteemaster_custmultibox .multi-select {
  width: 142px;
  min-width: 142px;
  max-width: 100%;
  left: 0px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
}
.dedcuteemaster_custmultibox
  .multi-select
  .dropdown
  span.dropdown-heading-value
  span {
  position: relative;
  top: -3px;
}
.dedcuteemaster_custmultibox
  .multi-select
  .dropdown
  .select-panel
  ul.select-list
  li
  label.select-item {
  padding: 1px 5px !important;
}

.btn-group.listoftransactionsboxgp .multi-select {
  max-width: 100%;
  width: 30%;
}
.btn-group.listoftransactionsboxgp .multi-select .select-panel {
  height: auto !important;
}
.btn-group.listoftransactionsboxgp {
  width: 35%;
  float: left;
}
.btn-group.listoftransactionsboxgp
  .multi-select
  .dropdown
  div
  span.dropdown-heading-value {
  text-align: center;
  color: #fc7d72 !important;
  line-height: 27px !important;
  min-width: 125px;
  width: 125px;
}
.btn-group.listoftransactionsboxgp .multi-select .dropdown div {
  border-radius: 0 !important;
  font-size: 12px;
  font-weight: 500;
  min-height: calc(1.2em + 0.75rem + 2px) !important;
  height: auto !important;
}
.btn-group.listoftransactionsboxgp
  .multi-select
  .dropdown
  div
  ul.select-list
  li
  label.select-item {
  padding: 0 5px 2px 7px !important;
  margin: 0;
}
.btn-group.listoftransactionsboxgp
  .multi-select
  .dropdown
  div
  ul.select-list
  li
  span {
  width: 100% !important;
}

.error_message {
  color: red;
  font-size: 10px;
  padding-left: 5px;
}

input.input-error {
  border: 1px solid #fc6a6a !important;
  box-shadow: 0px 1px 8px 0px #ff4545 !important;
}

input[type="textarea"] {
  width: 100%;
}

input.text-with-span-field {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  border: none !important;
  position: absolute;
  &.input-error{
    width: 100% !important;
    height: calc(1.2em + 0.75rem + 0px) !important;
    padding: 0 2px !important;
    position: relative;
  }

  &:focus {
    width: 100% !important;
    height: calc(1.2em + 0.75rem + 0px) !important;
    padding: 0 2px !important;
    position: relative;
  }
}

span.focus-next-span {
  display: block;
  padding: 1px;
}

input.text-with-span-field:focus + span.focus-next-span, input.text-with-span-field.input-error + span.focus-next-span {
  display: none;
}

.input_has_value {
  &:not(.input-error) {
    background: none;
    border: none;
  }

  &:focus-within {
    background: white;
    border: 1px solid #ced4da;
  }
}

.form-control-deductee {
  display: block;
  width: 100%;
  height: calc(1rem + 0.4rem + 1px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.deducteemaster_backsearchbox {
  // display: inline-flex;
  display: -webkit-box;
}
.deducteemaster_backsearchbox .deductee_master_searchbox {
  max-width: 100%;
  width: 38%;
  min-width: 235px;
}
.deducteemaster_backsearchbox .deductee_master_searchbox .input-group-prepend {
  margin-right: -1px;
  padding: 8px 4px 4px 3px;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #31719b;
  border-left: 0px;
  cursor: pointer;
  height: calc(1.5em + 0.75rem + -4px);
}
.deducteemaster_backsearchbox
  .deductee_master_searchbox
  img.dedmaster_search_text_icon {
  position: relative;
  padding: 0px 0px;
  top: -2px;
}
.deducteemaster_backsearchbox .deductee_master_searchbox input.form-control {
  padding: 0px 0px 0px 5px;
  height: calc(1.5em + 0.75rem + 3px);
  border-right: 0px;
}

.img.search_text_icon.doflistsiconboxmrgin {
  left: 252px;
}

.search_text_icon {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 302px;
  width: 15px;
}

img.search_text_icon.doflistsiconbox {
  left: 7px;
}

.dedcuteemaster_custmultibox .multi-select {
  width: 142px;
  min-width: 142px;
  max-width: 100%;
  left: 0px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
}
.dedcuteemaster_custmultibox
  .multi-select
  .dropdown
  span.dropdown-heading-value
  span {
  position: relative;
  top: -3px;
}
.dedcuteemaster_custmultibox
  .multi-select
  .dropdown
  .select-panel
  ul.select-list
  li
  label.select-item {
  padding: 1px 5px !important;
}

.btn-group.listoftransactionsboxgp1 .multi-select {
  max-width: 100%;
  width: 30%;
}
.btn-group.listoftransactionsboxgp1 .multi-select .select-panel {
  height: auto !important;
}
.btn-group.listoftransactionsboxgp1 {
  width: 35%;
  float: left;
}
.btn-group.listoftransactionsboxgp1
  .multi-select
  .dropdown
  div
  span.dropdown-heading-value {
  text-align: center;
  color: #fc7d72 !important;
  line-height: 27px !important;
  min-width: 125px;
  width: 125px;
}
.btn-group.listoftransactionsboxgp1 .multi-select .dropdown div {
  border-radius: 0 !important;
  font-size: 12px;
  font-weight: 500;
  min-height: calc(1.2em + 0.75rem + 2px) !important;
  height: auto !important;
}
.btn-group.listoftransactionsboxgp1
  .multi-select
  .dropdown
  div
  ul.select-list
  li
  label.select-item {
  padding: 0 5px 2px 7px !important;
  margin: 0;
}
.btn-group.listoftransactionsboxgp1
  .multi-select
  .dropdown
  div
  ul.select-list
  li
  span {
  width: 100% !important;
}

.dedcuteemaster_custmultibox
  .multi-select
  .dropdown
  div
  ul.select-list
  li
  label.select-item {
  padding: 0 5px 2px 7px !important;
  margin: 0;
}

.textoverflow.deducteemasteroverflow {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  /* display: inline-block; */
}

/*--------------------- media code -----------------------------*/

@media (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 821px) {
  h4.deductee_master_titlefs {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .fade.bulkpanvmbox.modal.show .modal-dialog {
    transform: none;
    width: 95%;
  }
  span.listofdeititle {
    font-size: 0.7rem;
    padding: 0rem 0rem 0rem 0rem;
  }
  .deducteemaster_backsearchbox {
    display: grid;
  }
}

@media only screen and (max-width: 1200px) {
  .deductormasterlist img.search_text_icon.doflistsiconboxmrgin {
    left: 208px;
  }
}

@media only screen and (max-width: 1025px) {
  .deductormasterlist img.search_text_icon.doflistsiconboxmrgin {
    left: 68px;
  }
}

.table.table-striped.deduct_master_tb {
  .k-grid-content.k-virtual-content {
    max-height: 500px !important;
    overflow: auto !important;
  }
}

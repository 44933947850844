.modal-header.yourtrialexpirhead {
	padding: 0px 7px;
	background-color: #eee;
}


.modal-header.yourtrialexpirhead button.close {
    position: relative;
    top: 4px;
}

.modal-header.yourtrialexpirhead .trialexpttle {
    font-size: 1rem;
    padding: 0px 0px 0px 0px;
    font-weight: 500;
}

.modal-header.yourtrialexpirhead button.close:focus {
	outline: 0px dotted;
	outline: 0px auto -webkit-focus-ring-color;
}

.fade.yourtrialexpirpoup.modal.show .modal-dialog {
    margin: 10rem auto 0;
    max-width: 318px !important;
}

p.youtrialexptextfs {
    font-size: 12px;
    font-weight: 500;
}

button.btn.btn-default.trialexppaynowbtn {
	background-color: #31719b;
	border-color: #31719b;
	border-radius: 28px;
	color: #fff;
	padding: 7px 15px;
	font-size: 12px;
	font-weight: 500;
}



@media only screen and (min-width: 576px) {
	.yourtrialexpirpoup .modal-dialog {
		max-width: 300px !important;
		margin: 1.75rem auto;
	}
}
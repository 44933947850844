.padding_top_manage .row .table-responsive-xl {
    width: 100% !important;
}

.k-grid-header-wrap table {
    width: 100% !important;
}

.k-grid-table {
    width: 100% !important;
}
.k-grid .k-grid-header{
    overflow: hidden;
}
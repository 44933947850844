.fade.importdatapupm01.modal.show .modal-dialog {
    width: 30%;
}

.fade.importdatapupm.modal.show .modal-dialog {
    width: 40%;
}
button.btn.btn-default.viewchallbt {
    border: 1px solid #eee;
    padding: 7px 14px!important;
    border-radius: 3px!important;
    font-size: 13px;
    background-color: #eee;
}
button.btn.btn-default.viewdecuteetrbt {
    border: 1px solid #eee;
    padding: 7px 14px!important;
    border-radius: 3px!important;
    font-size: 13px;
    background-color: #eee;
}
.modal-title.h4.dataimports {
    font-size: 1.2rem;
    text-align: center;
    margin: 0px auto;
    padding-top: 1px;
    color: #31719b;
    font-weight: 500;
}
.wefoundtext p {
    font-size: 12px;
    margin-bottom: 7px;
}
.wefoundtext.tdif {
    padding: 0px 12px;
}
table.table.table-striped.table-responsive.impotdatafaildtb tr th {
    font-size: 13.5px!important;
    font-weight: 500;
}
table.table.table-striped.table-responsive.impotdatafaildtb tr td {
    font-size: 12px;
    padding-top: 4px!important;
    text-align: left;
}

table.table.table-striped.table-responsive.impotdatafaildtb thead tr th {
    border-bottom: 1px solid #333;
    padding: 4px 12px;
    background: #d0d0d0;
}
table.subtableimpodd thead tr th {
    background-color: #d0d0d0;
    padding: 4px;
}
table.subtableimpodd tr td {
    padding: 0px 4px;
}
span.clserror {
    color: #FF5722;
}
button.btn.btn-default.retry.pb-1 {
    border: 1px solid #eee;
    padding: 7px 40px!important;
    border-radius: 3px!important;
    font-size: 15px;
    background-color: #eee;
}

.importdedcutort.modal-title.h4 {
    font-size: 1.2rem;
    text-align: center;
    padding-top: 1px;
    color: #333;
    font-weight: 500;
}
.modal-header.importdedcutorh {
    padding: 7px 30px;
    background-color: #eee;
}

/*import dedcutor tb height*/
.kdk_data_table.impdeductortbl {
    max-height: 60vh;
    overflow-y: auto;
}
.importddtorcont {
    display: inline-flex;
}
.importddtorcont span.readsf {
    font-size: 15px;
    padding: 6px 0px;
    color: #333;
}
.importddtorcont .iocnboxch {
    font-size: 14px;
    padding: 10px 9px 0px 0px;
    color: #FF5722;
}
.importddtorcont .filecontbox {
    font-size: 12px;
    padding: 2px 3px;
    line-height: 16px;
}
.importddtorcont .iocnboxch02{
    font-size: 14px;
    padding: 10px 9px 0px 0px;
    color: #E91E63;
}

.dataimports.modal-title.h4.tclrf {
    color: #d05959;
}
span.textclrtdfn {
    color: #E91E63;
}
tr.bgtrclrinnr {
    background-color: #e3dada!important;
    color: #333;
}
tr.bgtrclrinnr th {
    font-weight: 600 !important;
    padding: 4px 12px;
}

@media screen and (max-width: 767px){
    .fade.importdatapupm01.modal.show .modal-dialog {
        width: auto;
    }
    .fade.importdatapupm.modal.show .modal-dialog {
        width: auto;
    }
    button.btn.btn-default.viewchallbt {
        border: 1px solid #eee;
        padding: 7px 3px!important;
        border-radius: 3px!important;
        font-size: 10px;
        background-color: #eee;
    }
    button.btn.btn-default.viewdecuteetrbt {
        border: 1px solid #eee;
        padding: 7px 3px!important;
        border-radius: 3px!important;
        font-size: 10px;
        background-color: #eee;
    }


}
@media screen and (min-width: 768px) and (max-width: 941px){
    .fade.importdatapupm01.modal.show .modal-dialog {
        width: 40%;
    }
    .fade.importdatapupm.modal.show .modal-dialog {
        width: 55%;
    }
    .wefoundtext p {
        font-size: 1rem;
    }

}
@media screen and (min-width: 942px) and (max-width: 1024px){
    .fade.importdatapupm01.modal.show .modal-dialog {
        width: 40%;
    }
    .fade.importdatapupm.modal.show .modal-dialog {
        width: 40%;
    }
    .wefoundtext p {
        font-size: 1rem;
    }

}
body {
  background-color: #fff !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form3cdrem_timelinesection_sticky {
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 8;
    padding-top: 5px;
    padding-bottom: 10px;
    animation: all .2s ease;
    transition-duration: .2s;
    width: 100%;
}


.tds_form3CDRevamp_timeline {
  display: inline-flex;

  & a.btn.btn-default.tds_form3CDRevamp_backbtn {
      color: #fc7d72;
      font-size: 12px;
      font-weight: 500;
  }

  & .btn-group.btn-group-md.tds_form3CDRevamp_box01 {
      left: 0;
  }
  a.tds_form3CDRevamp_linkbox:hover {
      text-decoration: none;
  }
  & span.tds_form3CDRevamp_nobox {
      border: 1px solid #cccbcb;
      width: 28px;
      border-radius: 50%;
      position: relative;
      display: inline-flex;
      left: 29px;
      color: #fff;
      padding: 0 8px;
      top: 0;
      background-color: #cccbcb;
  }

  & span.tds_form3CDRevamp_innertital {
      padding: 0 5px 0 40px;
      margin-top: 0;
      font-size: 14px;
      color: #cccbcb;
      font-weight: 500;
  }

  & span.tds_form3CDRevamp_timeline_midborder {
      display: inline-block;
      position: absolute;
      z-index: 0;
      width: 100px;
      border-bottom: solid #f5f5f5;
      border-width: 2px;
      left: 12.7rem;
      top: 14px;
  }


  .btn-group.btn-group-md.tds_form3CDRevamp_box02 {
      left: 5.0rem;
  }
  span.timelinemid_boder02 {
      left: 17.0rem !important;
  }
  span.timelinemid_boder01 {
        left: 12.3rem !important;
    }


  .btn-group.btn-group-md.tds_form3CDRevamp_box04 {
      left: 10.0rem;
  }
  span.timelinemid_boder04 {
    left: 9.7rem !important;
  }

  .btn-group.btn-group-md.tds_form3CDRevamp_box05 {
    left: 15.0rem;
  }
  span.timelinemid_boder05 {
      left: 9.4rem !important;
  }

  .btn-group.btn-group-md.tds_form3CDRevamp_box06 {
      left: 20rem;
  }


  span.tds_form3CDRevamp_nobox.active {
      background-color: #33729b;
      outline-color: #33729b;
      border-color: #33729b;
  }

  span.tds_form3CDRevamp_innertital.active {

      color: #33729b;
  }
  


  
}

/* -------- media code start ---------- */

@media (max-width: 767px) {
  .tds_form3CDRevamp_timeline {
      display: block;
  }
  .btn-group.btn-group-md.tds_form3CDRevamp_btngrpbox {
      left: 0rem !important;
  }

  span.tds_form3CDRevamp_timeline_midborder {
      display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 821px) {
  .tds_form3CDRevamp_timeline {
      display: block;
  }

  .tds_form3CDRevamp_timeline span.tds_form3CDRevamp_timeline_midborder {
      
      width: 38px;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box02 {
      left: 0rem;
  }
  .tds_form3CDRevamp_timeline span.timelinemid_boder02 {
      left: 17.7rem !important;
      display: none;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box05 {
      left: 10.2rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box06 {
      left: 10rem;
  }

}

@media (min-width: 822px) and (max-width: 913px) {
  .tds_form3CDRevamp_timeline {
      display: block;
  }

  .tds_form3CDRevamp_timeline span.tds_form3CDRevamp_timeline_midborder {
      
      width: 38px;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box02 {
      left: 0rem;
  }
  .tds_form3CDRevamp_timeline span.timelinemid_boder02 {
      left: 17.7rem !important;
      display: none;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box05 {
      left: 10.2rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box06 {
      left: 10rem;
  }


}

@media (min-width: 914px) and (max-width: 1025px) {
  .tds_form3CDRevamp_timeline {
      display: block;
  }

  .tds_form3CDRevamp_timeline span.tds_form3CDRevamp_timeline_midborder {
      
      width: 38px;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box02 {
      left: 1rem;
  }
  .tds_form3CDRevamp_timeline span.timelinemid_boder02 {
      left: 17.7rem !important;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box04 {
      left: 0.4rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box05 {
      left: 1.2rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box06 {
      left: 10rem;
  }
  .tds_form3CDRevamp_timeline span.timelinemid_boder05 {
        left: 10.4rem !important;
        display: none;
    }

}

@media (min-width: 1026px) and (max-width: 1171px) {
  .tds_form3CDRevamp_timeline {
      display: block;
  }

  .tds_form3CDRevamp_timeline span.tds_form3CDRevamp_timeline_midborder {
      
      width: 38px;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box02 {
      left: 0rem;
  }
  .tds_form3CDRevamp_timeline span.timelinemid_boder02 {
      left: 17.7rem !important;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box04 {
      left: 0.4rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box05 {
      left: 0.2rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box06 {
      left: 10rem;
  }

}

@media (min-width: 1172px) and (max-width: 1281px) {
  .tds_form3CDRevamp_timeline {
      display: block;
  }

  .tds_form3CDRevamp_timeline span.tds_form3CDRevamp_timeline_midborder {
      
      width: 38px;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box02 {
      left: 0rem;
  }
  .tds_form3CDRevamp_timeline span.timelinemid_boder02 {
      left: 17.2rem !important;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box04 {
      left: 0.4rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box05 {
      left: 0.2rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box06 {
      left: 0.5rem;
  }

}

@media (min-width: 1282px) and (max-width: 1370px) {
  .tds_form3CDRevamp_timeline {
      display: block;
  }

  .tds_form3CDRevamp_timeline span.tds_form3CDRevamp_timeline_midborder {
      
      width: 38px;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box02 {
      left: 0rem;
  }
  .tds_form3CDRevamp_timeline span.timelinemid_boder02 {
      left: 17.2rem !important;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box04 {
      left: 0.4rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box05 {
      left: 0.2rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box06 {
      left: 0.5rem;
  }

}

@media (min-width: 1371px) and (max-width: 1400px) {
  .tds_form3CDRevamp_timeline {
      display: block;
  }

  .tds_form3CDRevamp_timeline span.tds_form3CDRevamp_timeline_midborder {
      
      width: 38px;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box02 {
      left: 0rem;
  }
  .tds_form3CDRevamp_timeline span.timelinemid_boder02 {
      left: 17.2rem !important;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box04 {
      left: 0.4rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box05 {
      left: 0.2rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box06 {
      left: 0.5rem;
  }

}

@media (min-width: 1401px) and (max-width: 1550px) {
  .tds_form3CDRevamp_timeline {
      display: block;
  }

  .tds_form3CDRevamp_timeline span.tds_form3CDRevamp_timeline_midborder {
      
      width: 38px;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box02 {
      left: 0rem;
  }
  .tds_form3CDRevamp_timeline span.timelinemid_boder02 {
      left: 17.2rem !important;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box04 {
      left: 0.4rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box05 {
      left: 0.2rem;
  }
  .tds_form3CDRevamp_timeline .btn-group.btn-group-md.tds_form3CDRevamp_box06 {
      left: 0.5rem;
  }

}
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #ffffff !important;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* ---------- tab css------------*/
.deductormasterlist nav.tds_listof_deduct_nav .nav.nav-tabs {
  border-bottom: 0px solid #dee2e6 !important;
}

.deductormasterlist nav.tds_listof_deduct_nav .nav.nav-tabs a.nav-item.tds_listof_deduct_tab {
  color: #31719b;
  background-color: #ffffff;
  border-color: #31719b;
  outline-color: #31719b !important;
  border-radius: 0px;
  padding: 2px 25px 3px 25px;
  font-size: 12px;
  border: 0px solid #31719b;
  font-weight: 500;
  border-width: 1.5px;
  border-right: 1px solid #31719b;
  text-align: center;
}

.deductormasterlist nav.tds_listof_deduct_nav .nav.nav-tabs a.nav-item.tds_listof_deduct_tab.active {
  color: #ffffff;
  background-color: #fc7d72;
  font-weight: 500;
}

.deductormasterlist nav.tds_listof_deduct_nav .nav.nav-tabs a.nav-item.tds_listof_deduct_tabretborderrightnon {
  border-right: 0px !important;
}

.deductormasterlist .btn.btn-default.tds_creatdeduct_pendbtnbgclr {
  font-size: 12px;
  padding: 3px 10px 5px 10px;
  background-color: #31719b;
  border-radius: 40px;
  font-weight: 500;
  border: 0px;
  color: #fff;
}

.deductormasterlist a.btn.btn-default.tds_creatdeduct_pendingbtnbgclr {
  font-size: 12px;
  padding: 3px 10px 5px 10px;
  background-color: #31719b;
  border-radius: 40px;
  font-weight: 500;
  color: #fff;
  border: 0px;
}

.deductormasterlist h4.createdeductor_titlefs {
  font-size: 1.2rem;
  font-weight: 500;
  position: relative;
  top: 7px;
}


.deductormasterlist h4.createdeductor_titlefs img {
  cursor: pointer;
  margin-top: 2px;
}

.deductormasterlist .textoverflow.overflowtooltipconttdsamt {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  /* display: inline-block; */
}

.invite-popup-table .textoverflow.overflowtooltipconttdsamt {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  /* display: inline-block; */
}

.deductormasterlist .search_text_icon {
  position: absolute;
  z-index: 1;
  top: 19px;
  left: 25px;
  width: 14px;
}

.deductormasterlist .form-control.forminputdeductormaster {
  padding: 4px 18px 4px 3px;
  display: inline-block;
  width: 300px;
  min-width: 300px;
  max-width: 100%;
  vertical-align: middle;
}

.deductormasterlist img.search_text_icon.doflistsiconboxmrgin {
  left: 275px;
}

.deductormasterlist ul.pagination.creatededutor_pagfs li.page-item.active a {
  background-color: #31719b;
  border-color: #31719b;
}

.deductormasterlist div.form-inline.createded_searchbox input {
  padding-left: 35px;
  width: 300px !important;
  font-size: 12px;
  font-weight: 500;
}

.deductormasterlist div.form-inline.createded_searchbox input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
}

.deductormasterlist table.table.table-striped.listofdedtb thead tr th {
  font-size: 12px;
  font-weight: 500;
  border-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.deductormasterlist .table.table-striped.listofdedtb thead tr th.k-header .k-cell-inner .k-link .k-column-title {
  font-size: 12px;
  font-weight: 500;
  border-top: 0px;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  text-align: center;
}

.deductormasterlist ul.pagination.creatededutor_pagfs {
  font-size: 12px;
  font-weight: 500;
}

.deductormasterlist .table.table-striped.listofdedtb {
  margin-bottom: 0;
}

.deductormasterlist table.table.table-striped.listofdedtb tr td {
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  font-size: 11px;
}

.deductormasterlist button.btn.btn-default.List_of_deductor_incompdeductbtn {
  color: #31719b;
  background-color: #ffffff;
  border-color: #31719b;
  outline-color: #31719b !important;
  border-radius: 0px;
  padding: 2px 25px 3px 25px;
  font-size: 12px;
  font-weight: 500;
  border-width: 1.5px;
  text-align: center;
  border-left: 0;

}

.deductormasterlist .deductorAllListcss span.k-icon.k-i-more-vertical {
  font-size: 12px !important;
}

.deductormasterlist .scrollvisible .k-grid-container .k-grid-content.k-virtual-content {
  overflow: visible;
}

.k-filter-menu-container .filter_ui span.k-widget.k-dropdown.filter_ui_select,
.k-filter-menu-container .filter_ui .k-textbox {
  width: 100%;
}

.deductormasterlist .k-widget.k-grid.table.table-striped .k-grid-header .k-grid-header-wrap table thead tr th:not(.k-filterable) .k-cell-inner .k-link {
  cursor: unset;
}

.deductormasterlist input[type="search"]::-webkit-search-decoration:hover,
.deductormasterlist input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

.recentlist .k-grid-norecords {
  background-color: white!important;
}

/*========== start media code ================*/
@media (max-width: 767px) {
  .deductormasterlist h4.compliancestcheck_titlefs {
    left: 0rem;
  }
}

/*# sourceMappingURL=List_of_deductor.css.map */

.deductormasterlist .borderdeductor {
  border: 1px solid rgba(34, 36, 38, .15);
  padding: 5px;
}

.deductormasterlist .dwldedlist {
  cursor: pointer;
}

.deductormasterlist .inactive {
  opacity: 1.2;
  color: #c7bfbf;
  font-size: 13px;
  font-weight: 500;
}

/* subscription Information message in bottom line in table master list */

.deductormasterlist .card.uexpirepaddtopbrem {
  padding: 6px 16px;
  background-color: #f2f2f2;
  border-right: 0 solid;
  border-radius: 0;
  bottom: 0;
  position: fixed;
  z-index: 1030;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, .125) !important;
}

.deductormasterlist .userexpirebox span.userexpiretextfs {
  font-size: 12px;
  vertical-align: middle;

}


.deductormasterlist img.info_imgalertbox {
  width: 16px;
  padding-top: 2px;
}

.deductormasterlist button.btn.btn-default.paynowbtn {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 28px;
  color: #fff;
  padding: 4px 15px 5px;
  font-size: 12px;
  font-weight: 500;
}

.deductormasterlist .modal-header.yourtrialexpirhead {
  padding: 0px 7px;
  background-color: #eee;
}

.deductormasterlist span.expalerttext {
  font-weight: 700;
  color: #fc7d72;
}

.deductormasterlist span.clickimg_icon {
  position: absolute;
  right: 0rem;
  bottom: 1px;
}

.deductormasterlist .exprit_expriebox_btnbox {
  position: absolute;
  padding: 10px 5px 13px 1px;
  top: -3rem;
  font-weight: 500;
  white-space: initial;
  max-width: initial;
  display: inline-block;
  margin: 0px 1px;
  font-size: 14px;
  width: 212px;
  background-color: #31719b;
  border: 1px solid #fc7d72;
  color: #fff;
  border-radius: 24px 24px 0px 0px;
  cursor: pointer;
}

.table td {
  padding: .25rem .75rem;
}

@media only screen and (min-width: 320px) and (max-width: 1025px) {
  .deductormasterlist .exprit_expriebox_btnbox {
    position: relative;
    top: 1rem;
  }
}



@media only screen and (max-width: 1025px) {
  .deductormasterlist img.search_text_icon.doflistsiconboxmrgin {
    left: 17rem;
  }
}

.deductormasterlist .cursor-pointer {
  cursor: pointer;
}

/* subscription Information message in bottom line in table master list */


/*deductor-category popup*/
.incometaxcredential.show {

  .modal-dialog {
    transform: none;
    width: 300px !important;
    top: 10rem;
    min-height: 400px;
  }

  .cat-head-title {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .cat-update-btn {
    font-size: 12px;
    padding: 3px 15px;
    background-color: #31719b;
    border-radius: 6px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    float: right;
    margin-bottom: 11px;
    margin-left: 0;
  }

  .mpoptextbor.modal-header {
    padding: 0px 15px 0px 15px;
  }
}

.header-check {
  display: flex;
  justify-content: center;
}



div.invite-popup-table .k-filterable .k-column-title{
  cursor: pointer!important;  
}
div.invite-popup-table .k-grid-header .k-link > .k-icon.k-i-sort-desc-sm{
  position: absolute;
  margin-right: 20px; 
  right: 0;
  margin-top: 3px!important;
}
div.invite-popup-table .k-grid-header .k-link > .k-icon.k-i-sort-asc-sm{
  position: absolute;
  margin-right: 20px; 
  right: 0;
  margin-top: 3px!important;
}

.assigned-user-list .textoverflow.overflowtooltipconttdsamt {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  /* display: inline-block; */
}

div.invite-popup-table .k-grid-header .k-header .k-link{
  cursor: pointer;
}





* {
  margin: 0;
  padding: 0;
}

body{   
 background-color: #ffffff!important;
  font-size: 1rem;
  font-weight: normal ;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",;
}

.modal.Edit_deductor_deductdetails_modal.show .modal-dialog fieldset {
  display: block;
  margin: 1px 2px 2px!important;
  background-color: #fff;
  padding: 0.2em 0.75em 0.625em!important;
  border: 2px groove (internal value);
}

.modal.Edit_deductor_deductdetails_modal.show .modal-dialog legend {
  margin-bottom: 0px;
}
.modal.Edit_deductor_deductdetails_modal.show .modal-dialog legend h4{
  margin-bottom: 0px;
}

.modal.Edit_deductor_deductdetails_modal.show .modal-dialog {
  margin: 1rem auto auto auto;
  max-width: 1150px;
}

.modal-header.Edit_deductor_deductdetails_header {
  background-color: #eee;
  padding: 0px 15px;
}

.modal-header.Edit_deductor_deductdetails_header h4.modal-title {
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 0px 4px 0px;
}

.form-row.tds_editdeduct_form .form-group {
  margin-bottom: 0rem!important;
}
.form-row.tds_editdeduct_form .form-group label {
  font-size: 12px;
  color: #777171;
  font-weight: 500;
  margin-bottom: 0.2rem;
}
span.textred {
  color: red;
}
.borderred {
  border-color: red!important;
}
.form-row.tds_editdeduct_form .form-group input.form-control {
  height: calc(1.2em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 500;
}
.form-row.tds_editdeduct_form .form-group select.form-control.tds_editdeduct_selbox {
  height: calc(1.2em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 500;
}

::placeholder{
  color: #ddd !important;
  font-size: 11px !important;
}
.form-row.tds_editdeduct_form .form-group input.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0,123,255,.25);
}

button.btn.btn-default.Edit_deductor_btnsave {
  background-color: #31719b;
  border-radius: 40px;
  color: #fff;
  padding: 5px 20px 7px;
  font-size: 12px;
  font-weight: 500;
  border: 0px;
}

button.btn.btn-default.Edit_deductor_btncancel {
  background-color: #f2f2f2;
  color: #636363;
  border-radius: 40px;
  padding: 5px 20px 7px;
  font-size: 12px;
  font-weight: 500;
  border: 0px;
}

h6.Edit_deductor_titlefs {
  font-size: 13px;
  font-weight: 500;
}

fieldset.deductor_details_fs {
  border: 1px solid #31719b;
}
legend.deductor_details_leg {
  width: auto;
}
legend.deductor_details_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
}

fieldset.Responsiblepersondetails_fs {
  border: 1px solid #fc7d72;
}
legend.Responsiblepersondetails_leg {
  width: auto;
}
legend.Responsiblepersondetails_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
}

p.verify_btn_bottom_margin.createdcontp {
  display: inline-flex;
  font-size: 12px;
}
button.btn.btn-default.copyform_btnbgclr {
  background-color: #31719b;
  color: #fff;
  border: none;
  padding: 1px 8px 4px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
}

fieldset.editedcdfieldset_fs {
  border: 1px solid #31719b;
}
legend.editedcdfieldset_leg {
  width: auto;
}
legend.editedcdfieldset_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
}
fieldset.deductor_add_info_fs {
  border: 1px solid #fc7d72;
}
legend.deductor_add_info_leg {
  width: auto;
}
legend.deductor_add_info_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
}


fieldset.responsibleperson_fs {
  border: 1px solid #31719b;
}

.responsibleperson_inndev {
  display: block;
}
.responsibleperson_left {
  float: left;
}
.responsibleperson_left h4 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0px;
  padding-top: 2px;
}
p.verify_btn_bottom_margin.createdcontp span.form-check label.form-check-label {
  vertical-align: middle;
  top: 2px;
  position: relative;
}
h4.editdeductor_titlefs {
  font-size: 1rem;
  font-weight: 500;
  background-color: #31719b;
  color: #fff;
  padding: 5px 5px 9px 5px;
}
.responsibleperson_inner {
  height: 1px;
  width: 100%;
  position: relative;
}
.responsibleperson_right {
  float: right;
}
h4.responsibleperson_copyfrm {
  top: -6px;
  position: relative;
}
span.responsibleperson_copybtnbox {
  background-color: #31719b;
  color: #fff;
  border: none;
  padding: 1px 8px 4px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
}
.responsibleperson_middleborder {
  border-top: 1px solid #31719b;
  display: flex;
  position: relative;
  margin-top: 14px;
}

.form-row.tds_editdeduct_form .form-group select.form-control.tds_editdeduct_selbox:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0,123,255,.25);
}

.form-group.col-md-6.colbotpaddrem {
  margin-bottom: 0.6rem !important;
}
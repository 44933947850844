.modal-open .modal.Deductee_trans_utilization_summ_modalbox{
    top: 90px;
    z-index: 2000;
}

// .modal-backdrop{
//     top: 90px !important;
//  }
 .modal.Deductee_trans_utilization_summ_modalbox.show .modal-dialog {
    margin: 11rem auto auto auto;
    max-width: 650px !important;
    & .modal-header.Deductee_trans_utilization_summ_header {
        background-color: #33729b;
        padding: 0px 15px;

        & h4.modal-title {
            width: 100%;
            font-size: 1rem;
            text-align: center;
            font-weight: 500;
            padding: 4px 0px;
            color: #ffffff;
        }
    }

    table.table.table-borderless.Deductee_trans_utilization_summ_table {
        & thead tr th {
            border-top: 0;
            font-size: 12px;
            text-align: center;
            font-weight: 500;
            vertical-align: middle;
            padding-bottom: 5px;
            padding-top: 0;
        }

        & tr td{
            font-size: 11px;
            padding: .25rem .75rem;
            font-weight: 500;
        }

        & .overmapped_textclr {
            color: #f54337;
        }

       

       
    }
    & p.Deductee_trans_utilization_summ_textfsclr {
        font-size: 12px;
        font-weight: 500;
        font-style: italic;
        color: #31719b;
        text-align: center;
    }
    


button.btn.btn-default.Deductee_trans_unmapbtn {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 40px;
    color: #fff;
    border: 0px;
    padding: 3px 10px 5px;
    font-size: 12px;
    font-weight: 500;
}

table.table.table-borderless.Deductee_trans_utilization_summ_table thead tr, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
table.table.table-borderless.Deductee_trans_utilization_summ_table tbody {
    display: block;
    max-height: 125px;
    overflow-x: auto;
}
table tbody tr:nth-of-type(odd) {
    background-color: #0000000d;
}

}
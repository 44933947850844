a.backtosupplier {
    font-size: 12px;
    padding: 0px 5px;
    color: #fc7d72;
    text-decoration: none;
    font-weight: 500;
    float: left;
}

span.emailtoselettitle {
    font-size: 1.2rem;
    padding-right: 10rem;
    top: -0.5rem;
    position: relative;
}

p.suppsubtitlefs {
    font-size: 12px;
    text-align: center;
    color: #31719b;
}

span.suppyrtextfs {
    font-weight: 700;
    text-decoration: underline;
}

.k-widget.k-grid.table.table-striped.tds_pendingreq_tb thead tr th {
    border-top: 0px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 5px;
}

.k-widget.k-grid.table.table-striped.tds_pendingreq_tb tr td {
    font-size: 11px;
    font-weight: 500;
    padding: .25rem .75rem;
}
.k-widget.k-grid.table.table-striped.tds_pendingreq_tb span.k-icon.k-i-more-vertical {
    font-size: 12px;
    vertical-align: baseline;
}

.card.cardsuppsendtosupp {
    padding: 0.5rem 0rem;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.form-check.checkleftpadd {
    padding-left: 0px;
}

td.overflowtextsendtosupptbtd {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}
.btn.btn-defualt.sendmailpdf_btn {
    background-color: #31719b;
    color: #fff;
    font-size: 12px;
    border-radius: 40px;
    padding: 5px 15px;
    font-weight: 500;
    position: relative;
    top: -5px;
}
a.btn.btn-defualt.suppsendmailbtn {
    background-color: #31719b;
    color: #fff;
    font-size: 12px;
    border-radius: 40px;
    padding: 5px 18px;
    font-weight: 500;
}

h4.wantuptextfs {
    font-size: 1rem;
    text-align: center;
}

table.table.table-borderless.updatesidetb thead tr th {
    border-top: 0px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding-top: 0px;
}

table.table.table-borderless.updatesidetb tr td {
    font-size: 11px;
    font-weight: 500;
    padding: .25rem .75rem;
    border-top: 0px;
}

.btn.btn-default.exportrightsbtn {
    background-color: #31719b;
    color: #fff;
    font-size: 12px;
    border-radius: 40px;
    padding: 3px 15px 4px 15px;
    font-weight: 500;
}

span.textfsbold {
    font-weight: 700;
}

.importdropzone {
    -webkit-flex: 1 1;
    flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    padding: 20px;
    border-radius: 2px;
    border: 2px dashed #eee;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    height: 150px;
    -webkit-justify-content: center;
    justify-content: center;
    cursor: pointer;
}

p.textfs12 {
    font-size: 12px;
}

p.fileselectedtext {
    font-size: 12px;
    padding-top: 3px;
}

a.btn.btn-defualt.importfilebtn {
    background-color: #e0e0e0;
    color: #fff;
    font-size: 12px;
    border-radius: 40px;
    padding: 5px 20px;
    font-weight: 500;
    border-color: #e0e0e0;
}

td.redbgclr {
    background-color: #f3cfcd;
    color: red;
}

span.b2btbtrthtextclr {
    text-align: right;
    color: #00f;
    font-size: 9px;
}

.card.cardsuppsendtosupp.mailshadowbox {
    box-shadow: 1px 2px 10px 2px #ddd;
}

.k-widget.k-grid.table.table-striped.sendmailsupptb thead tr th {
    border-top: 0px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding-top: 0px;
    vertical-align: top;
}

.k-widget.k-grid.table.table-striped.remove_sortclass.sendmailsupptb tr td {
    font-size: 11px;
    font-weight: 500;
    vertical-align: middle;
    position: relative;
    padding: 0.11rem 0.75rem;
}

.k-widget.k-grid.table.table-striped.sendmailsupptb .form-check {
    top: 3px;
}

.k-widget.k-grid.table.table.table-striped.sendmailsupptb tr td input {
    padding: 5px !important;
    font-size: 11px !important;
    height: 25px !important;
    width: 100%;
}

td.overflowinterstsup {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: revert;
}

.showmoretextleft span {
    float: left !important;
    position: relative;
    top: 5px;
}

.suppsendmailbtn {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 28px;
    color: #fff;
    padding: 4px 15px 6px;
    font-size: 12px;
    font-weight: 500;
    margin: 1rem 0 0 0;
    right: 0;
}


.input_has_value:not(.input-error) {
    background: none;
    border: none;
    line-height: 1.3rem;
    box-sizing: border-box;
    width: 100%;
}

.without_input_value{
    background: white;
    padding: 0rem 0.5rem;
    border-radius: 0.25rem;
    border: none;
    line-height: 1.3rem;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #cdcbcb;
}

.input_has_value:focus-within {
    background: white;
    padding: 0rem 0.5rem;
    border-radius: 0.25rem;
    border: none;
    line-height: 1.3rem;
    box-sizing: border-box;
    box-shadow: 0px 0 6px 2px #1e74f5 !important;
}

input.input-error:not(:disabled) {
    background: white;
    padding: 0rem 0.5rem;
    border-radius: 0.25rem;
    border: none;
    line-height: 1.3rem;
    box-sizing: border-box;
    box-shadow: 0px 0 6px 2px #ff4545 !important;
}

.k-grid-header .k-header {
    vertical-align: top;
}

.k-cell-inner .k-grid-filter,
.k-cell-inner .k-header-column-menu,
.k-cell-inner .k-hierarchy-cell .k-icon {
    align-self: flex-start;
    margin-top: 0.3rem;
}

.k-cell-inner > .k-link{
    justify-content: center;
}
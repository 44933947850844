.fade.raiserequesdonepopup.modal.show .modal-dialog.modal-sm {
    max-width: 568px!important;
}
.modal-header.raiserequesdonepopuph {
    padding: 2px 18px 2px 15px;
    background-color: #efecec;
}
.modal-header.raiserequesdonepopuph .modal-title.h4 {
    padding: 1px 0px;
}
.modal-header.raiserequesdonepopuph button.close {
    padding: 22px 12px;
}
button.btn.btn-default.raisereqdone.btn.btn-primary {
    background-color: #fc7d72;
    color: #fff;
    border: 1px solid #fc7d72;
    padding: 7px 26px!important;
    border-radius: 3px!important;
    font-size: 15px;
}
button.btn.btn-default.viewallbt.btn.btn-primary {
    background-color: #0062cc;
    color: #fff;
    border: 1px solid #0062cc;
    padding: 7px 26px!important;
    border-radius: 3px!important;
    font-size: 15px;
}
.aisedonesect p.donaisefz {
    font-size: 14px;
    color: #006400;
    display: inline-block;
}
.aisedonesect p.reqraisefinaltext {
    font-size: 12px;
}
.modal-footer.reqaisefoot.text-center {
    padding: 0.25rem 0rem;
    display: block;
}
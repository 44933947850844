.ui.pagination.menu .item {
    color: #007bff;
}

.ui.pagination.menu .active.item {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.clientmaster_allpagination {
    zoom: 0.75;
}
.clientmaster_allpagination .ui.pagination.menu {
    zoom: 0.8;
    margin-bottom: 40px;
    font-weight: bold;
    margin: 0;
    display: inline-flex;
    vertical-align: middle;
}
.clientmaster_allpagination .ui.pagination.menu .item {
    color: #007bff;
    cursor: pointer;
}
.clientmaster_allpagination .ui.pagination.menu .active.item {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    cursor: pointer;
}
.clientmaster_allpagination .ui.pagination.menu .item {
    min-width: 1em;
    text-align: center;
    font-size: 1.1em;
}
.clientmaster_allpagination .ui.pagination.menu .icon.item i.icon {
    vertical-align: top;
}
.clientmaster_allpagination .ui.menu .icon.item > .icon {
    width: auto;
    margin: 0 auto;
}
.clientmaster_allpagination .ui.menu .item > i.icon {
    opacity: 0.9;
    float: none;
    margin: 0 0.35714286em 0 0;
}
.clientmaster_allpagination i.icon, .clientmaster_allpagination i.icons {
    font-size: 1.1em;
}
.clientmaster_allpagination i.icon {
    display: inline-block;
    opacity: 1;
    margin: 0 0.25rem 0 0;
    width: 1.18em;
    height: 1em;
    font-family: Icons;
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    text-align: center;
    speak: none;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.clientmaster_allpagination i.icon.angle.left:before {
    content: "\2039";
}
.clientmaster_allpagination i.icon.angle.double.left:before {
    content: "\00AB";
}
.clientmaster_allpagination i.icon.angle.right:before {
    content: "\203A";
}
.clientmaster_allpagination i.icon.angle.double.right:before {
    content: "\00BB";
}
.clientmaster_allpagination .ui.pagination.menu .item.icon {
    font-size: 30px;
    padding: 6px 16px;
}
.clientmaster_allpagination .ui.pagination.menu .icon.item i.icon {
    font-family: sans-serif;
}

.ui.menu {
    display: flex;
    margin: 1rem 0;
    font-family: Lato,"Helvetica Neue",Arial,Helvetica,sans-serif;
    background: #fff;
    font-weight: 400;
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    border-radius: 0.28571429rem;
    min-height: 2.85714286em;
}

.ui.pagination.menu .active.item {
    border-top: none;
    padding-top: 0.92857143em;
    background-color: rgba(0,0,0,.05);
    color: rgba(0,0,0,.95);
    box-shadow: none;
}


.ui.menu .active.item {
    background: rgba(0,0,0,.05);
    color: rgba(0,0,0,.95);
    font-weight: 400;
    box-shadow: none;
}

.ui.menu .item {
    position: relative;
    vertical-align: middle;
    line-height: 1;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    flex: 0 0 auto;
    -webkit-user-select: none;
    user-select: none;
    background: 0 0;
    padding: 0.92857143em 1.14285714em;
    text-transform: none;
    color: rgba(0,0,0,.87);
    font-weight: 400;
    border-right: 1px solid rgba(34,36,38,.15);
}



i.icon.ellipsis.horizontal:before {
    content: "\2026";
    font-weight: 600;
    font-size: 1.5rem;
}
i.icon:before {
    background: 0 0!important;
}

.ui.menu .item:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: rgba(34,36,38,.1);
}
.clientmaster_allpagination .ui.pagination.menu .item.icon[type="ellipsisItem"] {
    font-size: 30px;
    padding: 0px 16px;
}
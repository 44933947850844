body {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  
  .modal.importexcelfile__importmodal.show {
    background-color: rgba(34, 34, 34, 0.8901960784);
  }
  .modal.importexcelfile__importmodal.show .modal-dialog {
    margin: 5rem auto auto auto;
    max-width: 475px;
  }
  .modal.importexcelfile__importmodal.show .modal-dialog .modal-header.importexcelfile__importmodal_header {
    padding: 0px 15px;
    background-color: #eee;
  }
  .modal.importexcelfile__importmodal.show .modal-dialog .modal-header.importexcelfile__importmodal_header h4.modal-title {
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    padding: 4px 0px;
    text-align: center;
  }
  .modal.importexcelfile__importmodal.show .modal-dialog .modal-header.importexcelfile__importmodal_header button:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
  .modal.importexcelfile__importmodal.show .modal-dialog button.btn.btn-default.importexcelfile__importmodal_submitbnt {
    background-color: #e98681;
    border-radius: 40px;
    color: #fff;
    border: 0px;
    padding: 4px 15px 7px;
    font-size: 12px;
    font-weight: 500;
  }
  .modal.importexcelfile__importmodal.show .modal-dialog button.btn.btn-default.importexcelfile__importmodal_calbtn {
    background-color: #ddd;
    border-color: #ddd;
    border-radius: 40px;
    border: 0px;
    padding: 4px 15px 7px;
    font-size: 12px;
    font-weight: 500;
  }
  .modal.importexcelfile__importmodal.show .modal-dialog span.importexcelfile__importmodal_Ortextfs {
    font-size: 13px;
    font-weight: 500;
    padding: 0px 25px;
  }
  .modal.importexcelfile__importmodal.show .modal-dialog button.btn.btn-default.balnktemplate_filenamebtn {
    max-width: 74%;
    min-width: 74%;
    width: 74%;
    border-radius: 40px;
    color: #c3b4b4;
    padding: 7px 10px 9px;
    font-size: 12px;
    font-weight: 500;
  }
  .modal.importexcelfile__importmodal.show .modal-dialog button.btn.btn-default.balnktemplate_dragdropfilebtnbgclr {
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    border: 2px dashed #eee;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    width: 90%;
    border-radius: 40px;
  }
  .modal.importexcelfile__importmodal.show .modal-dialog p.importexcel_textfs_style {
    font-size: 10px;
    font-weight: 500;
    color: #767e83;
    font-style: italic;
  }
  .modal.importexcelfile__importmodal.show .modal-dialog p.importexcel_notetextfs_clr {
    font-size: 11px;
    font-weight: 500;
    color: #767e83;
    font-style: italic;
  }
  .modal.importexcelfile__importmodal.show .modal-dialog button.btn.btn-default.downloadexceltemp_textclr {
    font-size: 12px;
    font-weight: 500;
    color: #31719b;
    text-decoration: underline;
    cursor: pointer;
    padding: 0px 0px;
  }
  .modal.importexcelfile__importmodal.show .modal-dialog button.btn.btn-default.downloadexceltemp_textclr :focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
  }
  .modal.importexcelfile__importmodal.show .modal-dialog button.btn.btn-default.viewpreupdata_textclr {
    font-size: 12px;
    font-weight: 500;
    background-color: #31719b;
    color: #fff;
    border-radius: 40px;
    padding: 3px 10px 5px 10px;
    border: 0px;
  }
  
  .impoetchoosefile_checkbox label.form-check-label {
    font-size: 12px;
    font-weight: 500;
  }
  .impoetchoosefile_checkbox label.form-check-label input.form-check-input.impoetchoosefile_inputcheckbox {
    position: relative;
    top: 2px;
  }
  .impoetchoosefile_checkbox label.form-check-label span.impoetchoosefile_applymaptextfs {
    padding-left: 5px;
    color: #fc7d72;
  }
  
  .dropdown.importchoose_filedropbox button.btn.btn-default.dropdown-toggle.importchoose_filedropbtn {
    background-color: #fff;
    color: #222;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid #ddd;
    min-width: 125px;
    border-radius: 0px;
  }
  .dropdown.importchoose_filedropbox button.btn.btn-default.dropdown-toggle.importchoose_filedropbtn:focus {
    color: #222;
    background-color: #ffffff;
    border-color: #0062cc;
    box-shadow: 0 0 0 0rem rgba(38, 143, 255, 0.5);
  }
  .dropdown.importchoose_filedropbox button.btn.btn-default.dropdown-toggle.importchoose_filedropbtn::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    font-size: 18px;
    position: relative;
    top: 4px;
  }
  .dropdown.importchoose_filedropbox .dropdown-menu.importchoose_filedropbox_menu.show {
    padding: 5px 0px;
    border-radius: 0px;
    min-width: 126px;
  }
  .dropdown.importchoose_filedropbox .dropdown-menu.importchoose_filedropbox_menu.show a.dropdown-item {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
  }
  .dropdown.importchoose_filedropbox .dropdown-menu.importchoose_filedropbox_menu.show .dropdown-divider {
    height: 0;
    margin: 0px;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
  
  img.curcosrpointer {
    cursor: pointer;
  }
  
  p.chhosebtn_overwrite_textfs {
    font-size: 12px;
    font-weight: 500;
  }
  
  button.btn.btn-default.chhosebtnTemplat1 {
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    padding: 3px 15px 3px 15px;
  }
  
  button.btn.btn-default.chhosebtnTemplat1:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
  }
  
  button.btn.btn-default.chhosebtn_overwrite_btnbgclr {
    background-color: #31719b;
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    padding: 3px 15px 5px 15px;
    border-radius: 40px;
    color: #fff;
  }
  
  button.btn.btn-default.chhosebtn_startsave_btnbgclr {
    background-color: #31719b;
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    padding: 3px 15px 5px 15px;
    border-radius: 40px;
    color: #fff;
  }
  
  /*------  media code    -----------*/
  @media (max-width: 512px) {
    button.btn.btn-default.balnktemplate_btnbgclr {
      width: 137px !important;
    }
    span.importexcelfile__importmodal_Ortextfs {
      padding: 0px 5px !important;
    }
    button.btn.btn-default.balnktemplate_dragdropfilebtnbgclr {
      width: 146px !important;
    }
    button.btn.btn-default.balnktemplate_filenamebtn {
      width: 65% !important;
      max-width: 65% !important;
      min-width: 65% !important;
    }
  }/*# sourceMappingURL=TDS_import_excelfile_modal.css.map */
.fade.allowancedspopup.modal.show .modal-dialog.modal-sm {
    width: 750px;
}
.MonthWSdetailsH.modal-header {
    padding: 0px 15px !important;
    background-color: #eee;
}
.modal-title.h4 span.MonthWSdetailsT {
    font-size: 1.2rem;
}
.card.allowance {
    padding: 0px 0px;
}

table.table.table-striped.allowanceWSDTB thead tr th {
    font-size: 13.5px;
    font-weight: 500;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}
table.table.table-striped.allowanceWSDTB tr td {
    font-size: 11px;
    vertical-align: middle;
}

table.table.table-striped.allowanceWSDTB tr td .year_input {
    width: 50%;
    display: inline;
    float: left;
    text-align: center;
}

table.table.table-striped.allowanceWSDTB tr td .month_input {
    width: 40%;
    display: inline;
    float: right;
    text-align: center;
}


.MonthWSdetailsH.modal-header button.close {
   position: relative;
   top:3px;
}
button.btn.btn-default.monthwsdetilssavebtn {
    background-color: #31719b;
    color: #fff;
    border: none;
    padding: 7px 40px;
    border-radius: 40px;
    font-size: 14px;
}
.monthly_salary_input {width: 40%; display: inline; height: auto; padding: 3px 5px;}
/*=======media code ==============*/

@media only screen and (max-width: 767px){
    .fade.MonthWSdetailspopup.modal.show .modal-dialog.modal-sm {
        width: auto;
    }
    .fade.allowancedspopup.modal.show .modal-dialog.modal-sm {
        width: auto;
    }
}
/*=======================Return Flow timeline box ======================*/
.tds_returnflow_timeline {
	display: inline-flex;

	& a.btn.btn-default.returnflow_timeline_backbtn {
		color: #fc7d72;
		font-size: 12px;
		font-weight: 500;
	}

	& .btn-group.btn-group-md.returnflow_btngrpbox {
		a.returnflow_linkbox {
			& span.returnflow_nobox {
				border: 1px solid #cccbcb;
				width: 28px;
				border-radius: 50%;
				position: relative;
				display: inline-flex;
				left: 29px;
				color: #fff;
				padding: 0px 8px 0px 8px;
				top: 0px;
				background-color: #cccbcb;


			}

			span.returnflow_nobox.active {
				background-color: #33729b !important;
				outline-color: #33729b !important;
				border-color: #33729b !important;
			}

			& span.returnflow_innertital {
				padding: 0 5px 0 40px;
				margin-top: 0;
				font-size: 13px;
				color: rgb(204, 203, 203);
				font-weight: 500;
				z-index: 5;
			}

			& span.returnflow_innertital.active {
				color: #31719b;
			}


		}

		& a.returnflow_linkbox:hover {
			text-decoration: none;
		}

		span.returnflow_linkbox {
			& span.returnflow_nobox {
				border: 1px solid #cccbcb;
				width: 28px;
				border-radius: 50%;
				position: relative;
				display: inline-flex;
				left: 29px;
				color: #fff;
				padding: 0px 8px 0px 8px;
				top: 0px;
				background-color: #cccbcb;


			}

			span.returnflow_nobox.active {
				background-color: #33729b !important;
				outline-color: #33729b !important;
				border-color: #33729b !important;
			}

			& span.returnflow_innertital {
				padding: 0 5px 0 40px;
				margin-top: 0;
				font-size: 14px;
				color: rgb(204, 203, 203);
				font-weight: 500;
				z-index: 5;
			}

			& span.returnflow_innertital.active {
				color: #31719b;
			}


		}

		& span.returnflow_linkbox:hover {
			text-decoration: none;
		}

		& span.returnflow_timeline_midborder {
			display: inline-block;
			position: absolute;
			z-index: 0;
			width: 30px;
			border-bottom: solid #f5f5f5;
			border-width: 2px;
			left: 13.0rem;
			top: 14px;

			&.midborder_decrease {
				left: 15.0rem;
			}
		}

	}

	/* timeline btnbox */
	& .returnflow_box01 {
		left: 0rem;
	}

	& .returnflow_box02 {
		left: 0.5rem;
	}

	& .returnflow_box03 {
		left: 1.4rem;
	}

	& .returnflow_box04 {
		left: 2.5rem;
	}

	& .returnflow_box05 {
		left: 3.5rem;
	}

	& .returnflow_box06 {
		left: 4.5rem;
	}

	// & .returnflow_boxtds03 {
	// 	left: 10rem;
	// }
	/* tineline middle border*/
	span.timelinemid_boder02 {
		left: 8.1rem !important;
	}

	span.timelinemid_boder03 {
		left: 10.1rem !important;
	}

	.btn-group.btn-group-md.returnflow_btngrpbox span.returnflow_timeline_midborder.timelinemid_boder03salary {
		left: 13.8rem;
		width: 25px;
	}

	span.timelinemid_boder04 {
		left: 12rem !important;
	}

	span.returnflow_timeline_midborder.timelinemid_boder05 {
		left: 13.5rem ;
	}
	/*--------data not found timeline box ----------*/




	/*======= media code ====================*/

	@media (max-width: 767px) {

		/* timeline btnbox */
		& .returnflow_box01 {
			left: 0rem;
		}

		& .returnflow_box02 {
			left: 0rem;
		}

		& .returnflow_box03 {
			left: 0rem;
		}

		& .returnflow_box04 {
			left: 0rem
		}

		& .returnflow_box05 {
			left: 0rem;
		}

		.returnflow_box06 {
			left: 0rem;
		}

		& .returnflow_timeline_midborder {
			display: none !important;
		}

	}


	@media (min-width: 768px) and (max-width: 821px) {

		/* timeline btnbox */
		& .returnflow_box01 {
			left: 0rem;
		}

		& .returnflow_box02 {
			left: 1.5rem;
		}

		& .returnflow_box03 {
			left: 3rem;
		}

		& .returnflow_box04 {
			left: -38rem;
			top: 30px;
		}

		& .returnflow_box05 {
			left: 0rem;
		}

		& .returnflow_box06 {
			left: 6.5rem;
		}
		span.timelinemid_boder04 {
			left: 41rem !important;
			top: -1rem !important;
		}

		& span.returnflow_timeline_midborder {
			width: 35px !important;
		}

		& span.timelinemid_boder03 {
			display: none !important;
		}

		.btn-group.btn-group-md.returnflow_btngrpbox span.returnflow_timeline_midborder.timelinemid_boder03salary {
			display: none;
		}

	}

	@media (min-width: 822px) and (max-width: 913px) {

		/* timeline btnbox */
		& .returnflow_box01 {
			left: 0rem;
		}

		& .returnflow_box02 {
			left: 1.5rem;
		}

		& .returnflow_box03 {
			left: 3rem;
		}

		& .returnflow_box04 {
			left: -29.5rem;
			top: 2rem;
		}

		& .returnflow_box05 {
			left: -6rem;
		}

		.returnflow_box06 {
			left: -3.5rem;
		}

		& span.returnflow_timeline_midborder {
			width: 35px !important;
		}

		& span.timelinemid_boder03 {
			display: none !important;
		}

		
	}

	@media (min-width: 914px) and (max-width: 1025px) {

		/* timeline btnbox */
		& .returnflow_box01 {
			left: 0rem;
		}

		& .returnflow_box02 {
			left: 0.5rem;
		}

		& .returnflow_box03 {
			left: 1rem;
		}

		& .returnflow_box04 {
			left: 1.3rem
		}

		& .returnflow_box05 {
			left: 2rem;
		}

		& .returnflow_box06 {
			left: 3.5rem;
		}

		& span.returnflow_timeline_midborder {
			width: 36px !important;
		}

		.tds_returnflow_timeline span.timelinemid_boder04 {
			left: 13.4rem !important;
		}


	}

	@media (min-width: 1026px) and (max-width: 1069px) {

		/* timeline btnbox */
		& .returnflow_box01 {
			left: 0rem;
		}

		& .returnflow_box02 {
			left: 0.5rem;
		}

		& .returnflow_box03 {
			left: 1rem;
		}

		& .returnflow_box04 {
			left: 1.3rem
		}

		& .returnflow_box05 {
			left: 1.1rem;
		}

		& .timelinemid_boder05 {
			display: none !important;
		}

		& .returnflow_box06 {
			left: 1.1rem;
		}

		& span.returnflow_timeline_midborder {
			width: 36px !important;
		}

		.tds_returnflow_timeline span.timelinemid_boder04 {
			left: 13.4rem !important;
		}


	}

	@media (min-width: 1070px) and (max-width: 1101px) {

		/* timeline btnbox */
		& .returnflow_box01 {
			left: 0rem;
		}

		& .returnflow_box02 {
			left: 0.5rem;
		}

		& .returnflow_box03 {
			left: 1rem;
		}

		& .returnflow_box04 {
			left: 1.3rem
		}

		& .returnflow_box05 {
			left: 1.1rem;
			top: 1px;
		}

		& .timelinemid_boder05 {
			display: none !important;
		}

		& .returnflow_box06 {
			left: 6.1rem;
		}

		& span.returnflow_timeline_midborder {
			width: 36px !important;
		}



	}

	@media (min-width: 1101px) and (max-width: 1171px) {

		/* timeline btnbox */
		& .returnflow_box01 {
			left: 0rem;
		}

		& .returnflow_box02 {
			left: 0.5rem;
		}

		& .returnflow_box03 {
			left: 1rem;
		}

		& .returnflow_box04 {
			left: 1.3rem
		}

		& .returnflow_box05 {
			left: 2rem;
			top: 1px;
		}



		& .returnflow_box06 {
			left: 4.1rem;
		}

		& span.returnflow_timeline_midborder {
			width: 36px !important;
		}

		 span.timelinemid_boder04 {
			
			display: none !important;
		}

	}

	@media (min-width: 1172px) and (max-width: 1256px) {

		/* timeline btnbox */
		& .returnflow_box01 {
			left: 0rem;
		}

		& .returnflow_box02 {
			left: 0rem;
		}

		& .returnflow_box03 {
			left: 0.3rem;
		}

		& .returnflow_box04 {
			left: 0.5rem
		}

		& .returnflow_box05 {
			left: 0.9rem;
		}

		& .returnflow_box06 {
			left: 1rem;
		}

		& span.returnflow_timeline_midborder {
			width: 25px !important;
		}


	}

	@media (min-width: 1257px) and (max-width: 1300px) {

		.tds_returnflow_timeline {
			display: inline-flex !important;
		}

		a.returnflow_linkbox span.returnflow_innertital {
			font-size: 11px !important;
		}

		/* timeline btnbox */
		& .returnflow_box01 {
			left: 0rem;
		}
		span.returnflow_timeline_midborder.midborder_decrease {
			left: 12.5rem !important
		}
		& .returnflow_box02 {
			left: -1rem;
		}
		span.timelinemid_boder02 {
			left: 7.0rem !important;
		}

		& .returnflow_box03 {
			left: -1.7rem;
		}
		span.returnflow_timeline_midborder.timelinemid_boder03salary {
			left: 13.9rem !important;
		}


		span.timelinemid_boder03 {
			left: 8.8rem !important;
		}
		& .returnflow_box04 {
			left: -2.8rem;
		}
		.returnflow_box003 {
			left: -2.3rem !important;
		}
		span.timelinemid_boder04 {
			left: 10.5rem !important;
		}

		& .returnflow_box05 {
			left: -3rem;
		}

		.timelinemid_boder05 {
			left: 11.5rem !important;
		}

		& .returnflow_box06 {
			left: -3.7rem !important;
			top: 0rem;
		}
		.timelinemid_boder05 {
			left: 11.5rem !important;
		}

		& span.returnflow_timeline_midborder {
			width: 15px !important;
		}

		span.returnflow_linkbox span.returnflow_innertital {
			font-size: 11px !important;
		}

	}

	@media (min-width: 1301px) and (max-width: 1451px) {

		.tds_returnflow_timeline {
			display: inline-flex !important;
		}

		a.returnflow_linkbox span.returnflow_innertital {
			font-size: 11px !important;
		}

		/* timeline btnbox */
		& .returnflow_box01 {
			left: 0rem;
		}
		span.returnflow_timeline_midborder.midborder_decrease {
			left: 12.5rem !important;
		}
		& .returnflow_box02 {
			left: -1rem;
		}
		span.timelinemid_boder02 {
			left: 7.0rem !important;
		}

		& .returnflow_box03 {
			left: -1.7rem;
		}
		span.returnflow_timeline_midborder.timelinemid_boder03salary {
			left: 11.9rem !important;
		}


		span.timelinemid_boder03 {
			left: 8.8rem !important;
		}
		& .returnflow_box04 {
			left: -2.8rem;
		}
		.returnflow_box003 {
			left: -2.3rem !important;
		}
		span.timelinemid_boder04 {
			left: 10.5rem !important;
		}

		& .returnflow_box05 {
			left: -3rem;
		}
		

		& .returnflow_box06 {
			left: -3.5rem;
		}
		.timelinemid_boder05 {
			left: 11.5rem !important;
		}

		& span.returnflow_timeline_midborder {
			width: 15px !important;
		}

		span.returnflow_linkbox span.returnflow_innertital {
			font-size: 11px !important;
		}


	}


	@media (min-width: 1452px) and (max-width: 1531px) {

		/* timeline btnbox */
		& .returnflow_box01 {
			left: 0rem;
		}

		& .returnflow_box02 {
			left: 0.3rem;
		}

		& .returnflow_box03 {
			left: 0.8rem;
		}

		& .returnflow_box04 {
			left: 2.0rem
		}

		& .returnflow_box05 {
			left: 2.3rem;
		}

		& .returnflow_box06 {
			left: 2.3rem;
		}

		& span.returnflow_timeline_midborder {
			width: 25px !important;
		}


	}


}

/*=======================end Return Flow  timeline box ======================*/


/*======= media code ====================*/

@media (max-width: 1559px) {
	.tds_returnflow_timeline {
		display: block;
	}

}
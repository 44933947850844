* {
    margin: 0;
    padding: 0;
}

body{   
   background-color: #ffffff!important;
    font-size: 1rem;
    font-weight: normal ;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",;
}

span.Deducteetrans_mappedfs {
    font-size: 12px;
    font-weight: 500;
}
span.Deducteetrans_mapped_textclrblue {
    color: #31719b;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;   
}
span.Deducteetrans_mapped_textclrblue.active {
    color: #fc7d72 !important;
}
span.Deducteetrans_with_issue_textclrblue {
    font-size: 12px;
    color: #31719b;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;   
}
span.Deducteetrans_with_issue_textclrblue.active {
    color: #fc7d72 !important;
}

span.Deducteetrans_unmapped_textclrblue.active {
    color: #fc7d72 !important;
}
span.Deducteetrans_unmapped_textclrblue {
    color: #31719b;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
}
span.vlinecolor {
    color: #d8d8d8;
}
span.Deducteetrans_showall_textclrblue {
    color: #31719b;
    text-decoration: underline;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
}
span.Deducteetrans_showall_textclrblue.active {
    color: #fc7d72 !important;
}

button.btn.btn-default.errorrecodsbtn {
    font-size: 12px;
    padding: 2px 6px 4px 6px;
    background-color: #31719b;
    border-radius: 40px;
    font-weight: 500;
    color: #fff;
}



/* --- ---- deduct trans tab css  --------*/
nav.Deducteetrans_nav {
    & .nav.nav-tabs {
        border-bottom: 0px solid #dee2e6 !important;
    }

    & a.nav-item.nav-link.Deducteetrans_tab_dedwise {
        color: #31719b;
        background-color: #ffffff;
        border-color: #31719b;
        outline-color: #31719b !important;
        border-radius: 0px;
        padding: 2px 10px 3px 10px !important;
        font-size: 12px;
        border: 0px solid #31719b;
        font-weight: 500;
        border-width: 1.5px;
        border-right: 1px solid #31719b;
        text-align: center;
        line-height: 15px;
    }

  

    & a.nav-item.nav-link.Deducteetrans_tab_dedwise.active {
        color: #ffffff;
        background-color: #fc7d72;
    }

    & a.nav-item.nav-link.Deducteetrans_borderrightnon {
        border-left: 0px !important;
    }

    & .Deducteetrans_dedwise_count_text {
        font-size: 11px;
        font-weight: 500;
    }

    a.nav-item.nav-link.Deducteetrans_defaulttab {
        border-left: 0px !important;
    }
}
/* --- ---- end  deduct trans tab css  --------*/


table.table.table-striped.Deducteetrans_deducteewise_tb {
    & thead tr th {
        font-size: 12px;
        font-weight: 500;
        border-top: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
        vertical-align: baseline;
    }
    & tr td {
        padding: 0.25rem 0.75rem;
            font-weight: 500;
            font-size: 11px;

            & .invoicefilctstb .k-widget.k-grid.table-responsive.deductee_transaction_inner_table .k-grid-container .k-grid-content.k-virtual-content {
                overflow-y: hidden;
            }
    }

    & .showmorebox {
        font-size: 12px;
        font-weight: 500;
    }

    & tr.Deducteetrans_deducteewise_1trthrow th {
        border-bottom: 0px;
        padding-top: 5px;
        padding-bottom: 0px;
    }

    & tr.Deducteetrans_deducteewise_2trthrow th {
        text-align: right;
        color: #31719b;
        font-size: 9px;
        padding-top: 0px;
        padding-bottom: 5px;
    }
    & .dtrans_textclr_blue {
        color: #31719b;
    }
    & img.emailcursor {
        cursor: pointer;
    }

    & input.checkboxvertalaign {
        vertical-align: bottom;
    }
    & span.cursorpoint {
        cursor: pointer;
    }
    
     
}
 ul.pagination.deducttrans_pagfs {
    font-size: 12px;
    font-weight: 500;

    & li.page-item.active a {
        background-color: #31719b;
        border-color: #31719b;
    }
} 

 button.btn.btn-default.shortdeducteebtnbgclr {
    border-radius: 0px;
    border-color: #31719b;
    border: 1px solid #31719b;
    outline-color: #31719b;
    font-size: 12px;
    font-weight: 500;
    padding: 0px 2px;
    border-right: 0px;
    height: calc(1.2em + 0.75rem + 2px);
    min-width: 130px;
}
.btn-group.deducttrans_btngrplistbox{
    & select.form-control.deducttrans_listbox_sel {
        border-radius: 0px;
        border-color: #31719b;
        border: 1px solid #31719b;
        outline-color: #31719b;
        font-size: 12px;
        font-weight: 500;
        padding: 0px 2px;
        border-right: 0px;
        height: calc(1.2em + 0.75rem + 2px);
        min-width: 130px;
        background-color: #fc7d72;
        color: #fff;


    }

    & select.form-control.deducttrans_listbox_sel.selbox04 {
        border-right: 1px solid #31719b !important;
    }
    & select.form-control.deducttrans_listbox_sel:focus {
        box-shadow: 0 0 0 0rem rgba(0,123,255,.25);
    }
}


/*-------- edit deductee trans modal ---------*/

.modal.editdeducteetrans_modal.show {
    cursor: no-drop;

    & .modal-dialog {
        margin: 0rem -1rem 0rem auto;
        cursor: auto;
        position: absolute;
        overflow: auto;
        top: 0;
        right: 0;
        width: 43%;
        height: 100%;
        min-width: 43%;
        max-width: 80%;
        background-color: #fff;

        & .modal-content {
            border-radius: 0px;
            max-height: 100%;
            height: 100vh;
            min-height: 100%;
            background-color: #fff;
            border: 0px solid rgba(0,0,0,.2);
            overflow-x: hidden; 
        }

        & .modal-header.editdeducteetrans_header {
            padding: 0px 15px;
            background-color: #eee;

            & h4.modal-title {
                font-size: 1rem;
                padding: 4px 0px 4px 0px;
                width: 100%;

                & span.editdeducteetrans_pretext {
                    text-align: left;
                    color: #31719b;
                    font-size: 11px;
                    font-weight: 500;
                    cursor: pointer;
                }
                & span.editdeducteetrans_titalcenter {
                    font-size: 1rem;
                    font-weight: 500;
                }
                & span.editdeducteetrans_nexttext {
                    text-align: right;
                    color: #31719b;
                    font-size: 11px;
                    font-weight: 500;
                    cursor: pointer;
                }
            }



            & button.close {
                position: relative;
                top: 2px;
                right: 7px;
            }

            & button:focus {
                outline: 1px dotted;
                outline: 0px auto -webkit-focus-ring-color;
            }
        }
    }

    & form.deducttrans_editform {
        padding-right: 5px;
        & label {
            font-size: 12px;
            color: #777171;
        }

        & select.deducttrans_editform_selbox {
            font-size: 11px;
            font-weight: 500;
            height: calc(1.2em + 0.75rem + 2px);
            border: 1px solid #ced4da;
            min-width: 100%;
            width: 100%;
        }

        & input.form-control {
            font-size: 11px;
            font-weight: 500;
            height: calc(1.2em + 0.75rem + 2px);
            border: 1px solid #ced4da;
        }
        & .form-control:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0rem rgba(0,123,255,.25);
        }

        & .form-group {
            margin-bottom: 0.3rem;
        }

        & span.interesttextfs {
            font-size: 9px;
            font-weight: 500;
            position: relative;
            top: -5px;
            color: #848484;
        }

        & button.btn.btn-default.editdeducteetrans_savebtn {
            font-size: 12px;
            padding: 2px 20px 5px 20px;
            background-color: #31719b;
            border-radius: 40px;
            font-weight: 500;
            color: #fff;
        }
        button.btn.btn-default.editdeducteetrans_cancel {
            font-size: 12px;
            font-weight: 500;
            text-decoration: underline;
            color: #31719b;
        }
        
        
    }
}


/*------- zooz img ----------*/
.return_flow_gifimgmainbox {
    & span.bottomrightsidegifimgbox {
        background-color: hsla(0,0%,76.9%,.47843137254901963);
        position: fixed;
        width: 5%;
        border-radius: 178px 0px 0 0;
        padding: 0px 107px 0 37px;
        margin-left: -1.1rem;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        bottom: 0;
        cursor: pointer;
        overflow: hidden;
        transition: 2s;
        right:0px;

        & img {
            position: relative;
            top: 1rem;
            bottom: 0;
        }
    }
}

.return_flow_gifimgmainbox:hover span.bottomrightsidegifimgbox {
    transition: all 0.7s ease-in-out;
    transform: scale(1.7);
    bottom: 2.1rem;
    box-shadow: 0 2px 10px #868686;
    overflow: hidden;
    transition: 2s;
    right: 2.7rem;
}


.expand-button.deduct_trans_collapse_icon {
	position: relative;
}
.accordion-toggle.expand-button.deduct_trans_collapse_icon:after	{
  position: absolute;
  left: 0.2rem;
  top: 60%;
  transform: translate(0, -50%);
  content: url(../../images/dangleup_icon.png);
      cursor: pointer;
}
.accordion-toggle.collapsed.expand-button.deduct_trans_collapse_icon:after	{
  content: url(../../images/dangledown_icon.png);
}


tr.deducteewise_tb_alternetrowbgclr_white td {
    background-color: #fff;
    border-top: 1px solid #dee2e6;
}
tr.deducteewise_tb_alternetrowbgclr_grey td {
    background-color: #f2f2f2;
}
td.deducteewise_tb_noneditbox_grey {
    background-color: #dee2e6 !important;
    border-bottom: 1px solid #f2f2f2;
}


.expand-button.deducteettransinner_collapseicon {
	position: relative;
}
.accordion-toggle.expand-button.deducteettransinner_collapseicon:after	{
  position: absolute;
  left: 0.2rem;
  top: 60%;
  transform: translate(0, -50%);
  content: url(../../images/anglupicon.png);
      cursor: pointer;
}
.accordion-toggle.collapsed.expand-button.deducteettransinner_collapseicon:after	{
  content: url(../../images/angldownicon.png);
}


table.returnflow_deducteetrans_innersubtable {
    width: 100%;
}
table.returnflow_deducteetrans_innersubtable tbody {
    display: block;
    max-height: 128px;
    overflow-x: auto;
}

table.returnflow_deducteetrans_innersubtable tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.transactionwiselisting .k-grid-content {
    overflow-y: auto;
}
.listofsalarydetailstb .k-grid-content {
    overflow-y: auto;
}  


.listofsalarydetailstb1 .k-grid-header {
    margin-right: 13px;
}

/*-------- multi-select deductee trans  ---------*/
button.btn.btn-default.nodeductions_btnbgclr {
    border-radius: 0;
    padding: 0.2rem 0rem;
    color: #aaaaaa;
    background-color: #ffffff;
    outline-color: #f2f2f2;
    font-size: 12px;
    font-weight: 500;
    width: 35%;
    border: 1px solid rgb(206, 212, 218);
}
button.btn.btn-default.nodeductions_btnbgclr:hover {
    background-color: #fc7d72;
    color: #fff;
}
button.btn.btn-default.nodeductions_btnbgclr.active {
    background-color: #fc7d72;
    color: #fff;
}
button.btn.btn-default.nodeductions_btnbgclr.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
.btn-group.listoftransactionsboxgp .multi-select {
    max-width: 100%;
    width: 30%;
}
.btn-group.listoftransactionsboxgp .multi-select .select-panel{
    height: auto!important;
}
.btn-group.listoftransactionsboxgp {
    width: 35%;
    float: left;
}
.btn-group.listoftransactionsboxgp .multi-select .dropdown div span.dropdown-heading-value {
    text-align: center;
    color: #fc7d72 !important;
    line-height: 27px !important;   
    min-width: 125px;
    width: 125px;
}
.btn-group.listoftransactionsboxgp .multi-select .dropdown div {
    border-radius: 0 !important;
    font-size: 12px;
    font-weight: 500;
    min-height: calc(1.2em + 0.75rem + 2px) !important;
    height: auto !important;
    
    
}
.btn-group.listoftransactionsboxgp .multi-select .dropdown div ul.select-list li label.select-item {
    padding: 0 5px 2px 7px!important;
    margin: 0;
}
.btn-group.listoftransactionsboxgp .multi-select .dropdown div ul.select-list li span {
    width: 100% !important;
}
/*-------- multi-select deductee trans ---------*/

.k-widget.k-grid.table.table-striped.listofsalarydetailstb.deducteewisetrns_heightfixed {
    & .k-grid-header .k-grid-header-wrap table {
        margin-bottom: -19px !important;
    }
    & .sorting_disabled .k-link{
        cursor: default!important;
    }
}
.k-widget.k-grid.table.table-striped.listofsalarydetailstb.deducteewisetrns_heightfixed {
    & thead tr th {
        background-color: #fff;
    }
}


td.dt_innertable_tdbox_width {
    min-width: 42%;
    width: 42%;
    max-width: 42%;
}
.trbottom_border {
    border-bottom: 2px dashed #a3a3a3;
    position: relative;
    top: -9px;
}


// span.dtname_textfs {
//     font-size: 12px;
//     font-weight: 500;
// }

 /* -------- media code  --------*/
 @media  (max-width: 767px) {
    .modal.editdeducteetrans_modal.show .modal-dialog {
        
        width: 100%;
        height: 100%;
        min-width: 100%;
        max-width: 100%;
    }
    .btn-group.listoftransactionsboxgp {
        width: 86%;
        float: left;
        max-width: 100%;
    }
}

@media  (min-width: 768px) and (max-width: 821px) {
    .modal.editdeducteetrans_modal.show .modal-dialog {
        
        width: 80%;       
    }

    .btn-group.listoftransactionsboxgp {
        width: 86%;
        max-width: 100%;
    }
    td.dt_innertable_tdbox_width {
        min-width: 108%;
        width: 108%;
        max-width: 108%;
    }
    .k-widget.k-grid.table.table-striped.listofsalarydetailstb.deducteewisetrns_heightfixed .k-grid-header .k-grid-header-wrap table {
        margin-bottom: -13rem !important;
    }

}

@media  (min-width: 822px) and (max-width: 913px) {


    .btn-group.listoftransactionsboxgp {
        width: 86%;
        max-width: 100%;
    }
    td.dt_innertable_tdbox_width {
        min-width: 42rem;
        width: 42rem;
        max-width: 42rem;
    }
    .k-widget.k-grid.table.table-striped.listofsalarydetailstb.deducteewisetrns_heightfixed .k-grid-header .k-grid-header-wrap table {
        margin-bottom: -12rem !important;
    }

}

@media  (min-width: 914px) and (max-width: 1025px) {


    .btn-group.listoftransactionsboxgp {
        width: 60%;
        max-width: 100%;
    }
    td.dt_innertable_tdbox_width {
        min-width: 110%;
        width: 110%;
        max-width: 110%;
    }
        .k-widget.k-grid.table.table-striped.listofsalarydetailstb.deducteewisetrns_heightfixed .k-grid-header .k-grid-header-wrap table {
        margin-bottom: -12rem !important;
    }


}
@media  (min-width: 1026px) and (max-width: 1171px) {


    .btn-group.listoftransactionsboxgp {
        width: 60%;
        max-width: 100%;
    }
    td.dt_innertable_tdbox_width {
        min-width: 77%;
        width: 77%;
        max-width: 77%;
    }

}
@media  (min-width: 1172px) and (max-width: 1281px) {


    .btn-group.listoftransactionsboxgp {
        width: 60%;
        max-width: 100%;
    }
    td.dt_innertable_tdbox_width {
        min-width: 62%;
        width: 62%;
        max-width: 62%;
    }

}
@media  (min-width: 1282px) and (max-width: 1399px) {


    .btn-group.listoftransactionsboxgp {
        width: 50%;
        max-width: 100%;
    }
    td.dt_innertable_tdbox_width {
        min-width: 62%;
        width: 62%;
        max-width: 62%;
    }

}
@media  (min-width: 1400px) and (max-width: 1501px) {


    .btn-group.listoftransactionsboxgp {
        width: 50%;
        max-width: 100%;
    }
    td.dt_innertable_tdbox_width {
        min-width: 51%;
        width: 51%;
        max-width: 51%;
    }

}
@media  (min-width: 1502px) and (max-width: 1599px) {


    .btn-group.listoftransactionsboxgp {
        width: 50%;
        max-width: 100%;
    }
    td.dt_innertable_tdbox_width {
        min-width: 41.5%;
        width: 41.5%;
        max-width: 41.5%;
    }

}


@media  (min-width: 1600px) and (max-width: 1749px) {
    td.dt_innertable_tdbox_width {
        min-width: 39%;
        width: 39%;
        max-width: 39%;
    }
}
@media  (min-width: 1750px) and (max-width: 1950px) {
    td.dt_innertable_tdbox_width {
        width: 29%;
    }
}
@media  (min-width: 1951px) and (max-width: 2150px) {
    td.dt_innertable_tdbox_width {
        width: 25.5%;
    }
}
@media  (min-width: 2151px) {
    td.dt_innertable_tdbox_width {
        width: 18.5%;
    }
}

.deductee_transaction_inner_table .k-grid-header {
    display: none;
}
//

//////////////////////////////


//mapped transaction
.mappedtransactionmodal .k-dialog-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 90%;
    height: auto;
    z-index: 11;
    margin: 0px 0px 0px 4rem;
  }
  
  span.k-icon.k-i-more-vertical {
    font-size: 12px;
  }
  
  .modal.mapped_trans_modal.show .modal-dialog {
    margin: 2rem auto 0rem auto;
    max-width: 97%;
  
    & .modal-header.Mismatch_report_header {
      background-color: #eee;
      padding: 0px 15px;
  
  
  
      & button.close {
        top: 4px;
        position: relative;
      }
  
      & button:focus {
        outline: 1px dotted;
        outline: 0px auto -webkit-focus-ring-color;
      }
    }
  }
  
  .k-dialog-wrapper.mapped_trans_modal
    .k-window-titlebar.k-dialog-titlebar.k-header {
    padding: 0px 15px;
    border-bottom: 0px;
    background-color:#2769b5c2 !important;
    color: #000000 !important;
  }
  
  .k-dialog-wrapper.mapped_trans_modal
    .k-window-titlebar.k-dialog-titlebar.k-header
    .k-dialog-title {
    padding: 0px;
    margin: 0px !important;
  }
  
  .k-dialog-wrapper.mapped_trans_modal
    .k-window-titlebar.k-dialog-titlebar.k-header
    .k-dialog-title
    kendo-dialog-titlebar
    h4.modal-title {
    margin: 4px;
    padding: 4px 0px 4px 0px;
    font-size: 1rem;
    font-weight: 500;
  }
  
  .modal-title {
    margin: 10px;
    padding-top: 7px;
    padding-bottom: 8px;
  }
  
  .k-dialog-wrapper.mapped_trans_modal .k-window-content.k-dialog-content {
    padding-top: 0px;
  }
  
  .manualpopuptb .k-grid-content {
    overflow-y: inherit;
  }
  
  button.btn.btn-default.default_btnbgclr {
    background-color: #fc7d72;
    color: #fff;
  }
  
  //other transactions
  .othertransactionmodal .k-dialog-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 90%;
    height: auto;
    z-index: 11;
    margin: 0px 0px 0px 4rem;
  }
  
  span.k-icon.k-i-more-vertical {
    font-size: 12px;
  }
  
  .modal.other_trans_modal.show .modal-dialog {
    margin: 2rem auto 0rem auto;
    max-width: 97%;
  
    & .modal-header.Mismatch_report_header {
      background-color: #eee;
      padding: 0px 15px;
  
      & h4.modal-title {
        font-size: 1rem;
        font-weight: 500;
        padding: 1px 0px 4px 0px;
        width: 100%;
    
        span.dtname_textfs {
            font-size: 12px;
            font-weight: 500;
        }
      }
  
      & button.close {
        top: 4px;
        position: relative;
      }
  
      & button:focus {
        outline: 1px dotted;
        outline: 0px auto -webkit-focus-ring-color;
      }
    }
  }
  
  .k-window-title {
    padding: 0.5em -7px;
    margin: -1.5em 0;
    font-size: 16px;
    line-height: 2.5;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default;
    flex: 1 1;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .k-dialog-wrapper.other_trans_modal
    .k-window-titlebar.k-dialog-titlebar.k-header {
    padding: 7px 15px;
    border-bottom: 0px;
    background-color:#2769b5c2;
    color: #000000 !important;
  }
  
  .k-dialog-wrapper.other_trans_modal .k-window-content.k-dialog-content {
    padding: 10px 0px;
  }
  
  .manualpopuptb .k-grid-content {
    overflow-y: inherit;
  }
  
 
  
 
  
  .k-widget.k-window.windowrapper .k-window-titlebar.k-dialog-titlebar {
    padding: 6px 11px;
    background-color: #31719b;
  }
  
 .innerheader{
    padding:0 12% 0 18%;
    font-size: 16px;
 }
 .tablealignCenter{
    margin: "-17px";
    max-width: "99%";
    display: contents;
 }
 .headerRowTable td {
   font-size: 12px;
    background-color: transparent !important;
    padding: 3px 0px;
 }
 .headerRowTable2 th {
    font-size: 13px;
    
  }
  .deducteesHeaderRow{
    background-color:transparent!important ;
  }

  .windowrapper .k-window-content{
    padding: 6px 6px;
  }

  .k-animation-container {
    z-index: 100000 !important;
  }

  .my-tooltip-with-linebreak {
    text-align: left;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: normal;
}

.newButtonClass{
    box-sizing: border-box; 
    background-color: rgb(255, 255, 255);     
    cursor: pointer; 
    display: table; 
    border-spacing: 0px; 
    border-collapse: separate; 
    height: 28px; 
    outline: none; 
    overflow: hidden; 
    position: relative; 
    width: 100%; 
    border: 1px solid rgb(206, 212, 218);
    font-size: 12px;
    color:rgb(170, 170, 170) ;

    &.active{
        color: white;
        background-color: rgb(255, 125, 114)!important;  
    }
    &.disabled {
        background-color: rgb(226, 223, 223)!important; 
    }
}

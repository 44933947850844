* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", ;
}

.salarary_detail_popup {
    max-height: 750px;

    .fontsize-12 {
        font-size: 12px;
    }

    .input-error {
        border-color: #c93333;
        box-shadow: 0 0 0 .0rem rgba(236, 3, 3, 0.25);
    }

    .input-group.Salary_Tax_Regime_from_editbox {
        margin-bottom: 0px !important;
        border-radius: 3px;
        position: relative;
        top: 3px;
        padding: 0px !important;
        line-height: 0px;

        & input.form-control.Salary_Tax_Regime_from_inputbox {
            font-size: 12px;
            font-weight: 500;
            border: 0px;
            height: 29px;

        }

        & input.form-control.Salary_Tax_Regime_from_inputbox:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
        }

        & .input-group-append.Salary_Tax_Regime_from_iconbox {
            padding: 0px 10px;
            background-color: #fff;
            border: 1px solid #ced4da;

            & span.Salary_Tax_Regime_from_editicon {
                position: relative;
                top: 7px;
                cursor: pointer;
            }
        }
    }


    & span.taxregim_textfs {
        font-size: 14px;
        font-weight: 500;
    }


    /*================ new toggl btn css========================*/
    label.switch.Salary_taxregim_SW {
        position: relative;
        display: inline-block;
        width: 25px;
        height: 11px;
        top: 9px;
        margin-left: 0px;
        margin-right: 8px;

        & input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        & .slider {
            position: absolute;
            cursor: pointer;
            top: 0px;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #cccbcb;
            transition: .4s;
        }

        & .slider:before {
            position: absolute;
            content: "";
            height: 8px;
            width: 8px;
            top: 1.4px;
            border-radius: 50%;
            left: 3px;
            background-color: #31719b;
            transition: 0.4s;
            outline-color: #fff;
        }

        & input:checked+.slider {
            background-color: #cccbcb;
        }

        & input:checked+.slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
            left: -12px;
            background-color: green;
        }

        & .slider.round {
            border-radius: 34px;
        }

        & .slider.round:before {
            border-radius: 50%;
        }
    }

    & span.taxregim_new_textfs {
        font-size: 14px;
        font-weight: 500;
        color: #31719b;
    }

    & span.taxregim_old_textfs {
        font-size: 14px;
        font-weight: 500;
        color: green;
    }

    & button.close {
        position: relative;
        top: 6px;
    }

    & button:focus {
        outline: 1px dotted;
        outline: 0px auto -webkit-focus-ring-color;
    }

    span.input-group.salarytaxreg_daterangegrp {
        font-size: 11px;
        color: rgb(147, 151, 155);
        position: relative;
        margin-bottom: 2px;
        display: inline-flex;
        justify-content: flex-end;

        .k-textbox-container {
            padding-top: 0px;
            width: 9em;

            .k-label {
                display: none;
            }
        }

        & span.salarytaxreg_daterangegrp_textfs {
            top: 0.1rem;
            position: relative;
        }

        & input.salarytaxreg_daterangegrp_input {
            padding: 1px 2px 1px 5px !important;
            border: 1px solid #CFD8DC;
            border-right: 0px !important;
            border-radius: 4px 0px 0px 4px !important;
            box-sizing: border-box;
            background-color: #fff !important;
            color: #fc7d72 !important;
            font-size: 12px !important;
            font-weight: 500;
        }

        & span.input-group-text.salarytaxreg_daterangegrp_calicon {
            background-color: white;
            padding: 2px 5px 2px 1px;
            border-left: 0px !important;
        }

        span.textred {
            color: red;
        }
    }

    span.textred {
        color: red;
    }

    img.cursorpointer {
        cursor: pointer;
    }

    /*========= master collapse tbale tr css =========*/
    tr.hide-table-padding td {
        padding: 0;
    }

    span.salarytaxregime_edit_mastercollapse {
        position: relative;
        top: 3px;
        cursor: pointer;
    }

    .accordion .salarytaxregime_edit_mastercollapse:after {
        cursor: pointer;
        content: url(../../images/anglupicon.png);
    }

    .accordion .salarytaxregime_edit_mastercollapse.collapsed:after {

        content: url(../../images/angldownicon.png);
        cursor: pointer;
    }

    /*========= master collapse tbale tr css =========*/

    /*========= collapse tbale tr css =========*/
    tr.hide-table-padding td {
        padding: 0;
    }

    span.salarytaxregime_edit_collapse {
        position: relative;
        top: 3px;
        cursor: pointer;
        line-height: 1.5;
    }

    .accordion .salarytaxregime_edit_collapse:after {
        cursor: pointer;
        content: url(../../images/accor_mins_icon.png);
    }

    .accordion .salarytaxregime_edit_collapse.collapsed:after {

        content: url(../../images/accor_plus_icon.png);
        cursor: pointer;
    }

    /*========= collapse tbale tr css =========*/


    table.table.table-borderless.Salary_Tax_Regime_from_edittable {
        tr th {
            font-size: 12px;
            text-align: center;
            border-top: 0px;
            font-weight: 500;
            padding-top: 5px;
            padding-bottom: 5px;
            padding: 0.25rem 0.55rem;
        }

        & tr td {
            font-size: 11px;
            font-weight: 500;
            padding: 0.25rem 0.75rem;
            vertical-align: baseline;

            .deleicon_pos {
                margin-left: 6px;
                padding-top: 4px;
                width: 20px;
            }
        }


        input.form-control.salarytaxregime_edit_inputbox {
            font-size: 12px;
            font-weight: 500;
            height: calc(1.2em + .75rem + 2px);
            padding: 0.2rem 0.2rem;


        }

        span.salarytax_otherdetails_Yesbtnbox button.btn.btn-default.salarytax_otherdetails_Yes {
            font-size: 11px;
            padding: 1px 10px 1px;
            background-color: #31719b;
            border-radius: 40px;
            font-weight: 500;
            border: 1px solid #31719b;
            color: #fff;
            outline-color: #31719b;
        }

        span.salarytax_otherdetails_Yesbtnbox button.btn.btn-default.salarytax_otherdetails_no {
            font-size: 11px;
            padding: 1px 10px 1px;
            background-color: #fff;
            border-radius: 40px;
            font-weight: 500;
            border: 1px solid #31719b;
            color: #333;
            outline-color: #31719b;
        }

        & .noneditinputbox_bgclr {
            background-color: #f8f6f6 !important;
            border: 0px;
        }

        input.form-control.salarytaxregime_edit_inputbox:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
        }

        tr.salarytaxregime_edit_alternetbgclrtoprow_grey {
            background-color: #ffefd8 !important;
            border-top: 1px solid #ddd;
        }

        tr.trrow_bgclr_purple {
            background-color: #ebf5e0;
        }

        tr.salarytaxregime_edit_alternetbgclrtoprow_white {
            background-color: #fff !important;
        }

        tr.Grosstax_income_bgclrblue {
            background-color: #31719b;
            color: #fff;
        }
    }

    .card.Salary_Tax_Regime_from_cardbox {
        padding: 5px 5px;
    }

    .staxtextcoral {
        color: #fc7d72 !important;
    }

    ::placeholder {
        color: #cacdcf !important;
    }

    tr.salarytaxregime_edit_rowshadow {
        box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 0 6px 0 rgba(60, 64, 67, .3), 0 1px 3px 0 rgba(60, 64, 67, .15);
        border-bottom: 1px inset rgba(60, 64, 67, .15);
        position: relative;
        background-color: #ededed;
    }

    h4.viewmodetitltextfs {
        font-size: 1rem;
        padding: 6px 0px 0px 0px;
        position: relative;
        top: 2px;
    }

    .salarytaxreg_innertb_bordertop {
        border-top: 1px solid #ddd !important;
    }

    .salarytaxreg_innertb_borderright {
        border-right: 1px solid #ddd !important;
    }

    .salarytaxreg_innertb_borderbottom {
        border-bottom: 1px solid #ddd !important;
    }

    .salarytaxreg_innertb_borderleft {
        border-left: 1px solid #ddd !important;
    }

    .salarytaxreg_innertb_bgclr_lightyell {
        background-color: #fffcf1;
    }

    .card.salary_taxreg_allowance {
        padding: 5px 5px;

        & .form.form-row.salary_taxreg_allowance_form {

            & .form-group {
                margin-bottom: 0.5rem;

                & label.salary_taxreg_allowance_labelfs {
                    font-size: 11px;
                    font-weight: 500;
                }

                & select.custom-select.salary_taxreg_allowance_selbox {
                    font-size: 11px;
                    font-weight: 500;
                }

                & select.custom-select.salary_taxreg_allowance_selbox:focus {
                    border-color: #66afe9;
                    outline: 0;
                    box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
                }



                label.form-check-label.salary_taxreg_allowance_whetherfs {

                    span.WhetamttextVetalig {
                        font-size: 12px;
                        color: #777171;
                        vertical-align: super;
                        position: relative;
                        top: 2px;
                    }

                }





            }
        }
    }

    & button.btn.btn-default.salary_taxreg_allowance_savebtn {
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        padding: 3px 10px 5px 10px;
        background-color: #31719b;
        color: #fff;
        border-radius: 40px;
    }


    input.form-control.salary_taxreg_allowance_inputbox {
        font-size: 11px;
        font-weight: 500;
    }

    input.form-control.salary_taxreg_allowance_inputbox:focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
    }

    nav.salary_taxreg_allowance_nav {
        .nav.nav-tabs.salary_taxreg_allowance_tabs {
            border-bottom: 0px;

            & .nav-item.nav-link.salary_taxreg_allowance_tabmonthyr {
                color: #31719b;
                color: #31719b;
                background-color: #fff;
                outline-color: #31719b !important;
                border-radius: 0;
                padding: 5px 20px 5px !important;
                font-size: 12px;
                font-weight: 500;
                border: 1.5px solid #31719b;
                border-right-width: 1px;
                text-align: center;
                line-height: 15px;
                width: 100px;
            }

            & .nav-item.nav-link.salary_taxreg_allowance_tab_borderleft {
                border-left: 0px !important;
            }

            .nav-item.nav-link.salary_taxreg_allowance_tabmonthyr.active {
                color: #fff;
                background-color: #fc7d72;
            }
        }
    }


    table.table.table-striped.salary_taxreg_allowance_houserent_tb {

        & thead tr th {
            font-size: 12px;
            font-weight: 500;
            border-top: 0px;
            padding-top: 5px;
            padding-bottom: 5px;
            text-align: center;
            vertical-align: baseline;
        }

        & tr td {
            padding: 0.25rem 0.75rem;
            font-weight: 500;
            font-size: 11px;
            vertical-align: baseline;
        }
    }

    .toinperr {
        .toerrorclass {
            border-color: #c93333;
            box-shadow: 0 0 0 .0rem rgba(236, 3, 3, 0.25);
        }
    }

    .frminperr {
        .fromerrorclass {
            border-color: #c93333;
            box-shadow: 0 0 0 .0rem rgba(236, 3, 3, 0.25);
        }
    }

    .overflow-visible {
        overflow: visible;
    }

    .supannuatioheadingcombine {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .supnatdaterangepicker {


        .k-daterangepicker {
            width: 100%;

            .k-daterangepicker-wrap {
                width: 100%;

                .k-textbox-container {
                    padding-top: 0px;
                    width: 48%;

                    .k-widget.k-dateinput {
                        border-radius: 3px;

                        .k-input {
                            height: 28px;
                            font-size: 12px !important;
                        }
                    }

                    .toerrorclass {
                        margin-left: 17px;
                    }

                    .k-label {
                        display: none;
                    }
                }
            }

        }
    } 

    

        .drpdwnlist_changedesign {
    
            .k-dropdown-wrap .k-input {
                color: #afadad;
                background: white;
                font-weight: 600;
                font-size: 12px !important;
            }
    
            &.clrblkmth {
                .k-dropdown-wrap .k-input {
                    color: black;
                }
            }
    
            .k-select {
                display: none;
            }
    
        }

    .salary_taxreg_downloadpdf {
        font-size: 12px;
        font-weight: 500;
        border: 0;
        padding: 0 10px 4px 10px;
        background-color: #31719b;
        color: #fff;
        border-radius: 40px;
        height: 27px;
    }

}

.cursor_default {
    cursor: default !important;

    &.box_shadow_disab {
        box-shadow: none;
    }
}

.overflow_visible {
    overflow: visible;
}
.staxtextblue{
    color: #31719b;
}


fieldset {
    display: block;
    margin: 9px 2px 2px 2px !important;
    background-color: #fff;
    padding: 0.35em 0.75em 0.625em !important;
    border: 2px groove(internal value);
}
legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

fieldset.salaryannux_details_fs {
    border: 1px solid #ddd;

    legend.salaryannux_details_leg {
        width: auto;

        & span.salaryannux_details_allcall {
            padding: 0px 5px;
            position: relative;
            top: -4px;
        }
    }

}
.cursor_pointer{
    cursor: pointer;
}


/*========== start media code ================*/

@media (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 820px) {}

@media (min-width: 821px) and (max-width: 913px) {}

@media (min-width: 914px) and (max-width: 1025px) {}
.createdeductorscss {

    a {
        &.backtonewcl_textclr {
            font-size: 12px;
            padding: 4px 0;
            color: #fc7d72;
            text-decoration: none;
            font-weight: 500;
        }
    }

    .tds_createnew_titlefs {
        h4 {
            font-size: 1.2rem;
            font-weight: 500;
        }
    }

    .card {
        &.tds_createnewclient_cardbox {
            background-color: #fafafa;

            .form-row {
                &.createclientnew_frm {
                    label {
                        font-size: 12px;
                        color: #777171;
                    }

                    span {
                        &.startred {
                            color: red;
                        }
                    }

                    input {
                        &.form-control {
                            height: calc(1.2em + 0.75rem + 2px) !important;
                            padding: 0.2rem 0.75rem;
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }

                    .form-control {
                        &:focus {
                            color: #495057;
                            background-color: #fff;
                            border-color: #80bdff;
                            outline: 0;
                            box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
                        }
                    }

                    button {
                        &.btn {
                            &.btn-default {
                                &.tds_btnbgclr_Proceed {
                                    background-color: #31719b;
                                    border-color: #31719b;
                                    border-radius: 40px;
                                    color: #fff;
                                    padding: 4px 22px 6px 22px;
                                    font-size: 12px;
                                    font-weight: 500;
                                    vertical-align: sub;
                                    border: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .createclientnew_frm {
        input {
            &.form-control {
                height: calc(1.2em + 0.75rem + 2px) !important;
                padding: 0.2rem 0.75rem;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }

    ::-moz-placeholder {
        font-size: 12px;
    }

    ::placeholder {
        font-size: 12px;
    }

    fieldset {
        display: block;
        margin: 9px 2px 2px 2px !important;
        background-color: #fff;
        padding: 0.35em 0.75em 0.625em !important;
        border: 2px groove internal value;

        &.tds_createblukclient_fs {
            border: 1px solid #fc7d72;

            legend {
                &.tds_createblukclient_leg {
                    width: auto;

                    h4 {
                        font-size: 1.2rem;
                        font-weight: 500;
                        padding: 0px 6px;
                    }
                }
            }

            .browseexcel_box {
                padding: 10px 5px;

                span {
                    &.cratemulticlient_textfs {
                        font-size: 12px;
                        font-weight: 500;
                        vertical-align: middle;
                    }
                }

                button {
                    &.btn {
                        &.btn-default {
                            &.browserexcelbtn_bgclr {
                                background-color: #31719b;
                                border-color: #31719b;
                                border-radius: 40px;
                                color: #fff;
                                padding: 4px 15px 6px 15px;
                                font-size: 12px;
                                font-weight: 500;
                                border: 0px;
                                float: right;
                                position: relative;
                                top: 2px;
                            }

                            &.browserexcelbtn_bgclr.autoscanbtn_pdding {
                                padding: 4px 5px;

                                .refrshsync {
                                    color: white !important;
                                }
                            }
                        }
                    }
                }
            }

            .ortextfs {
                h4 {
                    font-size: 1.2rem;
                    font-weight: 500;
                }
            }
        }
    }

    legend {
        display: block;
        padding: 0 5px;
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: -0.4rem;
        font-size: 1.5rem;
        line-height: inherit;
        color: inherit;
        white-space: normal;
    }

    .modal {
        &.fade {
            &.tds_crtnewclient_addaddit_modal {
                &.show {
                    .modal-dialog {
                        margin: 4rem auto 0rem auto;

                        .modal-header {
                            &.crtnewclient_addaddit_header {
                                background-color: #eee;
                                padding: 0px 15px;

                                h4 {
                                    &.modal-title {
                                        font-size: 1rem;
                                        font-weight: 500;
                                        padding: 4px 0px 4px 0px;
                                    }
                                }

                                button {
                                    &.close {
                                        top: 3px;
                                        position: relative;
                                    }
                                }
                            }
                        }

                        h4 {
                            &.crtnewclient_addaddit_titlefs {
                                font-size: 14px;
                                font-weight: 500;
                                text-align: center;
                            }
                        }

                        p {
                            &.crtnewclient_addaddit_subtitlefs {
                                font-size: 12px;
                                color: #837f7f;
                                font-weight: 500;
                                text-align: center;
                            }
                        }

                        button {
                            &.btn {
                                &.btn-default {
                                    &.addclientbtn_bgclr {
                                        background-color: #31719b;
                                        border-color: #31719b;
                                        border-radius: 40px;
                                        color: #fff;
                                        padding: 2px 10px 4px 10px;
                                        font-size: 12px;
                                        font-weight: 500;
                                        border: 0px;
                                    }
                                }
                            }
                        }

                        table {
                            &.table {
                                &.table-striped {
                                    &.crtnewclient_addaddit_tb {
                                        thead {
                                            tr {
                                                th {
                                                    border-top: 0;
                                                    font-size: 12px;
                                                    text-align: center;
                                                    font-weight: 500;
                                                    padding-top: 5px;
                                                    padding-bottom: 5px;
                                                    padding-left: 0px;
                                                }
                                            }
                                        }

                                        tr {
                                            td {
                                                font-size: 11px;
                                                padding: 0.25rem 0.75rem 0.25rem 0.2rem;
                                                font-weight: 500;
                                                vertical-align: baseline;

                                                input {
                                                    &.addclientinputbox_borclr {
                                                        border-color: #80bdff !important;
                                                        border: 1px solid #80bdff !important;
                                                    }

                                                    &.form-control {
                                                        &.mypc_credinputtextfs {
                                                            height: calc(1.2em + 0.75rem + 2px);
                                                            padding: 0.8rem 0.2rem;
                                                            font-size: 11px;
                                                        }
                                                    }

                                                    &.mypc_inputbox_borderrightnon {
                                                        border-right: 0px;
                                                    }
                                                }

                                                .mypc_credinputtextfs {
                                                    &.form-control {
                                                        &:focus {
                                                            color: #495057;
                                                            background-color: #fff;
                                                            border-color: #ced4da;
                                                            outline: 0;
                                                            box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
                                                        }
                                                    }
                                                }

                                                .input-group-prepend {
                                                    &.mypc_updatecredisysicon {
                                                        &.eyeblue_icon {
                                                            padding: 5px 1px 5px 1px;
                                                            border: 1px solid #ced4da;
                                                            border-left: 0px;
                                                            background-color: #fff;
                                                            border-radius: 0px 5px 5px 0px;

                                                            img {
                                                                -o-object-fit: scale-down;
                                                                object-fit: scale-down;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        span {
                                            &.textred {
                                                color: red;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .rotate_icon {
        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;
    }
}

@media (max-width: 767px) {
    .tds_createnewcalist_titlefs h4 {
        right: 0rem;
    }

    .tds_calist_titlefs h4 {
        right: 0rem;
    }
}

@media (min-width: 768px) and (max-width: 821px) {
    .tds_createnewcalist_titlefs h4 {
        right: 5rem;
    }

    .tds_calist_titlefs h4 {
        right: 5rem;
    }
}

@media (min-width: 822px) and (max-width: 913px) {
    .tds_createnewcalist_titlefs h4 {
        right: 5rem;
    }

    .tds_calist_titlefs h4 {
        right: 5rem;
    }
}

@media (min-width: 914px) and (max-width: 1025px) {
    .tds_createnewcalist_titlefs h4 {
        right: 8rem;
    }

    .tds_calist_titlefs h4 {
        right: 5rem;
    }
}

button {
    &.btn {
        &.epxtds_traces_captch_refreshbtn {
            color: #31719b;
            cursor: pointer;
            font-weight: 500;
        }
    }
}

.trace_captcha_img {
    img {
        height: 29px;
    }
}

.btn_refrsh_sync {
    margin: 0px 4px;
    cursor: pointer;
    color: #ffffff !important;
}


/*=======tooltip box=============*/
.usernametoolbox:before {
    content: "";
    background-color: #fff;
    border-left: 1px solid #f1e5e5;
    border-bottom: 1px solid #f1e5e5;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 90px;
    transform: rotate(45deg);
    right: 0px;
    left: -11px;
}

.usernametoolbox {
    position: absolute;
    z-index: 333;
    padding: 17px 10px;
    top: -4.11rem !important;
    right: -10.6rem !important;
    background-color: #fff;
    box-shadow: 2px 2px 3px #fbf6f6;
    border-radius: 2px;
    border: 1px solid #f1e5e5;
    display: block;
}

.pantoolbox:before {
    content: "";
    background-color: #fff;
    border-left: 1px solid #f1e5e5;
    border-bottom: 1px solid #f1e5e5;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 90px;
    transform: rotate(45deg);
    right: 0px;
    left: -11px;
}

.pantoolbox {
    position: absolute;
    z-index: 333;
    padding: 17px 10px;
    top: -4.11rem !important;
    right: -9.6rem !important;
    background-color: #fff;
    box-shadow: 2px 2px 3px #fbf6f6;
    border-radius: 2px;
    border: 1px solid #f1e5e5;
    display: block;
}

.paswordtoolbox:before {
    content: "";
    background-color: #fff;
    border-left: 1px solid #f1e5e5;
    border-bottom: 1px solid #f1e5e5;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 90px;
    transform: rotate(45deg);
    right: 0px;
    left: -11px;
}

.paswordtoolbox {
    position: absolute;
    z-index: 333;
    padding: 8px 10px;
    top: -4.11rem !important;
    right: -11.0rem !important;
    background-color: #fff;
    box-shadow: 2px 2px 3px #fbf6f6;
    border-radius: 2px;
    border: 1px solid #f1e5e5;
    display: block;
}

.passlist {
    padding: 0px 2px 0px 5px;
}

.passlist span {
    font-size: 9px;
    color: black;
}

p.passmust {
    font-size: 13px;
    padding: 0px 4px;
    margin-bottom: 0px;
    color: black;
}

span.passlisticon {
    padding: 0px 6px 0px 0px;
    color: #e7dede;
    font-size: 9px;
}

span.passlisticon:hover {

    color: #fc7d72;
}

p.alsintextsiz {
    font-size: 14px;
}

/*=======tooltip box=============*/
.fade.tracescredentials.modal.show .modal-dialog {
  transform: none;
  width: 350px!important;
  top: 7rem;
  min-height: 400px ;
}

button.tracesbtncancel.btn.btn-primary {
  background-color: #f2f2f2;
  color: #636363;
  border: 1px solid #ddd;
  padding: 5px 15px;
  border-radius: 3px;
  font-size: 12px;
}
button.tracesbtnsubmit.btn.btn-primary {
  background-color: #fc7d72 !important;
  color: #fff;
  border: 1px solid #fc7d72 !important;
  padding: 3px 16px !important;
  border-radius: 40px;
  font-size: 12px;
}
.tracecredfoot.modal-footer {
  padding: 3px 12px;
}
button.btn.btn-link.clickbtnbgclrnone.btn.btn-primary {
  margin: 0rem 0.2rem;
  padding: 0rem;
  font-size: 12px;
  border: none;
  background-color: transparent;
}
.fade.tracescredentials.modal.show .fade.alert.alert-danger.alert-dismissible.show {
  margin: 0rem auto;
  font-size: 13px;
  width: 362px;
}
.input-group-prepend.eyeipbox-create-deductor {
  margin-right: -1px;
  padding: 8px 4px 2px 3px;
  border: 1px solid #ced4da;
  border-radius: 0px .25rem .25rem 0px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #31719b;
}

@media only screen and (max-width: 767px){
  .fade.tracescredentials.modal.show .modal-dialog {
      width: auto;
  }
  .fade.tracescredentials.modal.show .fade.alert.alert-danger.alert-dismissible.show {
      margin: 0rem -4.4rem;
      font-size: 10px;
      width: 300px;
  }
  .fade.tracescredentials.modal.show .fade.alert.alert-danger.alert-dismissible.show button.close {
      top: -0.3rem;
  }
}

.fade.tracescrednewcss.modal.show .modal-dialog .modal-title.h4{
  font-size: 1rem;
  font-weight: 600;
}
.fade.tracescrednewcss.modal.show .modal-dialog p.ptextcreditial{
  font-size: 12px;
}
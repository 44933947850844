.btnStyle {
    border: none;
    outline: none;
    /* padding: 10px 16px; */
    background-color: #f1f1f1;
    cursor: pointer;
    font-size: 18px;
    height: 35px;
    width: 100px;
    padding: 5px;
    margin-bottom: 10px;
  }
  
  /* Style the active class, and buttons on mouse-over */
  .activeBtn, .btnStyle:hover {
    background-color: #666;
    color: white;
  }

  /*KDK HRALLow popup Y&Mbtn css*/
  
  .houserentallpopup button.hrallyearlybtn {
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 10px 5px 10px;
    height: auto;
    border-radius: 0px;
    border-color: #a2a3a4;
    border: 1px solid #a2a3a4;
    background-color: #fc7d72 !important;
    outline-color: #fc7d72 !important;
}
.houserentallpopup button.hrallmonthbtn {
  border-radius: 0;
  color: #31719b;
  background-color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px 5px 10px;
    height: auto;
  border-radius: 0px;
  border: 1px solid #a2a3a4;
  outline-color: #a2a3a4;
}
table.table.table-striped.monthWSDTB.houserenttbinputheight tr td input.form-control {
  height: calc(1em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
}

select.custom-select.slecttextfs {
  font-size: 12px;
}
span.WhetamttextVetalig {
  vertical-align: sub;
}
body {
  background-color: #ffffff !important;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.userexcel_salarydetails_modal.show .modal-dialog {
  margin: 2rem auto auto auto;
  max-width: 1150px !important;

  & table.table.table-striped.userexcel_salalrydetails_monthwise_tb {
    & thead tr th {
      border-top: 0px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      padding-top: 4px;
      padding-bottom: 5px;
    }

    & tr td {
      font-size: 11px;
      font-weight: 500;
      padding: 0.25rem 0.75rem;
    }

    & .cursor_pointer {
      cursor: pointer;
    }

    & td.userexcel_salarydetails_monthwise_textoverflow {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }

    td.userexcel_salarymonthTDS_textoverflow {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
  }
}

h4.modal-title.userexcel_salarydetails_titlebox {
  width: 100%;
  margin-bottom: 0px;
}

h4.userexcel_salarydetails_titlefs {
  font-size: 1rem;
  font-weight: 500;
  padding: 18px 0px 0px 0px;
  margin-bottom: 0px;
}

button.btn.btn-default.userexcel_salalrydetails_monthwise_textfs {
  font-size: 1rem;
  font-weight: 500;
  color: #31719b;
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  top: 6px;
}

button.close {
  position: relative;
  top: 3px;
}

button.close:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.userexcel_salarydetailsTDS_widthmodal .modal-dialog {
  max-width: 1490px !important;
}

.userexcel_salalrydetails_monthwise_tb .k-grid-content{
  overflow-y: auto;
}

.k-widget.k-window.windowWrapper.userexcel_salarydetails_modal.userexcel_salarydetailsTDS_widthmodal .k-window-titlebar.k-dialog-titlebar{
  background-color: #d3d3d3;
}

.sorting_disabled .k-cell-inner .k-link .k-column-title {
  cursor: default !important;
}

.k-grid-content {
  &.k-virtual-content {
      table {
          &.k-grid-table {
              .Return_register_overflowtext {
                  max-width: 100px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-wrap: break-word;
              }

              .textclr_blue {
                  color: #31719b;
              }
          }
      }
  }
}
.modal-backdrop:has(~ .tdsimport_inprogress) {
    display: none !important;
}

.modal-open .tdsimport_inprogress.modal {
    width: 0px;
    margin: 0 auto;
}

.modal.tdsimport_inprogress.show .modal-dialog {
    position: fixed;
    bottom: 20px;
    right: 20px;
    margin: 0px;
    box-shadow: 0 0 10px 5px rgb(54 53 53 / 31%);
}


#loading-bar-spinner.spinner {
    left: 200px;
    margin-left: 0px;
    top: 0;
    margin-top: 2px;
    position: absolute;
    z-index: 19 !important;
    animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
    width: 25px;
    height: 25px;
    border: solid 3px transparent;
    border-top-color: #fc7d72 !important;
    border-left-color: #fc7d72 !important;
    border-radius: 50%;
}

@keyframes loading-bar-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.err-dld-btn {
    color: #fff;
    border: none;
    border-radius: 40px;
    font-size: 12px;
    background-color: #31719b;
    padding: 7px 20px;
    cursor: pointer;
    margin: 10px auto;
    width: auto;
}
.disabled{
    opacity: 0.7;
}

body:has(.tdsimport_inprogress.show) {
    overflow: auto !important;
}
/*edit dedcutor details box css*/
fieldset.fieleidtddsect {
    border: 1px solid #31719b;
}
legend.legenteidtddsect {
    width: 0%;
}

p.editdedtdetails {
    margin-bottom: 0.1em;
    margin-top: 0.1em;
}
.editdedtortopbox p.editdedtdetails {
    margin-bottom: 0rem;
    font-weight: 500;
}


fieldset.ovfsdsborblue {
    border: 1px solid #fc7d72;
}
legend.ovlengentds.overflowsect {
    width: 20%;
}
.corroverviewinndev {
    display: block;
}
.corroverviewlefts {
    float: left;
    padding: 0 5px;
}
.corroverviewlefts h4 {
    font-size: 1.2rem;
}
.corroverviewinner {
    height: 1px;
    width: 100%;
    position: relative;
}
.corroverviewright {
    float: right;
    padding: 0 5px;
}
.corroverviewright h4 {
    font-size: 0.9rem;
}
.corroverviewtopbor {
    border-top: 1px solid #fc7d72;
    display: -webkit-flex;
    display: flex;
    position: relative;
    margin-top: 19px;
}

table.table.table-striped.table-responsive.ovtbpanno thead tr th {
    font-size: 13.5px;
}
table.table.table-striped.table-responsive.ovtbpanno tr td {
    font-size: 12px;
}

fieldset.cusummfsdsbor {
    border: 1px solid #31719b;
}
legend.cusummlengentds {
    width: 14rem;
}
legend.cusummlengentds h4 {
    font-size: 1.2rem;
}
table.table.table-striped.table-responsive.currectsumm thead tr th {
    font-size: 12px;
    font-weight: 500;
}
table.table.table-striped.table-responsive.ovtbpanno thead tr th {
    font-size: 12px;
    font-weight: 500;
}
table.table.table-striped.table-responsive.currectsumm tr td {
    font-size: 12px;
}
fieldset.quicklinkfieldsetclr {
    border: 1px solid #fc7d72;
}
legend.quicklinklengent {
    width: 8rem;
}
legend.quicklinklengent h4 {
    font-size: 1.2rem;
}

.quickslinnksect {
    font-size: 13px;
}

fieldset.challamtfieldsetclr {
    border: 1px solid #31719b;
}
legend.challamtlengent {
    width: 11rem;
}
legend.challamtlengent h4 {
    font-size: 1.2rem;
}
.totalamttextrdchallamt {
    font-style: italic;
    font-size: 15px;
    padding: 0px 0px;
    text-align: left;
}
span.col-md-6.challanamt {
    position: relative;
    top: 0px;
    color: #f90;
    font-style: italic;
    padding: 0px 21px;
    font-size: 18px;
}
fieldset.sectionsummwaisef {
    border: 1px solid #31719b;
}
legend.sectionsummwaiseleg {
    width: 20rem;
}
legend.sectionsummwaiseleg h4 {
    font-size: 1.2rem;
}
fieldset.topfdedfel {
    border: 1px solid #fc7d72;
}
legend.topfdedleg {
    width: 100%;
    padding: 0;
}
.topfdedinndev {
    display: block;
}
.topfdedtilefts {
    float: left;
    padding: 0 5px;
}
.topfdedtilefts h4 {
    font-size: 1.2rem;
}
.topfdedinner {
    height: 1px;
    width: 100%;
    position: relative;
}
.topfdedright {
    float: right;
    padding: 0 5px;
}
.bortoplg.topfdedbor {
    display: flex;
    position: relative;
    border-top: 1px solid #fc7d72;
}
.topfdedright h4 {
    font-size: 1.2rem;
}
span.float-right.topfdedfsize {
    font-size: 14px;
}
/*end edit dedcutor details box css*/

/*========= start media code ==========*/
@media (min-width: 320px) and (max-width: 767px){
    span.float-right.topfdedfsize {
        font-size: 9px;
    }
}
@media (min-width: 768px) and (max-width: 941px){
    span.float-right.topfdedfsize {
        font-size: 11px;
    }
    legend.sectionsummwaiseleg h4 {
        font-size: 0.9rem;
    }
    .topfdedtilefts h4 {
        font-size: 0.8rem;
    }
}
@media (min-width: 942px) and (max-width: 1024px){
    span.float-right.topfdedfsize {
        font-size: 12px;
    }
    .topfdedtilefts h4 {
        font-size: 1.2rem;
    }
    legend.sectionsummwaiseleg h4 {
        font-size: 1.2rem;
    }
}
.k-widget.k-window.windowConfirmWrapper {
    
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

   
    button.btn.btn-default.yes_btnbgclr_fs {
        text-align: right;
        font-size: 12px;
        font-weight: 500;
        background-color: #31719b;
        color: #fff;
        border-radius: 40px;
        padding: 4px 20px 6px 20px;
    } 

    .k-window-title{
        cursor: move;
    }
    
    // .k-window-titlebar {
    //     display: none;
    //   }
    
}

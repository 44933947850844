body {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.fuvgeneration_tdsuser {

    .container{
        max-width: 1325px;
    }

    button.btn.btn-default.efiling_flow_generatefvuit_btnbgle {
        background-color: #31719b;
        color: #fff;
        border: 0px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 40px;
        padding: 3px 10px 5px 10px;
        top: -5px;
        position: relative;
    }

    button.btn.btn-default.efiling_flow_generatefvuit_greybgclr {
        background-color: #31719b;
        color: #fff;
        border: 0px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 40px;
        padding: 3px 10px 5px 10px;
        top: -5px;
        position: relative;
        width: 160px;
        max-width: 100%;
    }

    fieldset.showstpfound_fs {
        border: 1px solid #fc7d72;
        margin-top: 0.7rem !important;

        & legend.showstpfound_leg {
            width: auto;

            & h4 {
                font-size: 1rem;
                font-weight: 500;
                padding: 3px 5px 5px 5px;
                position: relative;
                top: -2px;
                margin-bottom: 0px;
            }
        }

        .showstpfound_mediabox {
            display: inline-flex;

            & .media.showstpfound_mediabox {
                padding-right: 10px;

                & .showstpfound_mediabox_rightborder {
                    border-right: 1px solid #ddd;
                }

                & .showstpfound_roundborderbox {
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    padding: 5px 5px 5px 5px;
                    max-width: 100%;
                    width: auto;
                    vertical-align: middle;
                    height: auto;
                    min-height: 64px;
                    max-height: 100%;
                    min-width: 67px;
                    width: 67px;
                    max-width: 100%;
                    text-align: center;
                    box-shadow: 0px 1px 3px #ddd;

                    & .showstpfound_notextfs {
                        font-size: 13px;
                        font-weight: 700;
                        position: relative;
                        width: 57px;
                        white-space: inherit;
                        overflow: hidden;
                        word-wrap: break-word;
                        vertical-align: middle !important;
                        top: 1rem;
                        color: #fc7d72;
                    }
                }

                & .showstpfound_textbox {
                    cursor: pointer;

                    & h4.showstpfound_titlefs {
                        font-size: 13px;
                        font-weight: 700;
                        color: #fc7d72;
                        text-align: center;
                    }

                    p.challanverify_textclr_coral {
                        color: #fc7d72;
                    }
                }
            }

            & .media.showstpfound_mediabox_rightborder {
                border-right: 1px solid #ddd;
            }

            & .media.showstpfound_mediabox_leftpadd {
                padding-left: 10px;
            }
        }
    }


    fieldset.efiling_flow_returngentst_fs {
        border: 1px solid #34739c;

        legend.efiling_flow_returngentst_leg {
            width: auto;
            
            h4 {
                font-size: 1rem;
                font-weight: 500 !important;
                padding: 3px 5px 5px 5px !important;
                position: relative;
                top: -2px;
                margin-bottom: 0px;
            }

        }


        & button.btn.btn-default.efiling_flow_fetchingCSI_btn {
            border: 0px solid #ddd;
            border-radius: 0px;
            padding: 6px 30px 6px 30px;
            font-size: 13px;
            font-weight: 500;
            color: #31719b;
        }

        & button.btn.btn-default.efiling_flow_fetchingCSI_btn:focus {
            outline: 0;
            box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
        }

        & .efiling_flow_fetchingCSI_checkedicon {
            padding: 5px 5px 5px 5px;
            background-color: rgba(243, 242, 242, 0.4509803922);
            border: 1px solid #ddd;

            & span.efiling_flow_fetchingCSI_checked_padd {
                text-align: center;
            }
        }

        & .efiling_flow_fetchingCSI_checkedicon:focus {
            outline: 1px dotted;
            outline: 0px auto -webkit-focus-ring-color;
        }

    }



    fieldset.efiling_flow_downloaderror_fs {
        border: 1px solid #31719b;

        & legend.efiling_flow_downloaderror_leg {
            width: auto;

            & h4 {
                font-size: 1rem;
                font-weight: 500;
                padding: 3px 5px 5px 5px;
                position: relative;
                top: -2px;
                margin-bottom: 0px;
            }

            span.reviewfix_errorcount {
                color: #2296f3;
            }
        }

        table.table.table-borderless.comp_health_review_checkerrors_tb {
            tr td {
                font-size: 11px;
                font-weight: 500;
                padding: 0.25rem 0.75rem;
            }

            td.text_red {
                color: red;
            }
        }

        table.table.table-borderless.comp_health_review_checkerrors_tb {
            width: 100%;
        }

        table.table.table-borderless.comp_health_review_checkerrors_tb tbody {
            display: block;
            max-height: 300px;
            overflow-x: auto;
            // height: 300px;
        }

        table.table.table-borderless.comp_health_review_checkerrors_tb thead tr {
            display: table;
            width: 100%;
            table-layout: fixed;
        }

        table.table.table-borderless.comp_health_review_checkerrors_tb tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed;
        }
    }


    /*------ vertical timeline --------*/

    .efilingFlow_step_timelinebox {
        padding-left: 32px;
        padding-top: 29px;


        & ul.efilingFlow_step_timeline_list {
            position: relative;
            padding: 0px 0px;

            & li {
                color: #b5b5b5;
                list-style: none;
                font-size: 12px;
                margin-bottom: 8rem;
                font-weight: 500;

                & span.efiling_verticallinebor {
                    content: " ";
                    background: #d4d9df;
                    position: absolute;
                    width: 1.5px;
                    border-color: #d4d9df;
                    height: 38%;
                    margin-top: 30px;
                    right: 1rem;

                }
            }
        }
    }

    .eflingflow_rightbox_padd_st02 {
        padding-top: 26px;
    }

    .eflingflow_rightbox_padd_st03 {
        padding-top: 10px;
    }


    /*---------- media code  ------------*/

    @media (max-width: 767px) {

        fieldset.showstpfound_fs .showstpfound_mediabox {
            display: block;
        }

        fieldset.showstpfound_fs .showstpfound_mediabox .media.showstpfound_mediabox_rightborder {
            border-right: 0px solid #ddd;
        }

        fieldset.likeydefault_found_fs .challanverify_contentbox.likelydefaultboxinline {
            display: block;
        }

        .media.media.likelydefaults_mediabox_rightborder {
            border-right: 0px solid #ddd;
        }

    }


    @media (min-width: 768px) and (max-width: 821px) {

        fieldset.showstpfound_fs .showstpfound_mediabox {
            display: inline-flex;
        }

        fieldset.showstpfound_fs .showstpfound_mediabox .media.showstpfound_mediabox_rightborder {
            border-right: 1px solid #ddd;
        }

        fieldset.showstpfound_fs .showstpfound_mediabox .media.showstpfound_mediabox .showstpfound_textbox {
            cursor: pointer;
            padding-top: 3px;
            padding-right: 0px;
        }

        fieldset.showstpfound_fs .showstpfound_mediabox .media.showstpfound_mediabox .showstpfound_textbox h4.showstpfound_titlefs {
            font-size: 1rem;
            font-weight: 500;
            text-align: left;
            border: 1px solid #ddd;
            padding: 5px 5px 6px 5px;
            box-shadow: 0px 1px 3px #ddd;
            color: #fc7d72;
        }

        fieldset.likeydefault_found_fs .challanverify_contentbox.likelydefaultboxinline {
            display: inline-flex;
            overflow-y: auto;
            width: 100%;
        }

        .media.media.likelydefaults_mediabox_rightborder {
            border-right: 1px solid #ddd;
        }





    }


    @media (min-width: 822px) and (max-width: 913px) {

        fieldset.showstpfound_fs .showstpfound_mediabox {
            display: inline-flex;
        }

        fieldset.showstpfound_fs .showstpfound_mediabox .media.showstpfound_mediabox_rightborder {
            border-right: 1px solid #ddd;
        }

        fieldset.showstpfound_fs .showstpfound_mediabox .media.showstpfound_mediabox .showstpfound_textbox {
            cursor: pointer;
            padding-top: 3px;
            padding-right: 0px;
        }

        fieldset.showstpfound_fs .showstpfound_mediabox .media.showstpfound_mediabox .showstpfound_textbox h4.showstpfound_titlefs {
            font-size: 1rem;
            font-weight: 500;
            text-align: left;
            border: 1px solid #ddd;
            padding: 5px 5px 6px 5px;
            box-shadow: 0px 1px 3px #ddd;
            color: #fc7d72;
        }

        fieldset.likeydefault_found_fs .challanverify_contentbox.likelydefaultboxinline {
            display: inline-flex;
            overflow-y: auto;
            width: 100%;
        }

        .media.media.likelydefaults_mediabox_rightborder {
            border-right: 1px solid #ddd;
        }



    }

    @media (min-width: 1024px) and (max-width: 1170px) {


        fieldset.likeydefault_found_fs .challanverify_contentbox.likelydefaultboxinline {
            display: inline-flex;
            overflow-y: auto;
            width: 100%;
        }

        .efiling_flow_generatefvuit_btnbox {
            padding-top: 2rem !important;
        }
    }

    @media (min-width: 1171px) and (max-width: 1281px) {


        fieldset.likeydefault_found_fs .challanverify_contentbox.likelydefaultboxinline .media.media.challanverify_mediabox {
            padding-right: 10px;
            min-width: 21%;
            width: 21%;
            max-width: 21%;
        }
    }

    @media (min-width: 1282px) and (max-width: 1441px) {


        fieldset.likeydefault_found_fs .challanverify_contentbox.likelydefaultboxinline .media.media.challanverify_mediabox {
            width: 23%;
        }

    }

    .founderror_tableshow {
        display: none;
    }



    span.efilingflow_textclr_coral {
        color: #fc7d72 !important;
        line-height: 2.7;
    }

    span.efilingflow_textclr_coral a {
        color: #fc7d72 !important;
    }

    .efiling_flow_generatefvuit_btnbox {
        padding-top: 3rem;
    }





    /*-------  old css -----------------*/

    fieldset.authsing_fs {
        border: 1px solid #fc7d72;

        & legend.authsing_leg {
            width: auto;

            & h4 {
                font-size: 1rem;
                font-weight: 500;
                padding: 3px 5px 5px 5px;
                position: relative;
                top: -2px;
                margin-bottom: 0px;
            }
        }

        .form-check.authsing_radiobox {
            font-size: 12px;
            font-weight: 500;
            padding-left: 0px;
            line-height: 19px;
            color: #31719b;

            & a {
                color: #31719b;
                text-decoration: none;
                background-color: transparent;
            }

        }

    }



    fieldset.whetherreguler_fs {
        border: 1px solid #31719b;

        & legend.whetherreguler_leg {
            width: auto;

            & h4 {
                font-size: 1rem;
                font-weight: 500;
                padding: 3px 5px 5px 5px;
                position: relative;
                top: -2px;
                margin-bottom: 0px;
            }

            & h4 img {
                text-align: right;
                cursor: pointer;
                top: -3px;
                position: relative;
            }
        }

        span.whetherreguler_textleftfs {
            font-size: 11px;
            font-weight: 500;
            color: #31719b;
        }


        span.whetherreguler_textright {
            float: right;
            font-size: 12px;
            font-weight: 700;
            top: 3px;
            position: relative;

            & a {
                color: #31719b;
                text-decoration: none;
                background-color: transparent;
            }
        }

        p.entertokenno_fs {
            font-size: 11px;
            font-weight: 500;

            & input.entertokenno_inputbox {
                height: calc(1.2em + 0.75rem + 2px);
                padding: 0.375rem 0.75rem;
                max-width: 50%;
            }

            & input.entertokenno_inputbox:focus {
                color: #495057;
                background-color: #fff;
                border-color: #80bdff;
                outline: 0;
                box-shadow: 0 0 0 0.0rem rgba(0, 123, 255, .25);
            }
        }

    }


    button.btn.btn-default.validup_proforef_btn {
        background-color: #31719b;
        color: #fff;
        padding: 3px 15px 5px 15px;
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        border-radius: 40px;
    }


    fieldset.efilingstatus_fs {
        border: 1px solid #ddd;

        & legend.efilingstatus_leg {
            width: auto;

            & h4 {
                font-size: 1rem;
                font-weight: 500;
                padding: 3px 5px 5px 5px;
                position: relative;
                top: -2px;
                margin-bottom: 0px;
            }
        }

        & p.efilingstatus_textfs {
            font-size: 12px;
            font-weight: 500;
        }


    }

    input.form-control.entertokenno_inputbox {
        font-size: 11px;
        font-weight: 500;
        padding: 0px 5px;
        height: calc(1.2em + 0.75rem + 2px);
        opacity: 1;
        color: black;
        text-align: center;
    }

    ::placeholder {
        color: #ddd !important;

    }

    table.table.table-borderless.whetherreguler_tb tr td {
        font-size: 11px;
        font-weight: 500;
        padding: 0.25rem 0.3rem;
    }

    fieldset {
        display: block;
        margin: 9px 2px 2px !important;
        background-color: #fff;
        padding: 0.35em 0.75em 0.625em !important;
        border: 2px groove value;
    }

    legend {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: .5rem;
        font-size: 1.5rem;
        line-height: inherit;
        color: inherit;
        white-space: normal;
    }

    .cursor_pointer{
       cursor: pointer;
    }

    .media.media.likelydefaults_mediabox_rightborder {
        border-right: 1px solid #ddd;
    }


    fieldset.likeydefault_found_fs {
        border: 1px solid #fc7d72;

        legend.likeydefault_found_leg {
            width: auto;
        }

        legend.likeydefault_found_leg h4 {
            font-size: 1rem;
            font-weight: 500;
            padding: 3px 5px 0px 5px;
            position: relative;
            top: -2px;
            margin-bottom: 0px;
        }


        /*----- fvu gen. challan verifid   -------*/
        .challanverify_contentbox.likelydefaultboxinline {
            display: flex;

            & .media.media.challanverify_mediabox {
                // padding-right: 10px;
                // min-width: 29%;
                // width: 29%;
                // max-width: 29%;

                padding-right: 10px;
                min-width: 20%;
                width: 20%;
                max-width: 20%;

            }

            & .media.media.challanverify_conditional_mediabox {
                padding-right: 10px;
                min-width: 25% !important;
                width: 25% !important;
                max-width: 25% !important;
            }
        }

        /* .challanverify_contentbox {
        position: relative;
        height: 300px;
        min-height: 300px;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      } */
         
        .challanverify_mediabox {
            // cursor: pointer;
            text-align: center;
        }

        .challanverify_roundborderbox {
            border: 1px solid #ddd;
            border-radius: 50%;
            padding: 5px 5px 5px 5px;
            max-width: 100%;
            width: auto;
            vertical-align: middle;
            height: auto;
            min-height: 64px;
            max-height: 100%;
            min-width: 67px;
            width: 67px;
            max-width: 100%;
            text-align: center;
            box-shadow: 0px 1px 3px #ddd;
        }

        .smallno_textfs {
            font-size: 13px;
            font-weight: 700;
            position: relative;
            width: 57px;
            white-space: inherit;
            overflow: hidden;
            word-wrap: break-word;
            vertical-align: middle !important;
            top: 1rem;
        }

        .challanverify_textclr_red {
            color: #512da8;
        }

        .challanverify_textclr_coral {
            color: #2296f3;
        }

        .challanverify_textclr_blue {
            color: #22f333;
        }

        .challanverify_textclr_yellow {
            color: #009588;
        }

        .challanverify_textclr_orange {
            color: #fc7d72;
        }

        .backface_panstatus_card.card {
            padding: 10px 10px;
            cursor: pointer;
        }

        .likelydefault_textbox h4.likely_titlefs {
            font-size: 13px;
            font-weight: 700;
            text-align: center;
        }

        .likelydefault_textbox p.likely_textfs {
            font-size: 11px;
            font-weight: 500;
            color: #31719b;
        }



        p.challanverify_pan_unverufy_textfs {
            font-size: 12px;
            font-weight: 500;
            color: red;
        }

        p.challanverify_comp_unverufy_textfs {
            font-size: 12px;
            font-weight: 500;
            color: green;
        }

        p.challanverify_challan_textfs {
            font-size: 12px;
            font-weight: 500;
            color: #31719b;
        }

        p.challanverify_latefeesdef_textfs {
            font-size: 12px;
            font-weight: 500;
            color: green;
        }

    }

    p.likelydefault_nobox_fs {
        font-size: 14px;
        text-align: center;
        margin-bottom: 0rem !important;
    }

    hr.likelydefault_bottomborder {
        border-color: #009588;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    /*mediqa code */

    @media (max-width: 767px) {
        fieldset.likeydefault_found_fs .challanverify_contentbox.likelydefaultboxinline .media.media.challanverify_mediabox {
            padding-right: 10px;
            min-width: 100%;
            width: 100%;
            max-width: 100%;
        }

        fieldset.showstpfound_fs .showstpfound_mediabox .media.showstpfound_mediabox_leftpadd {
            padding-left: 0px !important;
        }
    }

    @media (min-width: 768px) and (max-width: 813px) {
        fieldset.likeydefault_found_fs .challanverify_contentbox.likelydefaultboxinline .media.media.challanverify_mediabox {
            padding-right: 10px;
            min-width: 40%;
            width: 40%;
            max-width: 40%;
        }
    }

    @media (min-width: 814px) and (max-width: 913px) {
        fieldset.likeydefault_found_fs .challanverify_contentbox.likelydefaultboxinline .media.media.challanverify_mediabox {
            padding-right: 10px;
            min-width: 40%;
            width: 40%;
            max-width: 40%;
        }
    }



}
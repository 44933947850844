//@use '../../../base';

.main-computation-head {
  a.expit_computation_backtoclr {
    color: #fc7d72;
    font-size: 12px;
    padding: 4px 0;
    font-weight: 500;
  }

  h4.exprit_compofincometitlefs {
    font-size: 1.2rem;
    text-align: center;
    // left: 3rem;
    position: relative;
  }

  .new_reg_button_class {
    background-color: #31719b;
    color: #fff;
    border: 1px solid #31719b;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
    padding: 0px 10px 0px 10px;
    display: inline-block;
  }

  .com-right-tax {
    float: right;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .new_reg_class {
    font-size: 11px;
    margin-right: 10px;
  }

  label.switch.expit_clmast_individual_homeadd_swbtn {
    margin-bottom: -0.4rem;
  }

  .switch.expit_clmast_individual_homeadd_swbtn {
    position: relative;
    display: inline-block;
    top: 0.5rem;
    width: 23px !important;
    height: 12px !important;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  label.switch.expit_clmast_individual_homeadd_swbtn {
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #cccbcb;
      transition: .4s;

      &:before {
        position: absolute;
        content: "";
        height: 7.3px;
        width: 3px;
        top: 2px;
        left: 3px;
        background-color: rgb(242 242 242);
        transition: .4s;
        outline-color: rgb(242 242 242);
      }
    }

    input:checked+.slider {
      background-color: #31719b;

      &:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
        left: -9px;
      }
    }

    .slider.round {
      border-radius: 34px;

      &:before {
        border-radius: 16%;
      }
    }
  }



  .ui {
    &.selection.dropdown.expit_clmast_individual_selboxfs.semantic_drop_input.input-error {
      border: 1px solid #fc6a6a !important;
      box-shadow: 0px 1px 8px 0px #ff4545 !important;
    }

    &.search.selection.dropdown>input.search {
      padding: 0.1em 2.1em 0.67857143em 1em !important;
    }

    &.selection.dropdown {
      width: 100% !important;
      min-height: auto !important;
      min-width: auto !important;
      height: unset;
      padding: 0rem 0.15rem !important;
      line-height: 1.5 !important;
      font-size: 11px !important;

      /* border: 1px solid #c9c9c9 !important;
  border-radius: 0.15rem !important; */
      /* display: flex!important; 
      @include base.fontFamily();*/
      justify-content: center;
      align-items: center;
      color: rgb(0 0 0) !important;
      height: calc(1.2em + 0.75rem + 0px);
    }

    &.dropdown>.text {
      display: inline-block;
      transition: none;
      font-size: 11px !important;
      top: calc(50% - 8px) !important;
    }

    &.selection.dropdown> {

      .delete.icon,
      .dropdown.icon,
      .search.icon {
        font-size: 11px;
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
        padding: 0 0.25em;
        height: inherit;
      }
    }

    &.search.dropdown>input.search.input-error {
      border: 1px solid #fc6a6a !important;
      box-shadow: 0 1px 8px 0 #ff4545 !important;
    }
  }


  .ui.selection.dropdown .menu>.item {
    font-size: 11px;
    line-height: 0.5;
    padding: 0.75rem 0.5rem !important;
  }









}


.main-computation {




  .container.expit_comp_contbox_leftsidepadd {
    padding-left: 5rem;
  }

  p.expit_comp_personalinfo_fs {
    background-color: #ffefd8;
    padding: 7px 10px 7px 10px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
  }

  form.form-row.expit_comp_personalinfo_frmfs .form-group {
    margin-bottom: 0px;
  }

  label.expit_comp_personalinfo_lable_inpboxfs {
    font-size: 12px;
    font-weight: 500;
  }

  form.form-row.expit_comp_personalinfo_frmfs label {
    margin-bottom: 0.2rem;
  }

  label {
    &.expit_comp_personalinfo_lablefs {
      font-size: 12px;
      font-weight: 500;
      padding-left: 10px !important;
      color: #777171;
    }

    &.expit_comp_personalinfo_frmfslabw {
      margin-right: 4.7rem;
      margin-left: 0px;
    }
  }

  p.expit_comp_personalinfo_nankdet_textfs {
    padding: 7px 0px 7px 10px;
    font-size: 12px;
    font-weight: 700;
  }

  .expit_comp_personalinfo_salaryboxbgclr {
    background-color: #ffefd8;
    padding: 7px 10px 7px 10px !important;
    text-transform: uppercase;
    font-weight: 700 !important;

    td {
      font-size: 14px !important;
      font-weight: 700 !important;
    }
  }

  .expit_comp_personalinfo_tataconsultboxbgclr td {
    padding: 6px 10px 8px 10px !important;
    text-transform: capitalize;
    font-weight: 700 !important;
  }

  .expit_comp_personalinfo_salaryfs {
    font-size: 14px;
  }

  .expit_comp_personalinfo_textunderline {
    text-decoration: underline;
  }

  table.table.table-borderless.expit_comp_personalinfo_salaryincome_tb tr td {
    font-size: 12px;
    font-weight: 500;
    padding: 0.1rem 0.75rem;
    vertical-align: inherit !important;
  }

  tr {
    &.grossincome_textclr td {
      color: #212529 !important;
    }

    &.expit_comp_personalinfo_salarytotle_shadow {
      box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 0 6px 0 rgb(60 64 67 / 30%), 0 1px 3px 0 rgb(60 64 67 / 15%);
      border-bottom: 1px inset rgba(60, 64, 67, 0.15);
      position: relative;

      td {
        font-weight: 700 !important;
        color: #31719b;
        background-color: #ebf5e0;
      }
    }

    &.expit_comp_personalinfo_textbold td,
    &.expit_comp_personalinfo_salarytotle_bold td {
      font-weight: 700 !important;
    }
  }

  td.expit_comp_personalinfo_2linevertalign {
    vertical-align: inherit !important;
  }

  table.table.table-borderless.expit_comp_exemptincome_tb_tb {
    thead tr th {
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      padding-top: 5px;
      padding-bottom: 5px;
      vertical-align: middle;
    }

    tr td {
      font-size: 12px;
      font-weight: 500;
      padding: 0.25rem 0.75rem;
    }
  }

  .expit_comp_personalinfo_textbold {
    font-weight: 700 !important;
  }

  a.matundlineremove:hover {
    color: #0056b3;
    text-decoration: none;
  }








  /*========= main outer  row collapse tbale tr css =========*/

  .expand-button.comp_annex_collapse {
    position: relative;
  }

  .accordion-toggle.expand-button.comp_annex_collapse:after {
    position: absolute;
    left: 0.2rem;
    top: 60%;
    transform: translate(0, -50%);
    // content: url('../../../images/anglupicon.png');
    cursor: pointer;
  }

  .accordion-toggle.collapsed.expand-button.comp_annex_collapse:after {
    // content: url('../../../images/angldownicon.png');
  }

  /*========= main outer  collapse tbale tr css =========*/


  /*========= inner row collapse tbale tr css =========*/

  .expand-button.expressit_comp_annex_innerrowcollapse {
    position: relative;
  }

  .accordion-toggle.expand-button.expressit_comp_annex_innerrowcollapse:after {
    position: absolute;
    left: 0.2rem;
    top: 60%;
    transform: translate(0, -50%);
    // content: url('../../../images/anglupicon.png');
    cursor: pointer;
  }

  .accordion-toggle.collapsed.expand-button.expressit_comp_annex_innerrowcollapse:after {
    // content: url('../../../images/angldownicon.png');
  }

  /*========= collapse inner tbale tr css =========*/

  tr.expressit_comp_annex_bgclr_lightgrey {
    background-color: #f7fcff;
  }

  tr.expressit_comp_annex_borderrow {
    border: 1px solid #d2d2d2;
  }

  tr.expit_comp_exemptincome_bgclr_lightyell td {
    background-color: #fffbe9;
  }

  tr.expit_comp_exemptincome_bgclr_white td {
    background-color: #fff;
  }

  tr.expit_comp_exemptincome_toptr_bordernone th {
    border-bottom: 0px !important;
  }

  tr.expit_comp_exemptincome_bgclr_lightyell {
    background-color: #fffcf1;
  }

  /* end computation new css add */

  .text_black{
    color: #000000;
  }









  /*========== start Media code ===============*/
  @media (max-width: 767px) {
    label.expit_comp_personalinfo_frmfslabw {
      max-width: 100%;
      margin-right: 0rem;
      margin-left: 0px;
    }

    input.form-control.expit_comp_personalinfo_frminputw {
      max-width: 100%;
    }

    h4.exprit_compofincometitlefs {
      padding-right: 0rem;
    }
  }

  @media (min-width: 768px) and (max-width: 820px) {
    label.expit_comp_personalinfo_frmfslabw {
      margin-right: 1.5rem;
      margin-left: 0px;
    }

    input.form-control.expit_comp_personalinfo_frminputw {
      width: 100%;
    }

  }

  @media (min-width: 821px) and (max-width: 912px) {
    label.expit_comp_personalinfo_frmfslabw {
      margin-right: 1.6rem;
      margin-left: 1px;
    }

    input.form-control.expit_comp_personalinfo_frminputw {
      width: 100%;
    }

    h4.exprit_compofincometitlefs {
      font-size: 1.2rem;
    }
  }

  @media (min-width: 913px) and (max-width: 1025px) {
    label.expit_comp_personalinfo_frmfslabw {
      margin-right: 3.3rem;
      margin-left: 1px;
    }

    input.form-control.expit_comp_personalinfo_frminputw {
      min-width: 87.3%;
    }
  }

  @media (min-width: 1026px) and (max-width: 1171px) {
    label.expit_comp_personalinfo_frmfslabw {
      margin-right: 3.4rem;
      margin-left: 1px;
    }

    input.form-control.expit_comp_personalinfo_frminputw {
      min-width: 87.3%;
    }


  }

  @media (min-width: 1172px) and (max-width: 1281px) {
    label.expit_comp_personalinfo_frmfslabw {
      margin-right: 4.8rem;
      margin-left: 1px;
    }

    input.form-control.expit_comp_personalinfo_frminputw {
      min-width: 87.3%;
    }


  }

  @media (min-width: 1282px) and (max-width: 1350px) {
    label.expit_comp_personalinfo_frmfslabw {
      margin-right: 4rem;
      margin-left: 0px;
    }

    input.form-control.expit_comp_personalinfo_frminputw {
      min-width: 87.3%;
    }

  }

  @media (min-width: 1351px) and (max-width: 1441px) {
    label.expit_comp_personalinfo_frmfslabw {
      margin-right: 5.5rem;
      margin-left: 12px;
    }

    input.form-control.expit_comp_personalinfo_frminputw {
      min-width: 87.3%;
    }

  }

  /* sw btn css box */



  /* label.switch.expit_clmast_individual_homeadd_swbtn input:focus + .slider {
  box-shadow: 0 0 1px #31719b;
} */

  /* Rounded sliders */




  .hide-extra-space {
    overflow: hidden;
    max-width: 100%;
  }

  .tr-add-hover tr:not(.expit_comp_personalinfo_salaryboxbgclr):hover {
    background-color: #dddddd;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f2f2f2;
  }
}

@media print {

  html,
  body {
    // height: 100%; /* Use 100% here to support printing more than a single page*/
    margin: 0px !important;
    padding: 10px !important;
    // overflow: hidden;
  }
}


tr.hide-row,
tr.hide-row+tr.hide-table-padding,
tr.hide-row+tr.hide-table-padding+tr.mr-table-top {
  display: none;
}
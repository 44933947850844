* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", ;
}

.modal.importreturnfrmexcel_modalbox.show .modal-dialog {
    margin: 4rem auto auto 0rem;
    max-width: 500px;

    & .modal-header.importreturnfrmexcel_header {
        background-color: #eee;
        padding: 0px 15px;

        & h4.modal-title {
            font-size: 1rem;
            font-weight: 500;
            padding: 4px 0px 4px 0px;
        }

        & button.close {
            top: 3px;
            position: relative;
        }
    }

    & .importreturnfrmexcel_dropzone {
        flex: 1 1;
        display: flex;
        text-align: center;
        margin: 0rem 0rem;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 20px;
        border-radius: 2px;
        border: 2px dashed #eee;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border 0.24s ease-in-out;
        height: 150px;
        font-size: 12px;
        justify-content: center;
        cursor: pointer;

        & p.importreturnfrmexcel_textfs {
            font-size: 12px;
            font-weight: 500;
        }
    }

    & p.importreturnfrmexcel_fileselfs {
        font-size: 12px;
        padding-top: 3px;
        text-align: left;
    }

    & .form-check-inline.importreturnfrmexcel_frmcheckval label {
        font-size: 12px;
        font-weight: 500;
    }

    & input.form-check-input.importreturnfrmexcel_radiocheck {
        vertical-align: sub;
    }

    & a.clickheredowntem_fs {
        color: #31719b;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
    }

    & p.importreturnfrmexcel_appends_textfs {
        font-size: 12px;
        font-weight: 500;
    }

    & p.importreturnfrmexcel_notefsbgclr {
        font-size: 12px;
        font-weight: 700;
        padding: 5px 5px;
    }

    & buttonn.btn.btn-default.importreturnfrmexcel_subbtn {
        background-color: #fc7d72;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        border-radius: 40px;
        padding: 4px 15px 7px 15px;
    }

    & buttonn.btn.btn-default.importreturnfrmexcel_cancelbtn {

        background-color: #f2f2f2;
        color: #636363;
        border: 1px solid #ddd;
        font-size: 12px;
        border-radius: 40px;
        padding: 5px 20px;
        font-weight: 500;
    }

    ol.importreturnfrmexcel_note_list {
        padding-top: 5px;
        padding-left: 11px;
    }

    ol.importreturnfrmexcel_note_list li {
        font-size: 12px;
        font-weight: 500;
    }


}


/*===============left side import data popup ================*/
.challans_leftsidepopupbox {
    position: fixed;
    padding: 0 0.4rem 0.1rem 0.2rem !important;
    background-color: #31719b;
    color: #fff;
    z-index: 20;
    top: 49%;
    transform: rotate(270deg);
    border-radius: 0 0 5px 5px;
    left: -4.8rem;
    overflow: hidden;


    .challans_AnnouncementsIcon {
        position: absolute;
        top: 0;
        right: -15px;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        cursor: pointer;
    }

    .challans_Announcements {
        background-color: #fc7d72;
        width: 55px;
        position: relative;
        padding-left: 26px;
        padding-top: 2px;
    }

    .Dropdown.challans_Announcements-wrapper .challans_Announcements-bubble {
        display: none;
        width: 24px;
        height: 26px;
    }

    .Dropdown.challans_Announcements-wrapper.challans_has-unseen-items .challans_Announcements-bubble {
        box-sizing: border-box;
        overflow: hidden;
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
        border-radius: unset;
        display: block;
        position: absolute;
        padding: 0;
        top: -10px;
        background-color: transparent;
        left: 40px;
    }

    .Dropdown.challans_Announcements-wrapper.challans_has-unseen-items .challans_Announcements-bubble:before {
        content: "";
        border: 2px solid #fff;
        border-radius: 50%;
        position: absolute;
        width: 10px;
        height: 10px;
        -webkit-animation: fadeInOut 1s ease .2s infinite normal none running;
        animation: fadeInOut 1s ease .2s infinite normal none running;
        left: -4px;
        bottom: -4px;
    }

    .Dropdown.challans_Announcements-wrapper.challans_has-unseen-items .challans_Announcements-bubble:after {
        content: "";
        border: 2px solid #fff;
        border-radius: 50%;
        position: absolute;
        width: 16px;
        height: 16px;
        -webkit-animation: fadeInOut 7s ease .7s infinite normal none running;
        animation: fadeInOut 7s ease .7s infinite normal none running;
        left: -6px;
        bottom: -6px;
    }

    a.challans_textfs {
        color: rgb(255, 255, 255);
        font-size: 15px;
        font-weight: 500;
        padding-right: 42px;
        padding-left: 22px;
    }

    a.challans_textfs:hover {
        color: rgb(255, 255, 255);
    }

    span.challans_textfs {
        color: rgb(255, 255, 255);
        font-size: 15px;
        font-weight: 600;
        padding-right: 46px;
        padding-left: 22px;
    }

    span.challans_textfs:hover {
        color: rgb(255, 255, 255);
    }

    .svg-inline--fa.fa-w-18 {
        width: 1.125em;
    }


    .svg-inline--fa {
        display: inline-block;
        font-size: inherit;
        height: 1em;
        overflow: visible;
        vertical-align: -0.125em;
    }

    svg:not(:root).svg-inline--fa {
        overflow: visible;
    }

    svg:not(:root).svg-inline--fa,
    svg:not(:host).svg-inline--fa {
        overflow: visible;
        box-sizing: content-box;
    }

    @-webkit-keyframes fadeInOut {
        0% {
            Opacity: 0;
        }

        100% {
            Opacity: 1;
        }
    }

    @keyframes fadeInOut {
        0% {
            Opacity: 0;
        }

        100% {
            Opacity: 1;
        }
    }
}

/*=============== end left side import data popup ================*/
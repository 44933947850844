.modal.left .modal-dialog {
    position: fixed;
    margin: auto;
    width: 800px;
    height: auto;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal.left .modal-body {
    padding: 15px 15px 80px;
}

.modal.left.fade .modal-dialog {
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog {
    left: 50px;
    top: 8%;
}
.summary a,.summary a:hover
{
	color:#ffffff;
}
.mapped_challan_option
{
	background-color: transparent!important;
	font-size: 12px!important;

}
.naturpaywsheader.modal-header {
    padding: 7px 29px;
    background-color: #eee;
}
h5#exampleModalLongTitle {
    font-size: 1.2rem;
}
button.close.natureofpay {
    color: #333;
    text-shadow: 0 1px 0 #fff;
    opacity: 1;
}

td.overflowtextnatureofcoll {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

@media (min-width: 576px){
.modal-dialog {
    min-width: 400px!important;
    max-width: 900px!important;
}
}


.summary {
    left: -140px;
    border-radius: 0px 0px 5px 5px !important;
    background-color: #31719B;
    color:#ffffff;
}
@media (min-width: 320px) and (max-width: 767px){
    h5#exampleModalLongTitle {
        font-size: 12.3px;
    }

}
.Return_Registercss {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    .cursor-pointer {
        cursor: pointer;
    }

    .curson-context-menu {
        cursor: unset;
    }

    .card {
        padding: 0;
    }

    nav {
        &.Return_register_nav {
            .nav {
                &.nav-tabs {
                    border-bottom: 0px solid #dee2e6 !important;

                    a {
                        &.nav-item {
                            &.nav-link {
                                &.Return_register_tab {
                                    &.actives {
                                        color: #ffffff;
                                        background-color: #fc7d72;
                                        font-weight: 500;
                                    }
                                }

                                &.Return_register_tabretborderrightnon {
                                    border-right: 0px !important;
                                }
                            }
                        }
                    }
                }
            }

            div {
                &.nav {
                    &.nav-tabs {
                        a {
                            &.nav-item {
                                &.nav-link {
                                    &.Return_register_tab {
                                        color: #31719b;
                                        background-color: #ffffff;
                                        border-color: #31719b;
                                        outline-color: #31719b !important;
                                        border-radius: 0px;
                                        padding: 2px 25px 3px 25px !important;
                                        font-size: 12px;
                                        border: 0px solid #31719b;
                                        font-weight: 500;
                                        border-width: 1.5px;
                                        border-right: 1px solid #31719b;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .Return_register_search_text_icon {
        position: absolute;
        z-index: 1;
        top: 8px;
        width: 15px;
        left: 19.5rem;
        cursor: pointer;
    }

    input[type="search"]::-webkit-search-decoration:hover,
    input[type="search"]::-webkit-search-cancel-button:hover {
        cursor: pointer;
    }

    div {
        &.form-inline {
            &.Return_register_searchbox {
                input {
                    padding-left: 10px;
                    padding-right: 25px;
                    width: 320px !important;
                    font-size: 11px !important;
                    font-weight: 500;
                }
            }
        }
    }

    a {
        &.btn {
            &.btn-default {
                &.Return_register_backbtn {
                    color: #fc7d72;
                    font-size: 12px;
                    font-weight: 500;
                    position: relative;
                    top: -4px;
                    padding-left: 0px;
                }

                &.Return_register_form24q_btnbgclr {
                    background-color: #2f739d;
                    padding: 3px 7px 4px 7px;
                    border: 0;
                    border-radius: 40px;
                    color: #fff;
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
    }

    button {
        &.btn {
            &.btn-default {
                &.Return_register_btnbgclr {
                    background-color: #2f739d;
                    padding: 4px 7px 7px 7px;
                    border: 0;
                    border-radius: 40px;
                    color: #fff;
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
    }

    span {
        &.Return_register_fyselbox {
            cursor: pointer !important;
            top: 0px;
            position: relative;

            select {
                &.Return_register_sel {
                    margin-top: -9px;
                    border-color: #f2f2f2;
                    color: #656565;
                    background-color: #f2f2f2 !important;
                    background-image: linear-gradient(#f2f2f2, #f2f2f2) !important;
                    border-radius: 0;
                    padding: 0;
                    font-size: 12px;
                    font-weight: 500;
                    height: calc(1.7em + 5px) !important;
                }
            }
        }

        &.btn {
            &.btn-default {
                &.synccontent_btnbgclr {
                    background-color: #2f739d;
                    padding: 2px 7px 4px 7px;
                    border: 0;
                    border-radius: 40px;
                    color: #fff;
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }

        &.synccontent_comfs {
            font-size: 12px;
            font-weight: 700;
        }

        &.textclr_red {
            color: red;
        }

        &.textclr_green {
            color: green;
        }
    }


    .table {
        &.table-striped {
            &.Return_register_rewisetb {
                thead {
                    tr {
                        th {
                            border-top: 0;
                            font-size: 12px;
                            text-align: center;
                            font-weight: 500;
                            vertical-align: initial;
                            padding-top: 5px;
                            padding-bottom: 5px;
                        }
                    }
                }

                tr {
                    td {
                        font-size: 11px;
                        padding: 0.25rem 0.75rem;
                        font-weight: 500;
                        vertical-align: middle;
                    }
                }

                .textclr_green {
                    color: green;
                }
            }
        }
    }

    .k-widget.k-grid.table.table-striped {
        .k-grid-header {
            .k-grid-header-wrap table {
                thead tr {
                    th:not(.k-filterable) {
                        .k-cell-inner {
                            .k-link {
                                cursor: unset;
                            }
                        }
                    }
                }
            }
        }
    }


    .k-grid-content {
        &.k-virtual-content {
            table {
                &.k-grid-table {
                    .Return_register_overflowtext {
                        max-width: 100px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                    }

                    .textclr_blue {
                        color: #31719b;
                    }
                }
            }
        }
    }

    tr {
        &.rowclick_showtb {
            background-color: #fff8b9 !important;
            box-shadow: inset 0 0 0 #dadce0, inset 0 0 0 #dadce0, 0 0 6px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 0 rgba(60, 64, 67, 0.15);
            cursor: pointer;

            td {
                color: rgba(255, 60, 0, 0.8588235294);
            }
        }

        &.refreshtable_toprow {
            th {
                border-bottom: 0px !important;
                padding-bottom: 0px;
            }
        }

        &.refreshtable_2row {
            th {
                padding-top: 0px;
            }
        }
    }

    .synccontentboxbgclr {
        background-color: #d7ccc8;
        padding: 4px 10px;
        border-bottom: 1px solid #ddd;
    }

    .modal {
        &.syncmow_modalbox {
            &.show {
                .modal-dialog {
                    margin: 3rem auto 0rem auto;
                    max-width: 400px;

                    .modal-header {
                        &.syncmow_modalbox_header {
                            background-color: #eee;
                            padding: 0px 15px;

                            h4 {
                                &.modal-title {
                                    font-size: 1rem;
                                    font-weight: 500;
                                    padding: 4px 0px 4px 0px;

                                    span {
                                        &.Tracesusername_textfs {
                                            font-weight: 700;
                                            font-size: 13px;
                                        }
                                    }
                                }
                            }

                            button {
                                &.close {
                                    top: 2px;
                                    position: relative;
                                }

                                &:focus {
                                    outline: 1px dotted;
                                    outline: 0px auto -webkit-focus-ring-color;
                                }
                            }
                        }
                    }

                    p {
                        &.modal_syncnoe_textfs {
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }

                    input {
                        &.form-control {
                            &.sync_pass_inputtextfs {
                                font-size: 11px;
                                font-weight: 500;
                                height: calc(1.2em + 0.75rem + 2px);
                                padding: 0.8rem 0.2rem;
                                font-size: 11px;
                            }

                            &.sync_pass_inputbox_borderrightnon {
                                border-right: 0px;
                            }
                        }
                    }

                    .input-group-prepend {
                        &.sync_pass_sysicon {
                            padding: 5px 5px 5px 5px;
                            border: 1px solid #ced4da;
                            background-color: transparent;
                            border-radius: 0px 5px 5px 0px;
                        }

                        &.sync_pass_eyeblue_icon {
                            img {
                                -o-object-fit: scale-down;
                                object-fit: scale-down;
                            }
                        }
                    }

                    form {
                        &.form {
                            &.form-row {
                                &.syncnow_captcha_form {
                                    label {
                                        font-size: 12px;
                                        color: #777171;
                                    }

                                    input {
                                        &.form-control {
                                            height: calc(1.2em + 0.75rem + 2px);
                                            padding: 0.375rem 0.75rem;
                                            font-size: 11px;
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    button {
                        &.btn {
                            &.btn-default {
                                &.syncnow_captcha_submitbtn {
                                    font-size: 12px;
                                    padding: 4px 15px 6px 15px;
                                    background-color: #31719b;
                                    border-radius: 40px;
                                    font-weight: 500;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .qrtmax_height {
        max-height: 255px;
    }

    .nrmalmaxheight {
        max-height: 492px;
    }

    &.containermaxwidth {
        max-width: 1275px;
    }

    .k-icon {
        &.k-i-more-vertical {
            font-size: 12px;
        }
    }

    .tr-cursor-pointer {
        cursor: pointer;

        .textclr_blue {
            color: #31719b;
        }
    }

    .k-grid-header {
        .k-grid-filter {
            &:hover {
                background-color: transparent;
            }
        }
    }

    .k-widget {
        &.k-dropdown {
            &.header_select {
                &.headerselect {
                    &.drpdwn_width {
                        width: 50%;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .receptright {
        padding-right: 29px;
    }

    .table-responsive {
        .k-grid-container {
            .k-grid-content {
                overflow: auto;
            }
        }
    }

    .k-icon.k-i-sort-asc-sm,
    .k-icon.k-i-sort-desc-sm {
        position: absolute;
        right: 21px;
        bottom: 8px;
    }


    span {
        &.clintmwloadingimg {
            color: #31719b;
            cursor: pointer;
        }

        &.clientstatusimgreturn {
            color: #ffffff;
            cursor: pointer;
            margin-right: 3px;
        }
    }

    .rotate_icon {
        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;
    }

    .imgborder {
        border: 1px solid #369b09;
    }
}



.processSync {
    &.progress-sync-popup {
        .progress {
            display: flex;
            height: 1rem;
            overflow: hidden;
            line-height: 0;
            font-size: .75rem;
            background-color: #e9ecef !important;
            border-radius: 0.25rem;
        }
    }
}


.progress-sync-popup {
    .show-progress-count {
        float: right;
        position: absolute;
        right: 0;
        margin-right: 20px;
    }
}






.k-animation-container {
    &.k-animation-container-relative {
        .k-child-animation-container {
            .k-columnmenu-item-wrapper {
                span {
                    &.k-widget {
                        &.k-datepicker {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.k-animation-container.k-animation-container-relative.k-list-container.k-reset.headerselectoption {
    .k-list.k-reset {
        font-size: 12px;

        .k-item {
            cursor: pointer;
        }
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


@media (max-width: 640px){
    .Return_Registercss .Return_register_search_text_icon {
      left: 17.5rem;
    }
}
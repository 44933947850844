.challan table thead tr th
{
	position: sticky;
	top: 0px;
	background-color: #ffffff;
	z-index: 2;
}
thead.thtrpofixed {
  position: sticky;
  top: 0px;
  background-color: #fafafa;
  z-index: 2;
}
.challan table thead tr td {
  position: sticky;
  top: 29px;
  background-color: #fafafa;
  z-index: 2;
  padding-bottom: 6px;
  margin-top: 16px;
}
.kdk_data_table tr td {
  font-size: 12px;
  padding-top: 4px!important;
  text-align: left;
}

span.dropdown-heading-value {
  height: 30px;
}
.multi-select {
    width: 100%;
    min-width: 107px !important;
    left: 0px;
    position: relative;
}
h4.text-center {
  padding-top: 5px;
}
.navbar {
 
  padding: 0px 3px !important;
}
/* .select-panel {
  height: 205px !important;
} */
.iocncensel {
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}
form.form-inline.bcrsearchiocntb img {
  float: right;
  left: 6.6rem !important;
}
table.table.table-striped.table-responsive.listofchasboxsize form#unmapped-amount-range-box .filter-box {
  top: 42px;
}
table.table.table-striped.table-responsive.listofchasboxsize form#amount-range-box .filter-box {
  margin-top: 3px;
}
table.table.table-striped.table-responsive.listofchasboxsize form.form-inline.unmapamtboxml {
  margin-left: 20px;
}
input#Checkall {
  margin-left: 0px;
}
.kdk_data_table tr td span img {
  width: 16px !important;
  margin: 0px 4px;
}
.orangetext
{
	color:#FF8C00;
}
.table th, table thead tr td
{
	border-top:0px!important;
	border-bottom: 0px!important;
}

.zero_margin_bottom
{
	margin-bottom: 0px!important;
}

.filter-box {
  left: 0px;
  position: absolute;
  top: 40px;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 5px;
  padding: 6px;
  background: #fff;
  display: flex;
  flex-direction: row;
}

.filter-box-field {
  margin: 5px;
}

.filter-box-field label {
  justify-content: flex-start;
}

.clear-filter-challan {
  position: absolute;
  right: 40px;
  font-size: 17px;
  top: 6px;
  cursor: 'pointer';
  background: #ffffff;
  padding-left: 3px;
  z-index: 1000;
}
/*
.form-check {
  
  padding-left: 0.25rem !important;
}*/
.form-check.chpaddleft {
  
  text-align: center;
}
/* KDK list of challan icon size*/
span.create_deductor.iconsize img {
  width: 18px !important;
}
.kdk_data_table.tbthfsize tr th {
  font-size: 13.5px !important;
  padding: 4px 3px;
  text-align: left;
  font-weight: 500;
  position: sticky;
  left: -12px;
  text-align: center;
}
/*seacrhbox 100% in challanlist*/
.kdk_data_table.challan.tbthfsize img.search_text_icon.listofchsearchicon {
  position: absolute;
  top: 10px;
  width: 15px;
  display: flex;
  z-index: 333;
  right: 0px;
  left: 61px;
}
.kdk_data_table tr td .form-inline input.form-control.searchnameboxlistoch {
  width: 100%;
  margin-top: 8px;
}
/*end seacrhbox 100% in challanlist*/
.kdk_data_table tr td .form-inline .form-control {
  z-index: 1;
  position: relative;
  padding: 4px 5px;
  width: 80px;
  
}
div.popup.popup-vertical#create-deductor .MyDatePicker .mdp-container {
  z-index: 1999;
  position: absolute;
  display: block;
  top: -4.1rem;
}
div.popup.popup-horizontal#create-deductor .MyDatePicker .mdp-container {
  z-index: 1999;
  position: absolute;
  display: block;
  top: -7.1rem;
}
.MyDatePicker .mdp-container {
  z-index: 1999;
  position: absolute;
  display: block;
  top: -5rem;
}
span.item-renderer {
  font-size: 10px;
  vertical-align: text-bottom;
  z-index: 333;
  position: relative;
  display: inline-flex;
  padding: 2px 0px;
}
span.item-renderer span {
  padding-top:10px;
}
.trtoppaddtb form.form-inline {
  z-index: 788;
  left: 0px;
  position: relative;
}
table.table.table-striped.table-responsive.listofchasboxsize tr td .form-inline .form-control {
  padding: 16px 5px;
  width: 130px;
  margin-left: 0.1rem;
  text-align: left;
}
form.form-inline.rangboxmt input.form-control {
  text-align: center;
}
form.form-inline.unmapamtboxml input.form-control {
  text-align: center;
}
form.form-inline.ddformboxc input.form-control {
  text-align: center;
}
.fade.modelbox_sm.modal.show {
  width: auto;
  align-items: center;
  margin-left: 30%;
  margin-right: 30%;
}
.fade.downloadcsifilelistofch.modal.show .modal-dialog {
  transform: none;
  width: 510px;
}
.modal-header.modhepaddsm {
  padding: 7px 12px;
  background-color: #eee;
  margin-bottom: 6px;
}
.captchaPopup{
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: -1rem;
}
.downloadcsifooter.modal-footer {
  padding: 0.1rem 0.9rem;
}
.downloadcsifooter.modal-footer button.btn.btn-primary {
  background-color: #fc7d72;
  color: #fff;
  border: 1px solid #fc7d72;
  padding: 5px 15px;
  border-radius: 3px;
  font-size: 14px;
}
.downloadcsifooter.modal-footer button.btn.btn-secondary {
  background-color: #f2f2f2;
  color: #636363;
  border: 1px solid #ddd;
  padding: 5px 19px;
  border-radius: 3px;
  font-size: 14px;
}
.downloadcsifooter.modal-footer .fade.alert.alert-danger.show {
  padding: 0.3rem 0.3rem;
  font-size: 12px;
}
.headerbgclrpadd.modal-header {
  background-color: #eee;
  padding: 7px 10px;
  margin-bottom: 5px;
}
.footborrem.modal-footer {
  padding: 3px 8px;
  border: 0px;
}
.footborrem.modal-footer button.btn.btn-secondary {
  background-color: #f2f2f2;
  color: #636363;
  border: 1px solid #ddd;
  padding: 5px 17px;
  border-radius: 3px;
  font-size: 14px;
}
.footborrem.modal-footer button.btn.btn-primary {
  background-color: #fc7d72;
  color: #fff;
  border: 1px solid #fc7d72;
  padding: 5px 15px;
  border-radius: 3px;
  font-size: 14px;
}
.modal-title.h4.texth4size {
  font-size: 16px;
  padding: 1px 5px;
}
.footborrem.modal-footer .fade.alert.alert-danger.show {
  padding: 0.3rem 0.3rem;
}

button#btnsizebsm {
  display: block;
  z-index: 777;

  float: left !important;
}
.sticky_top {
  padding-top: 8px !important;
}
.sticky_bottom {
  padding: 0px 15px 5px 15px !important;
}
.form-group {
    margin-bottom: 0.6rem;
}
.total_amount {
  margin-top: 19px !important;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}
.h4, h4 {
  font-size: 1.2rem;
  /* padding-top: 9px; */
}
div#create-deductor.popup-vertical span.f-12.mr-4.float-right.bokrigh {
  padding-left: 2.2rem;
  text-align: right;
  z-index: 222;
  display: block;
  position: initial;
}

div#create-deductor span.f-12.mr-4.float-right.bokrigh {
  padding-left: 2.2rem;
  text-align: right;
  z-index: 222;
  display: block;
  position: initial;
}
.popup.popup-horizontal.bookhalig span.f-12.mr-4.float-right.bokrigh {
  padding-left: 4.7rem !important;
}
.popup-vertical {
  min-height: auto !important;
  min-width: 580px;
  max-height: 100%;
  max-width: 80%;
}

/* .table-responsive {
  display: inline-table !important;
} */

.challan-popup.challan-popup-horizontal.bookhalig.cchallanlistpopup .mdp-container.cdtvpopup {
  top: -6.2rem !important;
}

.modal-footer.importchallangovtp {
  padding: 0.1rem 0.7rem;
  margin-top: 0.3rem;
}
.modal-footer.importchallangovtp button.btn.btn-secondary {
  background-color: #f2f2f2;
  color: #636363;
  border: 1px solid #ddd;
  padding: 7px 17px;
  border-radius: 40px;
  font-size: 12px !important;
}
.modal-footer.importchallangovtp button.btn.btn-primary {
  background-color: #fc7d72;
  color: #fff;
  border: 1px solid #fc7d72;
  padding: 7px 15px;
  border-radius: 40px;
  font-size: 12px !important;
}

.modal-footer.importchallangovtp .fade.alert.alert-danger.show {
  padding: 0.3rem 0.3rem;
  font-size: 13px;
}
.backbtntext {
  color: #fc7d72;
  font-size: 12px;
  cursor: pointer;
}

.backbtntext:hover {
  text-decoration: underline !important;
}

.importgovtbtn {
  background-color: #31719b;
  padding: 7px 15px 8px 15px;
  border-radius: 40px;
  font-size: 12px;
  position: relative;
  top: 10px;
  color: #fff;
}
.importgovtbtn:hover {
  
  color: #fff;
}
.dark_color_text.f-12.govtchallanbtn {
  color: #ffffff !important;
  padding: 5px 20px 7px 20px !important;
  border-radius: 40px !important;
  font-size: 12px !important;
  position: relative;
  background-color: #31719b;
  right: 0px;
  float: right;
}

.nextbtnview {
  color: #ffffff !important;
  border-radius: 40px !important;
  font-size: 12px !important;
  position: relative;
  outline-color: #31719b;
  background-color: #31719b;
  right: 2px;
  padding: 7px 15px 8px 15px;
  top: 10px;
  border: 0px;
}
h4.listofchallanfs.text-center {
  left: 7rem;
  position: relative;
}
.erroredimpobtnchall {
  background-color: rgb(252, 125, 114) !important;
  color: #ffffff !important;
  padding: 7px 15px !important;
  border-radius: 40px !important;
  font-size: 12px !important;
  top: 9px;
  position: relative;
  left: -2.3rem;
}
.listchcorleftpadd h4.text-center.list.ofchallant {
  padding-left: 12rem;
}

.captchaPopup {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: -1rem;
}

/*kdk media code for back to decdutor*/
@media only screen and (max-width: 767px) {

  button.btnblmrsm {
    margin-left: -11px;
    float: left;
  }
  button.dark_color_text.float-right.f-12.imchblmrsm.btn.btn-link {
    float: left;
    z-index: 899;
    display: contents;
    font-size: 11px;
  }

  .fade.modelbox_sm.modal.show {
    width: auto;
    align-items: center;
    margin-left: 0%;
    margin-right: 0%;
}
  .modal-title.h4.texth4size {
    font-size: 14px;
  }
  button.dark_color_text.f-12.float-right.btn.btn-link.smbtndccl {
    display: block;
    z-index: 5;
    position: relative;
    margin-top: -25px;
    align-items: center;
    right: 3rem;
  }
  .filter-box-field label {
    justify-content: flex-start;
    font-size: 9px !important;
  }
  .fade.downloadcsifilelistofch.modal.show .modal-dialog {
    transform: none;
    width: auto;
}
.erroredimpobtnchall {
  font-size: 12px !important;
  top: 6px;
  left: 0rem;
}
.nextbtnview {
  font-size: 12px !important;
}
.imchblmrsm {
  top: 0.6rem;
  font-size: 12px;
}
h4.listofchallanfs.text-center {
  left: 0rem;
  position: relative;
}
.displaybtn_sm {
  display: grid;
  text-align: center;
  margin-bottom: 1rem;
}

}
@media only screen and (min-width: 375px) and (max-width: 425px) {

  button.dark_color_text.f-12.float-right.btn.btn-link.smbtndccl {
    display: block;
    z-index: 5;
    position: relative;
    margin-top: -25px;
    align-items: center;
    right: 6.5rem;
  }

}
@media only screen and (min-width: 767px) and (max-width: 941px) {
  .modal-title.h4.texth4size {
    font-size: 15px;
  }
  .fade.modelbox_sm.modal.show {
    width: 47%;
    align-items: center;
    margin-left: 24%;
  }
  .filter-box-field label {
    justify-content: flex-start;
    font-size: 9px !important;
  }
  button.f-12.btnblmrsm.btn.btn-link {
      font-size: 9.6px !important;
  }
  h4.text-center.list.ofchallant {
    font-size: 1.1rem !important;
  }
  .erroredimpobtnchall {
    font-size: 12px !important;
    top: 6px;
    left: 0rem;
  }
  .nextbtnview {
    font-size: 12px !important;
  }
  .imchblmrsm {
    top: 0.6rem;
    font-size: 12px;
  }
  .card.padding_top_manage {
      top: 20px;
  }
  h4.listofchallanfs.text-center {
      left: 0rem;
      position: relative;
  }
}
@media only screen and (min-width: 942px) and (max-width: 1024px) {
 
  .erroredimpobtnchall {
    font-size: 12px !important;
    top: 6px;
    left: -2.5rem;
  }
  .nextbtnview {
    font-size: 12px !important;
  }
  .imchblmrsm {
    top: -0.9rem;
    font-size: 12px;
  }
  ul li.nav-item.dropdown.callmemainbox {
    right: 0rem;
}
}
@media only screen and (min-width: 320px) and (max-width: 1170px) {
  table.table.table-striped.table-responsive.listofchasboxsize thead tr td {
    top: 0px !important;
    padding-bottom: 6px;
    margin-top: 16px;
  }
}
.modal.fade.alertbox_popup {
    
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

    &.show {
        background-color: #0000008a;

        .modal-dialog {
            max-width: 475px !important;
        }

        .alertbox_popup_header.modal-header {
            background-color: #ddd;
            padding: 4px 15px;

            & h4.modal-title {
                font-size: 1rem;
                font-weight: 500;
                padding: 4px 0px 4px 0px;
            }
        }

        label.form-check-label.labletextfs {
            font-size: 12px;
            font-weight: 500;
        }
        p.alreadyfiled_textfs {
            font-size: 12px;
            font-weight: 500;
        }
        button.btn.btn-default.yes_btnbgclr_fs {
            text-align: right;
            font-size: 12px;
            font-weight: 500;
            background-color: #31719b;
            color: #fff;
            border-radius: 40px;
            padding: 4px 20px 6px 20px;
        }
        
    }
    
}

.editdedtrans_alertboxposition .modal-dialog{
    left: 30% ;
    top:10% ;
}


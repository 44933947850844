
a.usermanagement_backbtn {
  font-weight: 600;
  font-size: 12px;
  color: #fa837b;
}
h4.usermanagement_manageassing_title {
  font-size: 1.2rem;
  font-weight: 600;
}

nav.usermanagement_nav {

  .nav.nav-tabs.usermanagement_tabs {
      border-bottom: 0px solid #dee2e6;
      line-height: 1;

      & a.nav-item.nav-link.usermanagement_tabbox {
          color: #fff;
          background-color: #31719b;
          border-color: #31719b;
          outline-color: #31719b !important;
          border-radius: 0px;
          padding: 2px 10px 3px 10px;
          font-size: 12px;
          border: 0px solid #31719b;
          font-weight: 700;
          border-width: 1.5px;
          border-right: 1px solid #31719b;
          text-align: center;
      }

      a.nav-item.nav-link.usermanagement_tabbox.active {
          color: #ffffff;
          background-color: #fc7d72;
      }

      a.nav-item.nav-link.usermanagement_tabbox_leftborder_round {
          border-radius: 15px 0px 0px 15px !important;
          padding-left: 4px !important;
      }

  }

}

table.table.table-striped.usermanagement_table {

  thead tr th {
      font-size: 12px;
      font-weight: 500;
      border-top: 0px;
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
      vertical-align: baseline;
  }

  tr td{
      padding: 0.25rem 0.75rem;
      font-weight: 500;
      font-size: 11px;
      vertical-align: baseline;
  }

  img.cursorpointer {
      cursor: pointer;
  }

  tr.usermanagement_discol_greytextclr td {
      color: #b3b3b3;
  }
  a.usermanagement_mangetextfs {
      color: #007bff;
      text-decoration: underline;
  }
  a.greytextclr {
      color: #b3b3b3 !important;
  }
}

/*========= collapse table tr css =========*/
tr.hidetablepadding td {
  padding: 0;
}

td.accordion-toggle.expandbutton {
    position: relative;
}

td.accordion-toggle.expandbutton:after	{
  position: absolute;
  left:.75rem;
  top: 57%;
  transform: translate(0, -50%);
  content: url(../../images/anglupicon.png);
}
td.accordion-toggle.expandbutton.collapsed:after	{
  content: url(../../images/angldownicon.png);
}
/*========= collapse table tr css =========*/

table.table.table-striped.usermanagement_innertable tr:nth-child(even) {
  background-color: #fff;
}
table.table.table-striped.usermanagement_innertable tr:nth-child(odd) {
  background-color: #e8f5e9;
}
tr.usermanage_alternet_grey {
  background-color: #e8f5e9 !important;
}
tr.usermanage_alternet_white {
  background-color: #fff !important;
}

input.form-control.usermanage_adduserbox {
  font-size: 12px;
  font-weight: 600;
  height: calc(1.2em + 0.75rem + 2px);
  width: auto;
  padding: 0px 5px;
  border: 0px;
}
input.form-control.usermanage_adduserbox:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
}
::placeholder{
  font-size: 12px;
  font-weight: 500;
  color:#b8b8b8 !important;
}

table.assigned-user-list tr th .k-cell-inner{
  padding: 0px;
  margin: 0px;
}

.deactive-text{
  color: #8f8f8f;
}

.usr-auth-settings .modal-dialog {
  min-width: 400px!important;
  max-width: 550px!important;
}

.invite-popup-table .textoverflow.overflowtooltipconttdsamt {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  /* display: inline-block; */
}


.usermanagement_table .textoverflow.overflowtooltipconttdsamt {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  /* display: inline-block; */
}

.sd_usermanage_manageassing_table .textoverflow.overflowtooltipconttdsamt {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  /* display: inline-block; */
}

.usermanagetb .textoverflow.overflowtooltipconttdsamt {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  /* display: inline-block; */
}

div.usermanagement_table .k-filterable .k-column-title{
  cursor: pointer!important;  
}
div.usermanagement_table  .k-grid-header .k-link > .k-icon.k-i-sort-asc-sm{
  position: absolute;
  margin-right: 20px; 
  right: 0;
  margin-top: 3px!important;
}
div.usermanagement_table  .k-grid-header .k-link > .k-icon.k-i-sort-desc-sm{
  position: absolute;
  margin-right: 20px; 
  right: 0;
  margin-top: 3px!important;
}


div.assigned-user-list .k-filterable .k-column-title{
  cursor: pointer!important; 
}
div.assigned-user-list .k-grid-header .k-link > .k-icon.k-i-sort-desc-sm{
  position: absolute;
  margin-right: 20px; 
  right: 0;
  margin-top: 3px!important;  
}
div.assigned-user-list .k-grid-header .k-link > .k-icon.k-i-sort-asc-sm{
  position: absolute;
  margin-right: 20px; 
  right: 0;
  margin-top: 3px!important;
}


div.sd_usermanage_manageassing_table .k-filterable .k-column-title{
  cursor: pointer!important;  
}
div.sd_usermanage_manageassing_table .k-grid-header .k-link > .k-icon.k-i-sort-desc-sm{
  position: absolute;
  margin-right: 20px; 
  right: 0;
  margin-top: 3px!important;
}
div.sd_usermanage_manageassing_table .k-grid-header .k-link > .k-icon.k-i-sort-asc-sm{
  position: absolute;
  margin-right: 20px; 
  right: 0;
  margin-top: 3px!important;
}


.usermanagement_table .k-grid-content{
  overflow-x:hidden!important;
}

.assigned-user-list .k-grid-content{
  overflow-x:hidden!important;
}

.sd_usermanage_manageassing_table .k-grid-content{
  overflow-x:hidden!important;
}

div.usermanagement_table .k-grid-header .k-header .k-link{
  cursor: pointer;
}

div.assigned-user-list .k-grid-header .k-header .k-link{
  cursor: pointer;
}

div.sd_usermanage_manageassing_table .k-grid-header .k-header .k-link{
  cursor: pointer;
}

.usermanagetb{
  margin-bottom: 0px;
}
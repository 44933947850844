/*bulkpan mbox css=========*/
span.listofdeititle {
  font-size: 1.2rem;
  text-align: center;
  padding: 0rem 0rem 0rem 17rem;
}
button.dark_color_bg.rounded_btn.float-right.smpaddbulkpv {
  padding: 0.2rem 0.6rem;
}


.fade.bulkpanvmbox.modal.show .modal-dialog {
  transform: none;
  width: 35%;
}
.bulkpanvmboxh.modal-header {
  padding: 7px 15px;
  background-color: #eee;
}
.bulkpanvmboxh .modal-title {
  padding-top: 1px;
}
.bulkpanvmboxF.modal-footer {
  padding: 5px 15px;
}
button.bulkpanvmboxproc.btn.btn-primary {
  background-color: #31719b;
  color: #fff;
  border: 1px solid #31719b;
  padding: 7px 15px;
  border-radius: 40px;
  font-size: 12px;
}
button.bulkpanvmboxcls.btn.btn-secondary {
  background-color: #f2f2f2;
  color: #636363;
  border: 1px solid #ddd;
  padding: 7px 20px;
  border-radius: 40px;
  font-size: 12px;
}
.bulkpanvmboxF.modal-footer .fade.alert.alert-danger.show {
  font-size: 11px;
  padding: 0.3rem 0.3rem;
}

td.overflowtooltiplistofDE {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: inline-block;
}

table.table.table-striped.deduct_master_tb thead tr th {
  font-size: 12px;
  text-align: center;
  border-top: 0px;
  font-weight: 500;
  padding-top: 0px;
}
table.table.table-striped.deduct_master_tb tr td {
  font-size: 11px;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
}
table.table.table-striped.deduct_master_tb tr.deduct_master_tbtoprow th {
  padding-top: 5px;
  border-bottom: 0px !important;
  padding-bottom: 0px;
}
table.table.table-striped.deduct_master_tb tr.deduct_master_tb2row th {
  padding-top: 0px;
  padding-bottom: 5px;
  border-top: 0px;
  font-size: 9px;
  font-weight: 500;
}
table.table.table-striped.deduct_master_tb .deduct_master_textclr_red {
  color: red;
}
table.table.table-striped.deduct_master_tb .deduct_master_textclr_blue {
  color: rgb(0, 0, 255);
}
table.table.table-striped.deduct_master_tb td.doticonclr_green {
  color: green;
}
table.table.table-striped.deduct_master_tb label.switch.deduct_master_togglebtnbox {
  position: relative;
  top: 0.21rem;
  width: 23px;
  height: 11px;
}
table.table.table-striped.deduct_master_tb label.switch.deduct_master_togglebtnbox .slider.round {
  border-radius: 7px;
  background-color: #ccc;
}
table.table.table-striped.deduct_master_tb .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
table.table.table-striped.deduct_master_tb .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: green;
  transition: 0.4s;
}
table.table.table-striped.deduct_master_tb label.switch.deduct_master_togglebtnbox .slider:before {
  position: absolute;
  content: "";
  background-color: white;
  transition: 0.4s;
  left: 4px;
  height: 7.3px;
  width: 4px;
  top: 2px;
  bottom: 2.1px;
}
table.table.table-striped.deduct_master_tb label.switch.deduct_master_togglebtnbox input:checked + .slider:before {
  left: -10px;
}
table.table.table-striped.deduct_master_tb input:checked + .slider {
  background-color: #28a745 !important;
}
table.table.table-striped.deduct_master_tb input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
table.table.table-striped.deduct_master_tb input:checked + .slider:before {
  transform: translateX(26px);
}
table.table.table-striped.deduct_master_tb .slider.round {
  border-radius: 34px;
}
table.table.table-striped.deduct_master_tb .slider.round:before {
  border-radius: 34px;
}
table.table.table-striped.deduct_master_tb td.textclrblue {
  color: #31719b;
}
table.table.table-striped.deduct_master_tb span.doticonclr_green {
  color: green;
}
table.table.table-striped.deduct_master_tb span.doticonclr_yell {
  color: #ff9802;
}
table.table.table-striped.deduct_master_tb span.doticonclr_skyblue {
  color: #c9ccd1;
}
table.table.table-striped.deduct_master_tb span.exiocn_padd {
  cursor: pointer;
}
table.table.table-striped.deduct_master_tb input.inputbox_width100 {
  width: 100%;
}


span.b2btbtrthtextclr {
  text-align: right;
  color: rgb(0, 0, 255);
  font-size: 9px;
}


.search_text_icon {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 302px;
  width: 15px;
}

img.search_text_icon.doflistsiconbox {
	left: 7px;
}



.k-widget.k-grid.table.table-striped.listofsalarydetailstb.deducteewisetrns_heightfixed {
  & .k-grid-header .k-grid-header-wrap table {
      margin-bottom: -19px !important;
  }
  & .sorting_disabled .k-link{
      cursor: default!important;
  }
}
.k-widget.k-grid.table.table-striped.listofsalarydetailstb.deducteewisetrns_heightfixed {
  & thead tr th {
      background-color: #fff;
  }
}

.listofsalarydetailstb1 .k-grid-header {
  margin-right: 13px;
}



.stsicon {
  /* color: #33729c; */
      font-size: 13px;
      top: 0.1rem;
      position: relative;
  }
  
  .onhoverboxshow {
    position: relative;
    top: 0px;
    left: 0rem;
  }
  .k-widget.k-grid.table.table-striped.deduct_master_tb tr th {
    font-size: 12px;
    font-weight: 500;
    border-top: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}
.k-widget.k-grid.table.table-striped.deduct_master_tb tr td {
  padding: 0.25rem 0.75rem;
    font-weight: 500;
    font-size: 11px;
}
.k-widget.k-grid.table.table-striped.deduct_master_tb tr th input.checkboxvertalaign {
  top: 2px;
  position: relative;
}
.k-widget.k-grid.table.table-striped.deduct_master_tb tr td input#exampleCheck1 {
  top: 2px;
  position: relative;
}
  .k-widget.k-grid.table.table-striped.deduct_master_tb tr td {
    vertical-align: baseline;
}
.k-widget.k-grid.table.table-striped.deduct_master_tb tr td span.slider.round.listofdempswround:before {
  border-radius: 16%;
  position: absolute;
  content: "";
  height: 7.3px;
  width: 3px;
  top: 1.8px;
  left: 3px;
  background-color: #f2f2f2;
  transition: .4s;
  outline-color: #f2f2f2;
}
.k-widget.k-grid.table.table-striped.deduct_master_tb tr td input.form-control-deductee.text-left.input_has_value.text-with-span-field {
  height: calc(1.4rem + 1px) !important;
  font-size: 11px;
  font-weight: 500;
}
.k-widget.k-grid.table.table-striped.deduct_master_tb tr td input:focus {
    background: white;
    padding: 0rem 0.5rem;
    border-radius: 0.25rem;
    border: none;
    line-height: 1.3rem;
    box-sizing: border-box;
    box-shadow: 0px 0 4px 1px #1e74f5;
}
.k-widget.k-grid.table.table-striped.deduct_master_tb tr td input.form-control-deductee.input_has_value {
  max-width: 180px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: inline-block;
}
  /* .onhoverboxshow .tooltiptext {
    visibility: hidden;
    background-color: #fff;
    color: #fff;
    width: 246px;
    padding: 5px 7px;
    line-height: 1.3rem;
    position: absolute;
    z-index: 1;
    text-align: left;
    transition: opacity 0.3s;
    border: 1px solid #33729c;
  } */

  .onhoverboxshow .tooltiptext {
    visibility: hidden;
    background-color: #fff;
    color: #fff;
    width: 246px;
    padding: 5px 7px;
    line-height: 1.3rem;
    position: absolute;
    z-index: 1;
    text-align: left;
    left: 8rem;
    transition: opacity .3s;
    border: 1px solid #33729c;
}
  .onhoverboxshow .tooltiptext::after {
      content: "";
      position: absolute;
      top: 0%;
      left: -1.3rem;
      transform: rotate(90deg);
      border-width: 10px;
      border-style: solid;
      border-color: #33729c transparent transparent transparent;
  }
  
  .onhoverboxshow:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    position: fixed;
    margin: 0px 1px;
    z-index: 9;
  }
  span.tooltiptext ul.stsboxlist {
      padding: 0px;
      margin: 0px;
  }
  span.tooltiptext ul.stsboxlist li {
      list-style: none;
      padding: 0px 0px;
      color: #333;
      font-size: 12px;
  }
  /*end sts tooltipbox*/
  /*sts tooltipbox*/
  .Stsonhoverboxshow {
    position: inherit;
}
  .Stsonhoverboxshow .Ststooltiptext {
    visibility: hidden;
    background-color: #fff;
    color: #fff;
    width: 120px;
    padding: 5px 7px;
    position: absolute;
    z-index: 1;
    left: 5.7rem !important;
    margin-top: -3px;
    text-align: left;
    transition: opacity 0.3s;
    border: 1px solid #33729c;
  }
  .Stsonhoverboxshow .Ststooltiptext::after {
      content: "";
      position: absolute;
      top: 0%;
      left: -16%;
      transform: rotate(90deg);
      border-width: 10px;
      border-style: solid;
     border-color: #33729c transparent transparent transparent;
  }
  .Stsonhoverboxshow:hover .Ststooltiptext {
    visibility: visible;
    opacity: 1;
    position: absolute;
    z-index: 999;
  }
  span.Ststooltiptext ul.Stsstsboxlist {
      padding: 0px;
      margin: 0px;
  }
  span.Ststooltiptext ul.Stsstsboxlist li {
      list-style: none;
      padding: 0px 0px;
      color: #333;
      font-size: 12px;
  }
  .form-check img.delcionvert {
      padding: 2px 0px;
      cursor: pointer;
      margin-left: -2px;
  }
  /*rediocheckbox clr css*/
  .stsboxclrtext {
      color: rgb(51, 105, 30);
  }
  span.iocnfsready {
      font-size: 18px;
      padding: 0px 5px 0px 0px;
      position: relative;
      top: -3px;
      vertical-align: top;
  }
  span.ststextfs {
    font-size: 11px;
    white-space: break-spaces;
  }
  span.iocnfsuppot {
      font-size: 11px;
      padding: 0px 5px 0px 0px;
      vertical-align: middle;
  }
  .stswarningboxclr {
      color: #ffb74e;
  }
  .stsuploadpboxclr {
      color: #689e39;
  }
  .Stsonhoverboxshow .stserrorboxclr {
      color: #e57373;
  
  }
  th.k-header.thdisplaycontent {
    z-index: 3;
}
  th.k-header.thdisplaycontent span.k-icon.k-i-more-vertical {
    top: 0.4rem;
}

button.updatestatbtnfs {
  padding: 2px 10px 4px 10px;
}

.deductee_master_searchbox input.form-control {
  height: calc(1.5em + 0.75rem + 2px);
  width: 75%;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 11px !important;
  font-weight: 500;
}
.deductee_master_searchbox .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
}

h4.deductee_master_titlefs {
  font-size: 1.2rem;
  font-weight: 500;
}

.img.search_text_icon.doflistsiconboxmrgin {
  left: 252px;
}

button.btn.btn-defulat.deduct_master_compliance_checkbtnbox {
  font-size: 12px;
  font-weight: 500;
  background-color: #31719b;
  color: #fff;
  border: 0px;
  border-radius: 40px;
  padding: 5px 10px 6px 10px;
}

button.btn.btn-defulat.deduct_master_compliance_panverificatbtnbox {
  font-size: 12px;
  font-weight: 500;
  background-color: #31719b;
  color: #fff;
  border: 0px;
  border-radius: 40px;
  padding: 5px 10px 6px 10px;
}

.error_imports {background-color: #FC7D72 !important; padding: 7px 22px !important; font-size: 12px;}
.error_imports:hover {color: #fff !important;}

.no-padding {padding: 0px !important;}
/* ----- start media code------*/
@media only screen and (max-width: 767px){
  .fade.bulkpanvmbox.modal.show .modal-dialog {
      transform: none;
      width: 95%;
  }
  span.listofdeititle {
    font-size: 0.7rem;
    padding: 0rem 0rem 0rem 0rem;
  }
  .deducteemaster_backsearchbox {
      display: grid;
  }

}
@media (min-width: 512px) and (max-width: 767px){
  .deducteemaster_backsearchbox {
    display: flex !important;
}

}
@media only screen and (min-width: 768px) and (max-width: 941px){
  span.listofdeititle {
    padding: 0rem 0rem 0rem 3rem;
  }
  h4.deductee_master_titlefs {
    padding-left: 1rem;
}
}
@media only screen and (min-width: 942px) and (max-width: 1024px){
  span.listofdeititle {
    padding: 0rem 0rem 0rem 10rem;
  }
  h4.deductee_master_titlefs {
    padding-left: 0rem;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
  .fade.bulkpanvmbox.modal.show .modal-dialog {
      transform: none;
      width: 62%;
  }
  button.dark_color_bg.rounded_btn.float-right.smpaddbulkpv {
    padding: 7px 5px;
    font-size: 7.9px;
    margin-top: 10px;
    font-weight: 500;
  }
  .Stsonhoverboxshow .Ststooltiptext {
    left: 7rem;
  }
  .onhoverboxshow .tooltiptext {
    left: 6.9rem;
  }
 


}
@media only screen and (min-width: 1025px) and (max-width: 1349px){
  .Stsonhoverboxshow .Ststooltiptext {
    left: 8rem;
  }
}

@media (min-width: 576px){
  .kdk_data_table tr td .form-inline .form-control 
{
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
  }
  .padding_top_manage
  {
    padding-top: 0px!important;
    padding-bottom: 0px!important;
  }
.greentext
{
color:#419B00;
}
.redtext
{
color:#E90000;
}
.orangetext
{
color:#FF960B;
}
.action_btn
{
display: inline-flex;
}
.right_deductee_name
{
font-size: 10px;
}
.switch {
    position: relative;
  display: inline-block;
  width: 35px;
  height: 19px;
  margin-left: 10px;
  margin-top: 3px;
}

.switch input { 
opacity: 0;
width: 0;
height: 0;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ccc;
-webkit-transition: .4s;
transition: .4s;
}

.slider:before {
    position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
background-color: #2196F3;
}

input:checked + .slider:before {
  left: -7px;
}
input:checked+.slider.listofdempswround:before {
  left: -8px !important;
}

input:focus + .slider {
box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
-webkit-transform: translateX(26px);
-ms-transform: translateX(26px);
transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
border-radius: 34px;
}

.slider.round:before {
border-radius: 50%;
}
.darkgraytext
{
color:#636363;
}
.w-100percent
{
  width: 100%;
}
.dropdown-menu
{
  width: 100%;
}
.search_deucteename_firm svg
{
position: absolute;
right: 10px;
top: 11px;
color: #ddd;
}

.min-w-200
{
  min-width: 200px;
}
.tbl_sticky th
{
  position: sticky;
  position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 2;
}
.tbl_search_sticky th,.tbl_search_sticky td
{
  position: sticky;
  position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
  top: 45px;
  background-color: #fafafa!important;
  z-index: 2;
  padding:.75rem;
}
.create_deductee img
{
  width: 20px!important;
}

.w-1320
{
	max-width: 1320px;
}



/* button.btn.btn-default.nodeductions_btnbgclr {
  border-radius: 0;
  padding: 0.2rem 0rem;
  color: #aaaaaa;
  background-color: #ffffff;
  outline-color: #f2f2f2;
  font-size: 12px;
  font-weight: 500;
  width: 35%;
  border: 1px solid rgb(206, 212, 218);
}
button.btn.btn-default.nodeductions_btnbgclr:hover {
  background-color: #fc7d72;
  color: #fff;
}
button.btn.btn-default.nodeductions_btnbgclr.active {
  background-color: #fc7d72;
  color: #fff;
}
button.btn.btn-default.nodeductions_btnbgclr.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
.btn-group.listoftransactionsboxgp1 .multi-select {
  max-width: 100%;
  width: 30%;
}
.btn-group.listoftransactionsboxgp1 .multi-select .select-panel{
  height: auto!important;
}
.btn-group.listoftransactionsboxgp1 {
  width: 35%;
  float: left;
}
.btn-group.listoftransactionsboxgp1 .multi-select .dropdown div span.dropdown-heading-value {
  text-align: center;
  color: #fc7d72 !important;
  line-height: 27px !important;   
  min-width: 125px;
  width: 125px;
}
.btn-group.listoftransactionsboxgp1 .multi-select .dropdown div {
  border-radius: 0 !important;
  font-size: 12px;
  font-weight: 500;
  min-height: calc(1.2em + 0.75rem + 2px) !important;
  height: auto !important;
  
  
}
.btn-group.listoftransactionsboxgp1 .multi-select .dropdown div ul.select-list li label.select-item {
  padding: 0 5px 2px 7px!important;
  margin: 0;
}
.btn-group.listoftransactionsboxgp1 .multi-select .dropdown div ul.select-list li span {
  width: 100% !important;
} */

/* listofdemp tb md hgeight fixed*/

@media  (min-width: 1024px) and (max-width: 1349px){
  .k-widget.k-grid.table.table-striped.listofsalarydetailstb.listdemptbheightmd {
      height: 330px !important;
      overflow-x: auto;
  }
}

@media (min-width: 768px) and (max-width: 821px) {
  h4.deductee_master_titlefs {
    text-align: left;
  }
}

@media only screen and (max-width: 1200px) {
  .deductormasterlist img.search_text_icon.doflistsiconboxmrgin {
    left: 208px;
  }
}

@media only screen and (max-width: 1025px) {
  .deductormasterlist img.search_text_icon.doflistsiconboxmrgin {
    left: 68px;
  }
}


@media  (min-width: 768px) and (max-width: 821px) {
  .modal.editdeducteetrans_modal.show .modal-dialog {
      
      width: 80%;       
  }

  .btn-group.listoftransactionsboxgp1 {
      width: 86%;
      max-width: 100%;
  }
  td.dt_innertable_tdbox_width {
      min-width: 108%;
      width: 108%;
      max-width: 108%;
  }
  .k-widget.k-grid.table.table-striped.listofsalarydetailstb.deducteewisetrns_heightfixed .k-grid-header .k-grid-header-wrap table {
      margin-bottom: -13rem !important;
  }

}

@media  (min-width: 822px) and (max-width: 913px) {


  .btn-group.listoftransactionsboxgp1 {
      width: 86%;
      max-width: 100%;
  }
  td.dt_innertable_tdbox_width {
      min-width: 42rem;
      width: 42rem;
      max-width: 42rem;
  }
  .k-widget.k-grid.table.table-striped.listofsalarydetailstb.deducteewisetrns_heightfixed .k-grid-header .k-grid-header-wrap table {
      margin-bottom: -12rem !important;
  }

}

@media  (min-width: 914px) and (max-width: 1025px) {


  .btn-group.listoftransactionsboxgp1 {
      width: 60%;
      max-width: 100%;
  }
  td.dt_innertable_tdbox_width {
      min-width: 110%;
      width: 110%;
      max-width: 110%;
  }
      .k-widget.k-grid.table.table-striped.listofsalarydetailstb.deducteewisetrns_heightfixed .k-grid-header .k-grid-header-wrap table {
      margin-bottom: -12rem !important;
  }


}
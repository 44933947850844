*
{
	margin: 0px;
	padding: 0px;
}

@font-face 
{
	font-weight: 500!important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	src: url(./font/myfont.otf);
}

body
{
	font-weight: 500!important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	background-color: #fafafa!important;
}
.f-12
{
	font-size: 12px;
	font-weight: 500;
}
.f-14
{
	font-size: 14px;
}
a:hover
{
	text-decoration: none!important;
}
.nopadding
{
	padding:0px!important;
}

.btn_bg
{
	background-color: #3D7094;
	color:#fff;
}
.profile_img_bg
{
	background-color: #f2f2f2;
}
header ul li img
{
	width: 25px;
	cursor: pointer;
}

.align-center
{
	width: 100%;
	margin: auto;
	text-align: center;
}
.light_color_text
{
	color:#FC7D72;
	font-weight: 500;
}
.dark_color_text
{
	color:#31719B;
	font-weight: 500;
}
a
{
	color:#31719B;
}
a:hover
{
	color:#31719B;
	text-decoration: none;
}
.black_color_text
{
	color:#333333;
}
.black_color_text:hover
{
	color:#333333;
	text-decoration: none;
}

.align-center
{
	width: 100%;
	margin: auto;
	text-align: center;
}
.light_color_bg
{
	background-color:#FC7D72;
	color:#ffffff;
	border:1px solid #FC7D72;
}
.dark_color_bg
{
	background-color:#31719B;
	color:#ffffff;
}
.black_color_text
{
	color:#333333;
}
.black_color_text:hover
{
	color:#333333;
	text-decoration: none;
}
.green_text
{
	color:#40BC00;
}

.form-control {
  font-size: 11px;
}
.page-item.disabled .page-link, .page-item .page-link {
  font-size: 12px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #31719B;
  border-color: #31719B;
  font-size: 12px;
}
.page-link:hover {
  z-index: 2;
  color: #31719B;
  text-decoration: none;
  background-color: #dddddd;
  border-color: #dddddd;
}
.cdm input{
    font-size: 12px;
	font-weight: 500;
  }
  input::-webkit-input-placeholder, input::-webkit-input-value { /* Chrome/Opera/Safari */
font-size: 11px;
color:#dddddd;
}
input::-moz-placeholder, input::-moz-value { /* Firefox 19+ */
font-size: 11px;
color:#dddddd;
}
input:-ms-input-placeholder, input:-ms-input-value { /* IE 10+ */
font-size: 11px;
color:#dddddd;
}
input:-moz-placeholder,input:-moz-value { /* Firefox 18- */
font-size: 11px;
color:#dddddd;
}
.form-control::placeholder {
  color: #cccccc;
  opacity: 1;
}
.table td{
  padding: .25rem .75rem;
  }
.table th, table thead tr td 
{
  border-top: 0px!important;
  border-bottom: 0px!important;
}
.tbl_sticky th
{
  position: sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 2;
}
.link{color: #31719B; cursor: pointer;}
.link:hover{text-decoration: underline;}

.btn_blue:focus {
    outline-color: transparent;
    outline-style:solid;
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, .075), 0 0 8px rgba(49, 113, 115, 0.8);
}
.btn_corol:focus {
    outline-color: transparent;
    outline-style:solid;    
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, .075), 0 0 8px rgba(252, 125, 114, 0.8);    
}
.btn_gray:focus {
    outline-color: transparent;
    outline-style:solid;
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, .075), 0 0 8px rgba(221, 219, 219, 0.8);    
}
/* Toastify CSS settings - https://fkhadra.github.io/react-toastify/how-to-style/ */
.Toastify__toast {
	min-height: 44px!important;	
}
.Toastify__toast-container {
    width: auto!important;	
	z-index: 100000!important;
}
.Toastify__toast-body{
	padding: 4px!important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
	color: #155724!important;
    background-color: #d4edda!important;
    border-color: #c3e6cb!important;
}
.Toastify__progress-bar--success {
	background-color: #155724!important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
	color: #721c24!important;
    background-color: #f8d7da!important;
    border-color: #f5c6cb!important;
}
.Toastify__progress-bar--error {
	background-color: #721c24!important;
}
.Toastify__close-button {
    color: #000!important;
}
.excltionicon{
	width: 12px;
	margin-right: 3px;
  }
.input-error1  {
	border: 1px solid #fc6a6a!important;
	box-shadow: none!important;
}

.Toastify__toast.Toastify__toast-theme--colored.Toastify__toast--warning.custom-toast-warning{
    background-color: #FFA726;
	color: black;
}
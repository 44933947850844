.fade.MonthWSdetailspopup.modal.show .modal-dialog.modal-sm {
    width: 360px;
}
.MonthWSdetailsH.modal-header {
    padding: 0px 15px;
    background-color: #eee;
}
.modal-title.h4 sapn.MonthWSdetailsT {
    font-size: 1.2rem;
    padding: 4px 0px 4px 0px;
}
.card.MonthWSdetailscard {
    padding: 0px 0px;
}

table.table.table-striped.monthWSDTB thead tr th {
    font-size: 13.5px;
    font-weight: 500;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}
table.table.table-striped.monthWSDTB tr td {
    font-size: 12px;
    vertical-align: middle;
}

.MonthWSdetailsH.modal-header button.close {
    position: relative;
    top:3px;
}
button.btn.btn-default.monthwsdetilssavebtn {
    background-color: #31719b;
    color: #fff;
    border: none;
    padding: 7px 40px;
    border-radius: 40px;
    font-size: 14px;
}
.monthly_salary_input {width: 40%; display: inline; height: auto; padding: 3px 5px;}

.monthwise_salarydetails_rupsign {
    font-size: 12px;
    left: 36.5%;
    font-weight: 400;
    padding: 10px 5px 7px 6px;
    border: 1px solid #ced4da;
    background-color: white;
    border-radius: 5px 0px 0px 5px;
    border-right: 0px;
}
input.form-control.monthwise_inputborderrightnon {
    border-left: 0px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: right;
}
input.form-control.monthly_salary_input {
    padding: 0.375rem 0.75rem 0.375rem 1.3rem;
}
/*=======media code ==============*/

@media only screen and (max-width: 767px){
    .fade.MonthWSdetailspopup.modal.show .modal-dialog.modal-sm {
        width: auto;
    }
    .fade.allowancedspopup.modal.show .modal-dialog.modal-sm {
        width: auto;
    }
}
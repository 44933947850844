.insightportal_form_popup{
    
button.btn.btn-default.regist_on_insightsportal_submitbtn {
    font-size: 12px;
    padding: 3px 10px 5px;
    background-color: #31719b;
    border-radius: 40px;
    font-weight: 500;
    border: 0;
    color: #fff;
    float: right;
    margin-top: 2px;
    margin-bottom: 10px;
}
fieldset.principofficer_fs_borderclr {
        border: 1px solid #31719b !important;
    }
fieldset {
    display: block;
    margin: 9px 2px 2px !important;
    background-color: #fff;
    padding: 0.35em 0.75em 0.625em !important;
    &.Entity_details_fs {
        border: 1px solid #fc7d72;
    }
}
legend {
    &.Entity_details_leg{
        display: block;
        max-width: 100%;
        padding: 0;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        line-height: inherit;
        color: inherit;
        white-space: normal;
    }
    &.leg_principal_off{
        width: 40%;
        padding-left: 6px;
    }
    &.leg_entitydet_set{
        width: 24%;
        padding-left: 6px;
    }

}
.regist_on_insightsportal_bodyscroll {
    overflow-x: hidden;
    max-height: 650px;
    overflow-y: auto;
}

.css-1fhf3k1-control{
    min-height: 32px;
}

.regist_on_insightsportal_modal{
  

    // & .modal-header.regist_on_insightsportal_header {
    //     background-color: #eee;
    //     padding: 0px 15px;

    //     & h4.modal-title {
    //         font-size: 1rem;
    //         font-weight: 500;
    //         padding: 4px 0px 4px 0px;
    //     }

    //     & button:focus {
    //         outline: 1px dotted;
    //         outline: 0px auto -webkit-focus-ring-color;
    //     }

    //     & button.close {
    //         position: relative;
    //         top: 1px;
    //     }
    // }

    

    fieldset.Entity_details_fs {
        border: 1px solid #fc7d72;

        & legend.Entity_details_leg {
            width: auto;

            & h4.Entity_details_titlefs {
                font-size: 1.2rem;
                font-weight: 500;
                padding: 0px 5px;
            }
        }
    }

    .form.form-row.Entity_details_frm {

        & label.Entity_details_lebale {
            font-size: 12px;
            color: #777171;
            font-weight: 500;

            & span.red_text {
                color: red;
            }
        }

        & input.form-control.Entity_details_input {
            font-size: 12px;
            font-weight: 500;
        }

        & input.form-control.Entity_details_input:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
        }
    }

}

.css-1gtu0rj-indicatorContainer{
    padding: 4px !important
}
.css-1pahdxg-control{
    min-height: 20px !important;
}
.k-button-icon:has( span.k-i-window-minimize){
display:none}

}


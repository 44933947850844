body {
    background-color: #fff !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.downloadtcs_certificate_parent {
    span.issuescon_backbtn {
        font-size: 12px;
        padding: 4px 0;
        color: #fc7d72;
        font-weight: 500;
    }

    h4.issuestdstcscert_titlefs {
        text-align: center;
        font-size: 1.2rem;
        position: relative;
    }

    h4.emailselected_deductee_titlefs {
        font-size: 1.2rem;
        font-weight: 500;
    }

    h4.exptds_form16_status_wantuptextfs {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0px;
        padding: 4px 2px;
    }

    .card.email_pdf_deducteeboxbgclr {
        background-color: #f3f3f3;
        padding: 5px 5px 5px 5px;

        p.emailselected_deductee_subtitle {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0px;

            & span.emailselected_deductee_qboldtext {
                font-weight: 700 !important;
                padding-left: 5px;
                color: #fc7d72;
            }

            & span.email_pdf_deductee_vlineclr {
                padding: 0px 20px;
                color: #b4b4b4;
            }
        }
    }

    a.salaryrev_backbtn {
        color: #fc7d72;
        font-size: 13px;
        padding: 4px 0;
        font-weight: 500;
    }

    h4.salaryrev_titlefs {

        font-size: 1.2rem;
        font-weight: 500;


    }


    table.table.table-striped.form16_emailselected_tb {
        thead tr th {
            font-size: 12px;
            text-align: center;
            font-weight: 500;
            padding-top: 5px;
            padding-bottom: 5px;
            border: 0px;
            vertical-align: baseline;
        }

        tr td {
            font-size: 11px;
            padding: 0.25rem 0.75rem;
            font-weight: 500;
            vertical-align: baseline;
        }

        & input.form16_emailselected_checkbox {
            vertical-align: middle;
        }

        & td.form16_emailselected_textclr_blue {
            color: #31719b;
            cursor: pointer;
        }

        & td.form16_emailselected_overflowtext {
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            cursor: pointer;
        }

        & tr.form16_emailselected_toprow th {
            padding-bottom: 0px !important;
        }

        & tr.form16_emailselected_2row th {
            font-size: 9px !important;
            font-weight: 700;
            color: #31719b;
        }

        & input.form-control.form16_emailselected_emailinpubox {
            font-weight: 500;
            height: calc(1.2em + 0.75rem + 2px);
            font-size: 11px;
            border-radius: 5px;
        }


        & input.form-control.form16_emailselected_emailinpubox:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
        }


    }

    button.form16_emailselected_sendbtnbgclr {
        font-weight: 500;
        font-size: 12px;
        padding: 3px 15px 5px 15px;
        border: 0px;
        border-radius: 40px;
        background-color: #31719b;
        color: #fff;
    }



    /* ========== right side box css =================*/
    .card.exptds_salaryrev_status_cardboxshadow {
        padding: 0.5rem 0;
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: 0.25rem;
        box-shadow: 1px 2px 10px 2px #ddd;


        h4.exptds_salaryrev_status_wantuptextfs {
            font-size: 1rem;
            text-align: center;
        }

        table.table.table-borderless.exptds_salaryrev_status_updatethroexcel_tb tr td {
            font-size: 11px;
            font-weight: 500;
            padding: 0.25rem 0.4rem;
            border-top: 0;
        }

        .form16emailsel_importdropzone {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px 0px;
            border-radius: 2px;
            border: 2px dashed #eee;
            background-color: #fafafa;
            color: #bdbdbd;
            outline: none;
            transition: border 0.24s ease-in-out;
            justify-content: center;
            cursor: pointer;
            text-align: center;
            font-size: 11px;
        }

        ul.bulpanstatus_filesel_listtextfs {
            font-size: 11px;
            list-style: none;
            text-align: left;
            padding: 0px 0px 0px 0px;
            font-weight: 500;
            margin-bottom: 5px;
        }

        p.exptds_salaryrev_status_fileselectedtext {
            font-size: 12px;
            padding-top: 3px;
        }

        p.textfs12 {
            font-size: 12px;
            font-weight: 500;
        }


        button.blukpanstatus_stimport {
            font-weight: 500;
            font-size: 12px;
            padding: 3px 10px 5px 10px;
            border: 0px;
            border-radius: 40px;
            background-color: #31719b;
            color: #fff;
        }

        & button.btn.btn-default.exptds_salaryrev_exportfs {
            font-weight: 500;
            font-size: 12px;
            padding: 1px 7px 3px 7px;
            border: 0px;
            border-radius: 40px;
            background-color: #31719b;
            color: #fff;
        }

        & button.btn.btn-default.exptds_salaryrev_exportfs:focus {
            outline: 0;
            box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
        }


    }

    span.form16_emailselected_showmorefs {
        font-size: 12px;
        font-weight: 500;
        color: #31719b;
        cursor: pointer;
    }


    span.btn.btn-default.form16_selectedemail_sendbtn {
        font-weight: 500;
        font-size: 12px;
        padding: 3px 15px 5px 15px;
        border: 0px;
        border-radius: 40px;
        background-color: #31719b;
        color: #fff;
    }

    span.totaldedcuteebox {
        position: relative;
        top: 4px;
        float: right;
    }

    .importzone_newdesign .importdropzone {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 7px;
        border-radius: 2px;
        border: 2px dashed #eee;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out;
        height: 30px;
        justify-content: center;
        cursor: pointer;
    }

    .cursor_pointer {
        cursor: pointer;
    }

    /* media code */

    @media (max-width: 767px) {
        h4.salaryrev_titlefs {
            text-align: center;
        }

        .card.exptds_salaryrev_status_cardboxshadow table.table.table-borderless.exptds_salaryrev_status_updatethroexcel_tb tr td {
            font-size: 11px;
            font-weight: 500;
            padding: 0.25rem 0.2rem;
            border-top: 0;
        }

    }

    @media (min-width: 768px) and (max-width: 821px) {

        .card.exptds_salaryrev_status_cardboxshadow table.table.table-borderless.exptds_salaryrev_status_updatethroexcel_tb tr td {
            font-size: 11px;
            font-weight: 500;
            padding: 0.25rem 0.3rem;
            border-top: 0;
        }

    }

    @media (min-width: 1200px) {

        .container,
        .container-sm,
        .container-md,
        .container-lg,
        .container-xl {
            max-width: 1165px;
        }
    }
}
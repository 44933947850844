.borlefts {
    border-right: 1px solid #8a8888;
}


.latefeeamtheadbgclr.modal-header {
    background-color: #eee;
    padding: 0px 18px;
}
.latefeeamtheadbgclr.modal-header h5.modal-title {
    padding: 3px 0px 3px 0px;
    font-size: 1rem !important;
    font-weight: 500;
}
.latefeeamtheadbgclr.modal-header button.close {
    top: 9px;
    position: relative;
}
.bulkpanverificationscss {

  a.bulkpanverif_backtextfs {
    color: #fc7d72;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }

  h4.bulkpanverif_textfs {
    font-size: 1.2rem;
    font-weight: 500;
    position: relative;
  }

  .table.table-striped.dash_blukpanstatus_tb thead tr th {
    &.sortablefalsecolumn{
      .k-link{
        cursor: default;
      }
      
    }
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 0px;
  }

  .table.table-striped.dash_blukpanstatus_tb {
    &.gridmaxheight{
      .k-grid-container .k-grid-content {
        height: 425px
      }
    } 
    .k-grid-container .k-grid-content {
      tr td {
        font-size: 11px;
        padding: 0.25rem 0.75rem;
        font-weight: 500;
      }
    }
    
  }

  table.table.table-striped.dash_blukpanstatus_tb tr td .blukpan_textclr_blue {
    color: #31719b;
  }

  /* ========== right side box css =================*/
  .card.bulkpanstatus_cardboxshadow {
    padding: 0.5rem 0;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    box-shadow: 1px 2px 10px 2px #ddd;
  }

  .card.bulkpanstatus_cardboxshadow h4.bulkpanstatus_wantuptextfs {
    font-size: 1rem;
    text-align: center;
  }

  .card.bulkpanstatus_cardboxshadow table.table.table-borderless.bulkpanstatus_updatethroexcel_tb tr td {
    font-size: 11px;
    font-weight: 500;
    padding: 0.25rem 0.75rem;
    border-top: 0;
  }

  .card.bulkpanstatus_cardboxshadow .blukpanst_importdropzone {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 2px;
    border: 2px dashed #eee;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    height: 80px;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
  }

  .card.bulkpanstatus_cardboxshadow ul.bulpanstatus_filesel_listtextfs {
    font-size: 11px;
    list-style: none;
    text-align: left;
    padding: 0px 0px 0px 0px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .card.bulkpanstatus_cardboxshadow p.bulkpanstatus_fileselectedtext {
    font-size: 12px;
    padding-top: 3px;
  }

  .card.bulkpanstatus_cardboxshadow p.textfs12 {
    font-size: 12px;
    font-weight: 500;
  }

  .card.bulkpanstatus_cardboxshadow button.blukpanstatus_stimport {
    font-weight: 500;
    font-size: 12px;
    padding: 3px 10px 6px 10px;
    border: 0px;
    border-radius: 40px;
    background-color: #31719b;
    color: #fff;

    &.disabled {
      opacity: .65;
    }
  }

  .font11Cent {
    font-size: 11px;
  }
}

/*# sourceMappingURL=Deductor_dashboard_bulk_pan_verification_status.css.map */
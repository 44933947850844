.popup {
  z-index: 50;
  background-color: #ffffff;
}
@font-face {
  font-weight: 500!important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  src: url(../../font/myfont.otf);
}
body {
  font-weight: 500!important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  background-color: #fafafa !important;
}
/*Drgable */
.popup-vertical.vpopuphight {
  height: 100%;
}
.popup-vertical {
  position: absolute;
  /*resize: both; !*enable this to css resize*! */
  overflow: auto;
  top: 0px;
  right: 0px;
  width: 43%;
  height: 100%;
  opacity: 0;
  min-width: 580px;
  max-width: 80%;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 29999;
}
.popup-horizontal {
  position: absolute;
  /*resize: both; !*enable this to css resize*! */
  overflow: auto;
  bottom: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  min-height: 200px;
  min-width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  max-height: 80%;
  z-index: 200;
}

.popup-header {
  padding: 0px;
  /* cursor: move; */
  z-index: 10;
}

/*Resizeable*/

.popup .resizer-left {
  width: 5px;
  height: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: e-resize;
}

.popup .resizer-up {
  width: 100%;
  height: 5px;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  cursor: n-resize;
  z-index: 101;
}

.w-600 {
  max-width: 600px;
}
.zero_margin_bottom {
  margin-bottom: 0px !important;
}

.popup .resizer-both {
  width: 5px;
  height: 5px;
  background: transparent;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: nw-resize;
}
.MyDatePicker {
  width: 100%;
}

/*NOSELECT*/

.popup * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.view_btn.focus,
.view_btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.popup-header {
  border-bottom: 1px solid #dddddd;
}
.border-right {
  border-right: 1px solid #dddddd;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.3;
}
.col-md-3.col-sm-3.col-lg-3.col-xl-3.unconsumedAmtDiv {
  font-size: 10px;
  position: relative;
  left: 44px;
}
spna.unconsumedAmtValue {
  display: contents;
}
.sticky_top {
  position: sticky;
  top: 0px;
  z-index: 2;
  background: #fff;
  padding-top: 15px;
}
.sticky_bottom {
  position: sticky;
  bottom: 0px;
  z-index: 2;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.deductordetails .row .form-group {
  margin-bottom: 1.1rem;
}
.form-group.grcolmb0 {
  margin-bottom: -1rem;
}
.form-group label,
.form-group select {
  font-size: 11px;
  color: #636363;
}
.form-group select option {
  font-size: 12px;
  color: #333333;
  font-weight: 500;
}
.form-control:focus {
  box-shadow: none;
}
.view_btn img {
  width: 40px;
}
.cdf label {
  font-size: 9.5px;
  font-weight: 500;
}
.cdf input,
label {
  font-size: 11px;
  font-weight: 500;
}
.float_card {
  background-color: #fff;
  width: 100%;
  float: left;
  padding: 0 15px;
}
.bl-1 {
  border-left: 1px solid #dddddd;
}
.dark_color_bg.btnbgpadd {
  background-color: #31719b;
  color: #ffffff;
  border: none;
  padding: 7px 15px !important;
  border-radius: 1px !important;
  font-size: 12px;
  font-weight: 500;
}
.light_color_bg.btnbgpadd {
  background-color: #fc7d72;
  color: #ffffff;
  border: 1px solid #fc7d72;
  padding: 7px 15px !important;
  border-radius: 1px !important;
  font-size: 12px;
}
.deductee-trans-popup {
  z-index: 9999;
}
.mdp-container.cdtvpopup{
  left: 0.5px;
  top: 40px;
}


.msnd #mdp-calendar-div {
  left: -116.5px;
  top: -3.1rem ;
}

.deductordetails .row .form-group.grcolmb0 {
  margin-bottom: 0rem;
}

.deductordetails .form-group .single-select-d.css-2b097c-container .css-yk16xz-control {
  height: calc(1.2em + 0.75rem + 2px);
  font-weight: 500;
}
.deductordetails .form-group  .k-combobox .k-select {
  border-color: rgb(0 0 0 / 0%);
  color: #424242;
  background-color: #ffffff00;
  background-image: linear-gradient(rgb(255 255 255 / 0%), rgb(255 255 255 / 2%)) !important;
}
.deductordetails .form-group  .css-1okebmr-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: hsl(0,0%,80%);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 0px;
  box-sizing: border-box;
}
form#editdeducteeform kendo-dialog-titlebar {
  width: 100%;
}

svg.svg-inline--fa.fa-times.fa-w-11.closeicon_clr {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  position: relative;
  top: 3px;
}
form.createdeductee_editmodalbox .k-widget.k-window.windowWrapper.k-window-focused .k-window-titlebar.k-dialog-titlebar .k-window-title kendo-dialog-titlebar {
  width: 100% !important;
}

.k-widget.k-window.windowWrapper .k-window-titlebar.k-dialog-titlebar {
  padding: 7px 11px;
  background-color: #eee;
}

.k-dialog-titlebar {
  border-color: inherit;
  color: #000000;
  background-color: #d3d3d3;
}

.k-widget.k-window.windowWrapper .k-window-title {
  font-size: 14px;
  color: #000000;
}
.k-widget.k-window.windowWrapper button.k-button-icon.k-button {
  color: #000000 !important;
}

.k-widget.k-window.windowWrapper{
  right: 0 !important;
}

@media only screen and (max-width: 767px) {
  .h6,
  h6 {
    font-size: 14px;
  }
  .f-14 {
    font-size: 11px;
  }
  header ul li img {
    width: 20px;
    cursor: pointer;
    margin-left: -38px;
  }
  header ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 0px;
    font-size: 13px;
  }
  header .navbar-brand img {
    width: 100% !important;
  }
  header ul li {
    display: contents !important;
    margin-right: 1px !important;
    font-size: 12px !important;
  }
  header ul li span,
  .mailer span,
  .sms span {
    padding: 5px 4px 6px 8px;
    margin-top: 1px;
  }
  header ul li img {
    width: 19px !important;
  }
  span.black_color_text.f-14 {
    font-size: 8px;
    font-weight: 600;
  }
  .h4,
  h4 {
    font-size: 1rem;
  }
  .frDt .MyDatePicker .mdp-container {
    left: 35% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 942px) {
  .h4,
  h4 {
    font-size: 1.2rem;
  }
}

.slideUp {
  overflow-y: hidden;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slideUp.closed {
  max-height: 0;
}
.flDt .MyDatePicker .mdp-container {
  float: left !important;
}

div.popup.popup-vertical#create-deductor
  .MyDatePicker
  .mdp-container.cdtvpopup {
  z-index: 1999;
  position: absolute;
  display: block;
  top: 0rem;
  left: 0px;
}
.popup.popup-horizontal.deductee-trans-popup#create-deductor
  .MyDatePicker
  .mdp-container.cdtvpopup {
  z-index: 1999;
  position: absolute;
  display: block;
  top: -7.8rem !important;
  left: 1px !important;
}
div.popup.popup-vertical#create-deductor
  .MyDatePicker
  .mdp-container.cdtvpopup {
  z-index: 1999;
  position: absolute;
  display: block;
  top: 0rem;
  left: 0px;
}

div.popup.popup-vertical#create-deductor
  .MyDatePicker
  .mdp-container.cdtvpopup {
  z-index: 1999;
  position: absolute;
  display: block;
  top: 0rem;
  left: 0px;
}

.deductordetails {
  height: auto;
}

.horiviewbox {
  display: none;
}
.vortiviewbox {
  display: none;
}
.popup.popup-horizontal.deductee-trans-popup p.totalsectdisplaynoneh {
  display: none;
}
.popup-vertical.vpopuphight p.mt-3.totalforts.pt-3 {
  display: none;
}
.popup-vertical.vpopuphight small.vtexthide {
  display: none;
}
.popup-horizontal p.mt-3.totalforts.pt-3.hviewhide {
  display: none;
}
.popup-horizontal .form-group.col-md-4.hviewhide {
  display: none;
}
div#create-deductor.form-group {
  margin-bottom: 0.9rem !important;
}
.kdk_data_table tr td .selctwid .form-inline .form-control {
  z-index: 1;
  position: relative;
  padding: 4px 5px;
  width: 100px;
}
form#paid-amount-range-box input {
  width: 91px;
}

small.hviewhide {
  margin-top: 0px;
  z-index: 22;
  display: block;
  font-size: 11px;
  font-weight: 400;
  color: #777171;
  font-weight: 500;
}
p.totalsectdisplaynoneh {
  font-size: 12px;
  margin-bottom: 0rem;
}
.dark_color_bg.btnbgpadd1 {
  background-color: #31719b;
  color: #ffffff;
  border: none;
  padding: 4px 20px 6px 20px;
  font-size: 12px;
  border-radius: 40px !important;
  cursor: pointer;
  font-weight: 500;
}
.light_color_bg.btnbgpadd2 {
  background-color: #FC7D72;
  color: #ffffff;
  border: 0px;
  padding: 4px 10px 6px 10px;
  border-radius: 40px !important;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
}
button.light-btn.cdedtrscalbtn {
  border: 1px solid #eee;
  padding: 7px 30px !important;
  border-radius: 40px !important;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
  
}
.form-group label,
.form-group select {
  font-size: 11px;
  color: #777171;
}
label.labpb4 {
  padding-bottom: 9px;
}
.popup-vertical .hpopuphightbtn {
  display: none;
}
.popup-horizontal .vpopuphightbtn {
  display: none;
}
.popup-horizontal p.totalforts.pt-3 {
  margin-top: -10px !important;
}
label.labchsect {
  display: inline-block;
  position: absolute;
  z-index: 22;
  left: -55px;
  top: 9px;
}
.popup-horizontal p.totalforts.pt-3 {
  display: contents;
}
.unconsumedAmtDiv {
  font-size: 0.7rem;
  color: teal;
}
.unconsumedAmtValue {
  font-size: 1rem;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.clrbtnfrmDeducteetrans {
  clear: both;
  display: inline-block;
}

.autoCompDTF li {
  list-style: none;
  padding: 5px;
  width: 100%;
  cursor: pointer;
  font-size: 11px;
  color: #636363;
  margin-bottom: 1px;
  cursor: pointer;
}

.autoCompDTF li:hover {
  color: #0056b3;
  background-color: #ced4da;
}

.autoCompDTF ul {
  margin: 0 auto;
  padding: 0;
  max-height: 113px;
  overflow-y: auto;
  border: 1px solid #ced4da;

  border-radius: 3px;
  background: #fff;
  position: relative;
  z-index: 99999;
  /*border-left: none;
    border-right: none;*/
}



.autoCompDTF li:nth-of-type(2n + 1) {
  background-color: rgba(0, 0, 0, 0.05);
}

/*.sltWidt1 {max-width: 9% !important;}*/

/*creatededucteetrans vertical & horizotal view css box show*/
.popup.popup-horizontal.deductee-trans-popup .form-group.col-md-2.displaynonh {
  display: none;
}
.popup.popup-vertical.vpopuphight.deductee-trans-popup
  .form-group.col-md-4.desplaynonv {
  display: none;
}

.popup.popup-vertical.vpopuphight.deductee-trans-popup
  .form-group.col-md-4.displaynonv {
  display: none;
}

/*end creatededucteetrans vertical & horizotal view css box show*/

/*=========== overflow text with tooltip css ======*/
span.overflowtooltipconttdsamt {
  /* max-width: 150px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: inline-block;
}
.overflowtooltipsurcharge {
  /* max-width: 169px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: inline-block;
}

span.overflowtooltipreason {
  /* max-width: 255px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: inline-block;
}

/*=========== overflow text with tooltip css ======*/

.k-animation-container {
  z-index: 9999;
}
.k-autocomplete.k-header,
.k-autocomplete {
  color: #495057;
  height: 1.9rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.k-widget.form-control .k-input {
  padding: 0.5rem 0.75rem;
}
.autoCompDTF li.k-state-focused {
  box-shadow: none !important;
  color: #0056b3 !important;
  background-color: #ffffff !important;
  cursor: pointer;
}
.k-animation-container.k-animation-container-relative.k-list-container.k-reset.autoCompDTF.downslewidth.k-animation-container-shown .k-popup.k-child-animation-container .k-nodata {
  min-height: 5px;
}

.k-animation-container.k-animation-container-relative.k-list-container.k-reset.autoCompDTF.downslewidth
  ul
  li {
  padding: 11px 6px;
  line-height: 0px;
  cursor: pointer;
}
.addinnerbtnbgclr {
  color: #31719b !important;
  font-size: 15px !important;
  text-transform: capitalize;
  top: 10px !important;
  background-color: #fff !important;
  outline-color: #fff;
  padding: 2px 5px 2px 5px !important;
  left: 0rem;
  border: 0px;
  background-image: linear-gradient(rgb(255 255 255), rgb(255 255 255));
}

.updatedeductee_trans_compst_bgclr {
  background-color: #fff;
  font-size: 8px;
  font-weight: 500;
  padding: 2px 2px 2px 2px;
  width: 100%;
}
span.status-textfs {
  font-size: 9px;
}
span.nonflier-textclr {
  color: rgb(255, 0, 0);
  font-weight: 600;
  font-size: 9px;
}
span.getstatus-textclr {
  color: #31719b;
  font-weight: 700;
  font-size: 9px;
}
span.activenamematch-textclr {
  color: rgb(8, 200, 2);
  font-weight: 700;
}
span.reverifypan-textclr {
  color: #31719b;
  font-weight: 700;
}

span.lineclr_grey{
  color: #8b8b8b !important;
}

/*kdk media code for back to decdutor*/
@media only screen and (max-width: 767px) {
  .popup-vertical.vpopuphight {
    height: 100% !important;
    min-width: 300px;
  }
  .autoCompDTF.dninpboxsm {
    z-index: 0;
  }
  .form-group.col-md-4.tdsratebox {
    margin-top: 41px;
  }
  div#create-deductor .MyDatePicker {
    z-index: 0;
  }
  .unconsumedAmtValue {
    font-size: 14px;
    display: grid;
    margin-top: -16px;
  }
  .popup-horizontal .unconsumedAmtValue {
    top: -1px;
    position: relative;
  }
  .popup.popup-horizontal.deductee-trans-popup label.labchsect {
    margin-left: 57px;
  }
  .popup.popup-horizontal.deductee-trans-popup
    select#inputGroupSelect01#selecbox {
    margin-top: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 941px) {
  .popup-horizontal .unconsumedAmtValue.ruppboxicon {
    top: -18px;

    display: inline-block;
    margin-left: 49px;
  }
}
@media only screen and (min-width: 942px) and (max-width: 1024px) {
  .popup-horizontal .unconsumedAmtValue.ruppboxicon {
    top: -18px;

    display: inline-block;
    margin-left: 49px;
  }
}
.hideDiv {
  display: none;
}
.errorDTName {
  top: 30px;
  position: relative;
}
.tdsrateboxdd .dropdown-menu {
  max-height: 140px;
  overflow-y: scroll;
}

.react-select__control--is-disabled {
  min-height: 34px!important;
}
.react-select__single-value{
  color: #777171!important;
}
.css-26l3qy-menu {
  z-index: 1010!important;
}


.challan-popup {
  z-index: 50;
  background-color: #ffffff;
}
/*Drgable */

.challan-popup-vertical {
  position: fixed;
  /*resize: both; !*enable this to css resize*! */
  overflow: auto;
  top: 0px;
  right: 0px;
  width: 43%;
  height: 100%;
  opacity: 0;
  min-height: 100%;
  min-width: 580px;
  max-height: 100%;
  max-width: 80%;
}

.challan-popup-horizontal {
  position: fixed;
  /*resize: both; !*enable this to css resize*! */
  overflow: auto;
  bottom: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  min-height: 200px;
  min-width: 100%;
  max-width: 100%;
  background-color: #ffffff;
}

.challan-popup-header {
  padding: 0px;
  /* cursor: move; */
  z-index: 10;
}

/*Resizeable*/

.challan-popup .resizer-left {
  width: 5px;
  height: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: e-resize;
}

.challan-popup .resizer-up {
  width: 100%;
  height: 5px;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  cursor: n-resize;
  z-index: 101;
}

.w-600
{
	max-width: 600px;
}
.zero_margin_bottom
{
	margin-bottom: 0px!important;
}

.challan-popup .resizer-both {
  width: 5px;
  height: 5px;
  background: transparent;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: nw-resize;
}

/*NOSELECT*/

.challan-popup * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.view_btn.focus,.view_btn:focus
{
  outline: 0!important;
  box-shadow: none!important;
}
.challan-popup-header
{
  border-bottom: 1px solid #dddddd;
}
.border-right
{
  border-right: 1px solid #dddddd;
}

.challan-overlay
{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .3;
  z-index: 40;
}
.sticky_top{
position: sticky;
top: 0px;
z-index: 2;
background: #fff;
padding-top: 15px;

}
.sticky_bottom
{
position: sticky;
bottom: 0px;
z-index: 2;
background: #fff;
text-align: center;
padding: 15px;
}
.form-group label,.form-group select
{
  font-size: 11px;
  color:#636363;
}
.form-group.col-md-4.createchallan_bsrcode_inputbox .react-autosuggest__container input {
  height: calc(1.5em + 0.75rem + 2px);
}
.react-select-container.createchallan_selbox_height.css-2b097c-container .react-select__control.css-yk16xz-control {
  height: calc(1.5em + 0.75rem + 1px);
}
.form-group select option
{
  font-size: 12px;
  color:#333333;
  font-weight: 500;
}
.form-control:focus {
    box-shadow: none;
}
.view_btn img
{
  width: 40px;
}

.cdf input,label{
  font-size: 11px !important;
  font-weight: 500;
}
.cdf input.amount-box{
  padding-left: 15px;
}

.float_card{
  background-color: #fff;
    width: 100%;
    float: left;
    padding: 0 15px;
}

.total_amount {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  margin-top: 25px
}

.cdf.mt-1.verpopformamt input.amount-box {
  padding-left: 15px;
  text-align: right;
}

.challan-popup-horizontal .form-group.col-md-4.depdatecalepadd .mdp-container.cdtvpopup {
  top: -6.2rem !important;
}

.challan-popup.challan-popup-vertical.cchallanlistpopup .row.toppaddvview {
  padding-top: 0.5rem;
}

@media only screen and (max-width: 767px) {

  .challan-popup.challan-popup-vertical.cchallanlistpopup {
  min-width: 300px;
}


.challan-popup.challan-popup-vertical.cchallanlistpopup .MyDatePicker{

z-index: 0;
}
.challan-popup.challan-popup-horizontal.bookhalig.cchallanlistpopup {
  height: 468px;
  min-width: 300px;
}

}

/* auto suggest css */

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 33px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__suggestion-match {
  color: red;
  font-weight: bold;
}


.form-group.createchallan_bsrcodepadd .react-autosuggest__container input.react-autosuggest__input {
  height: calc(1.5em + 0.75rem + 4px);
}


.k-widget.k-window.windowWrapper.create-challan-form.k-window-focused .form-group .react-select-container.css-2b097c-container .react-select__menu.css-26l3qy-menu .react-select__menu-list {
  max-height: 180px !important;
}
.k-widget.k-window.windowWrapper.create-challan-form.k-window-focused .form-group .react-select-container.css-2b097c-container .react-select__menu.css-26l3qy-menu .react-select__menu-list.css-11unzgr {
  max-height: 180px !important;
}
.noticeOrderslistparentclass {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  fieldset {
    display: block;
    margin: 9px 2px 2px !important;
    background-color: #fff;
    padding: 0.35em 0.75em 0.625em !important;
    border: 2px groove internal value;
  }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }

  a {
    &.notice_order_backbtn {
      color: #fc7d72;
      font-size: 12px;
    }
  }

  h4 {
    &.notice_order_titlfs {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  .table {
    &.table-striped {
      &.notices_orders_tb {
        border: 1px solid rgba(34, 36, 38, 0.15);
        padding: 5px;

        table {
          thead {
            tr {
              th {
                font-size: 12px;
                font-weight: 500;
                border-top: 0px;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
                vertical-align: baseline;
              }
            }
          }

          tr {
            td {
              padding: 0.25rem 0.75rem;
              font-weight: 500;
              font-size: 11px;
              vertical-align: inherit;
            }

            &.notices_orders_toprow {
              th {
                border-bottom: 0px;
                padding-top: 5px;
                padding-bottom: 0px;
              }
            }
          }

          .notice_order_textclr_blue {
            color: #31719b;
          }

          td {
            &.notice_order_textclr_overflowtext {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              word-wrap: break-word;
              max-width: 170px;
            }
          }

          .downloadicon_pointer {
            cursor: pointer;
          }

          .notice_order_tooltip {
            position: absolute;
            z-index: 1;
            top: -5px;
            right: 100%;
          }
        }
      }
    }
  }

  span.k-icon.k-i-more-vertical {
    font-size: 12px;
    font-weight: 500;
  }

  // .noticepaddright {
  //     padding: 0.25rem 1.9rem 0.25rem 0.75rem !important;
  // }

  .k-grid-header {
    .k-grid-filter {
      &:hover {
        background-color: transparent;
      }
    }

    .k-header {
      &.k-first {
        border-left-width: 0px;
      }
    }
  }

    & button.btn.btn-default.monthwisebtn {
        background-color: #31719b;
        border-color: #31719b;
        border-radius: 40px;
        color: #fff;
        padding: 3px 15px 6px 15px;
        font-size: 12px;
        font-weight: 500;
        margin-right: 5rem;
    }

    .noticepaddright {
        padding: 0.25rem 1.9rem 0.25rem 0.75rem !important;
    }
    .transcaptilize {
      text-transform: capitalize;
    }
  .table.table-striped.notices_orders_tb
    table
    tr
    td.notices_orders_rowbgclr_yell,
  .table.table-striped.notices_orders_tb
    table
    tr
    th.notices_orders_rowbgclr_yell {
    // background-color: rgba(255, 252, 225, 0.568627451);
    background-color:#fffbdb;
  }
  td.notices_orders_rowbordergrey {
    border-bottom: 1px solid #dee2e6;
}

  .table.table-striped.notices_orders_tb
    table
    tr
    td.notices_orders_rowbgclr_pink,
  .table.table-striped.notices_orders_tb
    table
    tr
    th.notices_orders_rowbgclr_pink {
    // background-color: #f3f3f3;
    background-color:#e7e1f3ed;
  }
  .k-grid-content.k-virtual-content {
    overflow: visible;
  }

  .k-widget.k-grid.table.table-striped {
    .k-grid-header {
      .k-grid-header-wrap table {
        thead tr {
          th:not(.k-filterable),
          th.notices_orders_rowbgclr_yell {
            .k-cell-inner {
              .k-link {
                cursor: unset;
              }
            }
          }
        }
      }
    }
  }

  button.btn.btn-default.upstraiserebtn {
    border: 1px solid #31719b;
    padding: 1px 5px 2px 5px !important;
    border-radius: 40px !important;
    font-size: 12px !important;
    margin-left: 12px !important;
    background-color: #31719b;
    font-weight: 500;
    color: #fff;
  }

  button.btn.btn-default.updaterequest {
    border: 1px solid #31719b;
    padding: 1px 5px 2px 5px !important;
    border-radius: 40px !important;
    font-size: 12px !important;
    margin-left: 12px !important;
    background-color: #31719b;
    font-weight: 500;
    color: #fff;
  }
}

td.noticesorder_textoverflow {
  max-width: 290px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.k-animation-container {
  &.k-animation-container-relative {
    .k-child-animation-container {
      .k-columnmenu-item-wrapper {
        span {
          &.k-widget {
            &.k-datepicker {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.top {
  top: -40%;
}


p.empwisesalary_notetextfs {
  font-size: 12px;
  font-weight: 500;
  color: #fc7d72;
}

@media (max-width: 767px) {
  .noticeOrderslistparentclass h4.compliancestcheck_titlefs {
    left: 0rem;
  }
}

body {
    background-color: #fff ;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 ;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}


/* add new css */

.tds_form16_cercilebox {
    width: 15px;
    height: 15px;
    position: relative;
    border: 1px solid #ddd;
    display: block;
    border-radius: 40px;
    background-color: #ddd;
    left: 8px;
}

.form16lastsign_DSCtableshow {
    display: none;
}
.form16lastsign_emailcerttableshow {
    display: none;
}
.form16lastsign_progresscerttableshow {
    display: none;
}

table.table.table-striped.form16_certificate_lastsign_tb thead tr th {
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 0px;
    vertical-align: baseline;
}

table.table.table-striped.form16_certificate_lastsign_tb  tr td {
    font-size: 11px;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    vertical-align: baseline;
}


p.form16_generatest_textno {
    font-size: 12px;
    font-weight: 500;
    color: #31719b;
    text-align: left;
    &.text_right{
        text-align: right;
    }
}


span.tds_form16_bg_greyclr {
    // background-color: green;
    // border-color: green;

    background-color: #fc7d72;
    border-color: #fc7d72;
}
span.activebg {
    
    background-color: green ;
    border-color: green ;
}
span.tds_form16_bg_greyclr02 {
    // background-color: green;
    // border-color: green;
    background-color: #fc7d72;
    border-color: #fc7d72;
    
}
span.activebg02 {
    
    background-color: green;
    border-color: green;
}
span.tds_form16_bg_greyclr03 {
    background-color: #fc7d72;
    border-color: #fc7d72;
}
span.activebg03 {
    
    background-color: #ddd;
    border-color: #ddd;
}
span.tds_form16_bg_greyclr04 {
    background-color: #fc7d72;
    border-color: #fc7d72;
}
span.activebg04 {
    
    background-color: #ddd;
    border-color: #ddd;
}

span.tds_form16_bg_greyclr05 {
    background-color: #fc7d72;
    border-color: #fc7d72;
}
span.activebg05 {
    
    background-color: #ddd;
    border-color: #ddd;
}

span.tds_form16_bg_greyclr06 {
    background-color: #fc7d72;
    border-color: #fc7d72;
}
span.activebg06 {
    
    background-color: #ddd;
    border-color: #ddd;
}

/* end add new css */


.tds_form16_certif_DSCpopup .modal-dialog {
    max-width: 450px !important;
    margin: 3rem auto;

    & .modal-header.tds_form16_certif_DSCheader {
        background-color: #eee;
        padding: 0px 15px;

        & h4.modal-title {
            font-size: 1rem;
            font-weight: 500;
            padding: 4px 0px;
        }

        button:focus {
            outline: 1px dotted;
            outline: 0px auto -webkit-focus-ring-color;
        }
    }
}



 .tds_form16_certif_progressmodal{
    width: 750px;
    margin: auto auto auto auto;


    p.karmjit_textclrcoral {
        font-size: 12px;
        font-weight: 500;
            color: #fc7d72;
    }
}

h4.sd_usermanage_manageassing_title {
    font-size: 1.2rem;
    font-weight: 600;
}

a.sd_usermanage_backbtn {
    font-weight: 600;
    font-size: 12px;
    color: #fa837b;
}


nav.sd_usermanage_nav {

    .nav.nav-tabs.sd_usermanage_tabs {
        border-bottom: 1px solid #dee2e6;
        line-height: 1;

        & a.nav-item.nav-link.sd_usermanage_tabbox {
            color: #31719b;
            background-color: #ffffff;
            border-color: #31719b;
            outline-color: #31719b !important;
            border-radius: 0px;
            padding: 7px 10px !important;
            font-size: 12px;
            border: 0px solid #31719b;
            font-weight: 700;
            border-width: 1.5px;
            border-right: 1px solid #31719b;
            text-align: center;
        }

        a.nav-item.nav-link.sd_usermanage_tabbox.active {
            color: #ffffff;
            background-color: #fc7d72;
        }
        a.nav-item.nav-link.sd_usermanage_borderleft {
            border-left: 0px !important;
        }

    }


}

p.sd_usermanage_zeroclients_ass_textfs {
    font-size: 12px;
    font-weight: 600;
    color: #726c6c; //#acacac;
}


table.table.table-striped.sd_usermanage_manageassing_table {

    thead tr th {
        font-size: 12px;
        font-weight: 500;
        border-top: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
        vertical-align: baseline;
    }

    tr td{
        padding: 0.25rem 0.75rem;
        font-weight: 500;
        font-size: 11px;
        vertical-align: baseline;
    }
    img.cursorpointer {
        cursor: pointer;
    }
    span.pl-1 img {
        vertical-align: sub;
    }

    
}

button.btn.btn-deauld.sd_usermanage_assing_submitbtn {
    background-color: #317199;
    color: #fff;
    border: 0px;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 10px 5px 10px;
    border-radius: 40px;
}


.modal.usermanage_inviteuser_modalbox.show .modal-dialog {
    margin: 3rem auto 0rem auto;
    max-width: 900px;

    .modal-header.usermanage_inviteuser_header {
        background-color: #eee;
        padding: 0px 15px;

        & h4.modal-title {
            font-size: 1rem;
            font-weight: 600;
            padding: 4px 0px;
        }

        button.close {
            position: relative;
            top: 3px;
        }
    }

    & .form-group.usermanage_inviteuser_grp {
        margin-bottom: 1rem;

        & label {
            font-size: 12px;
            color: #777171;
        }

        & input.form-control.usermanage_inviteuser_inputbox {
            font-size: 12px;
            font-weight: 600;
        }

        & input.form-control.usermanage_inviteuser_inputbox:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
        }
        .red_text {
            color: #ff2c39;
        }
    }

}

p.mb-0.assignclient_textfsclr {
    background-color: #fc7d72;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    max-width: 100%;
    min-width: 12%;
    width: 12%;
    padding: 2px 5px 4px 5px;
    text-align: center;
}

::placeholder{
    font-size: 12px;
    font-weight: 500;
    color: #d4d4d4 !important;
}

button.btn.btn-default.SendInvitation_btnbgclr {
    background-color: #fc7d72;
    color: #fff;
    border: 0px;
    padding: 3px 10px 5px 10px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 600;
}
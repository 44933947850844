.Createnewreturn_Q2browser_popup {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    &.show {
        background-color: #2222227a;

        .modal-dialog {
            max-width: 384px !important;


            .Createnewreturn_Q2browser_header.modal-header {
                padding: 0px 15px;
                background-color: #ddd;


                & .modal-title.h4 {
                    font-size: 1rem;
                    font-weight: 500;
                    padding: 4px 0px 4px 0px;
                    margin: 0;
                }
            }

            p.fileselectedtextfs {
                font-size: 12px;
                font-weight: 500;
            }

            table.table.table-striped.alreadyfiled_table{
                tbody tr{

                    &.cursorpoint td {
                        cursor: pointer;
                    }
    
                    &.textrow_clorgrey td {
                        color: #a3a3a3;
                        opacity: 0.5;
                    }
    
                }

                 thead tr {

                th {
                    font-size: 12px;
                    text-align: center;
                    font-weight: 500;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border: 0px;
                    vertical-align: baseline;
                }
            }
        }
        }

    }
}

.slick-arrow {
    background: orangered !important;
}

.mappedtransitionchallans{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    .k-window-content{
        padding: 2px;
    }
    .k-window-titlebar.k-dialog-titlebar{
        padding: 7px 11px;
        background-color: #31719b;
        .k-window-title{
            padding: 0.5em 0;
            margin: -1.5em 0;
            font-size: 16px;
            line-height: 2.5;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: default;
            flex: 1 1;
            display: flex;
            flex-flow: row nowrap;
            padding-top: 18px;
        
            h4.modal-title{
                padding-top: 0;
                font-size: 1.1rem;
                margin: 0px
            }
        }
    }
    
    .table-responsive.table-responsive-xl.table-responsive-sm{
        .table.table-striped.blukimport_tb.scrollvisible{
            .k-grid-content.k-virtual-content{
                max-height: 250px;
            }
        }
    } 
    .k-widget.k-window.windowWrapper.k-window-focused{
        z-index: 200 !important;
    }
}


.monthwisedeductee{
    .k-widget.k-window.windowWrapper .k-window-title {
        font-size: 18px;
        color: #ffffff;
        line-height: 3.4;
    }
    .k-grid-content.k-virtual-content{
      max-height: 360px;
      overflow: auto;
    }
}

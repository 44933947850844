.incomplete_deductorlis_css {

    a {
        &.backtonewcl_textclr {
            font-size: 12px;
            padding: 4px 0;
            color: #fc7d72;
            text-decoration: none;
            font-weight: 500;
        }
    }

    h4 {
        &.blukimport_titlefs {
            font-size: 1.2rem;
            font-weight: 500;
            text-align: center;
        }
    }

    p {
        &.blukimp_suntitlefs {
            font-size: 12px;
            color: #837f7f;
            font-weight: 500;
            text-align: center;
        }
    }

    .card {
        &.blukimp_cardbox {
            padding: 0;
        }
    }

    .table{
        &.table-striped {
            &.blukimport_tb {
                .k-grid-container{
                    max-height: 440px;
                    overflow: auto;
                }
                thead {
                    tr {
                        th {
                            border-top: 0;
                            font-size: 12px;
                            text-align: center;
                            font-weight: 500;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-left: 0px;

                            & .k-grid-column-menu.k-grid-filter span.k-icon.k-i-more-vertical {
                                font-size: 12px;
                            }
                        }
                    }
                }

                tr {
                    td {
                        font-size: 11px;
                        padding: 0.25rem 0.2rem;
                        font-weight: 500 !important;
                        vertical-align: middle;

                        & input.form-control.incompded_inputheight {
                            height: calc(1.2em + 0.75rem + 2px);
                        }

                        input {
                            &.form-control {
                                padding: 0.30rem 0.50rem;
                                &.mypc_credinputtextfs {
                                    height: calc(1.2em + 0.75rem + 2px);
                                    padding: 0.8rem 0.2rem;
                                    font-size: 11px;
                                }

                                &.blukimp_inputbox_borderrightnon {
                                    border: 0px;
                                    background-color: transparent;
                                    font-size: 11px;
                                    font-weight: 500;
                                    height: calc(1.2em + 0.75rem + 2px);
                                    padding: 0.8rem 0.2rem;
                                    font-size: 11px;
                                }

                                &.blukimp_credinputtextfs {
                                    &:focus {
                                        color: #495057;
                                        background-color: #fff;
                                        border-color: #80bdff;
                                        outline: 0;
                                        box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
                                    }
                                }
                            }
                        }

                        .input-group-prepend {
                            &.blukimp_updatecredisysicon {
                                &.eyeblue_icon {
                                    padding: 8px 5px 5px 5px;
                                    border: 1px solid #ced4da;
                                    border-left: 0px;
                                    background-color: transparent;
                                }
                            }
                        }

                        .eyeblue_icon {
                            img {
                                -o-object-fit: scale-down;
                                object-fit: scale-down;
                            }
                        }

                        button {

                            &.btn {
                                &.btn-default {
                                    &.blukimp_captchbtn {
                                        background-color: #31719b;
                                        border-color: #31719b;
                                        border-radius: 40px;
                                        color: #fff;
                                        padding: 2px 10px 3px 10px;
                                        font-size: 12px;
                                        font-weight: 500;
                                        border: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
                &.incvisibleover{
                    .k-grid-container .k-grid-content.k-virtual-content{
                        overflow: visible;
                    } 
                }
            }
        }
    }


    .modal {
        &.fade {
            &.tds_blukimportdedcut_modal {
                &.show {
                    .modal-dialog {
                        .modal-header {
                            &.tds_blukimportdedcut_header {
                                background-color: #eee;
                                padding: 0px 15px;

                                h4 {
                                    &.modal-title {
                                        font-size: 1rem;
                                        font-weight: 500;
                                        padding: 4px 0px 4px 0px;
                                    }
                                }

                                button {
                                    &.close {
                                        position: relative;
                                        top: 2px;
                                    }
                                }
                            }
                        }


                        .table {
                            &.table-striped {
                                &.tds_blukimportdedcut_tb {
                                    thead {
                                        tr {
                                            th {
                                                border-top: 0;
                                                font-size: 12px;
                                                text-align: center;
                                                font-weight: 500;
                                                padding-top: 5px;
                                                padding-bottom: 5px;
                                                padding-left: 0px;
                                            }
                                        }
                                    }

                                    tr {
                                        td {
                                            font-size: 11px;
                                            padding: 0.25rem 0.75rem;
                                            font-weight: 500;
                                            vertical-align: baseline;
                                        }
                                    }
                                }
                            }
                        }


                        button {
                            &.btn {
                                &.btn-default {
                                    &.blukimp_deduct_btnbgclr {
                                        background-color: #31719b;
                                        border-color: #31719b;
                                        border-radius: 40px;
                                        color: #fff;
                                        padding: 4px 20px 6px 20px;
                                        font-size: 12px;
                                        font-weight: 500;
                                        border: 0px;
                                    }
                                }
                            }
                        }

                        p {
                            &.tds_TRACES_credentials_textfs {
                                font-size: 12px;
                                font-weight: 500;
                            }
                        }

                        .form-row {
                            &.tds_TRACES_credentials_form {
                                label {
                                    &.tds_TRACES_credentials_leb {
                                        font-size: 12px;
                                        color: #777171;
                                    }
                                }

                                input {
                                    &.form-control {
                                        &.inputpaddbox {
                                            height: calc(1.2em + 0.75rem + 2px) !important;
                                            padding: 0.2rem 0.75rem;
                                            font-size: 12px;
                                            font-weight: 500;

                                            &:focus {
                                                color: #495057;
                                                background-color: #fff;
                                                border-color: #80bdff;
                                                outline: 0;
                                                box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
                                            }
                                        }

                                        &.TRACES_cred_credinputtextfs {
                                            height: calc(1.2em + 0.75rem + 2px);
                                            padding: 0.8rem 0.6rem;
                                            font-size: 11px;
                                            font-weight: 500;

                                            &.form-control {
                                                &:focus {
                                                    color: #495057;
                                                    background-color: #fff;
                                                    border-color: #80bdff;
                                                    outline: 0;
                                                    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
                                                }
                                            }
                                        }

                                        &.TRACES_cred_inputbox_borderrightnon {
                                            border-right: 0px;
                                        }
                                    }
                                }

                                .input-group-prepend {
                                    &.TRACES_cred_updatecredisysicon {
                                        padding: 5px 5px 5px 1px;
                                        border: 1px solid #ced4da;
                                        border-left: 0px;
                                        background-color: #fff;
                                        border-radius: 0px 5px 5px 0px;
                                    }

                                    &.eyeblue_icon {
                                        img {
                                            -o-object-fit: scale-down;
                                            object-fit: scale-down;
                                        }
                                    }
                                }

                                p {
                                    &.readcaptcha_textfs {
                                        font-size: 12px;
                                        font-weight: 500;
                                    }
                                }

                                a {
                                    &.recaptcha_clciktextfsclr {
                                        color: #31719b;
                                        font-weight: 700;
                                        padding: 0px 5px;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }

                &.tds_TRACES_credentials_popupwidth {
                    &.show {
                        .modal-dialog {
                            max-width: 460px;
                        }
                    }
                }
            }
        }

        &.tdsreimport_modalbox {
            &.show {
                .modal-dialog {
                    .modal-header {
                        &.tdsreimport_modalboxheader {
                            background-color: #eee;
                            padding: 0px 15px;

                            h4 {
                                &.modal-title {
                                    font-size: 1rem;
                                    padding: 4px 0px 4px 0px;
                                    font-weight: 500;
                                }
                            }

                            button {
                                &.close {
                                    position: relative;
                                    top: 3px;
                                }

                                &:focus {
                                    outline: 1px dotted;
                                    outline: 0px auto -webkit-focus-ring-color;
                                }
                            }
                        }
                    }

                    .selecttype-reimportdropzone {
                        flex: 1 1;
                        display: flex;
                        text-align: center;
                        margin: 0rem 0rem;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        padding: 20px;
                        border-radius: 2px;
                        border: 2px dashed #eee;
                        background-color: #fafafa;
                        color: #bdbdbd;
                        outline: none;
                        transition: border 0.24s ease-in-out;
                        height: 150px;
                        font-size: 12px;
                        justify-content: center;
                        cursor: pointer;
                    }

                    p {
                        &.reimport_selecttypefileselectedtext {
                            font-size: 12px;
                            padding-top: 3px;
                            text-align: left;
                        }
                    }

                    button {
                        &.btn {
                            &.btn-default {
                                &.reimport_conpgain-importbtnbgclr {
                                    background-color: #31719b;
                                    border-color: #31719b;
                                    border-radius: 28px;
                                    color: #fff;
                                    padding: 4px 20px 5px;
                                    font-size: 12px;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .blukimport_leftsidepopupbox {
        position: fixed;
        padding: 0 0.4rem 0.1rem 0.2rem !important;
        background-color: #31719b;
        color: #fff;
        z-index: 20;
        top: 49%;
        transform: rotate(270deg);
        border-radius: 0 0 5px 5px;
        left: -3.5rem;
        overflow: hidden;
    }

    .blukimport_AnnouncementsIcon {
        position: absolute;
        top: 0;
        right: -15px;
        transform: rotate(90deg);
        cursor: pointer;
    }

    .blukimport_Announcements {
        background-color: #fc7d72;
        width: 55px;
        position: relative;
        padding-left: 26px;
        padding-top: 2px;
    }

    .Dropdown {
        &.blukimport_Announcements-wrapper {
            .blukimport_Announcements-bubble {
                display: none;
                width: 24px;
                height: 26px;
            }

            &.blukimport_has-unseen-items {
                .blukimport_Announcements-bubble {
                    box-sizing: border-box;
                    overflow: hidden;
                    transform: rotate(15deg);
                    border-radius: unset;
                    display: block;
                    position: absolute;
                    padding: 0;
                    top: -10px;
                    background-color: transparent;
                    left: 40px;

                    &:before {
                        content: "";
                        border: 2px solid #fff;
                        border-radius: 50%;
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        animation: fadeInOut 1s ease 0.2s infinite normal none running;
                        left: -4px;
                        bottom: -4px;
                    }

                    &:after {
                        content: "";
                        border: 2px solid #fff;
                        border-radius: 50%;
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        animation: fadeInOut 7s ease 0.7s infinite normal none running;
                        left: -6px;
                        bottom: -6px;
                    }
                }
            }
        }
    }

    button {
        &.blukimport_textfs {
            color: rgb(255, 255, 255);
            font-size: 15px;
            font-weight: 600;
            padding-right: 40px;
            padding-left: 25px;

            &:hover {
                color: rgb(255, 255, 255);
            }
        }
    }

    .svg-inline--fa {
        display: inline-block;
        font-size: inherit;
        height: 1em;
        overflow: visible;
        vertical-align: -0.125em;

        &.fa-w-18 {
            width: 1.125em;
        }
    }

    svg {
        &:not(:root).svg-inline--fa {
            overflow: visible;
        }
    }

    .disabled-color {
        background-color: #6998b7 !important;
        cursor: unset !important;
    }

    input {
        .form-control {
            padding: 0.30rem 0.50rem;
            &.input-error {
                border: 1px solid #fc6a6a !important;
                box-shadow: 0px 1px 8px 0px #ff4545 !important;
            }
        }
    }

    .modal {
        &.tdsreimport_modalbox {
            &.show {
                .modal-dialog {
                    margin: 6rem auto auto 0rem;
                    max-width: 370px;
                }
            }
        }
    }

    .form-groupposition .input-group,
    .form-groupposition .input-group>.form-control {
        position: unset;
    }
}

svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
}

@keyframes fadeInOut {
    0% {
        Opacity: 0;
    }

    100% {
        Opacity: 1;
    }
}

@media only screen and (max-width: 1550px) {
    .incomplete_deductorlis_css .table.table-striped.blukimport_tb tr td button.btn.btn-default.blukimp_captchbtn.create_deductor {
        min-width: 120px;
    }
}






.cursor_pointer {
    cursor: pointer;
}




   
/*-------- cl master banner css  ----------*/
.clientmaster_leftbottom_bannerbox {
    position: fixed;
    width: 11%;
    border-radius: 0 0px 0 0;
    margin-left: -0.6rem;
    bottom: 6px;
    cursor: pointer;
    overflow: hidden;
    transition: 2s;
    box-shadow: 0px 1px 16px #000000;
    cursor: pointer;
}
  
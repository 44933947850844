/*table*/
.kdk_data form input
{
	padding-left: 35px;
	width: 400px!important;
}
.kdk_data form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 12px;
  color:#dddddd;
}
.kdk_data form input::-moz-placeholder { /* Firefox 19+ */
  font-size: 12px;
  color:#dddddd;
}
.kdk_data form input:-ms-input-placeholder { /* IE 10+ */
  font-size: 12px;
  color:#dddddd;
}
.kdk_data form input:-moz-placeholder { /* Firefox 18- */
  font-size: 12px;
  color:#dddddd;
}
span.itd_logo.float-right.efilinglogo {
    margin-top: -13px;
}
.rounded_btn
{
	border-radius: 40px;
	padding:5px 22px 7px 22px;
	font-size: 12px;
	font-weight: 500;
}
.light-cancel-btn
{
	background-color: #f2f2f2;
	color:#636363;
	border:1px solid #dddddd;
}
.captcha_img img
{
	height: 38px;
}
.light_grey_text
{
	color:#dddddd;
}
.kdk_data, .card
{
	background-color: #fff;
	border-radius: 3px;
	width: 100%;
	float: left;
	padding:15px;
	
}
.kdk_data_table
{
	max-height: 60vh;
	overflow-y: auto;
}
.kdk_data a span img
{
	width: 15px;
}
.kdk_data a
{
	text-decoration: none;	
	font-size: 13px;
}
.kdk_data_table tr td span img
{
	width: 20px;
	margin:0px 10px;
}
.kdk_data_table
{
	width: 100%
}
.kdk_data_table tr th
{
	font-size: 14px;
}
.kdk_data_table tr td
{
	font-size: 12px;
}
.border_left
{
	border-left:1px solid #dddddd;
}
.create_deductor img
{
	width: 25px!important;
}
.top_image_text form:after {
    font: 16px FontAwesome;
    content: "\f002";
    color: #999;
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 25px;
}
input.form-control.entermcreated {
    margin: 0px 17px 0px 0px;
}
/*Modal popup*/

.modal-xl
{
	max-width: 1140px!important;
}
.toggle_mail_sms
{
	border:1px solid #dddddd!important;
	border-radius: 50px;
	width: 260px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #31719B!important;
    border-radius: 50px;
}
.modal-header
{
	border:none!important;
}
.mailbox,.preview_section
{
	border:1px solid #dddddd;
	padding: 15px 15px 0px 15px;
}
.bdr_right
{
	border-right: 1px solid #dddddd;
}
.nav-link 
{
    padding: 0.5rem 2rem!important;
}
.preview_section_mobile
{
	background-image: url('../../images/mobile.png');
	    height: 411px;
    width: 202px;
}
.preview-device
 {
    background-size: 100%;
    margin: auto;
    }
    .manual_text
    {
    	text-align: center;
    	margin: auto;
    	width: 100%;
    	float: left;
    }
    .padding_style
    {
    	padding:0px 15px;
	}
  
  .light_color_bg_disabled {
    background-color: #e6bbb8;
    color: #ffffff;
    border: 1px solid #e6bbb8;
    padding: 5px 15px;
    border-radius: 3px;
    font-size: 12px;
  }

  span.text-center.crtdedtor h4 {
	padding-right: 17rem;
	padding-top: 3px;
}
.input-group-prepend.eyeipbox-create-deductor {
    margin-right: -1px;
    padding: 8px 4px 2px 3px;
    border: 1px solid #ced4da;
    border-radius: 0px .25rem .25rem 0px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #31719b;
  }
	span.text-center.crtdedtor h4 {
		padding-right: 23rem;
		padding-top: 3px;
	}
	.mobileotpsect {
		display: inline-flex;
	}

	.fade.modelbox_sm.downloadcsiffvu_modal.modal.show .modal-dialog {
		max-width: 550px;
		margin: 5rem 0rem 1rem 0rem;
	}
	.fade.modelbox_sm.downloadcsiffvu_modal.modal.show .modal-dialog .doncoltscsih.modal-header {
		background-color: #eee;
		padding: 0px 15px;
	}
	.fade.modelbox_sm.downloadcsiffvu_modal.modal.show .modal-dialog .doncoltscsih.modal-header .modal-title.h4 {
		padding: 4px 0px;
		font-size: 1rem;
		font-weight: 500;
	}
	.fade.modelbox_sm.downloadcsiffvu_modal.modal.show .modal-dialog .doncoltscsih.modal-header button.close {
		position: relative;
		top: 4px;
	}
	.fade.modelbox_sm.downloadcsiffvu_modal.modal.show .modal-dialog button.btn.btn-secondary {
		background-color: #5a6268;
		padding: 3px 15px 5px 15px;
		font-size: 12px;
		font-weight: 500;
		border-radius: 40px;
	}
	.fade.modelbox_sm.downloadcsiffvu_modal.modal.show .modal-dialog button.btn.btn-primary {
		background-color: #31719b;
		padding: 3px 15px 5px 15px;
		font-size: 12px;
		font-weight: 500;
		border-radius: 40px;
	}


	.btn.btn-default.validup_generatefvu_btn.validup_downFVUbtn.latefees_paybtn_bgclr {
		background-color: #31719b;
		color: #fff;
		padding: 4px 20px 5px 20px;
		font-size: 12px;
		font-weight: 500;
		border: 0px;
		border-radius: 40px;
	}

  /*  ============= start media code=================*/

  @media only screen and (max-width: 767px) and (min-width: 320px){
	span.itd_logo.float-right.efilinglogo {
		margin-top: 0px;
	}
	span.itd_logo.float-right.efilinglogo img {
		width: 190px;
		float: right;
	}
	span.text-center.crtdedtor h4 {
		padding-right: 0rem !important; 
		padding-top: 3px;
	}
}


  @media only screen and (max-width: 942px) and (min-width: 768px){
		span.itd_logo.float-right.efilinglogo {
			margin-top: 0px;
		}
		span.itd_logo.float-right.efilinglogo img {
			width: 190px;
			float: right;
		}
		span.text-center.crtdedtor h4 {
			padding-right: 14rem;
			padding-top: 3px;
		}
  }

  

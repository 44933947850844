* {
    margin: 0;
    padding: 0;
}

body {
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(33, 37, 41);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    background-color: rgb(255, 255, 255) !important;
    font-weight: 500 !important;
}

fieldset {
    display: block;
    margin: 9px 2px 2px !important;
    background-color: #fff;
    padding: 0.35em 0.75em 0.625em !important;
    border: 2px groove (internal value);
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: -0.4rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

fieldset.tdscomp_fs {
    border: 1px solid #31719b;
    max-width: 100%;
}

legend.tdscomp_leg {
    width: 23%;
}

legend.tdscomp_leg h4 {
    font-size: 1.1rem;
    padding: 0px 5px;
    position: relative;
    top: 2px;
}

table.table.table-striped.tdscomp_tb thead tr th {
    border-top: 0;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    vertical-align: middle;
    padding-bottom: 5px;
    padding-top: 0px;
}

table.table.table-striped.tdscomp_tb tr td {
    font-size: 11px;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
}

td.topfive_decutees_withmaxtax_overflowtext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

tr.tdscomp_timel2tdheadting td {
    background-color: rgba(241, 197, 193, .611764705882353);
    font-weight: 600;
}

.tdscomp_textclrunderline {
    color: #007bff;
    text-decoration: underline;
}

button.btn.btn-default.tds_generatechallan_btnngclr {
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    background-color: #fc7d72;
    color: #fff;
    border-radius: 40px;
    padding: 4px 15px 6px 15px;
}

button.btn.btn-default.tds_proceedver_btnngclr {
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    background-color: #31719b;
    color: #fff;
    border-radius: 40px;
    padding: 4px 15px 6px 15px;
}

fieldset.tds_healthAnalysis_report_fs {
    border: 1px solid #fc7c71;
}

.tds_healthAnalysis_report_inndev {
    display: block;
}

.tds_healthAnalysis_report_leftside {
    float: left;
}

.tds_healthAnalysis_report_leftside h4 {
    font-size: 1.1rem;
    padding: 0 5px;
    top: 8px;
    position: relative;
}

.tds_healthAnalysis_report_innerhieghtdiv {
    height: 1px;
    width: 100%;
    position: relative;
}

.tds_healthAnalysis_report_rightsidediv {
    float: right;
}

.tds_healthAnalysis_report_middlebor {
    border-top: 1px solid #fc7d72;
    display: flex;
    position: relative;
    margin-top: 19px;
}

button.btn.btn-default.tds_healthAnalysis_report_downloadbtn {
    background-color: #31719b;
    color: #fff;
    border: 0px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 15px 4px 15px;
}

.tds_healthAnalysis_report_numbbox01 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tds_healthAnalysis_report_boxbgclr {
    background-color: rgb(238, 238, 238);
    padding: 7px 5px 9px 5px;
    display: flex;
    top: 0px;
    position: relative;
}

span.tds_healthAnalysis_report_rightsideborder {
    padding: 0px 10px;
    color: #c8b9b4;
    font-size: 12px;
    font-weight: 500;
}

span.tds_healthAnalysis_report_rightsideborder:last-child {
    display: none;
}

.tds_healthAnalysis_report_titlefs {
    font-size: 12px;
    font-weight: 500;
}

span.tds_healthAnalysis_report_chalanamt {
    font-size: 13px;
    color: #31719b;
    font-weight: 700;
    padding-left: 2px;
}

table.table.table-striped.tds_healthAnalysis_report_tb thead tr th {
    border-top: 0;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    vertical-align: middle;
    padding-bottom: 5px;
    padding-top: 31px;
}

table.table.table-striped.tds_healthAnalysis_report_tb tr td {
    font-size: 11px;
    padding: 0.49rem 0.75rem;
    font-weight: 500;
}

td.tds_healthAnalysis_report_textwarp {
    writing-mode: vertical-rl;
    text-align: center;
    transform: rotate(180deg);
    font-weight: 700 !important;
    background-color: #f9f9f9;
}

td.healthanan_challancol_draggreybgclr {
    background-color: #f3f3f3 !important;
}

td.healthanan_pancol_lightgreybgclr {
    background-color: #e0e0e0 !important;
}

td.tds_healthAnalysis_report_overflowtext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 250px;
}

td.tds_healthAnalysis_report_textclr_bluewline {
    color: #31719b;
    text-decoration: underline;
}

tr.tds_healthAnalysis_report_rowbglcr_grey td {
    background-color: #f5f5f5;
}

tr.tds_healthAnalysis_report_rowbglcr_grey td {
    background-color: #f5f5f5;
}

tr.tds_healthAnalysis_report_rowbglcr_white td {
    background-color: #fff;
}


.modal.fade.tds_healthAnalysis_report_mailsend_popup.show .modal-dialog {
    margin: 5rem auto 0rem auto;
}

.modal-header.tds_healthAnalysis_report_mailsend_header {
    background-color: #eee;
    padding: 0px 15px;
}

.modal-header.tds_healthAnalysis_report_mailsend_header h4.modal-title {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 4px 0px 5px 0px;
}

.modal-header.tds_healthAnalysis_report_mailsend_header button.close {
    top: 6px;
    position: relative;
}

.modal-header.tds_healthAnalysis_report_mailsend_header button:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

p.tds_healthAnalysis_report_mailsend_textfs {
    font-size: 12px;
}

ul.tds_healthAnalysis_report_mailsend_listbox {
    padding: 0px 15px;
}

ul.tds_healthAnalysis_report_mailsend_listbox li {
    list-style: auto;
    font-size: 12px;
    font-weight: 500;
}

input.form-control.mailsend_listbox_inputwidth {
    border: 1px solid #ced4da;
    height: calc(1.3em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
}

input.form-control.mailsend_listbox_inputwidth:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}

button.mailsend_listbox_sendbtnbgclr {
    background-color: #31719b;
    color: #fff;
    border: 0px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 22px 7px 22px;
}

.modal-header.tds_healthAnalysis_report_summaryofchallan_header {
    background-color: #eee;
    padding: 0px 15px;
}

.modal-header.tds_healthAnalysis_report_summaryofchallan_header h4.modal-title {
    font-size: 1.2rem;
    padding: 3px 0px 5px 0px;
}

.modal-header.tds_healthAnalysis_report_summaryofchallan_header button.close {
    top: 5px;
    position: relative;
}

.modal-header.tds_healthAnalysis_report_summaryofchallan_header button:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

.tds_healthAnalysis_report_summaryofchallan_borlefts {
    border-right: 1px solid #8a8888;
}

.tds_healthAnalysis_report_summaryofchallan_topmarbox {
    padding-top: 7px;
    margin-top: 7rem;
}

.tds_summary_topborbox {
    border-top: 1px solid #333;
    padding-top: 5px;
    padding-bottom: 5px;
}

span.summary_totalfs {
    padding-left: 15px;
    font-size: 12px;
    font-weight: 500;
}

.tds_healthAnalysis_report_summaryofchallan_liabilitypadd p {
    margin-bottom: 0.25rem;
    font-size: 11px;
    padding: 0.15rem 0.15rem;
    font-weight: 500;
}

td.tds_comp_overflowtext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 150px;
}

fieldset.summary_challantodedutee_trans_fs {
    border: 1px solid #fc7d72;
    padding: 0px 15px 0px 15px !important;
}

legend.summary_challantodedutee_trans_leg h4 {
    font-size: 1.1rem;
    font-weight: 500;
    top: 2px;
    position: relative;
    padding: 0px 5px;
}

legend.summary_challantodedutee_trans_leg {
    width: auto;
}

fieldset.decutees_sectionwisechart_fs {
    border: 1px solid #fc7d72;
}

legend.topfive_decutees_sectionwise_leg h4 {
    font-size: 1.2rem;
    font-weight: 500;
}

fieldset.topfive_decutees_withmaxtax_comp_fs {
    border: 1px solid #fc7d72;
}

legend.topfive_decutees_withmaxtax_comp_leg h4 {
    font-weight: 500;
    font-size: 1.2rem;
    top: 2px;
    position: relative;
    padding: 0px 5px;
}

legend.topfive_decutees_withmaxtax_comp_leg {
    width: 29%;
}

fieldset.challanwise_summarymap_fs {
    border: 1px solid #31719b;
}

legend.topfive_decutees_challanwise_leg {
    width: 29%;
}

legend.topfive_decutees_challanwise_leg h4 {
    font-weight: 500;
    font-size: 1.2rem;
    top: 2px;
    position: relative;
    padding: 0px 5px;
}

.card.tdsphase4_generatechallan_cardbox {
    padding: 6px 16px;
    background-color: #f2f2f2;
    border-right: 0 solid;
    border-radius: 0;
    bottom: 0;
    position: fixed;
    z-index: 1;
    display: block;
    width: 100%;
}


fieldset.decutees_compliancecheck_fs {
    border: 1px solid #31719b;
}

.decutees_compliancecheck_inndev {
    display: block;
}

.decutees_compliancecheck_leftside {
    float: left;
}

.decutees_compliancecheck_leftside h4 {
    font-size: 1.1rem;
    font-weight: 500;
    top: 9px;
    position: relative;
    padding: 0px 5px;
}

.decutees_compliancecheck_innerhieghtdiv {
    height: 1px;
    width: 100%;
    position: relative;
}

.decutees_compliancecheck_rightsidediv {
    float: right;
}

button.btn.btn-default.decutees_compliancecheck_re-check {
    background-color: #31719b;
    color: #fff;
    border: 0px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 15px 4px 15px;
}

button.btn.btn-default.decutees_compliancecheck_viewcompdetabtnbgclr {
    background-color: #31719b;
    color: #fff;
    border: 0px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 15px 4px 15px;
}

.decutees_compliancecheck_middlebor {
    border-top: 1px solid #31719b;
    display: flex;
    position: relative;
    margin-top: 19px;
}

span.comphealth_linkicongifbox {
    display: inline-flex;
}

table.table.table-striped.comphealth_tdscomp_allocatefees_tb thead tr th {
    border-top: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

table.table.table-striped.comphealth_tdscomp_allocatefees_tb thead tr td {
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    font-size: 11px;
    vertical-align: middle;
}

input.form-control.comphealth_tdscomp_allocatefees_inputbox {
    height: calc(1.2em + 0.75rem + 2px);
    font-size: 11px;
    font-weight: 500;
}

input.form-control.comphealth_tdscomp_allocatefees_inputbox:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, .25);
}

td.totaladjust_textclrblod {
    font-size: 12px;
    font-weight: 700;
}

td.totaladjust_textclrblodred {
    font-size: 12px;
    font-weight: 700;
    /* color: red; */
    background-color: #fff;
    border-top: 0px !important;
}
.green_text
{
	color:#40BC00;
}
.red_text
{
	color:red;
}

p.comphealth_tdscomp_allocatefees_remember_textfs {
    font-size: 11px;
    font-weight: 500;
    font-style: italic;
    color: #949494;
    margin-bottom: 0px;
    position: relative;
    top: 7px;
}

button.comphealth_tdscomp_allocatefees_savebtn {
    font-size: 12px;
    padding: 3px 15px 5px 15px;
    background-color: #31719b;
    border-radius: 40px;
    font-weight: 500;
    color: #fff!important;
    border: 0px;
}

img.allocatefees_cuticon {
    cursor: pointer;
}

h4.modal-title.comphealth_tdscomp_allocatefees_title {
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    padding: 8px 0px;
}

span.comphealth_tdscomp_allocatefees_totalefees {
    font-size: 14px;
    font-weight: 500;
}
span.comphealth_tdscomp_allocatefees_textblue {
    color: #31719b;
}

.fee-allocations .k-grid-content {
    overflow-y: auto;
}


/*========== start media code ================*/

@media (max-width: 767px) {
    p.tds_returndetails_topfive_subtitlefs {
        left: 0rem;
    }

    h4.tds_returndetails_topfive_titlefs {
        font-size: 1rem;
        text-align: center;
    }

    legend.topfive_decutees_withmaxtax_leg {
        width: 100%;
    }

    legend.topfive_decutees_withmaxtax_leg h4 {
        font-size: 0.7rem;
    }

    legend.topfive_decutees_sectionwise_leg {
        width: 100%;
    }

    legend.topfive_decutees_sectionwise_leg h4 {
        font-size: 0.7rem;
    }

    legend.topfive_decutees_challanwise_leg {
        width: 100%;
    }

    legend.topfive_decutees_challanwise_leg h4 {
        font-size: 0.7rem;
    }

    legend.topfive_decutees_sectionwise_leg h4 {
        font-size: 0.7rem;
    }

    legend.tdscomp_leg {
        width: 71%;
    }

    legend.summary_challantodedutee_trans_leg h4 {
        font-size: 0.9rem;
    }

    .tds_healthAnalysis_report_boxbgclr {
        display: block;
    }

    legend.topfive_decutees_withmaxtax_comp_leg {
        width: 100%;
    }

}

@media (min-width: 768px) and (max-width: 820px) {
    legend.tdscomp_leg {
        width: 65%;
    }

    legend.summary_challantodedutee_trans_leg h4 {
        font-size: 0.9rem;
    }

    .tds_healthAnalysis_report_summaryofchallan_topmarbox {
        padding-top: 49px;
    }

    .tds_healthAnalysis_report_boxbgclr {
        display: block;
    }

    legend.topfive_decutees_withmaxtax_comp_leg {
        width: 57%;
    }

}

@media (min-width: 821px) and (max-width: 913px) {


    legend.summary_challantodedutee_trans_leg h4 {
        font-size: 1rem;
    }

    .tds_healthAnalysis_report_summaryofchallan_topmarbox {
        padding-top: 54px;
    }

    legend.tdscomp_leg {
        width: 56%;
    }
}

@media (min-width: 914px) and (max-width: 1025px) {
    legend.tdscomp_leg {
        width: 48%;
    }

    .tds_healthAnalysis_report_summaryofchallan_topmarbox {
        padding-top: 38px;
    }
}

@media (min-width: 1026px) and (max-width: 1173px) {
    legend.tdscomp_leg {
        width: 28%;
    }

    .tds_healthAnalysis_report_summaryofchallan_topmarbox {
        padding-top: 21px;
    }

    legend.summary_challantodedutee_trans_leg h4 {
        font-size: 1rem;
    }
}

@media (min-width: 1174px) and (max-width: 1283px) {
    legend.tdscomp_leg {
        width: 28%;
    }

    .tds_healthAnalysis_report_summaryofchallan_topmarbox {
        padding-top: 21px;
    }

    legend.summary_challantodedutee_trans_leg h4 {
        font-size: 1rem;
    }
}

@media (min-width: 1284px) and (max-width: 1350px) {
    legend.tdscomp_leg {
        width: 28%;
    }

    .tds_healthAnalysis_report_summaryofchallan_topmarbox {
        padding-top: 7px;
    }

}
body {
  background-color: #fff !important;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.card.tds_form3CDRevamp_genrepottyp_cardbox {
  padding: 5px 10px;

  & p.tds_form3CDRevamp_genrepottyp_textfs {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0px;
      background-color: #f5f5f5;
      padding: 5px 5px;
      /* box-shadow: 0px 1px 3px #222; */

      & span.genrepottyp_seticon {
          float: left;
          padding: 0px 0px 0px 19px;
      }
  }

  

  & table.table.table-striped.tds_form3CDRevamp_pansit_tb {
      thead tr th {
          border-top: 0;
          font-size: 12px;
          text-align: center;
          font-weight: 500;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 0px;
      }

      tr td{
          font-size: 11px;
          padding: 0.25rem 0.75rem;
          font-weight: normal;
          vertical-align: baseline;
      }

      & td.tds_form3CDRevamp_pancell_bgclrwhite {
          background-color: #fff;
          border-right: 1px solid #ddd;
          vertical-align: middle;
      }
  }
}

.tds_form3CDRevamp_yr_dropdownbox {

  & button.btn.btn-default.dropdown-toggle.tds_form3CDRevamp_yr_dropbtn {
      padding: 3px 10px;
      font-size: 12px;
      font-weight: 500;
      border: 1px solid #ddd;
      border-radius: 0px;
      background-color: aliceblue;
  }

  & button.btn.btn-default.dropdown-toggle.tds_form3CDRevamp_yr_dropbtn:focus {
      outline: 0;
      box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
  }

  & .dropdown-menu.tds_form3CDRevamp_yr_dropmenu.show {
      padding: 0px 0px;
      font-size: 12px;
      font-weight: 500;
      width: 38px;
      min-width: 79px;
      border-radius: 0px;
      top: -2px !important;
      position: relative;

      & a.dropdown-item {
          padding: 3px 10px 3px 10px;
      }

      & .dropdown-divider {
          margin: 0px;
      }
  }

  button.tds_form3CDRevamp_imptaxtbluk_btn {
      background-color: #31719b;
      border-color: #31719b;
      border-radius: 40px;
      color: #fff;
      padding: 3px 10px 5px 10px;
      font-size: 12px;
      font-weight: 500;
      border: 0px;
  }



  .input-group-append.exptds_panwise_searchlist {
    min-width: 250px;
    width: 250px;
    max-width: 100%;

    input.form-control.panwisetan_inputsearch{
      font-weight: 500;
      padding: 9px 0 9px 5px !important;
      font-size: 11px;
      border-radius: .25rem 0 0 .25rem;
      border-right: 0 solid #ced4da;
    }

    input.form-control.panwisetan_inputsearch:focus {
        color: #495057;
        background-color: #fff;
        border-color: #ced4da;
        outline: 0;
        box-shadow: 0 0 0 0.0rem rgba(0, 123, 255, 0.25);
    }
    

    span.input-group-text.exptds_panwise_searchiconbox {
        background-color: #fff;
        color: #ced4da;
        font-size: 14px;
        border-radius: 0 0.25rem 0.25rem 0;
        border: 1px solid #ced4da;
        border-left-width: 0;
        cursor: pointer;
        padding: 2px 5px 2px 5px;
    }
      
  }


}

::placeholder{
  font-size: 11px;
  color: #c5c5c5 !important;
  font-weight: 400 !important;
}

p.tds_form3CDRevamp_wecloud_textfs {
  font-size: 12px;
  font-weight: 500;
  // color: #fc7d72;
  font-style: italic;
}



table.table.table-striped.Incomplete_deductors_tb {

  thead tr th {
      border-top: 0;
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 0px;
  }

  tr td{
      font-size: 11px;
      padding: 0.25rem 0.75rem;
      font-weight: 500;
      vertical-align: baseline;
  }

  & input.form-control.Incomplete_deductors_inputfs {
      height: calc(1.2em + 0.75rem + 2px);
      padding: 0.8rem 0.2rem;
      font-size: 11px;
  }

  & input.form-control.Incomplete_deductors_passinputfs {
      background-color: white;
      font-size: 11px;
      font-weight: 500;
      height: calc(1.2em + 0.75rem + 2px);
      padding: 0.8rem 0.2rem;
      font-size: 11px;
  }

  & .input-group-prepend.Incomplete_deductors_passeye_icon {
      padding: 8px 3px 0px 3px;
      border: 1px solid #ced4da;
      border-left: 0px;
      background-color: white;
      border-radius: 0px 5px 5px 0px;
      -o-object-fit: scale-down;
      object-fit: scale-down;
      color: #31719b;
  }

  & button.btn.btn-default.incompdeductor_captchbtn {
      background-color: #31719b;
      border-color: #31719b;
      border-radius: 40px;
      color: #fff;
      padding: 2px 5px 4px 5px;
      font-size: 12px;
      font-weight: 500;
      border: 0px;
      width: 100px;
  }

  & button.btn.btn-default.incompdeductor_createdeductbtn {
      background-color: #31719b;
      border-color: #31719b;
      border-radius: 40px;
      color: #fff;
      padding: 2px 5px 4px 5px;
      font-size: 12px;
      font-weight: 500;
      border: 0px;
      width: 100px;
  }

  & input.form-control.Incomplete_deductors_inputfs:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
  }
}

table.table.table-striped.tanwise_returnsumm_tb {
  & thead tr th {
      border-top: 0;
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      background-color: #fff;
      vertical-align: initial;
      padding-top: 5px;
      padding-bottom: 5px;
  }

  & tr td{
      font-size: 11px;
      padding: .25rem .75rem;
      font-weight: 500;
      vertical-align: baseline;
  }
}

span.tds_form3CDRevamp_downloadicon {
  position: relative;
  top: 2px;
  cursor: pointer;
}

td.formqtextclr_blue {
  color: #2f709a;
}

span.red_text {
  color: red;
}

::placeholder{
  color: #a0a0a0 !important;
}

.kdk_data, .card
{
  padding: 7px;
}
.card.tds_form3CDRevamp_report34a_cardbox {
  background-color: #fcffef;
  box-shadow: 0px 1px 5px #22222254;
  border-radius: 0px;
  margin: 0px;

  & h4.report34a_clasuse34_title {
      font-size: 1rem;
      font-weight: 500;
      margin: 0px;
      padding: 0px;

  }
}

.report-gridpan-to-tan-table{
    & thead tr th {
      font-size: 12px;
      border-top: 0;
      font-weight: 500;
      border-bottom: 0 solid #dee2e6;
      vertical-align: baseline;
      padding: 5px 0px 5px 0px;
  }

  & tr td{
      font-size: 11px;
      font-weight: 500;
      padding: .25rem .75rem;
  }
}

table.table.table-striped.report34a_tb {
  & thead tr th {
      font-size: 12px;
      border-top: 0;
      font-weight: 500;
      border-bottom: 0 solid #dee2e6;
      vertical-align: baseline;
      padding: 5px 0px 5px 0px;
  }

  & tr td{
      font-size: 11px;
      font-weight: 500;
      padding: .25rem .75rem;
  }

  & th.report34a_a_titleleft {
      text-align: left;
  }

  td.report34a_tancol_bglcr_white {
      background-color: #fff;
      border-right: 1px solid #ddd;
  }



}

span.report34a_showmoretextfs {
  font-size: 12px;
  font-weight: 500;
  color: #31719b;
  cursor: pointer;
}

td.report34a_vertical {
  vertical-align: middle !important;
  font-weight: 500 !important;
}

td.report34a_clasuse34b_textclrred {
  color: red !important;
}


.k-column-title {
  white-space: normal !important; /* Allow text wrapping */
  font-size: 0.8rem; /* Adjust font size as needed */
  // font-weight: normal;
  line-height: 1.4; /* Adjust line height for readability */
  max-height: auto; /* Let the height grow as needed */
  overflow: visible; /* Allow overflow beyond the header */
}

.genrepottyp_seticon_input{
  font-size: 16px;
}


span.tds_form3CDRevamp_yr_dropdownbox{
  cursor: pointer;
  font-size: 12px!important;
}

/* 34a */
.k-cell-inner > .k-link > .k-icon.k-i-sort-desc-sm, .k-cell-inner > .k-link > .k-icon.k-i-sort-asc-sm{
  margin-top: 5px!important;
}

.k-cell-inner > .k-link {
  padding: 8px 12px;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start !important;
  flex: 1 1;
  overflow: hidden;
}

.form3cd34c tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.form3cd34c tr:nth-of-type(even) {
  background-color: white !important;
}

.report-grid-table{
  
    border: 0px;
    background-color: unset;
    max-height: 100px;
    overflow-y: auto;
    display: grid;
}

.report-grid34c-table{
  
  border: 0px;
  background-color: unset;
  max-height: 251px;
  overflow-y: auto;
  display: grid;
}

.report-grid34b-table {
  border: 0px;
  background-color: unset;
  max-height: 251px;
  overflow-y: auto;
  display: grid;
}

.report34c_tb .k-grid-content{
  
  border: 0px;
  background-color: unset;
  max-height: 455px;
  overflow-y: auto;
  display: grid;
}

.report-grid34a-table{
  
  border: 0px;
  background-color: unset;
  max-height: 250px;
  overflow-y: auto;
  display: grid;
}

.report-gridpan-to-tan-table .k-grid-content{
  
  border: 0px;
  background-color: unset;
  max-height: 431px;
  overflow-y: auto;
  display: grid;
}

.non-sort .k-column-title{
  cursor: default!important;

}

.inst-pybl .k-cell-inner > .k-link{
    justify-content: right!important;
}


.search_text_icon{
  position: absolute;
    z-index: 1;
    top: 20px;
    left: 690px;
    width: 15px;
}

.form-control.panwise-tan-input {
  padding: 4px 18px 4px 3px;
  display: inline-block;
  width: 270px;
  min-width: 270px;
  margin-left: 5px;
  max-width: 100%;
  vertical-align: middle;
  font-size: 14px;
  text-transform:uppercase;
}

.form-control.panwise-tan-input::placeholder {
  font-size: 14px!important;  
  text-transform: capitalize;
}

.form-inline.pan-input{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex-direction: column;
}


@media only screen and (max-width: 1200px) {
  .deductormasterlist img.search_text_icon.doflistsiconboxmrgin {
    left: 208px;
  }
}

@media only screen and (max-width: 1025px) {
  .deductormasterlist img.search_text_icon.doflistsiconboxmrgin {
    left: 68px;
  }
}


/* .k-icon.k-i-sort-asc-sm, .report34a_tb .k-icon.k-i-sort-desc-sm {
  position: absolute;
  right: 18px;
  top: 30px;
}

.report34a_tb .k-grid-column-menu.k-grid-filter{
  position: absolute;
  right: 18px;
}

.report34a_tb .custom-header{
  text-align: center;
  border: 1px solid #CCC;
  margin-bottom: 9px;
}

.report34a_tb > .k-cell-inner > .k-link {
  padding: 8px 12px!important;
 
} */
.k-widget.k-grid.table.table-striped.report34a_tb tr th {
  border-top: 0;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0px;
}
.k-widget.k-grid.table.table-striped.report34a_tb tr td {
  font-size: 11px;
  padding: 0.25rem 0.75rem;
  font-weight: 500 !important;
  vertical-align: baseline;
  border-bottom: 1px solid #dee2e6;
}

.report34a_tb .custom-header{
  text-align: center;
  color: #33729b;
  font-size: 11px;
  
}


.custom-subheader{  
  color: #00f;
  font-size: 11px;  
  cursor: default;
}

.report34a_tb .custom-subheader{  
  color: #00f;
  font-size: 10px;  
  cursor: default;
}


.report-grid34a-table td{
  font-weight: normal!important;

}

.tansummary_tb .custom-header{
  text-align: center;
  color: #33729b;
  
}

.report34a_tb .k-grid-header .k-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
 
}

td.tan-summary-tancol-bglcr-white {
  background-color: #fff;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
}


.tansummary_tb th.deductcol .k-cell-inner > .k-link {
  padding: 8px 12px;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start !important;
  flex: 1 1;
  overflow: hidden;
  justify-content: flex-start;
  margin-left: 40px;
}
.green{
  color: green;
}
.red{
  color: red;
}

.form3cd_nature_overflowtext{
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  cursor: pointer;
  white-space: nowrap;
}
td.form3cd_borderbottom_grey {
  border-bottom: 1px solid #dee2e6;
}
// table.table.table-striped.form3cd34c.form3cd34ctbinner_bordertop tr td {
//   border-top: 0px;
// }

.adjust-td{
  padding-right:17px;
}

.report-grid34a-table.scroll-table{  
  overflow-y: scroll;
}

.deductormasterlist img.search_text_icon.doflistsiconboxmrgin{
  left: 66.5rem;
  top: 0.7rem;
}

.textBlue{
  color: #00f;
  font-size: 14px;
}

.textcoral{
  color: #fc7d72;
  font-size: 12px;
}


table.table.table-borderless.tds_tanwise_returnsumm_legendtb tr td {
  font-size: 11px;
  padding: 0.1rem 0.75rem;
  font-weight: 500;
  vertical-align: baseline;
}

td.report34c_rowbgclr_lgrey {
  background-color: #fdf4e7;
  border-right: 1px solid #cfcfcfee;
}

span.tds_form3CDRevamp_underst_textfs {
  font-size: 12px;
  font-weight: 500;
  color: #fc7e73;
  text-decoration: underline;
  cursor: pointer;
  padding: 7px 5px 4px 5px;
  position: relative;
  top: 2px;
}

.card.tds_form3CDRevamp_underst_showcard {
  visibility: hidden;
  width: 40%;
  max-width: 100%;
  background-color: #fff;
  z-index: 1;
  top: 3rem;
  left: 0px;
  margin: 0rem auto 0rem auto;
  border-radius: 2px;
  border: 1px solid #ccc7c7;
  box-shadow: 0px 2px 15px #ddd;
  position: absolute;
  padding: 10px 10px;;
}

.tds_form3CDRevamp_underst_showcard.show {
  visibility: visible;
  animation: ratefinderfadeIn 1s;
}


table.table.table-borderless.report34a_linkcard_tb {

  & thead tr th {
      border-top: 0;
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      padding-top: 5px;
      padding-bottom: 5px;
  }

  & tr td{
      font-size: 11px;
      padding: 0.25rem 0.75rem;
      font-weight: 500;
      vertical-align: baseline;
  }

  tr.report34a_linkcard_2row th {
      border-bottom: 1px solid #ddd !important;
  }
  & tr.report34a_linkcard_1row th {
      padding-top: 0px !important;
  }



  & hr.report_bordermiddle {
      margin: 0px 0px !important;
  }

  & span.report34a_datetextfs {
      text-align: right;
      position: absolute;
      font-size: 9px;
      color: #333;
      background-color: #fff;
      padding: 0px 2px;
      border: 1px solid #ddd;
      border-radius: 40px;
  }
}

.report34a_linkcard_cardcolor_lightblue {
  color: #b5dcfc;
}
.report34a_linkcard_cardcolor_yell {
  color: #fff9be;
}
span.report34a_linkcard_cardtextfs {
  font-size: 12px;
  font-weight: 500;
}

input.form-control.tanwiseretun_passinputfs {
  background-color: white;
  font-size: 11px;
  font-weight: 500;
  height: calc(1.2em + 0.75rem + 2px);
  padding: 0.8rem 0.2rem;
  font-size: 11px;
  border-right: 0px;
}
input.form-control.tanwiseretun_passinputfs:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
}
.input-group-prepend.tanwiseretun_passeye_icon {
  padding: 8px 3px 0px 3px;
  border: 1px solid #ced4da;
  border-left: 0px;
  background-color: white;
  border-radius: 0px 5px 5px 0px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  color: #31719b;
}
span.getpatchabox {
  display: ruby-text;

  &  input.form-control.tanwisereturn_captchinputbox {
      background-color: #fff;
      font-size: 11px;
      font-weight: 500;
      height: calc(1.2em + .75rem + 2px);
      padding: .8rem .2rem;
      font-size: 11px;
      min-width: 100px;
      width: 120px;
  }
  &  input.form-control.tanwisereturn_captchinputbox {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
  }
}
button.btn.btn-default.tanwisereturn_captchbtn {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 40px;
  color: #fff;
  padding: 2px 5px 4px 5px;
  font-size: 12px;
  font-weight: 500;
  border: 0px;
}
button.btn.btn-default.tanwisereturn_craetededbtn {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 40px;
  color: #fff;
  padding: 2px 5px 4px 5px;
  font-size: 12px;
  font-weight: 500;
  border: 0px;
  width: 100px;
}

.sync_dropboxline {
  display: inline-flex;
}
button.btn.btn-default.form3cd_sync_btn {
  background-color: #2f739d;
  padding: 3px 7px 6px;
  border: 0;
  border-radius: 40px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  top: 5px;
}

.source-text{
  font-size: 12px;
  color: rgb(252, 126, 115);
  cursor: pointer;
  display: flex;
  width: 280px;
}

span.tds_form3CDRevamp_underst_textfs {
  font-size: 12px;
  font-weight: 500;
  color: #fc7e73;
  text-decoration: underline;
  cursor: pointer;
  padding: 1px 5px 4px 5px;
  position: relative;
  top: -2px;
}

.conso-source-text{
  color: #bcf0ec;
}

.txt-source-text{
  color: #fefda9;
}


td.tdstanwise_textclr_blue {
  color: #0048ff;
}
td.tdstanwise_textclr_underline {
  text-decoration: underline;
}

td.tdstanwise_textclr_green {
  color: #7e9e39;
}

td.tdstanwise_textclr_yell {
  color: #40e40a;
}

td.tdstanwise_textclr_purpal {
  color: #f54337;
}


.incomplete_deductorlis_css .table.table-striped.blukimport_tb tr td button.btn.btn-default.blukimp_captchbtn{
  font-size: 11px;
}

.card.tds_form3CDRevamp_cardtxtfile_box {
background-color: #f0f8ff;
    padding: 2px 9px 3px 9px;
    background-image: linear-gradient(to right, #c4727a, #3a7199);
    border: 1px solid #f0f8ff;
    border-radius: 40px;
    cursor: pointer;
    display: inline-block;


 & span.tds_form3CDRevamp_cardtxtfile_textfs {
     font-size: 12px;
     font-weight: 500;
     color: #fff;
     padding: 0px 10px;
     vertical-align: bottom;
     position: relative;
     top: -7px;
 }


}

span.tds_form3CDRevamp_imprtimgbox img {
    vertical-align: middle;
    position: relative;
    top: 6px;
}



.cursor-pointer{
  cursor: pointer;
}

.cursor-pointer .k-cell-inner > .k-link{
  cursor: pointer;
}

.form3cd_panwise_legendcardbox {
  font-size: 12px;
  font-weight: 500;
}

.box-txt{
  width: 1rem;
  height: 1rem;
  background-color: #fefda9;
  float: left;
}

div.box-conso{
  width: 1rem;
  height: 1rem;
  background-color: #bcf0ec;
  float: left;
}

label.src-lbl-txt{
  font-size: 12px!important;
  font-weight: 500;
}

.card.trbottom_border {
  border :0px;
  border-bottom: 2px dashed #a3a3a3;
  position: relative;
  margin-bottom: 10px;    
}

.custom-background {
  background-color: #ecdbd8;
  vertical-align: middle; 
  writing-mode: vertical-rl; /* Make text vertical */
  transform: rotate(180deg); /* Adjust direction of the text */
}

.vertical-text-center { 
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */  
  white-space: nowrap; /* Prevent text from wrapping */
  margin-top: 0px;
  font-weight: 600;
  font-size: 12px;
  color: #ff4131; 
   
}



.incomp-deductor-list .k-header > .k-cell-inner{
  margin: -5px;
}

.center-div {
  position: relative;
  left: 2.4rem !important;
}
th.k-header.linkmodal_textleft span.k-cell-inner span.k-link {
  justify-content: left;
  padding-left: 2.6rem;
}
 span.dropdwonfy{
  padding: 0;
  color: lightgray;
  vertical-align: middle;
  font-size: 13px;
  
}
 span.dropdwonyearlist{
  top: -1px;
  position: relative;  
}

span.dropdwonyearlist .k-dropdown, .k-dropdowntree{
  width: 6em;
}

.tds_form3CDRevamp_genrepottyp_cardbox .k-grid-container{
max-height: 202px;
}


.k-widget.k-grid.table.table-striped.tanwise_returnsumm_tb.scrollvisible.incvisibleover {

  & tr th{

    border-top: 0;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;

  }

  & tr td {
      font-size: 11px;
      padding: 0.25rem 0.2rem;
      font-weight: 500 !important;
      vertical-align: middle;
  }
}

.panwisereturn_bottomsection {
  position: fixed;
  bottom: 0rem;
  width: 100%;
}


tr.even_oddrowbgclr:nth-of-type(even) table.table.table-striped.form3cd34c.report-grid34a-table.scroll-table tr td {
  background-color:  #fdf4e7;
}

tr.even_oddrowbgclr:nth-of-type(odd) table.table.table-striped.form3cd34c.report-grid34a-table.scroll-table tr td {
  background-color: #f2f2f2;
}

tr.even_oddrowbgclr:nth-of-type(even) table.table.table-striped.form3cd34c.report-grid34b-table.evendodd_rowbgclr tr td {
  background-color:  #fdf4e7;
}

tr.even_oddrowbgclr:nth-of-type(odd) table.table.table-striped.form3cd34c.report-grid34b-table.evendodd_rowbgclr tr td {
  background-color: #f2f2f2;
}
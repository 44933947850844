*
{
	margin: 0px;
	padding: 0px;
}
@font-face 
{
	font-weight: 500!important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	src: url(../../font/myfont.otf);
}
body
{
	font-weight: 500!important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	background-color: #fafafa!important;
}
.f-14
{
	font-size: 14px;
}
.f-12
{
	font-size: 12px;
	font-weight: 500;
}
a:hover
{
	text-decoration: none!important;
}
.nopadding
{
	padding:0px!important;
}
header
{
	position: sticky;
	top: 0px;
	z-index: 10;
	box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28); 
	background-color: #fff;
}
header nav
{
	margin-bottom: 0px !important;
	border:0px!important;
		background-color: #fff;
}
header .navbar-brand img
{
	width: 130px;
}
header ul li
{
	list-style-type: none;
	display: inline-block;
	margin-right: 0px;
}

header ul li span,.mailer span, .sms span
{
    border-radius: 50%;
    padding: 10px 7px 6px 11px;
    margin-top: 15px;
}
.btn_bg
{
	background-color: #3D7094;
	color:#fff;
}
.profile_img_bg
{
	background-color: #f2f2f2;
}
header ul li img
{
	width: 25px;
	cursor: pointer;
}
label, .custom-select
{
	font-size: 12px;
}
p.verify_btn_bottom_margin.createdcontp {
    display: inline-flex;
}


.verify_btn_bottom_margin
{
	margin-bottom: 0px!important;
}
/*table*/
.kdk_data form input
{
	padding-left: 35px;
	width: 400px!important;
}

.align-center
{
	width: 100%;
	margin: auto;
	text-align: center;
}
.light_color_text
{
	color:#FC7D72;
	font-weight: 500;
}
.light_color_bg
{
	background-color:#FC7D72;
	color:#ffffff;
	border:1px solid #FC7D72;
	padding: 9px 30px;
	border-radius: 3px;
	font-size: 12px;
}
.dark_color_bg
{
	background-color:#31719B;
	color:#ffffff;
	border:none;
	padding: 9px 15px;
	border-radius: 3px;
	font-size: 12px;
	font-weight: 500;
}
.dark_color_bg2
{
	background-color:#31719B;
	color:#ffffff;
	border:none;
	padding: 1px 8px 4px 8px;
	border-radius: 3px;
	font-size: 12px;
	font-weight: 500;
}
.red_text
{
	color:#FF2C39;
}
.grey-60
{
	color:#636363;
}
.dark_color_text
{
	color:#31719B;
}
.black_color_text
{
	color:#333333;
}
.black_color_text:hover
{
	color:#333333;
	text-decoration: none;
}
.green_text
{
	color:#40BC00;
}
.rounded_btn
{
	border-radius: 40px;
	padding:5px 22px 7px 22px;
}
.light-btn
{
	background-color: #f2f2f2;
	color:#636363;
	border:1px solid #dddddd;
	padding: 9px 30px;
	border-radius: 3px;
	font-size: 12px;
	font-weight: 500;
}
.light_grey_text
{
	color:#dddddd;
}
.kdk_data, .card
{
	background-color: #fff;
	border-radius: 3px;
	width: 100%;
	float: left;
	padding:15px;
	
}
.w-1300
{
	max-width: 1300px;
}
.kdk_data_table
{
	max-height: 60vh;
	overflow-y: auto;
}
.kdk_data a span img
{
	width: 15px;
}
.kdk_data a
{
	text-decoration: none;	
	font-size: 13px;
}
.kdk_data_table tr td span img
{
	width: 20px;
	margin:0px 10px;
}
.kdk_data_table
{
	width: 100%
}
.kdk_data_table tr th
{
	font-size: 14px;
}
.kdk_data_table tr td
{
	font-size: 12px;
}
.border_left
{
	border-left:1px solid #dddddd;
}
.create_deductor img
{
	width: 25px!important;
}
.top_image_text form:after {
    font: 16px FontAwesome;
    content: "\f002";
    color: #999;
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 25px;
}

/*Modal popup*/

.modal-xl
{
	max-width: 1140px!important;
}
.toggle_mail_sms
{
	border:1px solid #dddddd!important;
	border-radius: 50px;
	width: 260px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #31719B!important;
    border-radius: 50px;
}
.modal-header
{
	border:none!important;
}
.mailbox,.preview_section
{
	border:1px solid #dddddd;
	padding: 15px 15px 0px 15px;
}
.bdr_right
{
	border-right: 1px solid #dddddd;
}
.nav-link 
{
    padding: 0.5rem 2rem!important;
}
.preview_section_mobile
{
	background-image: url('../../images/mobile.png');
	    height: 411px;
    width: 202px;
}
.preview-device
 {
    background-size: 100%;
    margin: auto;
    }
    .manual_text
    {
    	text-align: center;
    	margin: auto;
    	width: 100%;
    	float: left;
    }
  
    /*create deductor manually*/
    .label_image
    {
    	height: 20px;
    }
    .deductordetails, .rp_details
    {
    	height: auto;
	}
	button.light-btn.float-right.mr-2.editdcanclebtn {
		padding: 5px 21px;
		font-size: 14px;
		margin-top: 2px;
	}
	button.light_color_bg.float-right.editdsavebtn {
	    background-color: #fc7d72;
    color: #fff;
    border: 1px solid #fc7d72;
    padding: 7px 26px !important;
    border-radius: 40px;
    font-size: 12px;
	font-weight: 500;
	}
	button.light-btn.mr-2.editdcanclebtn {
		background-color: #f2f2f2;
		color: #636363;
		border: 1px solid #ddd;
		padding: 7px 20px !important;
		border-radius: 40px;
		font-size: 12px !important;
		font-weight: 500;
		
		}
    .deductorcontact h6
    {
    	margin-bottom: 13px;
    	margin-top: 0px;
    }
    .error_message{
      color: red;
      font-size: 10px;
      padding-left: 5px;
    }

    .auto-capitalize {
      text-transform: uppercase;
    }

    .cdm input{
        font-size: 12px;
        font-weight: 500;
    }
    select.form-control.deductordetails_inputbox {
        height: calc(1.5em + 0.75rem + 2px);
        font-size: 12px;
        font-weight: 500;
    }

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/*fieldset details css*/
fieldset.editeddfieldset {
    border: 1px solid #31719a;
}
legend.editeddlegent {
    width: 30%;
}
fieldset.editeddrpdfieldset {
    border: 1px solid #fc7d72;
}
legend.editeddrpdlegent {
    width: 48%;
}
fieldset.editedcdfieldset {
    border: 1px solid #31719a;
}
legend.editedcdlegent {
    width: 42%;
}
fieldset.editedaifieldset {
    border: 1px solid #31719a;
}
legend.editedailegent {
    width: auto;
}
fieldset.editerpaiddfieldset {
    border: 1px solid #fc7d72;
}
.respperaddrinndev.editedai {
    display: block;
}
.respperaddrlefts.editedai {
    float: left;
    padding: 0px 5px;
}
.respperaddrlefts.editedai h4 {
    font-size: 1.2rem;
}
.respperaddrright.editedai h4 span.dark_color_bg2.float-right.editecopyfded {
    padding: 5px 5px;
    margin-top: -3px;
}
.form-group.col-md-6.colbotpaddrem {
    margin-bottom: 0.6rem !important;
}
h4.tds_editdeductor_titlefs {
    font-size: 1.2rem;
    font-weight: 500;
    position: relative;
    top: 7px;
}

a.editddtorgivebackbtn {
    color: #fc7d72;
    font-size: 12px;
    padding: 4px 0;
	font-weight: 500;
}
a.editddtorgivebackbtn:hover {
    color: #31719B;
}
.deductee-popup.deductee-popup-horizontal.creatededcandemp .css-yk16xz-control {
    min-height: 30px !important;

}
.css-9nhy1o-MenuList {
    max-height: 153.53125px;
    overflow-y: auto;
    padding-bottom: 4px;
    padding-top: 4px;
    position: relative;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
}
.css-tlfecz-indicatorContainer {
     padding: 5px !important;
    
}

/*editdecutor timeine section css */
.topbtnbox.editdedcutorbox {
    display: inline-flex;
}
span.iocnb0x.hedFontAws.editdedtimelineiconact {
    padding: 5px 10px;
    border: 1px solid #31719a;
    border-radius: 50%;
    display: flex;
    position: relative;
    left: 22px;
    background-color: #31719a;
    color: #fff;
    top: 3px;
    display: inline-block;
    font-size: 18px;
}
button.btn.btn-default.bgbtnclr.editdedtimelinebgclractive {
    border: 1px solid #31719a;
    padding: 0px 10px 0px 10px;
    border-radius: 15px 40px 40px 16px !important;
    background-color: #31719a;
    height: 30px;
    top: 12px;
    outline-color: #31719a;
    color: #fff;
    font-size: 15px;
    z-index: 5;
    left: 12px;
}
.brbline.editdedtimelineborbact {
    border-bottom: 1px solid #f2f2f2;
    display: inline-block;
    position: absolute;
    z-index: 0;
    width: 100px;
    border-width: 3px;
    left: 14.9rem;
    top: 26px;
    margin-left: 14px;
}

.brbline.editdedtimelineborb01 {
    border-bottom: 1px solid #f2f2f2;
    display: inline-block;
    position: absolute;
    z-index: 0;
    width: 100px;
    border-width: 3px;
    left: 7.9rem;
    top: 26px;
    margin-left: 14px;
}
span.iocnb0x.boxbg2.bx3.hedFontAws.editdedtimelineicon {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
	color: #333;
	    font-size: 15px;
}
.topbtnbox .brbline.editdedtimelineborb01 {
    border-bottom: 1px solid #f2f2f2;
    display: inline-block;
    position: absolute;
    z-index: 0;
    width: 100px;
    border-width: 3px;
    left: 7.9rem;
    top: 26px;
    margin-left: 14px;
}
.btn-group.btn-group-md.box2.editdedcutortimelinebox02 {
        left: 2rem;
}
button.btn.btn-default.bgbtnclr.boxbg2.timelineboxunactclr {
    background-color: #f2f2f2;
    color: #333;
    outline-color: #f2f2f2;
    border-color: #f2f2f2;
    padding: 0px 10px 0px 10px;
    font-size: 15px !important;
}

.topbtnbox .brbline2.editdedtimelineborb02 {
    border-bottom: 1px solid #f2f2f2;
    display: inline-block;
    position: absolute;
    z-index: 0;
    width: 100px;
    border-width: 3px;
    left: 10.4rem;
    top: 26px;
    margin-left: 14px;
}


.btn-group.btn-group-md.box3.editdedcutorbox03 {
	left: 4.5rem;
}
.topbtnbox .brbline3.editdedtimelineborb03 {
    border-bottom: 1px solid #f2f2f2;
    display: inline-block;
    position: absolute;
    width: 100px;
    border-width: 3px;
    left: 7.6rem;
    top: 26px;
    margin-left: 14px;
    z-index: 0;
}
.btn-group.btn-group-md.box4.editdedcutorbox04 {
	left: 7rem;
}
.brbline4.editdedtimelineborb04 {
    border-bottom: 1px solid #f2f2f2;
    display: inline-block;
    position: absolute;
    width: 100px;
    border-width: 3px;
    left: 11.6rem;
    top: 26px;
    margin-left: 14px;
    z-index: 0;
}
.btn-group.btn-group-md.box4.editdedcutorbox05 {
	left: 9.5rem;
}
.brbline4.editdedtimelineborb05 {
    border-bottom: 1px solid #f2f2f2;
    display: inline-block;
    position: absolute;
    width: 100px;
    border-width: 3px;
    left: 10.6rem;
    top: 26px;
    margin-left: 14px;
    z-index: 0;
}
.btn-group.btn-group-md.box4.editdedcutorbox06{
    left: 12rem;
}
button.btn.btn-default.savebtneditd {
    padding: 0.3rem 1.3rem;
    font-size: 15px;
    margin-top: -2px;
    background-color: #FC7D72;
    color: #ffffff;
    border: 1px solid #FC7D72;
}

span.editvaluesclr {
    color: #4d9ed2;
    font-size: 10px;
    padding-left: 7px;
}

.smbtnmrl.switchtogglebtn .switch.btn {
    min-width: 7.7rem;
    min-height: calc(1.5em + .75rem + 2px);
}

input.input-error1 {
    border: 1px solid #fc6a6a !important;
    box-shadow:none!important;
}
select.input-error1 {
    border: 1px solid #fc6a6a !important;
    box-shadow:none!important;
}

.excltionicon {
    width: 12px;
    margin-right: 3px;
}



fieldset.tdseditdeductor_itFS {
    border: 1px solid #31719b;
}
.tdseditdeductor_it_inndev {
    display: block;
}
.tdseditdeductor_it_left {
    float: left;
}
.tdseditdeductor_it_left h4 {
    font-size: 1.2rem !important;
    font-weight: 500;
    padding: 0px 5px;
    position: relative;
    top: 4px;
}
.tdseditdeductor_it_innerdiv {
    height: 1px;
    width: 100%;
    position: relative;
}
.tdseditdeductor_it_right {
    float: right;
}
.tdseditdeductor_it_middleborder {
    border-top: 1px solid #31719b;
    display: flex;
    position: relative;
    margin-top: 17px;
}
button.btn.btn-default.tdseditdeductor_it_verifytextfs {
    font-weight: 500;
    font-size: 12px;
    padding: 4px 6px;
    position: relative;
    top: -5px;
    border: 1px solid #31719B;
    border-radius: 40px;

}
span.tdseditdeductor_it_verifytextfs_span {
    font-weight: 500;
    font-size: 12px;
    padding: 2px 4px;
    position: relative;
    top: -5px;
    border: 1px solid #40BC00;
    border-radius: 40px;

}
fieldset.tdseditdeductor_tracsFS_color {
    border: 1px solid #fc7d72;
}
.tdseditdeductor_tracsFS_middclr {
    border-top: 1px solid #fc7d72;
}

/*  sw btn box*/
.switch.expreco_editclientd_swbtn {
    display: inline-block;
}
     .toggle.btn {
        min-width: 3.7rem;
        min-height: 1.15rem;
        border-radius: 40px;
        width: 100px !important;
        height: 30px !important;
        padding: 0px 0px;
        font-size: 12px !important;
        font-weight: 500;
    }

    label.btn.btn-primary.toggle-on {
        background-color: green;
        color: #fff;
        top: -2px;
        font-size: 12px;
        font-weight: 500;
    }
    label.btn.btn-light.toggle-off {
        top: -2px;
        font-size: 12px;
        font-weight: 500;
        background-color: #cdcdcd;
    }

@media only screen and (max-width: 767px) and (min-width: 320px){

	.btn-group.btn-group-md.box2.editdedcutortimelinebox02 {
		left: 0rem;
	}
	.btn-group.btn-group-md.box3.editdedcutorbox03 {
		left: 0rem;
	}
	.btn-group.btn-group-md.box4.editdedcutorbox04 {
		left: 0rem;
	}
	.btn-group.btn-group-md.box4.editdedcutorbox05 {
		left: 0rem;
	}
	.btn-group.btn-group-md.box4.editdedcutorbox06 {
		left: 0rem;
		margin-bottom: 34px;
	}
	.topbtnbox .brbline.editdedtimelineborb01 {
		display: none;
	}
	.topbtnbox .brbline2.editdedtimelineborb02 {
		display: none;
	}
	.topbtnbox .brbline3.editdedtimelineborb03 {
		display:none;
	}
	.brbline4.editdedtimelineborb04 {
		display: none;

	}
	.brbline4.editdedtimelineborb05 {
		display: none;
	}
}
@media only screen and (max-width: 941px) and (min-width: 768px){

	.btn-group.btn-group-md.box2.editdedcutortimelinebox02 {
		left: 6.5rem;
	}
	.btn-group.btn-group-md.box3.editdedcutorbox03 {
		left: 12.5rem;
	}

	.btn-group.btn-group-md.box4.editdedcutorbox04 {
		left: -32.5rem;
		top: 2.8rem;
	}
	.btn-group.btn-group-md.box4.editdedcutorbox05 {
		left: 17.5rem;
	}
	.btn-group.btn-group-md.box4.editdedcutorbox06 {
		left: 23.7rem;
	}

	.topbtnbox .brbline.editdedtimelineborb01 {
		width: 170px;
	}
	.topbtnbox .brbline2.editdedtimelineborb02 {
		width: 170px;
		left: 8.4rem;
	}
	.topbtnbox .brbline3.editdedtimelineborb03 {
		display: none;
	}
	.brbline4.editdedtimelineborb04 {
		width: 170px;
		
	}
	.brbline4.editdedtimelineborb05 {
		width: 170px;
		left: 8.6rem;
	}
	.smbtnmrl.switchtogglebtn .switch.btn {
		min-width: 6.7rem;
		min-height: calc(1.5em + .75rem + 2px);
	}
}

.css-yk16xz-control {
    min-height: 30px !important;
}
.addcompbtnpositionfixed {
    padding: 6px 16px;
    background-color: #f2f2f2d9;
    border-right: 0 solid;
    border-radius: 0;
    bottom: 0;
    position: fixed;
    z-index: 5;
    width: 100%;
    box-shadow: 0px 0px 0px #8a8a8a;

}

.k-grid-header {
    padding: 0px 0px 0px 0px !important;
}

legend.editedailegent h4 {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0px 5px;
}

/*media code for xs padding*/
@media only screen and (max-width: 767px) {
	.float-right.smtpadd {
		padding-top: 12px;
	}
	.deductorcontact h6 {
		margin-bottom: 20px;
	}
	.deductorcontact.mt-4.smpadcot {
		padding-top: 0em;
	}
	h6.respoh6padd {
		padding-top: 37px;
	}
	.smbtnmrl {
		margin-left: -17px;
	}

	legend.editeddlegent {
		width: 56%;
	}
	legend.editeddlegent h4 {
		font-size: 15px;
	}
	legend.editeddrpdlegent {
		width: 83%;
	}

	legend.editeddrpdlegent h4 {
		font-size: 15px;
	}
	legend.editedcdlegent {
		width: 84%;
	}

	legend.editedcdlegent h4 {
		font-size: 15px;
	}


	legend.editedailegent h4 {
		font-size: 15px;
	}
	.respperaddrlefts.editedai h4 {
		font-size: 12px;
	}


  }

  @media only screen and (min-width: 768px) and (max-width: 941px){
  legend.editeddlegent {
	width: 46%;
}
legend.editeddlegent h4 {
	font-size: 15px;
}
legend.editeddrpdlegent {
	width: 70%;
}

legend.editeddrpdlegent h4 {
	font-size: 15px;
}
legend.editedcdlegent {
	width: 70%;
}

legend.editedcdlegent h4 {
	font-size: 15px;
}


legend.editedailegent h4 {
	font-size: 15px;
}
.respperaddrlefts.editedai h4 {
	font-size: 10px;
}

}
@media only screen and (min-width: 942px) and (max-width: 1024px){
	legend.editeddlegent {
	  width: 46%;
  }
  legend.editeddlegent h4 {
	  font-size: 1.2rem;
  }
  legend.editeddrpdlegent {
	  width: 70%;
  }
  
  legend.editeddrpdlegent h4 {
	  font-size: 1.2rem;
  }
  legend.editedcdlegent {
	  width: 70%;
  }
  
  legend.editedcdlegent h4 {
	  font-size: 1.2rem;
  }


  legend.editedailegent h4 {
	  font-size: 1.2rem;
      padding: 0px 5px;
  }
  .respperaddrlefts.editedai h4 {
	  font-size: 15px;
  }
  
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px){
	.dark_color_bg2 {
	
		font-size: 7.6px;
		padding: 7px 4px;
	}
  }

  h4.fieldtitlefosiz{
	font-size: 1.2rem;
  }
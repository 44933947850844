/*----------------- Start correction timeline box css --------------*/

.corrctiontimelinebox {
  display: inline;
  font-size: 0.8rem;
}
.btn-group.btn-group-md.corrsuminnerbox01 {
  padding-left: 1rem;
}

.btn-group.btn-group-md.corrsuminnerbox02 {
  left: 5.5rem;
}
.btn-group.btn-group-md.corrsuminnerbox03 {
  left: 17.1rem;
}
.btn-group.btn-group-md.corrsuminnerbox04 {
  left: 23.3rem;
}
.btn-group.btn-group-md.corrsuminnerbox05 {
  left: 29.7rem;
}
.btn-group.btn-group-md.corrsuminnerbox06 {
  left: 36.0rem;
}

.btn-group.btn-group-md.corrsuminnerboxlong01 {
  padding-left: 1rem;
}
.btn-group.btn-group-md.corrsuminnerboxlong02 {
  left: 5.5rem;
}
.btn-group.btn-group-md.corrsuminnerboxlong03 {
  left: 11.1rem;
}
.btn-group.btn-group-md.corrsuminnerboxlong04 {
  left: 16.6rem;
}
.btn-group.btn-group-md.corrsuminnerboxlong05 {
  left: 22.8rem;
}
.btn-group.btn-group-md.corrsuminnerboxlong06 {
  left: 28.3rem;
}

.btn-group.btn-group-md.corrsuminnerbox07 {
  left: 11.4rem;
}
span.timelinerightbor {
  display: inline-block;
  position: absolute;
  z-index: 0;
  width: 83px;
  border-bottom: solid #f5f5f5;
  border-width: 2px;
  left: 4.9rem;
  top: 13px;
}
span.timelinerightbor.correctbor02 {
  left: 7rem;
}
span.timelinerightbor.correctbor03 {
  left: 9.2rem;
}
span.timelinerightbor.correctbor04 {
  left: 3.6rem;
}
span.timelinerightbor.correctbor05 {
  left: 5.6rem;
}
span.timelinerightbor.correctbor07 {
  left: 2.5rem;
}
span.timelinerightbor.correctborlong02 {
  left: 6.5rem;
}
span.timelinerightbor.correctborlong03 {
  left: 8.5rem;
}
span.timelinerightbor.correctborlong04 {
  left: 3.1rem;
}
span.timelinerightbor.correctborlong05 {
  left: 5.2rem;
}

a.timelinetextclr {
  color: #31719b;
    font-size: 12px;
}
.activetextclr {
  color: #31719b;
  font-weight: 700;
}
/*----------------- end correction timeline box css --------------*/

a {
  color: #33729c;
}

span.red_text {
  color: #ff2c39;
}
.backbtntextclr {
  color: #fc7d72;
  font-size: 12px;
  cursor: pointer;
}

.backbtntextclr:hover {
  color: #0056b3 !important;
  text-decoration: underline !important;
}

p.textclrblue {
  text-align: right;
  color: #31719b;
  font-size: 12px;
}
.row.deducteesect input.form-control {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
}
.row.deducteesect select.form-control {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
}
fieldset {
  display: block;
  margin: 9px 2px 2px 2px !important;
  background-color: #fff;
  padding: 0.35em 0.75em 0.625em !important;
  border: 2px groove (internal value);
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
fieldset.FSdeductee {
  border: 1px solid #fc7d72;
}
legend.lengdeductee {
  width: 28%;
}
legend.lengdeductee h4 {
  font-size: 1.2rem;
  padding-top: 0.4rem;
}
.row.deducteesect label {
  font-size: 12px;
}
a.float-right.verifytextclr {
  font-size: 12px;
  color: #31719b;
}
fieldset.FSdeducteeblurclr {
  border: 1px solid #31719b;
}
legend.lengdeducteeresperdetails {
  width: 43%;
}
legend.lengdeducteeresperdetails h4 {
  font-size: 1.2rem;
  padding-top: 0.4rem;
}
a.btn.btn-default.copyfrmdedbtn {
  background-color: #31719b;
  color: #fff;
  border: none;
  padding: 1px 8px;
  border-radius: 3px;
  font-size: 12px;
  float: right;
}
input.form-control::placeholder {
  font-size: 10px;
  color: #cccccc;
  opacity: 1;
}
.row.deducteesect .form-group {
  margin-bottom: 0.5rem;
}
a.btn.btn-default.cancelbtntextclr {
  background-color: #f2f2f2;
  color: #636363;
  border: 1px solid #ddd;
  padding: 0.2rem 1.5rem;
  font-weight: 500;
  border-radius: 40px;
  font-size: 12px;
}
a.btn.btn-default.savetextclr {
  background-color: #fc7d72;
  color: #fff;
  border: 1px solid #fc7d72;
  border-radius: 3px;
  padding: 0.2rem 1.7rem;
  font-weight: 500;
  border-radius: 40px;
  font-size: 12px;
}
fieldset.FSdeducteeaddinfo {
  border: 1px solid #31719b;
}
legend.lengdeducteeaddinfo {
  width: 48%;
}
legend.lengdeducteeaddinfo h4 {
  font-size: 1.2rem;
  padding-top: 0.4rem;
}
fieldset.FSdeducteeresperaddrinfo {
  border: 1px solid #fc7d72;
}
.resperaddrinfoinndev {
  display: block;
}
.resperaddrinfolefts {
  float: left;
  padding: 0 5px;
}
.resperaddrinfolefts h4 {
  font-size: 1.2rem;
  padding-top: 0.4rem;
}
.resperaddrinfodivinner {
  height: 1px;
  width: 100%;
  position: relative;
}
.resperaddrinforight {
  float: right;
  padding: 0 5px;
}
.resperaddrinfomiddbor {
  border-top: 1px solid #31719b;
  display: -webkit-flex;
  display: flex;
  position: relative;
  margin-top: 19px;
}
.resperaddrinforight h4 a.btn.btn-default.copyformdbtn {
  background-color: #31719b;
  color: #fff;
  border: none;
  padding: 1px 8px;
  border-radius: 3px;
  font-size: 12px;
}

/*--------------------Start Media code ---------------------*/
@media (max-width: 767px) {
  legend.lengdeductee {
    width: 52%;
  }
  legend.lengdeducteeresperdetails {
    width: 76%;
  }
  legend.lengdeducteeaddinfo {
    width: 82%;
  }
  .resperaddrinfolefts h4 {
    font-size: 0.7rem;
  }
  .resperaddrinforight h4 a.btn.btn-default.copyformdbtn {
    font-size: 7px;
  }
}

@media (min-width: 768px) and (max-width: 941px) {
  legend.lengdeductee {
    width: 52%;
  }
  legend.lengdeducteeresperdetails {
    width: 76%;
  }
  legend.lengdeducteeaddinfo {
    width: 82%;
  }
  .resperaddrinfolefts h4 {
    font-size: 0.7rem;
  }
  .resperaddrinforight h4 a.btn.btn-default.copyformdbtn {
    font-size: 7px;
  }
}

@media (min-width: 942px) and (max-width: 1024px) {
  legend.lengdeductee {
    width: 35%;
  }
  legend.lengdeducteeresperdetails {
    width: 55%;
  }
  legend.lengdeducteeaddinfo {
    width: 60%;
  }
  .resperaddrinfolefts h4 {
    font-size: 1rem;
  }
  .resperaddrinforight h4 a.btn.btn-default.copyformdbtn {
    font-size: 0.7rem;
  }
}

/*=============== media code start ====================*/

@media (max-width: 767px) {
  .corrctiontimelinebox {
    display: block;
    padding: 0rem 0rem 3rem;
  }
  .corrctiontimelinebox a.backbtntextclr {
    left: 0rem;
    display: block;
  }
  span.timelinerightbor {
    display: none;
  }
  .btn-group.btn-group-md.corrsuminnerbox01, .btn-group.btn-group-md.corrsuminnerboxlong01 {
    padding-left: 0rem;
    top: 0.8rem;
  }
  .btn-group.btn-group-md.corrsuminnerbox02, .btn-group.btn-group-md.corrsuminnerboxlong02 {
    left: 0rem;
    top: 2.0rem;
    display: block;
  }
  .btn-group.btn-group-md.corrsuminnerbox07, .btn-group.btn-group-md.corrsuminnerboxlong07 {
    left: 0rem;
    top: 5px;
}
  .btn-group.btn-group-md.corrsuminnerbox03, .btn-group.btn-group-md.corrsuminnerboxlong03 {
    left: 0rem;
    display: block;
  }
  .btn-group.btn-group-md.corrsuminnerbox04, .btn-group.btn-group-md.corrsuminnerboxlong04 {
    left: 0rem;
    top: 5px;
}
.btn-group.btn-group-md.corrsuminnerbox05, .btn-group.btn-group-md.corrsuminnerboxlong05 {
  left: -2.8rem;
  top: 23px;
}
.btn-group.btn-group-md.corrsuminnerbox06, .btn-group.btn-group-md.corrsuminnerboxlong06 {
  left: -7.8rem;
  top: 44px;
}
    a {
      color: #33729c;
    }

    span.red_text {
      color: #ff2c39;
    }
    .backbtntextclr {
      color: #fc7d72;
      font-size: 12px;
      cursor: pointer;
    }

    .backbtntextclr:hover {
      text-decoration: underline !important; 
    }

    .row.deducteesect input.form-control {
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 0.8rem;
    }
    .row.deducteesect select.form-control {
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 0.8rem;
    }
    fieldset {
      display: block;
      margin: 9px 2px 2px 2px !important;
      background-color: #fff;
      padding: 0.35em 0.75em 0.625em !important;
      border: 2px groove (internal value);
    }
    legend {
      display: block;
      width: 100%;
      max-width: 100%;
      padding: 0;
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      line-height: inherit;
      color: inherit;
      white-space: normal;
    }
    fieldset.FSdeductee {
      border: 1px solid #fc7d72;
    }
    legend.lengdeductee {
      width: 28%;
    }
    legend.lengdeductee h4 {
      font-size: 1.2rem;
      padding-top: 0.4rem;
    }
    .row.deducteesect label {
      font-size: 12px;
    }
    a.float-right.verifytextclr {
      font-size: 12px;
      color: #31719b;
    }
    fieldset.FSdeducteeblurclr {
      border: 1px solid #31719b;
    }
    legend.lengdeducteeresperdetails {
      width: 43%;
    }
    legend.lengdeducteeresperdetails h4 {
      font-size: 1.2rem;
      padding-top: 0.4rem;
    }
    a.btn.btn-default.copyfrmdedbtn {
      background-color: #31719b;
      color: #fff;
      border: none;
      padding: 1px 8px;
      border-radius: 3px;
      font-size: 12px;
      float: right;
    }
    input.form-control::placeholder {
      font-size: 10px;
      color: #cccccc;
      opacity: 1;
    }
    .row.deducteesect .form-group {
      margin-bottom: 0.5rem;
    }
    a.btn.btn-default.cancelbtntextclr {
      background-color: #f2f2f2;
      color: #636363;
      border: 1px solid #ddd;
      padding: 0.2rem 1.5rem;
      font-weight: 500;
      border-radius: 40px;
      font-size: 12px;
    }
    a.btn.btn-default.savetextclr {
      background-color: #fc7d72;
      color: #fff;
      border: 1px solid #fc7d72;
      border-radius: 3px;
      padding: 0.2rem 1.7rem;
      font-weight: 500;
      border-radius: 40px;
      font-size: 12px;
    }
    fieldset.FSdeducteeaddinfo {
      border: 1px solid #31719b;
    }
    legend.lengdeducteeaddinfo {
      width: 48%;
    }
    legend.lengdeducteeaddinfo h4 {
      font-size: 1.2rem;
      padding-top: 0.4rem;
    }
    fieldset.FSdeducteeresperaddrinfo {
      border: 1px solid #fc7d72;
    }
    .resperaddrinfoinndev {
      display: block;
    }
    .resperaddrinfolefts {
      float: left;
      padding: 0 5px;
    }
    .resperaddrinfolefts h4 {
      font-size: 1.2rem;
      padding-top: 0.4rem;
    }
    .resperaddrinfodivinner {
      height: 1px;
      width: 100%;
      position: relative;
    }
    .resperaddrinforight {
      float: right;
      padding: 0 5px;
    }
    .resperaddrinfomiddbor {
      border-top: 1px solid #31719b;
      display: -webkit-flex;
      display: flex;
      position: relative;
      margin-top: 19px;
    }
    .resperaddrinforight h4 a.btn.btn-default.copyformdbtn {
      background-color: #31719b;
      color: #fff;
      border: none;
      padding: 1px 8px;
      border-radius: 3px;
      font-size: 12px;
    }
    .salarytitlerpadd h4 {
      padding-right: 4rem;
    }
  }

    /*--------------------Start Media code ---------------------*/
    @media (max-width: 767px) {
      legend.lengdeductee {
        width: 52%;
      }
      legend.lengdeducteeresperdetails {
        width: 76%;
      }
      legend.lengdeducteeaddinfo {
        width: 82%;
      }
      .resperaddrinfolefts h4 {
        font-size: 0.7rem;
      }
      .resperaddrinforight h4 a.btn.btn-default.copyformdbtn {
        font-size: 7px;
      }
    }

  
    @media (min-width: 942px) and (max-width: 1024px) {
      legend.lengdeductee {
        width: 35%;
      }
      legend.lengdeducteeresperdetails {
        width: 55%;
      }
      legend.lengdeducteeaddinfo {
        width: 60%;
      }
      .resperaddrinfolefts h4 {
        font-size: 1rem;
      }
      .resperaddrinforight h4 a.btn.btn-default.copyformdbtn {
        font-size: 0.7rem;
      }
  .btn-group.btn-group-md.corrsuminnerbox06 {
    left: 0rem;
    top: 0.8rem;
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 941px) {

  .corrctiontimelinebox {
    display: inline-flex;
    padding-bottom: 1rem;
  }
  span.timelinerightbor {
    display: block;
  }
  .btn-group.btn-group-md.corrsuminnerbox01, .btn-group.btn-group-md.corrsuminnerboxlong01 {
    padding-left: 1rem;
  }
  .btn-group.btn-group-md.corrsuminnerbox02, .btn-group.btn-group-md.corrsuminnerboxlong02s {
    left: 7rem;
  }

  .btn-group.btn-group-md.corrsuminnerbox03, .btn-group.btn-group-md.corrsuminnerboxlong03 {
    left: 11.0rem;
    top: 0.0rem;
  }
  span.timelinerightbor.correctbor03, span.timelinerightbor.correctborlong03 {
      display: none;
      left: 8.5rem;
  }
  .btn-group.btn-group-md.corrsuminnerbox04, .btn-group.btn-group-md.corrsuminnerboxlong04 {
    left: -18.5rem;
    top: 1.2rem;
}
span.timelinerightbor.correctbor04, span.timelinerightbor.correctborlong04 {
  left: 3.6rem;
}
  .btn-group.btn-group-md.corrsuminnerbox05, .btn-group.btn-group-md.corrsuminnerboxlong05 {
    left: -12.5rem;
    top: 1.2rem;
  }
  span.timelinerightbor.correctbor05, span.timelinerightbor.correctborlong05 {
    left: 5.6rem;
}
  .btn-group.btn-group-md.corrsuminnerbox06, .btn-group.btn-group-md.corrsuminnerboxlong06 {
    left: -7rem;
    top: 1.2rem;
  }
  legend.lengdeductee {
    width: 52%;
  }
  legend.lengdeducteeresperdetails {
    width: 76%;
  }
  legend.lengdeducteeaddinfo {
    width: 82%;
  }
  .resperaddrinfolefts h4 {
    font-size: 0.7rem;
  }
  .resperaddrinforight h4 a.btn.btn-default.copyformdbtn {
    font-size: 7px;
  }
  .btn-group.btn-group-md.corrsuminnerbox07 {
      left: 13.2rem;
  }
  span.timelinerightbor.correctbor07 {
      left: 2.5rem;
      display: none;
  }
  span.SSS.listofdeititle {
      padding: 0rem 0rem 0rem 0rem;
      font-size: 1rem;
  }
}

@media (min-width: 942px) and (max-width: 1024px) {
  .corrctiontimelinebox {
    display: inline-flex;
    padding-bottom: 1rem;
  }
  .btn-group.btn-group-md.corrsuminnerbox01, .btn-group.btn-group-md.corrsuminnerboxlong01 {
    padding-left: 1rem;
  }
  .btn-group.btn-group-md.corrsuminnerbox02, .btn-group.btn-group-md.corrsuminnerboxlong02 {
    left: 7rem;
  }

  .btn-group.btn-group-md.corrsuminnerbox03, .btn-group.btn-group-md.corrsuminnerboxlong03 {
    left: 12.1rem;
}
span.timelinerightbor.correctbor03, span.timelinerightbor.correctborlong03 {
  left: 8.5rem;
}

.btn-group.btn-group-md.corrsuminnerbox04, .btn-group.btn-group-md.corrsuminnerboxlong04 {
  left: 18.5rem;
}
  span.timelinerightbor.correctbor04, span.timelinerightbor.correctborlong04 {
    display: none;
  }
  .btn-group.btn-group-md.corrsuminnerbox05, .btn-group.btn-group-md.corrsuminnerboxlong05 {
    left: -22.5rem;
    top: 1.2rem;
  }
  .btn-group.btn-group-md.corrsuminnerbox06, .btn-group.btn-group-md.corrsuminnerboxlong06 {
    left: -17rem;
    top: 1.4rem;
  }
  .btn-group.btn-group-md.corrsuminnerbox07 {
    left: 13.2rem;
}
span.timelinerightbor.correctbor07 {
  left: 2.5rem;
}
span.SSS.listofdeititle {
  padding: 0rem 0rem 0rem 10rem;
  font-size: 1rem;
}
}

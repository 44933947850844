.pdf_template {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
}
.pdf_template table th {
text-align: center;
}
.pdf_template table th, td {
    padding: 3px 5px;
    line-height: 16px;
}
td.tabletdthpadding {
    padding: 0px 0px;
}
.pdf_template table.reporttb, th, td {
    border: 0px solid black;
    vertical-align: top;
    font-size: 11px;
    vertical-align: top;
}
.pdf_template table.table04bor {
    border: 1px solid #3f3f3f;
    border-collapse: collapse;
}
.pdf_template table.table04bor tr td {
    border: 1px solid #3f3f3f;
    border-collapse: collapse;
}
.pdf_template table.table04bor tr th {
    border: 1px solid #3f3f3f;
    border-collapse: collapse;
}
.pdf_template table.table04bor tr th {
    font-size: 10px;
}
.pdf_template table.table04bor tr td {
    font-size: 10px;
}


.pdf_template table.table27bor {
    border: 1px solid #3f3f3f;
    border-collapse: collapse;
}
.pdf_template table.table27bor tr td {
    border: 1px solid #3f3f3f;
    border-collapse: collapse;
}
.pdf_template table.table27bor tr th {
    border: 1px solid #3f3f3f;
    border-collapse: collapse;
}
.pdf_template table.table27bor tr th {
    font-size: 8px;
}
.pdf_template table.table27bor tr td {
    font-size: 7px;
}

.pdf_template table.table04bor.tb24no4fs tr th {
    font-size: 11.5px;
}

tr.totaltdboldtext td {
    font-weight: 600;
}

.pdf_template_modal .modal-dialog{max-width: 90% !important;}

body {
    font-weight: 500!important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    background-color: #fafafa!important;
}


 .h4, h4 {
    font-size: 1.2rem;
  }

/*rightside navbar---*/

 .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
        z-index: 2999;
    top: 0;
    right: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 42px;
    border-left: 1px solid #333333b8;
       box-shadow: 1px 0px 1px #333333b8;
}


 .sidenav a:hover {
    color: #31719a;
}
 .sidenav .closebtn {
       position: absolute;
    top: -8px;
    right: 0rem;
    font-size: 31px;
    margin-left: 0px;
    color: #292525;
    background-color: #f7f7f7;
    padding: 6px 17.3rem 0px 13px;
    box-shadow: 0px 4px 3px #dcdcdc5c;
}

 span.openbgclr {
    z-index: 400;
    position: relative;
    display: inline-block;
    top: -3.2rem;
    margin-left: 0rem;
    color: #212121;
   
    padding: 12px 102px 12px 43px;
    font-size: 18px;
    text-align: right;
}
fieldset.tds_healthAnalysis_report_fs {
    border: 1px solid #fc7c71;
}

/*end nav bar*/

span.btn.btn-info.tdscobtnbgclr {
    outline-color: #fc7d72;
    border-color: #fc7d72;
    background-color: #fc7d72;
    color: #fff;
    border: 1px solid #fc7d72;
    padding: 5px 20px;
    border-radius: 3px;
    font-size: 14px;
}
span.btn.btn-info.tdscobtnbgclr a {
    color: #fff;
}

 table.table.table-striped.tblretunre tr th {
    font-size: 12.5px;
    font-weight: 500;
}
 table.tblretunre tr td {
    padding: 0.22rem 0.75rem !important;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    
        width: 13%;
}
 span.float-right span.iocclr.pr-1 {
    display: inline-block;
    padding: 0px 0px;
}
 span.iocclr.pr-1 {
    color: #FC7D72;
    font-size: 19px;
}
 ul.pagination.mleftpadd {
    margin-left: 13rem;
}
 span.tbclricon {
    color: #FC7D72;
}
 .page-link {
  
    color: #31719B;
    background-color: #fff;
    border: 1px solid #31719B;
}
 /* .table td, .table th {
    
    border-top: 0px solid #dee2e6;
} */
 form.float-right.textfr {
    font-size: 12px;
}

 .topbtnbox {
    display: inline-flex;
}
 .topbtnbox:befor{
    border-bottom: 1px solid #ebe0e0;
    display: inline-block;
    z-index: 400;
    border-width: 3px;
    padding-top: -8px !important;
    position: absolute;
    top: -8px;
}
span.iocnb0x.hedFontAws {
    padding: 11px;
    display: inline-block;
    font-size: 15px;
}
button.btn.btn-default.bgbtnclr {
    border: 1px solid #31719a;
    padding: 0px 16px 0px 26px;
    border-radius: 15px 40px 40px 16px !important;
    background-color: #31719a;
    height: 30px;
    top: 12px;
    outline-color: #31719a;
    color: #fff;
    font-size: 18px;
    z-index: 5;
    left: 12px;
}

.topbtnbox .brbline {
    border-bottom: 1px solid #f2f2f2;
    display: inline-block;
    position: absolute;
    z-index: 0;
    width: 100px;
    border-width: 3px;
    left: 14.9rem;
    top: 26px;
    margin-left: 14px;
}
.topbtnbox .brbline2 {
    border-bottom: 1px solid #f2f2f2;
    display: inline-block;
    position: absolute;
    z-index: 0;
    width: 100px;
    border-width: 3px;
    left: 15.4rem;
    top: 26px;
    margin-left: 14px;
}

.topbtnbox .brbline3 {
    border-bottom: 1px solid #f2f2f2;
    display: inline-block;
    position: absolute;
    z-index: 0;
    width: 100px;
    border-width: 3px;
    left: 13.6rem;
    top: 26px;
    margin-left: 14px;
    z-index: 0;
}

.selectedChallan{background: lightgrey;}
span.iocnb0x.boxbg2.bx3 {
    padding: 6px 12px;  
}


.btn-group.btn-group-md.box2 {
    left: 2rem;
}

.btn-group.btn-group-md.box3 {
        left: 4.5rem;
}
.btn-group.btn-group-md.box4 {
    left: 7rem;
}

.float-right.mrtop {
    margin-top: 12px;
    float: right !important;
}
/*timeline step2 bgcolor======*/
button.btn.btn-default.bgbtnclr.boxbg2.bgclrstep2 {
    background-color: #31719a;
    color: #fff;
    z-index: 5 !important;
    border-color: #31719a;
}
span.iocnb0x.boxbg2.bx3.hedFontAws.bgclrstep2 {
    background-color: #31719a;
    border-color: #31719a;
    color: #fff;
}
.brbline.bgclrstep2 {
    border-bottom: solid #31719a;
    border-width: 3px;
}

/*end timeline step2 bgcolor======*/

 .switch {
    position: relative;
    display: inline-block;
     width: 40px;
    height: 20px;
}

 .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

 .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #31719a;
  -webkit-transition: .4s;
  transition: .4s;
}

 .slider:before {
position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 1px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

 input:checked + .slider {
    background-color: #ccc;
}
 input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

 input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
 .slider.round {
  border-radius: 34px;
}

 .slider.round:before {
  border-radius: 50%;
}

 ul.pagination.pamarginleft {
    margin-left: 15rem;
}


 .slidecontainer {
  width: 100%;
}

 .slider2 {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

 .slider2:hover {
  opacity: 1;
}

 .slider2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 23px;
  height: 24px;
  border: 0;
  cursor: pointer;
}

 .slider2::-moz-range-thumb {
  width: 23px;
  height: 24px;
  border: 0;
  cursor: pointer;
}

 span.clrchall {
    color: #636363;
}

 .card-header.carpadd {
    padding: 0.5rem 0.25rem;
    
    height: 41px;
}

 p.ptextpadd {
    margin-top: 0;
    margin-bottom: 0.35rem;
    font-size: 14px;
}

 .card-body.bodpaddtopb {
    padding: 4px 7px;
}



 button.btn.btn-default.bgbtnclr.boxbg2.bgclrtds {
    background-color: #31719a;
    color: #fff;
    z-index: 0 !important;
    border-color: #31719a;
}

 span.iocnb0x.boxbg2.bx3.bgclrtds2 {
    background-color: #31719a;
    border-color: #31719a;
    color: #fff;
}
 span.iocnb0x.boxbg2.box4icon {
    background-color: #f2f2f2;
    color: #333;
    border-color: #f2f2f2;
}

 p.text-left.borbott {
    border-bottom: 1px solid #333;
}

 button.btn.btn-default.bgbtnclr.boxbg2.screen12{
    background-color: #31719a;
    color: #fff;
    outline-color: #31719a;
    border-color: #31719a;
}
 span.iocnb0x.boxbg2.bx3.screen12 {
        background-color: #31719a;
    color: #fff;
    outline-color: #31719a;
    border-color: #31719a;
}

 .bgclrfrm {
    background-color: #fff;
    border: 1px solid #e5d7ff;
    padding: 15px 15px;
}
 p.textlrfrm {
    color: #e7aa25;
}
 span.textsizeclr {
    color: #e7aa25;
    text-align: right;
    float: right;
    font-size: 12px;
}

 button.btn.btn-info.btnchallanver {
    background-color: #fc7d72;
    outline-color: #fc7d72;
    border-color: #fc7d72;
    border-radius: 0px;
    padding: 6px 25px;
    font-size: 18px;
}

/*card css*/

.card.mt-2.cardpadd0parti {
    padding: 0px;
}

.card.timel2patop {
    padding: 0px 10px;
    margin-bottom: 5px;
    margin-top: 0.1rem;
}
tr.timel2tdheadting td {
    background-color: #f1c5c19c;
    font-weight: 600;
}

/*btn css==*/
button.btn.btn-default.timelst2bgbtngc.mr-1 {
    background-color: #fc7d72;
    color: #fff;
    border: 1px solid #fc7d72;
    padding: 5px 15px 7px 15px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 500;
}
button.btn.btn-default.timelst2bgbtnpfv {
    background-color: #31719b;
    color: #ffffff;
    border: 1px solid #ddd;
    padding: 5px 15px 7px 15px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 500;
}
.mr10Step2 {
    margin-top: 10px;
}

td.textbotbor span.link {
    border-bottom: 1px solid #31719a;
}
.link:hover {
    text-decoration: none;
}

/*media code===========*/
@media only screen and (min-width: 320px) and (max-width: 767px){
    .topbtnbox {
       display: block;
    }
    .topbtnbox .brbline {
        display: none;
    }
    .btn-group.btn-group-md.box2 {
    left: 0rem;
    top: 07px;
    }
    .topbtnbox .brbline2 {
        display: none;
    }
    .btn-group.btn-group-md.box3 {
    left: 0px;
    top: 13px;
    }
    .topbtnbox .brbline3 {
       
        display: none;
    }
    .btn-group.btn-group-md.box4 {
        left: 0rem;
        top: 17px;
    }
    ul.pagination.pamarginleft {
        margin-left: 6rem;
    }
    button.btn.btn-info.btnunautobg {
        border-radius: 0px;
        font-size: 18px;
        margin-top: 6px;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
        margin-left: 6rem;
    }
    .MuiSlider-root {
      
        width: 60% !important;
       
    }

    span.btn.btn-info.tdscobtnbgclr {
       
        margin-top: 19px;
    }

    table.table.table-striped.tblretunre tr th {
        font-size: 11.5px;
        font-weight: 500;
    }
    .form-inline .form-control {
        width: 86px;  
   }
    .clear-filter.clriconttd {
        left: 74px !important;
    }
    .clear-filter.clricon {
        left: 67px !important;
    }
    .selctwid.seacrhboxsize form#paid-amount-range-box input {
        width: 100%;
    }
    .selctwid form.form-inline .multi-select {
        max-width: 100% !important;
        min-width: 100% !important;
    }
    button.btn.btn-default.bgbtnclr {
       font-size: 15px;
   }
   .mr10Step2{
       margin-top: 10px;
    }
    button.btn.btn-default.timelst2bgbtngc.mr-1 {
        margin-bottom: 6px;
    }
    .card.timel2patop {
      
        margin-top: 2rem;
    }

}

@media only screen and (min-width: 768px) and (max-width: 941px){
    span.btn.btn-info.tdscobtnbgclr {
        
        margin-top: 0px;
    }
    .topbtnbox {
       display: block;
    }
    .topbtnbox .brbline {
        display: block;
    }
    .btn-group.btn-group-md.box2 {
        left: 1rem;
        top: 0px;
    }
    .topbtnbox .brbline2 {
        display: none;
    }
    .btn-group.btn-group-md.box3 {
        left: 2rem;
        top: 0px;
    }
    .topbtnbox .brbline3 {
        width: 43px;
        left: -1.1rem;
    }
    .btn-group.btn-group-md.box4 {
        left: 0rem;
        top: 2px;
    }
    .float-right.mrtop {
        margin-top: -32px;
    }
    .card.cardpaddrf {
        padding: 0px;
        margin-top: 9px;
    }
    .MuiSlider-root {
      
        width: 60% !important;
       
    }
    .MuiTypography-gutterBottom {
    
        font-size: 9px !important;
        padding-top: 0px;
       
    }
    .form-inline .form-control {
        width: 86px;  
   }
    .clear-filter.clriconttd {
        left: 74px !important;
    }
    .clear-filter.clricon {
        left: 67px !important;
    }
    .selctwid.seacrhboxsize form#paid-amount-range-box input {
        width: 100%;
    }
    .selctwid form.form-inline .multi-select {
        max-width: 100% !important;
        min-width: 100% !important;
    }
    button.btn.btn-default.bgbtnclr {
        font-size: 15px;
    }
}
@media only screen and (min-width: 942px) and (max-width: 1025px){
    .topbtnbox {
       display: block;
    }
    .topbtnbox .brbline {
        display: block;
    }
    .btn-group.btn-group-md.box2 {
    left: 1.6rem;
    top: 0px;
    }
    .topbtnbox .brbline2 {
        display: block;
        left: 12.4rem;
    }
    .btn-group.btn-group-md.box3 {
    left: 3.3rem;
    top: 0px;
    }
    .topbtnbox .brbline3 {
        left: 12.4rem;
        display: block;
    }
    .btn-group.btn-group-md.box4 {
        left: 5rem;
        top: 0px;
    }
    .float-right.mrtop {
        margin-top: 13px;
    }
    .card.cardpaddrf {
        padding: 0px;
        margin-top: 9px;
    }
    .MuiSlider-root {
      
        width: 60% !important;
       
    }
    .MuiTypography-gutterBottom {
    
        font-size: 9px !important;
        padding-top: 0px;
       
    }

    .form-inline .form-control {
        width: 86px;  
   }
    .clear-filter.clriconttd {
        left: 74px !important;
    }
    .clear-filter.clricon {
        left: 67px !important;
    }
    .selctwid.seacrhboxsize form#paid-amount-range-box input {
        width: 100%;
    }
    .selctwid form.form-inline .multi-select {
        max-width: 100% !important;
        min-width: 100% !important;
    }
    button.btn.btn-default.bgbtnclr {
        font-size: 15px;
    }
    button.btn.btn-default.bgbtnclr.boxbg2.bgclrstep2, span.iocnb0x.boxbg2.bx3.hedFontAws.bgclrstep2 {
        z-index: 5 !important;
    }
 

}

@media only screen and (min-width: 1025px) and (max-width: 1399px){
    .topbtnbox {
       display: inline-flex;
    }
    .topbtnbox .brbline {
        display: inline-flex;
    }
    .topbtnbox .brbline2.newtimelinebor2 {
       width: 50px !important;
    }
    .topbtnbox .brbline3.newtimelinebor3, .topbtnbox .brbline4.newtimelinebor4 {
        width: 50px !important;
    }
    .btn-group.btn-group-md.box2 {
        left: 0.5rem !important;
        top: 1px;
    }
    .topbtnbox .brbline2 {
        display: block;
    }
    .btn-group.btn-group-md.box3 {
        left: 1rem !important;
        top: 1px;
    }
    .topbtnbox .brbline3.newtimelinebor3 {
        left: 8.6rem;
    }
    .topbtnbox .brbline3 {
       
        display: block;
    }
    .btn-group.btn-group-md.box4 {
        left: 2.3rem !important;
    }
    .topbtnbox .brbline4.newtimelinebor4 {
        left: 16.6rem;
    }
    .btn-group.btn-group-md.box5.newtimelinebox5 {
        left: 2.9rem;
    }

#drag-element-tooltip-main{
    position: absolute;
    left: -999em;
    opacity: 1;
}
#drag-element-tooltip {
    width: 200px;
    height: 50px;
    border: 1px solid #333333b8;
    box-shadow: 1px #333333b8;
    background-color: #fff;
    color: #212121;
    font-size: 12px;
    text-align: center;
    padding: 5px;
    border-radius: 19px 20px 20px 19px;
    position: absolute;
    z-index: 1000;
    opacity: 1;
  }
    button.btn.btn-default.bgbtnclr.boxbg2.bgclrstep2, span.iocnb0x.boxbg2.bx3.hedFontAws.bgclrstep2 {
        z-index: 5 !important;
    }
}




.text-center.addpusecent.justify-content-center.modal-title.h4 {
  font-size: 1.2rem;
  text-align: center;
  margin: 0px 1rem;
  padding-top: 1px;
  color: #333;
  font-weight: 500;
  padding: 4px 0px 4px 0px;
}
.headerinvite.modal-header {
  background-color: #eee;
  padding: 0px 12px 0px 12px;
}
.headerinvite.modal-header button.close {
  top: 2px;
  position: relative;
}
button.inviteuser_btnbgclr {
  background-color: #FC7D72;
  color: #ffffff;
  border: 0px;
  padding: 4px 20px 7px 20px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 40px;
}

.invite-popup-table tr td {
  padding: 5px;
}

.usr-invite .modal-dialog {
  max-width: 1024px !important;
}

.usr-invite .borderdeductor {
  border: 1px solid rgba(34, 36, 38, .15);
 
}
.invite-popup-table{
  margin-bottom: 0px!important;
}

@media (max-width: 767px){


}
@media (min-width: 768px) and (max-width: 1024px){


}
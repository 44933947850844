body {
  font-weight: 500!important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  background-color: #fff!important;

}
/*===== timeline=================*/

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;

}
ul.timeline > li {
  margin: 12px 0;
  padding-left: 52px;
}

ul.timeline > li::before {
  content: ' ';
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #31719b;
  left: 18px;
  width: 25px;
  height: 25px;

}
ul.timeline > li.step01:before{
  background: #31719b;
}
span.textalig {
  font-size: 16px;
}
ul.timeline > li span.step {
  margin-left: -25px;
  position: absolute;
  display: inline-block;
  top: 0px;
  font-size: 16px;
  color: #fff;
}
ul.timeline > li.step02:before{
  background: #333;
}
ul.timeline > li span.step02 {
  top: 2.4rem;
  font-size: 13px;
  color: #fff;
}
ul.timeline > li.step03:before{
  background: #333;
}
ul.timeline > li span.step03 {
  top: 4.7rem;
  font-size: 13px;

}
ul.timeline > li.step04:before{
  background: #333;
}
ul.timeline > li span.step04 {
  top: 7rem;
  font-size: 13px;

}
span.textsizechbox {
  font-size: 12px;
}
span.iconclrcnc {
  padding: 6px 13px;
  font-size: 16px;
}
li.step02 {
  color: #b5b5b5;
}
ul.timeline > li.step02:before {
  background: #bcb2b2;
  border-color: #b5b5b5;
}

ul.timeline > li.step03:before {
  background: #bcb2b2;
  border-color: #b5b5b5;
}
ul.timeline > li.step04:before {
  background: #bcb2b2;
  border-color: #b5b5b5;
}
span.martopclr {
  color: #b5b5b5;
 
}
span.martopclr span.iconclrcnc {
  
  font-size: 14px;
}
.borboxtime {
  padding: 2px 0px;
  margin: 0px 0px;
  border: 1px solid #eee;
  box-shadow: 0px 1px 1px #eee;
  cursor: pointer;
}
.borboxtime:hover {
  border: 1px solid #e6dede;
  box-shadow: 0px 3px 7px #e6dede;
  animation: all 0.5s ease;
  cursor: pointer;
}
.bortopbox {
  border-top: 1px solid #ece0e0;
  padding: 3px 0px;
}

a.bgclrtext {
  background-color: #e2e2e2;
  padding: 5px 6px;
  margin-left: 19px;
}
/*===== end timeline=================*/
/*===== start fieldset=================*/

fieldset.fieldover {
  border: 1px solid #fc7d72;
}
legend.legentover {
  width: 7rem;
}

fieldset.fieldchallanamt {
  border: 1px solid #31719b;
}
legend.legchallanamt {
  width: 11rem;
}

fieldset.fieldview {
  border: 1px solid #fc7d72;
}
legend.legentview {
  width: 7rem;
}

fieldset.fieldsectwsumm {
  border: 1px solid #31719b;
}
legend.legsectwsumm {
  width: 17rem;
}
legend.legsectwsumm h4 {
  font-size: 1.2rem;
}
/*===== end fieldset=================*/



.fstext {
  font-size: 14px;
}

.backtoclr {
  color: rgb(252, 125, 114);
  font-size: 12px;
  padding: 4px 0px;
}

span.listborrem p {
  padding: 0px 5px 0px 37px;
  font-size: 13px;
}
p.paddbrem {
  padding-bottom: 0px;
  margin-bottom: 2px;
}
span.iconclr {
  color: rgb(252, 125, 114);
  font-size: 8px;
}

ul.quick-links {
  font-size: 12px;
  list-style: none;
  height: 173px;
  overflow: auto;
  overflow-x: visible;
  margin-bottom: 2px;
}

ul.quick-links li {
  padding: 2.2px 0px;
  font-size: 11px;
}

ul.quick-links li a {
  text-decoration: underline;
}

ul.quick-links li span {
  text-decoration: underline;
  color: #ccc;
}

ul.quick-links li span.link{
  text-decoration: underline;
  color: #31719B;
  cursor: pointer;
}

ul.quick-links li span.link:hover{text-decoration: none;}

.card.paddcard .card-header {
  padding: 0.25rem 1.25rem;

}
.card.paddcard .card-body {
  padding: 0.25rem 0.65rem !important;
}
.card-body.cbodymb {
  margin-bottom: -18px;
}

span.float-right.tbhfsize {
  font-size: 14px;
}

button.btn.btn-secondary.bgclrcan {
  border-radius: 0px;
  font-size: 18px;
  padding: 5px 34px;
  background-color: rgb(220 202 201);
  border-color: rgb(220 202 201);
  outline-color: rgb(220 202 201);
  color: #333;
}
h4.modal-title.modelbpadd {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
}
button.btn.btn-secondary.bgclrcreat {
  border-radius: 0px;
  font-size: 18px;
  padding: 3px 34px;
      background-color: rgb(252, 125, 114);
  border-color: rgb(252, 125, 114);
  outline-color: rgb(252, 125, 114);
}

table.table.table-striped.tbdedutr1 thead tr th {
  font-size: 12.4px !important;
  padding-top: 0px;
  font-weight: 500;
  padding-bottom: 5px;
}
table.table.table-striped.tbdedutr1 tr td {
  font-size: 11px;
 
}
.textclrordd {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 2.3rem;
  color: #fc7d72;
}

.verifyselect {
  font-size: 12px;
  
}
span.totalamttextrd {
  font-style: italic;
  font-size: 14px;
  padding: 0px 0px;
  text-align: left;
}
span.totalamtrd {
  position: relative;
  top: 0px;
  color: #f90;
  font-style: italic;
  padding: 0px 21px;
  font-size: 15px;
}


/*================= fieldset start========*/
fieldset.fsdsborblue {
  border: 1px solid #31719b;
}
legend.lengentds {
  width: 100%;
  padding: 0;
}
.fielddsinndev {
  display: block;
}
.dstilefts {
  float: left;
  padding: 0 5px;
}
.dsinner {
  height: 1px;
  width: 100%;
  position: relative;
}
.viewcompnright {
  float: right;
  padding: 0 5px;
}
.bortoplg.dsborblue {
  border-top: 1px solid #31719b;
  display: flex;
  position: relative;

}

.centerAlign {
  text-align: center;
}

/*=== overflow tooltip css*/
td.overflowtooltipcont {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: inline-block;
}
td.overflowtooltiptopfive1col {
  max-width: 98px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: inline-block;
}
td.overflowtooltiptopfive {
  max-width: 98px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
td.overflowtextcollcode {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}


a {
  color: #33729c;
}

.backbtntextclr {
  color: #fc7d72;
  font-size: 12px;
  cursor: pointer;
}

.backbtntextclr:hover {
  text-decoration: underline !important;
}
/*----------------- return dash SC table --------------*/

.btn.btn-default.startcorrbtnbgclr {
  background-color: #31719B;
  color: #ffffff;
  border: none;
  padding: 7px 15px;
  border-radius: 40rem;
  font-size: 12px;
  float: right;
  font-weight: 500; 
}

fieldset {
  display: block;
  margin-left: 2px !important;
  margin-right: 2px !important;
  background-color: #fff;
  padding-top: 0rem !important;
  padding-bottom: 0.625em !important;
  padding-left: 0.75em !important;
  padding-right: 0.75em !important;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0rem !important;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

fieldset.FSreturndashcorr {
  border: 1px solid #fc7d72;
}
legend.lengreturndashcorr {
  width: 6rem;
}
h4.returndaashtitle {
  font-size: 0.9rem;
  padding: 0rem 0.5rem;
  margin-top: 0.4rem;
}

table.table.table-striped.returndashcorrtb thead tr th {
  font-size: 12px!important;
  text-align: center!important;
  font-weight: 500;
  border-top: 0rem;
}
table.table.table-striped.returndashcorrtb tr td {
font-size: 11px !important;
  font-weight: 500;
    padding: .25rem .75rem;

}
fieldset.FScorrectsum {
  border: 1px solid #31719b;
}
legend.lengcorrectsum {
  width: 35%;
}
h4.correctsumtitle {
  font-size: 0.9rem;
  padding: 0rem 0.5rem;
  margin-top: 0.4rem;
}
table.table.table-striped.correctionsummtb thead tr th {
  font-size: 12px!important;
  text-align: center!important;
  font-weight: 500;
  border-top: 0rem;
}
table.table.table-striped.correctionsummtb tr td {
font-size: 11px !important;
  font-weight: 500;
padding: .25rem .75rem;
}
td.textclrgray {
  color: #808080;
}

a.downloadtextclr {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #31719b;
}
fieldset.FSquicklink {
  border: 1px solid #fc7d72;
}
legend.lengquicklink {
  width: 11%;
}
h4.quicklinktitle {
  font-size: 0.9rem;
  padding: 0rem 0.5rem;
  margin-top: 0.4rem;
}
fieldset.FSchallanamt {
  border: 1px solid #31719b;
}
legend.lengchallanamt {
  width: 14%;
}
h4.challanamttitle {
  font-size: 0.9rem;
  padding: 0rem 0.5rem;
  margin-top: 0.4rem;
}

fieldset.FSsectionwaisesumm {
  border: 1px solid #fc7d72;
}
legend.lengsectionwaisesumm {
  width: 48%;
}
h4.sectionwaisesummtitle {
  font-size: 0.9rem;
  padding: 0rem 0.5rem;
  margin-top: 0.4rem;
}


fieldset.fstopfivedeductee {
  border: 1px solid #31719b;
}
.fivededucteeinndev {
  display: block;
}
.fivededucteelefts {
  float: left;
  padding: 0 5px;
}
.fivededucteeinner {
  height: 1px;
  width: 100%;
  position: relative;
}
.fivededucteelefts h4 {
  font-size: 0.9rem;
  padding-top: 0.3rem;
}


.fivededucteeright {
  float: right;
  padding: 0 5px;
}
h4.topfivededtitle a {
  font-size: 0.8rem;
  color: #31719b;
}
.fivededucteetopborclr {
  border-top: 1px solid #31719b;
  display: flex;
  position: relative;
  margin-top: 17px;
}
table.table.table-striped.topfivededtb thead tr th {
  font-size: 12px!important;
  text-align: center!important;
  font-weight: 500;
  border-top: 0rem;
}
table.table.table-striped.topfivededtb tr td {
font-size: 11px !important;
  font-weight: 500;
padding: .25rem .75rem;
}

fieldset.FSeditaddfield {
  border: 1px solid #31719b;
}
legend.lengeditaddfield {
  width: 0%;
}
h6.editaddtextclr {
  font-size: 0.8rem;
  color: #31719b;
}
.editaddfieldsect {
  border: 1px solid #31719b;
  padding: 1rem 1.1rem;
}
span.textblod {
  font-weight: 600;
}
/*=============== media code start ====================*/

@media (max-width: 767px){
legend.lengcorrectsum {
  width: 67%;
}
legend.lengquicklink {
  width: 50%;
}
legend.lengchallanamt {
  width: 61%;
}
legend.lengsectionwaisesumm {
  width: 81%;
}
.fivededucteelefts h4 {
  font-size: 0.9rem;
}
h4.topfivededtitle a {
  font-size: 0.8rem;
}
}

@media (min-width: 768px) and (max-width: 941px){
legend.lengcorrectsum {
  width: 67%;
}
legend.lengquicklink {
  width: 18%;
}
legend.lengchallanamt {
  width: 23%;
}
legend.lengsectionwaisesumm {
  width: 81%;
}
.fivededucteelefts h4 {
  font-size: 0.9rem;
}
h4.topfivededtitle a {
  font-size: 0.7rem;
}
}

@media (min-width: 942px) and (max-width: 1024px){
legend.lengcorrectsum {
  width: 43%;
}
legend.lengquicklink {
  width: 18%;
}
legend.lengchallanamt {
  width: 17%;
}
legend.lengsectionwaisesumm {
  width: 81%;
}
.fivededucteelefts h4 {
  font-size: 1.2rem;
}
h4.topfivededtitle a {
  font-size: 1rem;
}
}

/*==================start media code-==================*/
@media  (min-width: 320px) and (max-width: 1024px){

  .table-responsive {
      display: block !important;
  }

}

@media  (min-width: 320px) and (max-width: 767px){
    span.textalig {
        font-size: 9px;
    }
    span.textsizechbox {
        font-size: 9px;
    }
    .deductee-popup-vertical {
      min-width: 300px;
      max-width: 80%;
    }
    .dstilefts h4 {
      font-size: 13px;
      margin-top: 18px;
    }
    .viewcompnright h4 span.float-right.tbhfsize {
      font-size: 10px;
    }
    .bortoplg.dsborblue {
     display: none;
    }

}


@media  (min-width: 768px) and (max-width: 941px){
  span.listborrem p {
      font-size: 14px;
  }
  span.textalig {
    font-size: 9px;
    }
    span.textsizechbox {
        font-size: 9px;
    }
    
    .dstilefts h4 {
      font-size: 13px;
    }
    .viewcompnright h4 span.float-right.tbhfsize {
      font-size: 8px;
    }



  }


@media  (min-width: 942px) and (max-width: 1024px){
  span.listborrem p {
      font-size: 14px;
  }
  span.textalig {
    font-size: 9px;
    }
    span.textsizechbox {
        font-size: 9px;
    }
    
    .dstilefts h4 {
      font-size: 13px;
    }
    .viewcompnright h4 span.float-right.tbhfsize {
      font-size: 8px;
    }


  }
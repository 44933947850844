.fade.grosssalaryvpopup.modal.show .modal-dialog {
  margin: 0px 0px;
  float: right;
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;
  top: 0;
  right: 0;
  width: 47%;
  height: 100%;
  min-width: 580px;
  max-width: 80%;
  border: 0px;
  border-radius: 0px;
  padding: 0px 0px;
}
.fade.grosssalaryvpopup.modal.show {
  overflow-x: hidden;
  overflow-y: hidden;
}
.fade.grosssalaryvpopup.modal.show .modal-dialog .modal-content {
  height: 100vh !important;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}
.grosssalaryheader.modal-header {
  padding: 1px 15px;
  background-color: #eee;
  position: sticky;
}
span.topnexttle {
  font-size: 14px;
  color: #423d3d;
}
.headermaintitle {
  font-size: 0.9rem;
}
.headermaintitle .active {
  color: #fff !important;
  background-color: #fc7d72 !important;
  outline-color: #fc7d72 !important;
}
.grosssalaryheader.modal-header button.close {
  margin-top: -9px;
  font-size: 19px;
}
.grosssalarybody.modal-body {
  padding: 4px 1px;
  height: 400px;
  position: relative;
  overflow-x: auto;
}
img.grosssalaryicon {
  position: absolute;
  z-index: 1;
  top: 29px;
  width: 5px;
  right: 24px;
  cursor: pointer;
}
.card.grosssalaryph3card {
  padding: 0px 0px;
}
.card.grosssalaryph3card .card-header {
  padding: 3px 7px;
  font-size: 13.5px;
}
.card.grosssalaryph3card .card-body {
  padding: 1px 7px;
}
span.fsizeiconrup {
  font-size: 15px;
  font-weight: 500;
}
span.netsalarytext {
  font-size: 13.5px;
}

span.filedoticonwidth img {
  width: 5px;
  cursor: pointer;
  margin-left: 0.4rem;
  position: relative;
  vertical-align: middle;
  top: 12px;
}
span.threedoticonpadd_delicon {
  position: relative;
  top: 9px;
  margin-left: 5px;
}
span.threedoticonpadd {
  position: relative;
  left: 5px;
  cursor: pointer;
}
span.allowaicon img {
  width: 16px;
  cursor: pointer;
  position: relative;
  top: 26px;
  left: 3px;
}
span.alladdicon img {
  width: 16px;
  cursor: pointer;
}
span.alladdiconbox {
  position: relative;
  left: 7px;
  cursor: pointer;
}
span.alladdiconbox img {
  width: 16px;
  cursor: pointer;
}
button.btn.btn-default.grossph3btnblueclr {
  background-color: #31719b;
  color: #ffffff;
  border: none;
  padding: 7px 20px !important;
  font-size: 12px !important;
  border-radius: 40px !important;
  cursor: pointer;
}
.gorsstotlincome {
  margin: 5px 0px;
  font-size: 1.1rem;
}
.gorsstotlincome.pt-2 span {
  font-size: 0.9rem;
}
span.ductchapsectionicon img {
  width: 16px;
  margin-top: 0.6rem;
  cursor: pointer;
  margin-right: 5px;
}
span.ductchapsectionicon.topcolicon img {
  margin-top: 2.2rem;
}
span.taxcopinnertext {
  margin-left: 1rem;
}

span.taxcopinnertext img {
  width: 14px;
  margin-top: -0.2rem;
  cursor: pointer;
}
span.whethericonsect img {
  width: 16px;
  padding-top: 2.2rem;
  cursor: pointer;
}

span.whethericonsect span.Wplusiconleft {
  float: right;
  padding-right: 23px;
}
.salary_add_cell {
  top: 60px;
  z-index: 9;
}

ul.autoCompDTF {
  margin: 0 auto;
  padding: 0;
  max-height: 113px;
  overflow-y: auto;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background: #fff;
  position: relative;
  z-index: 99999;
  position: absolute;
}

.salary_details_form .error_message {
  color: red;
  font-size: 10px;
  padding-left: 0px;
}

.error_div {
  color: #ff0000;
}

.salary_add_cell input {
  min-width: 122px !important;
}
.error_input {
  border: 1px solid red !important;
}
.grosssalaryvpopup {
  z-index: 1049 !important;
}
.k-animation-container {
  z-index: 9999;
}
.k-popup {
  z-index: 9999 !important;
}
.form-control {
  font-size: 12px;
}
.form-control-option .k-list > .k-item {
  font-size: 11px;
}
.k-autocomplete.k-header,
.k-autocomplete {
  color: #495057;
  height: 2rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.k-widget.form-control .k-input {
  padding: 0px;
  height: 1.1rem;
  font-size: 11px;
}
form.salary_details_form.salarydpopup input.k-input::placeholder {
  font-size: 10px;
  color: #cccccc;
  opacity: 1;
}
form.salary_details_form.salarydpopup input.form-control::placeholder {
  font-size: 10px;
  color: #cccccc;
  opacity: 1;
}
.daterangepicker.ltr.show-calendar.opensright {
  margin: auto 52px;
}
.daterangepicker.ltr.show-calendar.opensright.otherDetailsDatePicker {
  margin: -9.5rem 52px 0 52px;
}
.headermaintitle {
  /* border: 1px solid #a2a3a4; */
  outline-color: #a2a3a4;
  margin-bottom: 5px;
  margin-top: -2px;
  padding: 3px 0px;
}

.headermaintitle .salarydbtnbox {
  border-radius: 0;
  color: #31719b;
  background-color: #fff;
  border: 0;
  outline-color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 30px 5px 30px;
  border-right: 1px solid #a2a3a4;
}
.headermaintitle .otherincombtnbox {
  border-radius: 0;
  color: #31719b;
  background-color: #fff;
  border: 0;
  outline-color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px 5px 10px;
  border-right: 1px solid #a2a3a4;
}
.headermaintitle .otherdetbtnbox {
  border-radius: 0;
  color: #31719b;
  background-color: #fff;
  border: 0;
  outline-color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 30px 5px 30px;
}
.savebtnxsdisblock {
  position: fixed;
  background-color: #ffffff;
  display: flex;
  text-align: left;
  width: 100%;
  padding: 1px 37px;
  /*top: 38.7rem;*/
  bottom: 0px;
  margin-left: -1rem;
}

.k-clear-value {
  position: absolute;
  top: 0px;
  right: 0px;
}
.form-control-option li.k-state-focused {
  box-shadow: none !important;
  color: #0056b3 !important;
  background-color: #ced4da !important;
}
.calendar_input .form-control {
  font-size: 11px !important;
}
.tds_amount_field input {
  width: 85%;
  display: inline-block;
}
.refrsh_sync {
  font-size: 12px;
  color: #fc7d72;
  margin-left: 5px;
  cursor: pointer;
}
.mdp-container {
  top: 40px!important;
}

.addinnerbtnbgclr {
  color: #31719b !important;
  font-size: 15px !important;
  text-transform: capitalize;
  top: 10px !important;
  background-color: #fff !important;
  outline-color: #fff;
  padding: 2px 5px 2px 5px !important;
  left: 0rem;
  border: 0px;
  background-image: linear-gradient(rgb(255 255 255), rgb(255 255 255));
}

span.addnewded_textfs {
  color: #31719b;
  font-size: 11px;
  font-weight: 500;
}

.div.nodata.k-no-data, .k-nodata {
  min-height: 92px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  text-align: center;
  white-space: normal;
}

/*============Tax Regime Toggle=========*/

.salary_rightsidetextfs {
  cursor: pointer;
  color: #31719b;
}

.salary_rightsidetextfs:hover {
  text-decoration: underline;
}

#toggle-button2 { margin-right: 3px; }
#toggle-button3 { margin-left: 3px; }

.salary_statetoggle_ht02 {
  background: rgba(165, 170, 174, 0.25);
  box-shadow: inset 0 2px 8px 0 rgba(165, 170, 174, 0.25);
  border-radius: 24px;
  display: inline-block;
  overflow: hidden;
  display: inline-flex;
  transition: all 500ms ease;
  vertical-align: inherit;
  top: 1px;
  position: relative;
  cursor: pointer;
}

.salary_statetoggle_button__ht02 {
  border-radius: 3px !important;
  height: 11px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px !important;
  background-color: transparent;
  border: 0px solid transparent;
  margin: 0px 0px;
  color: #727C8F;
  transition: all 0.5s ease;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.salary_statetoggle_button__ht02.active {
  border: 1px solid rgba(207, 207, 207, 0.6);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  color: #6744B1;
  font-weight: 500;
  transition: all 0.4s ease-in;
  background-color: #31719b;
  border-radius: 11px !important;
  min-width: 10px;
  cursor: pointer;

}

.salary_statetoggle_button__ht02.salary_togglebtn_02.active {
  border: 1px solid rgba(207, 207, 207, 0.6);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  color: #6744B1;
  font-weight: 500;
  transition: all 0.4s ease-in;
  background-color: #fff;
  border-radius: 11px !important;
  min-width: 10px;
  cursor: pointer;

}

.salary_statetoggle_button__ht02.salary_togglebtn_03.active {
  border: 1px solid rgba(207, 207, 207, 0.6);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  color: #6744B1;
  font-weight: 500;
  transition: all 0.4s ease-in;
  background-color: green;
  border-radius: 11px !important;
  min-width: 10px;
  cursor: pointer;
}

.salary_statetoggle_button__ht02:focus {
  outline: none;
}

/*============Tax Regime Toggle=========*/


/*============start media code=========*/
@media only screen and (max-width: 767px) {
  .fade.grosssalaryvpopup.modal.show .modal-dialog {
    min-width: 100%;
  }
  .btn-group.mt-2.mb-1.savebtnxsdisblock {
    display: block;
  }
  .daterangepicker.ltr.show-calendar.opensright {
    margin: auto 0px;
  }
  /*fixedbtnbott css*/
  .salarydtopm {
    margin-bottom: 4rem !important;
  }
  .savebtnxsdisblock {
    display: block;
    text-align: left;
  }
}
@media only screen and (min-width: 768px) and (max-width: 941px) {
  /*fixedbtnbott css*/
  .salarydtopm {
    margin-bottom: 2rem !important;
  }
  .savebtnxsdisblock {
    display: block;
    text-align: left;
  }
}

@media only screen and (min-width: 942px) and (max-width: 1025px) {
  /*fixedbtnbott css*/
  .salarydtopm {
    margin-bottom: 2rem !important;
  }
  .savebtnxsdisblock {
    display: block;
    text-align: left;
  }
  .monthwisesalalry_dateboxwidth .MyDatePicker input.form-control {
      padding: 3px 5px;
  }
  .monthwisesalalry_dateboxwidth {
    max-width: 114px !important;
  }
}
@media only screen and (min-width: 1026px) and (max-width: 1349px) {
  /*fixedbtnbott css*/
  .salarydtopm {
    margin-bottom: 2rem !important;
  }
  .savebtnxsdisblock {
    display: block;
    text-align: left;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1281px) {
.monthwisesalalry_dateboxwidth {
  max-width: 116px !important;
}

}

@media only screen and (min-width: 1282px) and (max-width: 1400px) {
  .monthwisesalalry_dateboxwidth {
    max-width: 122px !important;
  }
  
  }
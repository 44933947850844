* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #ffffff !important;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

a.exptds_justformtype_backbtn {
  color: #fc7d72;
  font-size: 12px;
  font-weight: 500;
  position: relative;
}

h4.exptds_justformtype_titlefs {
  font-size: 1rem;
  font-weight: 500;
}

nav.exptds_justformtype_nav {
  & .nav.nav-tabs {
    border-bottom: 0px solid #dee2e6 !important;

    & a.nav-item.nav-link.exptds_justformtype_tab {
      color: #31719b;
      background-color: #ffffff;
      border-color: #31719b;
      outline-color: #31719b !important;
      border-radius: 0px;
      padding: 2px 5px 3px 5px !important;
      font-size: 12px;
      border: 0px solid #31719b;
      font-weight: 500;
      border-width: 1.5px;
      border-right: 1px solid #31719b;
      text-align: center;
    }

    & a.nav-item.nav-link.exptds_justformtype_tab.active {
      color: #ffffff;
      background-color: #fc7d72;
    }

    & a.nav-item.nav-link.exptds_justformtype_borderleftnone {
      border-left: 0px !important;
    }
  }
}


table.table.table-striped.exptds_justformtype_table {
  thead tr th {
    border-top: 0;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    vertical-align: initial;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  tr td {
    font-size: 11px;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    vertical-align: middle;
  }

  tr.exptds_justformtype_toprow th {
    border-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  tr.exptds_justformtype_2row th {
    border-bottom: 0px !important;
  } 
}

.k-widget.k-grid.table.table-striped.exptds_justformtype_table tr th {
  border-top: 0;
  font-size: 11px !important;
  text-align: center;
  font-weight: 500 !important;
  vertical-align: initial;
  padding-top: 5px;
  padding-bottom: 5px;
   

  & th.sorting_disabled .k-link {
    cursor: default !important;
  }
}

.k-widget.k-grid.table.table-striped.exptds_justformtype_table tr th{
  .k-column-title {
    white-space: normal !important;
    font-size: 0.7rem !important;
    font-weight: 500 !important;
    line-height: 1.4;
    max-height: auto;
    overflow: visible;
    cursor: pointer;
  }
}

tr.tdsjustification_rowshadow {
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
    0 0 6px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 0 rgba(60, 64, 67, 0.15);
  border-bottom: 1px inset rgba(60, 64, 67, 0.15);
  position: relative;

  & td {
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #31719b;
    background-color: #ebf5e0 !important;
  }
}

h4.exptds_justformtype_intcons_title {
  font-size: 1rem;
  font-weight: 500;
  padding: 0px 5px;
}

fieldset {
  display: block;
  margin: 9px 2px 2px !important;
  background-color: #fff;
  padding: 0.35em 0.75em 0.625em !important;
  border: 2px groove internal value;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

fieldset.exptds_justformtype_intcons_fs {
  border: 1px solid #31719b;
}
legend.exptds_justformtype_intcons_leg {
  width: auto;
}

fieldset.exptds_justformtype_09fs {
  border: 1px solid #fc7d72;
}
legend.exptds_justformtype_09leg {
  width: auto;
}

table.table.table-striped.exptds_coversheet_table tr th {
  font-size: 12px;
  font-weight: 700;
  color: #fc7d72;
  padding: 0.25rem 0.75rem;
}
table.table.table-striped.exptds_coversheet_table tr td {
  font-size: 11px;
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  vertical-align: middle;
  color: #31719b;
}
.notices_orders_rowbgclr_yell {
  background-color: rgba(255, 252, 225, 0.568627451);
}

.notices_orders_rowbgclr_grey {
  background-color: #dee2e6;
}

.k-grid-content.k-virtual-content {
  overflow: auto;
}

.k-filterable.k-header.k-sorted.notices_orders_rowbgclr_yell {
  background-color: rgba(255, 252, 225, 0.568627451);
}

.k-filterable.k-header.k-sorted.notices_orders_rowbgclr_grey {
  background-color: #dee2e6;
}


a.btn.btn-default.tds_creatdeduct_pendbtnbgclr.planexpiredalert {
  padding: 3px 10px 5px 10px;
  text-align: center;
  margin: 0px;
  border: 0px;
}

a.btn.btn-default.tds_creatdeduct_pendbtnbgclr.planexpiredalert::focus {
  outline: 0;
  box-shadow: 0 0 0 0.0rem rgba(0, 123, 255, 0.25);
}


* {
  margin: 0;
  padding: 0;
}

body {
  font-weight: 500!important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  background-color: #ffffff !important;
}

/*===================start header css===============*/
header.returnhealth {
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  background-color: #fff;
}
.header-sticky {
  position: fixed;
  top: 78px;
  left: 25%;
  z-index: 99992;
  width: 50%;
  text-align: center;
}

header.returnhealth ul li {
  list-style-type: none;
  display: inline-block;
}

li.nav-item.dropdown.profileuserpaddtop {
  top: -7px;
}
span.profile_imgbgclr {
  padding: 7px 8px 10px;
  color: #31719b;
  background-color: #f2f2f2;
  border-radius: 50%;
}
span.profile_imgbgclr svg {
  position: relative;
  top: -2px;
  left: 1px;
}
span.activeuserpro svg {
  height: 10px;
  width: 10px;
  float: right;
  top: 3px;
  position: relative;
  display: inline-flex;
  color: #4caf50;
}
span.prousersigupicon img {
  float: right;
  height: 14px;
  width: 14px;
  top: 3px;
  position: relative;
}
.dropdown-menu.show.profileuserdropdshow {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
    0 5px 20px 0 rgba(21, 27, 38, 0.08);
  box-sizing: border-box;
  border-radius: 1px;
  font-size: 14px;
  margin-top: 6px;
  border-radius: 0;
  max-width: 100%;
  right: 0;
  left: auto;
}

/*===================end header css===============*/
a {
  color: #33729c;
}

.backbtntextclr {
  color: #fc7d72;
  font-size: 12px;
  cursor: pointer;
}

.backbtntextclr:hover {
  color: #0056b3 !important;
  text-decoration: underline !important;
}
/*----  top button css-----*/
button.btn.btn-primary.submitbtntext {
  color: #fff;
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 40px;
  font-size: 12px;
  padding: 5px 18px;
}
span.consolidtextbgclr {
  outline-color: #31719b;
  border-radius: 40px;
  color: #333;
  background-color: #33729c00;
  padding: 0.15rem 0.75rem;
  font-size: 12px;
  border: 0px solid #31719b;
  cursor: pointer;
}
/*---- end top button css-----*/

fieldset {
  display: block;
  margin: 9px 2px 2px 2px !important;
  background-color: #fff;
  padding: 0.35em 0.75em 0.625em !important;
  border: 2px groove (internal value);
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
fieldset.Fsetraisereqtraces {
  border: 1px solid #31719b;
}
.innerdevraisereqtraces {
  display: block;
}
.leftsidfileraisereqtraces {
  float: left;
  padding: 0px 5px 0px 5px;
}
.leftsidfileraisereqtraces h4 {
  font-size: 1.2rem;
  top: -3px;
  position: relative;
}
.innerhieghtdivraisereqtraces {
  height: 1px;
  width: 100%;
  position: relative;
}
.rightsidedivraisereqtraces {
  float: right;
  color: #fff;
  border-radius: 40px;
  margin-top: 4px;
  background-color: #33729c;
  padding: 0rem 0rem;
}
.rightsidedivraisereqtraces h4 {
  font-size: 0.9rem;
  padding: 0rem 0.75rem;
  top: 0.2rem;
  position: relative;
}
.midltoborraisereqtraces {
  border-top: 1px solid #31719b;
  display: flex;
  position: relative;
  margin-top: 17px;
}
.tokentextfs {
  font-size: 12px;
}
.toppaddlable {
  top: -3px !important;
  position: relative;
}

.form-group.col-md-6.pr-1 input.react-autosuggest__input {
  padding: .5rem .75rem;
  height: 1.8rem;
}
input.form-check-input.checkboxveralig {
  vertical-align: middle;
}
span.red_text {
  color: #ff2c39;
}
h4.challandetfs {
  font-size: 1.2rem;
  font-weight: 500;
}
.card.cardborclr {
  padding: 1px 8px 10px 8px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 1px 3px #eee;
}
.inputboxheight {
  padding: .5rem .75rem!important;
  height: 1.8rem!important;
}
p.optionprovidtextfs {
  font-size: 12px;
}
span.optiontextblod {
  font-weight: 600;
}
.card.cardborclr label {
  margin-bottom: 0.3rem;
  font-size: 12px;
}
.form-group.tracesdateofdpadd .MyDatePicker div {
  z-index: 9 !important;
}
.form-group.datecaliconzindex .MyDatePicker div {
  z-index: 9 !important;
}
.form-group.tracesdateofdpadd .MyDatePicker div input.form-control {
  padding: .5rem .75rem;
  height: 1.8rem;
}
.form-group.tracesdateofdpadd .MyDatePicker div span {
  top: -2px;
  position: relative;
}
.form-group.datecaliconzindex .MyDatePicker div input.form-control {
  padding: .5rem .75rem;
  height: 1.8rem;
}
.form-group.datecaliconzindex .MyDatePicker div span {
  position: relative;
  top: -2px;
}
.form-group.tracesdateofdpadd .MyDatePicker div span {
  top: -2px;
  position: relative;
}
.card.cardborclr .form-control {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
}
.card.cardborclr .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff70;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #80bdff70;
  transition: 0.5s all;
}
label.form-check-label.tokentextfs input.form-check-input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input.form-check-input.inputborborcle:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff70;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #80bdff70;
  transition: 0.5s all;
}
.card.cardborclr .form-group {
  margin-bottom: 0.3rem;
}

a.btn.btn-default.submitbtnbgclr {
  background-color: #31719b;
  border: 1px solid #31719b;
  color: #fff;
  padding: 0.3rem 1.3rem;
  border-radius: 40rem;
  font-size: 0.9rem;
}
a.btn.btn-default.cancelbtnbgclr {
  background-color: #f2f2f2;
  padding: 0.3rem 1.3rem;
  border-radius: 40rem;
  font-size: 12px;
  color: #636363;
  border: 1px solid #ddd;
}

.submitbtnbgclrs {
  background-color: #31719b;
  border: 1px solid #31719b;
  color: #fff;
  padding: 7px 20px;
  border-radius: 40rem;
  font-size: 12px;
}
.cancelbtnbgclrs {
  background-color: #f2f2f2;
  padding: 7px 20px;
  border-radius: 40rem;
  font-size: 12px;
  color: #636363;
  border: 1px solid #ddd;
}

label {
  font-size: 14px;
}

button.btn.btn-default.browsebtnTR {
  outline-color: #33729c;
  border-radius: 40px;
  color: #fff;
  background-color: #33729c;
  padding: 0rem 0.75rem;
  font-size: 12px;
  top: -0.1rem;
  position: relative;
}

.fileuppopup_dropzone {
  flex: 1 1;
  align-items: center;
  padding: 8px 10px;
  border-width: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  /* height: 200px; */
  background-color: #fafafa;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  width: 90%;
  border-radius: 40px;
}
.incometaxcredential.show {
    .modal-dialog {
    transform: none;
    width: 300px!important;
    top: 7rem;
    min-height: 400px ;
  }

  .mpoptextbor.modal-header .modal-title.h4{
    font-size: 1rem;
    font-weight: 500;
  }
  
  button.tracesbtncancel.btn.btn-primary {
    background-color: #f2f2f2;
    color: #636363;
    border: 1px solid #ddd;
    padding: 5px 15px;
    border-radius: 3px;
    font-size: 12px;
  }
  button.tracesbtnsubmit.btn.btn-primary {
    background-color: #fc7d72;
    color: #fff;
    border: 1px solid #fc7d72;
    padding: 3px 16px;
    border-radius: 40px;
    font-size: 12px;
  }
  .tracecredfoot.modal-footer {
    padding: 3px 12px;
  }
  button.btn.btn-link.clickbtnbgclrnone.btn.btn-primary {
    margin: 0rem 0.2rem;
    padding: 0rem;
    font-size: 12px;
    border: none;
    background-color: transparent;
  }
  .fade.alert.alert-danger.alert-dismissible.show {
    margin: 0rem auto;
    font-size: 13px;
    width: 362px;
  }

  
  @media only screen and (max-width: 767px){
    .modal-dialog {
        width: auto;
    }
    .fade.alert.alert-danger.alert-dismissible.show {
        margin: 0rem -4.4rem;
        font-size: 10px;
        width: 300px;
    }
    .fade.alert.alert-danger.alert-dismissible.show button.close {
        top: -0.3rem;
    }
  }
}

.fade.insightsconnwidthcaptcha_modal.modal.show .modal-dialog {
  max-width: 408px !important;
  margin: 2rem auto auto auto;

    & .insightsconnwidthcaptcha_header.modal-header {
        background-color: #eee;
        padding: 0px 10px;
        border-bottom: 1px solid #dee2e6;

        & .insightsconnwidthcaptcha_title.modal-title.h4 {
          font-size: 1rem;
          font-weight: 500;
          padding: 4px 0px 4px 0px;
          margin: 0px;
      }
    }

    & .insightsconnwidthcaptcha_body.modal-body {
        padding: 0px 10px 0px 10px;
    }
}
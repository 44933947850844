* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
}

h4.tds_listofdeducted_titlefs {
    font-size: 1.2rem;
    font-weight: 500;
    padding-right: 12rem;
}

a.tds_pendingreq_backtextclr {
    color: #fc7d72;
    font-size: 12px;
}

table.table.table-striped.tds_pendingreq_tb thead tr th {
    border-top: 0;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    vertical-align: middle;
    padding-bottom: 5px;
}

table.table.table-striped.tds_pendingreq_tb tr td {
    font-size: 12px;
    padding: .25rem .75rem;
    border: 0px;
}

span.px-1.borderline_clr {
    border-left: 1px solid #ddd;
}

td.tds_listofdeducted_tb_overflowtext {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: auto;
}

button.btn.btn-default.pending_updatest_btncglr {
    outline-color: #31719b;
    border: 0px;
    background-color: #31719b;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    padding: 2px 10px 3px 10px;
    border-radius: 40px;
}

button.pendingreq_getcaptcha_btnbgclr {
    outline-color: #31719b;
    border: 0px;
    background-color: #31719b;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    padding: 1px 10px 4px 10px;
    border-radius: 40px;
    width: 85px;
}

input.pending_enterrecaptcha_inputw {
    border: 1px solid #ddd;
    font-size: 11px;
    font-weight: 500;
    padding: 2px 0px 3px 3px;
}

p.pendingtb_showmore_textfsclr.mb-0 {
    color: #31719b;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}
.k-widget.k-grid.table.table-striped.tds_pendingreq_tb thead tr th .k-cell-inner > .k-link {
    padding: 8px 12px;
    width: auto;
    display: block;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1 1;
    overflow: hidden;
    text-align: center;
}
h4.tds_pendingreq_titlefs {
    font-size: 1.2rem;
    font-weight: 500;
}
/*========== start media code ================*/

@media (max-width: 767px) {
    h4.tds_pendingreq_titlefs {
        right: 0rem;
    }
}

@media (min-width: 768px) and (max-width: 820px) {
    h4.tds_pendingreq_titlefs {
        right: 6rem;
    }
}

@media (min-width: 821px) and (max-width: 913px) {
    h4.tds_pendingreq_titlefs {
        right: 6rem;
    }
}
@media (min-width: 914px) and (max-width: 1025px) {
    h4.tds_pendingreq_titlefs {
        right: 6rem;
    }
}

.pending-request-column .table-responsive {
    display: block !important;
}

/* .k-grid-content table,
.k-grid-header-wrap table {
    table-layout: auto;
} */
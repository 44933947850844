body {
  font-weight: 500!important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  background-color: #fafafa !important;
}

.bgtextboxclr {
  background-color: #fff;
  border: 1px solid #ebebeb;
  padding: 3px 0px;
}
.backtoclr {
  color: rgb(252, 125, 114);
}
ul.list-group.listborrem {
  border: 1px solid #dcdcdc;
  border-radius: 0px;
}
ul.listborrem li {
  border: 0px solid #333;
}
ul.ulpadd {
  padding: 0px 15px;
}
span.iconclr i {
  color: rgb(252, 125, 114);
  font-size: 8px;
}

ul.listborrem li {
  border: 0px solid #333;
  list-style: none;
}
ul li {
  list-style: none;
}
.card.paddcard .card-header {
  padding: 0.25rem 1.25rem;
}
.card.paddcard .card-body {
  padding: 0.25rem 0.1rem !important;
}

table.table.tallisside tr th {
  border-top: 0px solid;
  font-size: 13.5px;
  font-weight: 600;
  padding: 1px 3px;
}
span.clrtd {
  color: rgb(252, 125, 114);
}

button.btn.btn-secondary.bgclrcan {
  border-radius: 0px;
  font-size: 18px;
  padding: 5px 34px;
  background-color: rgb(220 202 201);
  border-color: rgb(220 202 201);
  outline-color: rgb(220 202 201);
  color: #333;
}
h4.modal-title.modelbpadd {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
}
button.btn.btn-secondary.bgclrcreat {
  border-radius: 0px;
  font-size: 18px;
  padding: 5px 34px;
  background-color: rgb(252, 125, 114);
  border-color: rgb(252, 125, 114);
  outline-color: rgb(252, 125, 114);
}
.modal-header.mrkdmodelh {
  padding: 2px 30px;
  background-color: #ccc;
  color: #333;
}

.fade.raisenewreqpopup.modal.show .modal-dialog {
  max-width: 986px !important;
}
.modal-footer.ftpaddmk .fade.alert.alert-danger.alert-dismissible.show {
  padding: 3px 31px 3px 5px;
}
.ftpaddmk.modal-footer button.close {
  padding: 2px 3px;
}
.mpoptextbor.modal-header {
  padding: 0px 15px;
  background-color: #efecec;
}
.mpoptextbor.modal-header .modal-title.h4 {
  padding: 2px 0px;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
}
.mpoptextbor.modal-header .close {
  position: relative;
  top: 4px;
}
button.mboxcbtn.btn.btn-secondary {
  background-color: #f2f2f2 !important;
  color: #636363;
  border: 1px solid #ddd;
  padding: 7px 20px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
}
button.mboxsubmbtn.btn.btn-primary {
  background-color: #fc7d72;
  color: #fff;
  border: 1px solid #fc7d72;
  padding: 7px 20px !important;
  border-radius: 40px;
  font-size: 12px !important;
  font-weight: 500;
}
.modal-footer.ftpaddmk {
  padding: 2px 6px;
}
.ftpaddmk.modal-footer .fade.alert.alert-danger.show {
  padding: 3px 26px 3px 5px;
  font-size: 12px;
}
.ftpaddmk.modal-footer button.close {
  padding: 1px 4px;
}
button.mboxsubmbtn.btn.btn-info {
  padding: 5px 15px;
  border-radius: 3px;
  font-size: 12px;
}

.fade.mkdmdbox.modal.show div#mdp-calendar-div {
  top: -9rem;
  margin-left: 5rem;
}

p.newraisereturntext {
  font-size: 12px;
}

/* recaptcha css start*/
button.btn.btn-default.recaptchaclsbtn.btn.btn-primary {
  background-color: #f2f2f2;
  color: #636363;
  border: 1px solid #ddd;
  padding: 5px 20px 5px 20px!important;
  border-radius: 40px;
  font-size: 12px!important;
}
button.btn.btn-default.recaptchaprocbtn.btn.btn-primary {
  background-color: #31719b;
  border-color: #31719b;
  margin: 4px 4px 4px 1px;
  color: #fff;
  border: 1px solid #31719b;
  padding: 5px 20px 5px 20px;
  border-radius: 40px;
  font-size: 12px;
}
/* recaptcha css start*/

/*======  fieldsetbox ============       */

fieldset.newrretchaladefieldset {
  border: 1px solid #fc7d72;
}
legend.newrretchaladelegent {
  width: 9rem;
}
legend.newrretchaladelegent h4 {
  font-size: 1rem;
}
p.newrretprovidetextsize {
  font-size: 12px;
}
p.newrrettext {
  font-size: 12px;
  padding-bottom: 1rem;
}
.form-check-inline.newrretcheckinlinenillsect {
  padding-top: 1.1rem;
}
input.form-check-input.newrretchecklebtopalignnill {
  vertical-align: sub;
}
p.newrretslecnilltextsect {
  font-size: 12px;
}
fieldset.newrretdeduteetransfieldset {
  border: 1px solid #31719b;
}
legend.newrretdeduteetrans {
  width: 13rem;
}
legend.newrretdeduteetrans h4 {
  font-size: 1rem;
}

fieldset.newrrettracescaptchafield {
  border: 1px solid #fc7d72;
  margin-top: 1.1rem;
}
legend.tracescaptchaleg {
  width: 0rem;
}

.newrretorscet {
  position: relative;
  top: 3.5rem;
  text-align: center;
}
/* ========= fieldsetbox        **/

/*============ start media code===============*/

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .fade.mkdmdbox.modal.show div#mdp-calendar-div {
    top: -9rem;
    margin-left: 0rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
  .fade.mkdmdbox.modal.show div#mdp-calendar-div {
    top: -9rem;
    margin-left: -1rem;
  }
}
@media only screen and (min-width: 376px) and (max-width: 767px) {
  .fade.mkdmdbox.modal.show div#mdp-calendar-div {
    top: -9rem;
    margin-left: 0rem;
  }
}

.bulk_importcss, .creaeclient_ImportSt{
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Ubuntu","Liberation Sans","Roboto","Oxygen","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif!important;
}


.bulk_importcss .font12 {
    font-size: 12px;
}
.bulk_importcss .font11Center {
    font-size: 11px;
    text-align: center;
    color: #dedada;
}
.bulk_importcss .font12Left {
    font-size: 12px;
    text-align: center;
}
.bulk_importcss .font12CursorPoint {
    font-size: 12px;
    color: #31719b;
    cursor: pointer;
}
.bulk_importcss .dropzonestyle {
    height: 150px;
    width: 100%;
}
.bulk_importcss .paddingZeroDrawer {
    padding: 0px !important;
}
.bulk_importcss button.btn.btn-default.gstrfFbtnimport {
    padding: 3px 10px 5px 10px;
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    border-radius: 40px;
}
.fade.bookVerify.bulk_importcss.modal.show .modal-dialog {
    max-width: 45rem !important;
    margin: 3rem auto auto;
}
.bulk_importcss .bookVerify.modal-header {
    background-color: #eee;
    padding: 0px 15px;
}
.bulk_importcss .bookVerify.modal-header .modal-title.h4 {
    font-size: 1rem;
    font-weight: 500;
    padding: 6px 0px 7px 0px;
    margin: 0;
}
.bulk_importcss .bookVerify.modal-header button.close {
    top: 6px;
    position: relative;
}

.creaeclient_ImportSt .modal-header.creaeclient_ImportSt_header {
    background-color: #eee;
    padding: 0px 15px;
}

.creaeclient_ImportSt h4.creaeclient_ImportSt_title {
    font-size: 1rem;
    font-weight: 500;
    padding: 4px 0px 4px 0px;
    margin-bottom: 0;
}

.creaeclient_ImportSt button.close {
    position: relative;
    top: 2px;
}
.creaeclient_ImportSt .modal-dialog{
    max-width: 600px !important;
}

.creaeclient_ImportSt table.table.table-striped.tds_blukimportdedcut_tb thead tr th{
    border-top: 0;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
}
.creaeclient_ImportSt table.table.table-striped.tds_blukimportdedcut_tb tr td{
    font-size: 11px;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    vertical-align: baseline;
}

.creaeclient_ImportSt button.btn.btn-default.blukimp_deduct_btnbgclr {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 40px;
    color: #fff;
    padding: 4px 20px 6px 20px;
    font-size: 12px;
    font-weight: 500;
    border: 0px;
}

 .tdsreimport_modalbox.show .modal-dialog {
    margin: 6rem auto auto 0rem;
    max-width: 500px !important;
}

.tdsreimport_modalbox.show .modal-dialog .modal-header.tdsreimport_modalboxheader {
    background-color: #eee;
    padding: 0px 15px;
}

.tdsreimport_modalbox.show .modal-dialog .tdsreimport_modalboxheader.modal-header div.modal-title.h4 {
    font-size: 1rem;
    padding: 4px 0px 4px 0px;
    font-weight: 500;
    margin: 0;
}

.tdsreimport_modalbox.show .modal-dialog .modal-header.tdsreimport_modalboxheader button.close {
    position: relative;
    top: 3px;
}

.tdsreimport_modalbox.show .modal-dialog .selecttype-reimportdropzone {
    flex: 1 1;
    display: flex;
    text-align: center;
    margin: 0rem 0rem;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    outline: none;
    transition: border 0.24s ease-in-out;
    height: 150px;
    font-size: 12px;
    justify-content: center;
    cursor: pointer;
}

.tdsreimport_modalbox.show .modal-dialog p.reimport_selecttypefileselectedtext {
    font-size: 12px;
    padding-top: 3px;
    text-align: left;
}

.tdsreimport_modalbox.show .modal-dialog button.btn.btn-default.reimport_conpgain-importbtnbgclr {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 28px;
    color: #fff;
    padding: 4px 20px 5px;
    font-size: 12px;
    font-weight: 500;
}

.tdsreimport_modalbox.show .modal-dialog .selecttype-reimportdropzone {
    flex: 1 1;
    display: flex;
    text-align: center;
    margin: 0rem 0rem;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-radius: 2px;
    border: 2px dashed #eee;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    height: 150px;
    font-size: 12px;
    justify-content: center;
    cursor: pointer;
}

.tdsreimport_modalbox.show .modal-dialog button.btn.btn-default.reimport_conpgain-importbtnbgclr {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 28px;
    color: #fff;
    padding: 4px 20px 5px;
    font-size: 12px;
    font-weight: 500;
}
.bulk_importcss .k-window-title {
    cursor: move;
}
.creaeclient_ImportSt .k-window-title {
    cursor: move;
}

/*deductee Transactions*/
h6 {
    font-size: 14px;
}
.popup-horizontal .unconsumedAmtValue {
   
    top: -20px;
    position: relative;
}
.deductee_transactions table thead tr td {
    position: sticky;
    top: 42px;
    background-color: #fafafa;
    z-index:0;
}
table thead tr th
{
position: sticky;
top: 0px;
background-color: #ffffff;
}
.summary {
    background-color: #31719b;
    color: #fff;
    left: -6.8rem!important;
    z-index: 20;
    top: 50%;
    transform: rotate(
270deg
);
    border-radius: 0 0 5px 5px;
    padding: -1px 0px;
}
.position-absolute.summary a {
    font-size: 13px;
    padding: 0px 3px 5px 3px;
    margin: 0px;
}


span.naturoficon {
    display: none;
    transform: rotate(88deg);
    transform-box: fill-box;
    margin-top: 1rem;
    top: 0rem;
    left: 9rem;
    position: absolute;
}




.kdk_data_table.deductee_transactions {
    height: 60vh;
}
.kdk_data_table tr th {
    font-size: 14px !important;
    font-weight: 500;
}
header ul li span, .mailer span, .sms span {

    padding: 4px 10px 8px 8px;

}

.w-98
{
width: 98%!important;
}


/*2nd&3rdscreen- 26-7-2020*/
.modal.left .modal-dialog {
    position: fixed;
    margin: auto;
    width: 800px;
    height: auto;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal.left .modal-body {
    padding: 15px 15px 80px;
}

.modal.left.fade .modal-dialog {
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog {
    left: 50px;
    top: 8%;
}
.summary a,.summary a:hover
{
	color:#ffffff;
}
.mapped_challan_option
{
	background-color: transparent!important;
	font-size: 12px!important;

}

span.b2btbtrthtextclr {
    text-align: right;
    color: rgb(0, 0, 255);
    font-size: 9px;
}

/*css for table select box width*/
.selctwid {
    width: 75px;
}
/*end css for table select box width*/

.green_color {color: #08C802;}
.blue_color {color: #0000ff;}
.black_color {color: #000;}

.deductee_link {color: #31719B; cursor: pointer;}
.deductee_link:hover{text-decoration: underline;}
@media (min-width: 576px){
.modal-dialog {
    max-width: 900px;
}
}



/* .deductee-transaction-multi-select .chip{display: none;} */
.selctwid .clear-filter {
    left: 87px !important;
    right: unset !important;
    position: relative;
    top: -25px;
    z-index: 2;
}
.deductee-transaction-multi-select .chip{  
padding: 0px 0px;
background:transparent;
margin-right: 1px;
margin-bottom: 0px;
border-radius: none;
display: inline-flex;
align-items: center;
font-size: 13px;
color: rgb(0, 0, 0);
}
.deductee-transaction-multi-select .icon_cancel{display: none;}
.deductee-transaction-multi-select #multiselectContainerReact ._2iA8p44d0WZ-WqRBGcAuEV{height: 31px; overflow: -moz-hidden-unscrollable; white-space: nowrap; text-overflow: ellipsis; width: 80px; position: absolute;}
.deductee-transaction-multi-select .chip:not(:first-child):before {
    content: ",";
  }
  .deductee-transaction-multi-select .chip:not(:first-child) {
    margin-left: -.1em;  
  }
  .deductee-transaction-multi-select .optionListContainer{
    margin-top: 0px;
    padding: 0px 0px;
    width: 90px;
}
/*listof dt table header*/

.clear-filter.listchaltbpdsboxicon03 {
    margin-left: -7px !important;
}

span.create_deductor.listofdticon img {
    width: 16px !important;
    cursor: pointer;
}
form.form-inline {
    left: 0px;
    position: relative;
}
.table-responsive.listofdtth {
    overflow-x: initial;
}
.kdk_data_table tr td .selctwid .form-inline input.form-control.boxwisecrh {
    width: 129px;
}
.kdk_data_table tr td .selctwid .form-inline input.form-control.boxwidth01 {
    width: 90px;
}
table.table.table-striped.table-responsive.listofdtth {
    padding: 4px 3px;
}

.kdk_data_table .listofdtth tr th {
    font-size: 13.5px !important;
    font-weight: 500;
    text-align: center;
}
.form-inline .form-group {
    display: block;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
    padding: 4px 4px;
}


.form-inline .form-group {
    display: block;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
    padding: 2px 2px;
}

.selctwid .filter-box .form-group .MyDatePicker .mdp-container {
    z-index: 1999;
    position: absolute;
    display: block;
    top: -8rem;
    left: -20rem;
}
.mdp-container {
    float: left;
    position: absolute;
    left: 0;
    top: 40px;
    width: 300px;
    min-height: 280px;
    background: #fff;
    box-shadow: 1px 3px 13px rgba(0,0,0,0.2);
    border-radius: 6px;
    overflow: hidden;
    padding: 0px 0px;
    z-index: 200;
}
.cc-head {
    height: 23px;
    width: 100%;
    margin-top: 0px;
}
.selctwid form.form-inline {
    left: 0px;
    top: 0px;
    padding: 0px 0px;
    position: relative;
    height: 32px;
}
.selctwid.mapchwidth {
    width: 100px;
    max-width: 100px !important;
}
.selctwid.mappchallwidth form.form-inline {
    max-width: 100px !important;
}
.deductee-transaction-multi-select #multiselectContainerReact ._2iA8p44d0WZ-WqRBGcAuEV {
    position: relative;
}
.float-right.attextclr.btnAhref a.dark_color_bg.rounded_btn {
    padding: 7px 15px 8px 15px !important;
}
a.dark_color_bg.rounded_btn.nextpaddlistch {
    background-color: #31719B;
    color: #ffffff;
    border: none;
    font-size: 12px;
    border-radius: 40px;
    padding: 7px 22px;
    font-weight: 500;
}
.selctwid.boxwidth .form-inline .form-control {
    width: 136px;
}

._3vt7_Mh4hRCFbp__dFqBCI ul {
   
    max-height: 160px !important;
    overflow-y: scroll ;
}
.selctwid.boxwpan .form-inline .form-control {
    z-index: 1;
    position: relative;
    padding: 4px 5px;
    width: 92px !important;
}
.header-sticky {
    z-Index: 99992;
}
.kdk_data_table tr td .selctwid .form-inline input.form-control {
    padding: 17px 5px;
}

.float-right.attextclr a:hover {
    color: #fff;
}

a.addsalalry_detaile_btn {
    color: #ffffff;
    border: none;
    padding: 7px 15px;
    border-radius: 40px;
    font-size: 12px;
    background-color: #31719B;
}
a.dark_color_bg.rounded_btn.nextbtnbgclr {
    background-color: #31719B;
    color: #ffffff;
    border: none;
    padding: 4px 6px 7px 6px !important;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 500;
}

.erroredimpobtn {
    background-color: #fc7d72 !important;
    color: #ffffff !important;
    border: none !important;
    padding: 7px 15px 7px 15px !important;
    border-radius: 40px !important;
    font-size: 12px !important;
    top: 0rem;
    position: relative;
    left: 1px;
    outline-color: transparent;
}

.k-input {
    font-size: 11px !important;
}
.k-textbox {
    font-size: 12px;
}
.float-right.attextclr.btnAhref.topmar {
    position: relative;
    top: 2px;
}
.deducteeTransListCal #mdp-calendar-div{top: 2rem; left: -2rem;}

.DTpaddright h4.listofDTtext {
    padding-right: 2rem;
}


.btn-group.listofchallanboxgp .multi-select {
    max-width: 100%;
    width: 35%;
}
.btn-group.listofchallanboxgp .multi-select .select-panel{
    height: auto!important;
}
.btn-group.listofchallanboxgp {
    width: 45%;
    float: right;
}
button.btn.btn-default.nonvailofpan_btnbgclr {
    border-radius: 0;
    padding: 0.2rem 0rem;
    color: #212529;
    background-color: #ffffff;
    outline-color: #f2f2f2;
    font-size: 14px;
    width: 35%;
    border: 1px solid rgb(206, 212, 218);
}
button.btn.btn-default.nonvailofpan_btnbgclr:hover {

    background-color: #fc7d72;
    color: #fff;
}

button.btn.btn-default.nonvailofpan_btnbgclr.active {
    background-color: #fc7d72;
    color: #fff;
}
button.btn.btn-default.nonvailofpan_btnbgclr.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
.btn-group.listofchallanboxgp .multi-select .dropdown div span.dropdown-heading-value {
    text-align: center;
    color: #fc7d72!important;
}
.btn-group.listofchallanboxgp .multi-select .dropdown div {
    border-radius: 0!important;
    font-size: 14px;
    font-weight: 500;
}
.btn-group.listofchallanboxgp .multi-select .dropdown div ul.select-list li label.select-item {
    padding: 0 5px 2px 7px!important;
    margin: 0;
}
.btn-group.listofchallanboxgp .multi-select .dropdown div ul.select-list li span {
    width: 100% !important;
}

::placeholder{
    font-size: 11px !important;
    font-weight: 500 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
span.k-widget.k-combobox.mapped_challan_combobox span.k-dropdown-wrap span.k-searchbar input {
    font-size: 11px !important;
    font-weight: 500 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}


/*kdk media code for back to decdutor*/
@media only screen and (max-width: 767px) {

    .form-inline .form-group {
        display: grid;
  
    }
    h4.h4fssm {
        font-size: 13px;
        padding-top: 9px;
    }
    .kdk_data_table .listofdtth tr th {
        font-size: 12.5px !important;
        font-weight: 500;
    }
    .topmar {
        margin-top: 1rem;
    }
  
}
@media only screen and (min-width: 768px) and (max-width: 941px) {

    .form-inline .form-group {
        display: grid;
  
    }
    h4.h4fssm {
        font-size: 13px;
        text-align: center;
        padding-top: 9px;
    }
    .kdk_data_table .listofdtth tr th {
        font-size: 12.5px !important;
        font-weight: 500;
    }
    h4.listofDTtext {
        font-size: 1rem !important;
    }
    a.addsalalry_detaile_btn {
        font-size: 12px;
    }
    a.dark_color_bg.rounded_btn.nextbtnbgclr {
        font-size: 12px;
        font-weight: 500;
    }
    .erroredimpobtn {
        font-size: 12px !important;
    }
}
@media only screen and (min-width: 942px) and (max-width: 1024px) {

    .form-inline .form-group {
        display: grid;
  
    }
    h4.h4fssm {
        font-size: 16px;
        text-align: center;
        padding-top: 9px;
    }
    .kdk_data_table .listofdtth tr th {
        font-size: 12.5px !important;
        font-weight: 500;
    }
    h4.listofDTtext {
        font-size: 1rem !important;
    }
    a.addsalalry_detaile_btn {
        font-size: 12px;
    }
    a.dark_color_bg.rounded_btn.nextbtnbgclr {
        font-size: 12px;
        font-weight: 500;
    }
    .erroredimpobtn {
        font-size: 12px !important;
        left: 33px;
    }
}

/*.filterPanHidden input {visibility: hidden;}*/
.filterPanHidden ._2iA8p44d0WZ-WqRBGcAuEV span {display: none !important;}
.filterPanHidden .dropdown-heading-loading-container{display: none !important;}
.filterPanHidden .dropdown-heading-value{display: none !important;}
.filterPanHidden .dropdown-heading-dropdown-arrow{display: none !important;}
.filterPanHidden .dropdown > :first-child {display: none !important;}
.filterPanHidden {width: 130px !important; background-color: #fff; position: absolute; top: 32px; left: 0px;}
.filterPanHidden .multi-select{min-width: 130px !important;}
.filterPanHidden .select-list .select-item{background-color: #fff!important;}

.filterPanHidden .select-panel{height: auto !important; max-height: 180px !important; min-height: 78px !important;}

.filterMapHidden ._2iA8p44d0WZ-WqRBGcAuEV span {display: none !important;}
.filterMapHidden .dropdown-heading-loading-container{display: none !important;}
.filterMapHidden .dropdown-heading-value{display: none !important;}
.filterMapHidden .dropdown-heading-dropdown-arrow{display: none !important;}
.filterMapHidden .dropdown > :first-child {display: none !important;}
.filterMapHidden {width: 130px !important; background-color: #fff; position: absolute; top: 32px; left: 0px;}
.filterMapHidden .multi-select{min-width: 130px !important;}
.filterMapHidden .select-list .select-item{background-color: #fff!important;}

.filterMapHidden .select-panel{height: auto !important; max-height: 180px !important; min-height: 78px !important;}

.dtNofpayMulti .item-renderer{width: 100%;}

.dtNofpayMulti .select-panel{height: auto !important; max-height: 180px !important; min-height: 78px !important;}


.btnAhref .dark_color_bg:hover {
    color: #ffffff !important;
}
.dtparentcls .inactive{opacity: 1.2; color: #c7bfbf;}

.sectiontrfil {max-width: 90px;}
.sectiontrfil .multi-select {min-width: 90px !important;}
.sectiontrfil .item-renderer{width: 100%;}

/*.filterPanHidden{position: absolute; top: 0px;}
.filterPanHidden .multiselectContainerReact{position: relative;}*/
/* .select-panel{
    height: 100px!important;
} */
body {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  
  .modal.Deductee_trans_importmodal.show {
    
    & .modal-dialog {
      margin: 5rem auto auto auto;
      max-width: 500px;
  
      & .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 61%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1pxsolidrgba (0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: 0;
        left: 10rem;
      }
  
      & .modal-header.Deductee_trans_importmodal_header {
        padding: 0px 15px;
        background-color: #eee;
  
        & h4.modal-title {
          width: 100%;
          font-size: 1rem;
          font-weight: 500;
          padding: 4px 0px;
          text-align: center;
        }
      }
  
      & button.btn.btn-default.Deductee_trans_importmodal_submitbnt {
        background-color: #fc7d72;
        border-radius: 40px;
        color: #fff;
        border: 0px;
        padding: 4px 15px 7px;
        font-size: 12px;
        font-weight: 500;
      }
  
      & button.btn.btn-default.Deductee_trans_importmodal_calbtn {
        background-color: #ddd;
        border-color: #ddd;
        border-radius: 40px;
        border: 0px;
        padding: 4px 15px 7px;
        font-size: 12px;
        font-weight: 500;
      }
  
      & button.btn.btn-default.balnktemplate_btnbgclr {
        background-color: #31719b;
        border-color: #31719b;
        border-radius: 40px;
        color: #fff;
        border: 1px solid #31719b;
        padding: 3px 10px 5px;
        font-size: 12px;
        font-weight: 500;
        width: 160px;
      }
      & span.Deductee_trans_importmodal_Ortextfs {
        font-size: 13px;
        font-weight: 500;
        padding: 0px 25px;
      }
  
      & button.btn.btn-default.balnktemplate_filenamebtn {
        max-width: 90%;
        min-width: 90%;
        width: 90%;
        border-radius: 40px;
        color: #c3b4b4;
        padding: 7px 10px 9px;
        font-size: 12px;
        font-weight: 500;
      }
  
      & button.btn.btn-default.balnktemplate_dragdropfilebtnbgclr {
        flex: 1 1;
        flex-direction: column;
        align-items: center;
        border-radius: 2px;
        border: 2px dashed #eee;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border 0.24s ease-in-out;
        justify-content: center;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        width: 90%;
        border-radius: 40px;
      }
  
      & button.btn.btn-default.balnktemplate_fileremovebtn {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;
        font-size: 12px;
        font-weight: 500;
        padding: 0px 6px 2px 6px;
      }
  
      & p.importexcel_textfs_style {
        font-size: 10px;
        font-weight: 500;
        color: #767e83;
  
        font-style: italic;
      }
  
      & p.importexcel_notetextfs_clr {
        font-size: 11px;
        font-weight: 500;
        color: #767e83;
        font-style: italic;
      }
  
      button.btn.btn-default.balnktemplate_textclr {
        color: #31719b;
        padding: 3px 10px 5px;
        font-size: 12px;
        font-weight: 500;
        width: 160px;
        text-decoration: underline;
      }
    }
  }
  
  /*------  media code    -----------*/
  
  @media (max-width: 512px) {
    button.btn.btn-default.balnktemplate_btnbgclr {
      width: 137px !important;
    }
  
    span.Deductee_trans_importmodal_Ortextfs {
      padding: 0px 5px !important;
    }
  
    button.btn.btn-default.balnktemplate_dragdropfilebtnbgclr {
      width: 146px !important;
    }
  
    button.btn.btn-default.balnktemplate_filenamebtn {
      width: 65% !important;
      max-width: 65% !important;
      min-width: 65% !important;
    }
  }
  
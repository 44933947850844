* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #ffffff !important;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

h4.listof_challan_titalfs {
  font-size: 1.2rem;
  font-weight: 500;
}

button.btn.btn-default.Challans_errorrecordsbtn {
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  background-color: #31719b;
  color: #fff;
  border-radius: 40px;
  padding: 2px 10px 4px 10px;
  float: right;
}

.showalluncol_box {
  display: flex;
}

.listofchallan_rangsliderbox {
  top: 15px;
  position: relative;
}
.errorbtn_toppadd {
  top: 11px;
  position: relative;
}

.showallunconbox {
  padding-top: 18px;
}

.showchallan_box {
  & span.showchallan_textfs {
    font-size: 12px;
    font-weight: 500;
  }

  & span.previewicon {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: #fc7d72;
    vertical-align: middle;
  }

  & span.nextviewicon {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: #fc7d72;
    vertical-align: middle;
  }

  & span.quaterbox {
    padding: 2px 5px;
    background-color: aliceblue;
    font-size: 12px;
    border: 1px solid #ddd;
    font-weight: 500;
  }
}

/*===============left side import data popup ================*/
.challans_leftsidepopupbox {
  position: fixed;
  padding: 0 0.4rem 0.1rem 0.2rem !important;
  background-color: #31719b;
  color: #fff;
  z-index: 20;
  top: 49%;
  transform: rotate(270deg);
  border-radius: 0 0 5px 5px;
  left: -4.8rem;
  overflow: hidden;

  .challans_AnnouncementsIcon {
    position: absolute;
    top: 0;
    right: -15px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    cursor: pointer;
  }

  .challans_Announcements {
    background-color: #fc7d72;
    width: 55px;
    position: relative;
    padding-left: 26px;
    padding-top: 2px;
  }

  .Dropdown.challans_Announcements-wrapper .challans_Announcements-bubble {
    display: none;
    width: 24px;
    height: 26px;
  }

  .Dropdown.challans_Announcements-wrapper.challans_has-unseen-items
    .challans_Announcements-bubble {
    box-sizing: border-box;
    overflow: hidden;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
    border-radius: unset;
    display: block;
    position: absolute;
    padding: 0;
    top: -10px;
    background-color: transparent;
    left: 40px;
  }

  .Dropdown.challans_Announcements-wrapper.challans_has-unseen-items
    .challans_Announcements-bubble:before {
    content: "";
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    width: 10px;
    height: 10px;
    -webkit-animation: fadeInOut 1s ease 0.2s infinite normal none running;
    animation: fadeInOut 1s ease 0.2s infinite normal none running;
    left: -4px;
    bottom: -4px;
  }

  .Dropdown.challans_Announcements-wrapper.challans_has-unseen-items
    .challans_Announcements-bubble:after {
    content: "";
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    width: 16px;
    height: 16px;
    -webkit-animation: fadeInOut 7s ease 0.7s infinite normal none running;
    animation: fadeInOut 7s ease 0.7s infinite normal none running;
    left: -6px;
    bottom: -6px;
  }

  div.challans_textfs {
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-weight: 600;
    padding-right: 40px;
    padding-left: 25px;
  }

  div.challans_textfs:hover {
    color: rgb(255, 255, 255);
  }

  .svg-inline--fa.fa-w-18 {
    width: 1.125em;
  }

  .svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }

  svg:not(:root).svg-inline--fa {
    overflow: visible;
  }

  svg:not(:root).svg-inline--fa,
  svg:not(:host).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
  }

  @-webkit-keyframes fadeInOut {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

/*=============== end left side import data popup ================*/

table.table.table-striped.return_flow_challans_tb {
  & thead tr th {
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 0px;
    vertical-align: baseline;
  }

  & tr td {
    font-size: 11px;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    vertical-align: baseline;
  }

  & tr.dash_totalverchall_trth2romamt th {
    text-align: right;
    color: #31719b;
    font-size: 9px;
    padding-top: 0px;
    padding-bottom: 5px;
  }

  & .challan_stclr {
    color: red;
  }

  & .return_flow_challans_trrowbgclr_yell {
    background-color: rgb(255 252 219 / 46%);
  }

  & span.dash_createdeductee_infonicon {
    color: #fc7e73;
    cursor: pointer;
  }

  & .cursorpointer {
    color: #31719b;
    cursor: pointer;
  }
}

.return_flow_adjust_challans_trrowbgclr_yell {
  background-color: rgb(255 252 219 / 46%) !important;
}

.rem_bal_main_col {
  font-weight: bold !important;
  font-size: 10px !important;
}

.modal.challansno_modalbox.show .modal-dialog {
  margin: 3rem auto 0rem auto;
  max-width: 850px;

  & .modal-header.challansno_modalbox_header {
    background-color: #eee;
    padding: 0px 15px;

    & .modal-title {
      padding: 0px 0px 6px 0px;

      span.challanno_textfs {
        font-size: 14px;
        font-weight: 500;
        color: #848484;
      }
    }

    & button.close {
      top: 9px;
      position: relative;
    }

    & span.challanno_vline {
      font-size: 14px;
      font-weight: 500;
      padding: 0px 6px;
      position: relative;
      top: -1px;
      color: #a9a9a9;
    }

    & span.challan_numtextfsclr {
      font-weight: 600;
      color: #333;
    }
  }
}

table.table.table-striped.dash_totalverchall_tb {
  & thead tr th {
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 0px;
    vertical-align: baseline;
  }

  & tr td {
    font-size: 11px;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    vertical-align: baseline;
  }

  & .challan_textclrred {
    color: red;
  }

  & .challan_textfsblue {
    color: #31719b;
  }
}

p.mappedchalal_textfs {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 500;

  & span.mappedchalal_showmorefs {
    color: #31719b;
    text-decoration: underline;
    cursor: pointer;
    padding-left: 5px;
    font-weight: 700;
  }
}

button.btn.btn-default.\35 othermappedchallbtn {
  background-color: #31719b;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  border-radius: 40px;
  border: 0;
  padding: 4px 20px 7px 20px;
}

.challanno_textclr_blurundline {
  color: #31719b;
  text-decoration: underline;
  cursor: pointer;
}

.modal.adjamt_againsttds_modalbox.show .modal-dialog {
  margin: 2rem auto;

  & .modal-header.challansno_modalbox_header {
    background-color: #eee;
    padding: 0px 15px;

    & .modal-title {
      font-size: 1rem;
      font-weight: 500;
      padding: 4px 0px 4px 0px;
    }

    & button.close {
      top: 3px;
      position: relative;
    }
  }

  form.form.form-row.adjamt_againsttds_frmmodal {
    & .form-group {
      margin-bottom: 2px;
    }

    & label {
      font-size: 12px;
      color: #777171;
    }

    & input.form-control.text-right.adjamt_against_input {
      height: calc(1.2em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 12px;
      font-weight: 500;
    }

    & input.form-control.text-right.adjamt_against_input:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
    }
  }
}

.dropdown.tds_returnflow_challan_hover_link {
  display: none;
}

td.tds_returnflow_challan_hoverbox:hover
  .dropdown.tds_returnflow_challan_hover_link {
  z-index: 999;
  position: absolute !important;
  display: block;

  & span.dropdown-content.tds_returnflow_challan_dropcont {
    position: absolute;
    background-color: #f9f9f9;
    max-width: 500px;
    min-width: 170px;
    width: 170px;
    box-shadow: 0 4px 9px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 0px 8px;
    &.positionclass{
      bottom: 1.7rem;
    }  
  }

  & ul.tds_returnflow_challan_hoverlist {
    list-style: none;
    padding: 7px 4px 7px 4px;
    margin-bottom: 0px;

    & li {
      font-size: 11px;
      font-weight: 500;
      line-height: 19px;

      & span.challan_hoverlist_cellwidthleftbox {
          text-align: left;
      }
    }
  }
}

p.challan_found_textfs {
  font-size: 12px;
  font-weight: 500;

  & span.showtheme_textfs {
    color: #31719b;
    cursor: pointer;
  }
}

.modal.showtheme_modalbox.show .modal-dialog {
  margin: 3rem auto;
  max-width: 703px;

  & .modal-header.showtheme_modalbox_header {
    background-color: #eee;
    padding: 0px 15px;

    & h4.modal-title {
      padding: 4px 0px 4px 0px;
      font-size: 1rem;
      font-weight: 500;
    }

    & button.close {
      top: 4px;
      position: relative;
    }
  }

  & table.table.table-striped.showtheme_modalbox_tb {
    & thead tr th {
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      padding-top: 5px;
      padding-bottom: 5px;
      border: 0px;
      vertical-align: baseline;
    }

    & tr td {
      font-size: 11px;
      padding: 0.25rem 0.75rem;
      font-weight: 500;
    }
  }
}

.regflow_challan_textslide.unverify_red {
  border: 1px solid red;
  outline-color: red;
}

.regflow_challan_textslide.verify_green {
  border: 1px solid green;
  outline-color: green;
}

.regflow_challan_textslide.allchallan_black {
  border: 1px solid black;
  outline-color: black;
}

.regflow_challan_textslide_slidingVertical {
  display: block;
  top: -4px;
  position: relative;
}

.regflow_challan_textslide {
  background-color: #fff;
  // margin: 0px auto 0px auto;
  /* padding: 8px 5px 8px 17px; */
  position: relative;
  width: 100%;
  left: 3px;
  border-radius: 40px;
  box-shadow: 0px 2px 14px #ddd;
  padding-top: 4px;
  /* padding-left: 10px; */
}

.regflow_challan_textslide_sentence {
  font-size: 12px;
  font-weight: 500;

  & .slider-items {
    font-size: 12px !important;
    line-height: 20px;
    margin-top: 4px;
    text-align: center;
  }
}

.regflow_challan_textslide_slidingVertical.unverify_red div {
  color: red;
}

.regflow_challan_textslide_slidingVertical.verify_green div {
  color: green;
}

.regflow_challan_textslide_slidingVertical.allchallan_coral div {
  color: #ff7f50;
}

.regflow_challan_textslide_slidingVertical span {
  animation: topToBottom 10.5s linear infinite 0s;
  -ms-animation: topToBottom 10.5s linear infinite 0s;
  -webkit-animation: topToBottom 10.5s linear infinite 0s;
  color: green;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

.regflow_challan_textslide_slidingVertical span:nth-child(2) {
  animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}

.regflow_challan_textslide_slidingVertical span:nth-child(3) {
  animation-delay: 5s;
  -ms-animation-delay: 5s;
  -webkit-animation-delay: 5s;
}

.slick-slide slick-active slick-current {
  height: 30px !important;
}

/*topToBottom Animation*/

@-moz-keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    -moz-transform: translateY(10px);
  }

  10% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }

  25% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }

  30% {
    opacity: 0;
    -moz-transform: translateY(-10px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    -webkit-transform: translateY(10px);
  }

  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  30% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-ms-keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    -ms-transform: translateY(10px);
  }

  10% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }

  25% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }

  30% {
    opacity: 0;
    -ms-transform: translateY(-10px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

//mapped transaction
.mappedtransactionmodal .k-dialog-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  height: auto;
  z-index: 11;
  margin: 0px 0px 0px 4rem;
}

span.k-icon.k-i-more-vertical {
  font-size: 12px;
}

.modal.mapped_trans_modal.show .modal-dialog {
  margin: 2rem auto 0rem auto;
  max-width: 97%;

  & .modal-header.Mismatch_report_header {
    background-color: #eee;
    padding: 0px 15px;

    & h4.modal-title {
      font-size: 1rem;
      font-weight: 500;
      padding: 4px 0px 4px 0px;
    }

    & button.close {
      top: 4px;
      position: relative;
    }

    & button:focus {
      outline: 1px dotted;
      outline: 0px auto -webkit-focus-ring-color;
    }
  }
}

.k-dialog-wrapper.mapped_trans_modal
  .k-window-titlebar.k-dialog-titlebar.k-header {
  padding: 0px 15px;
  border-bottom: 0px;
  background-color: #eee;
  color: #000000 !important;
}

.k-dialog-wrapper.mapped_trans_modal
  .k-window-titlebar.k-dialog-titlebar.k-header
  .k-dialog-title {
  padding: 0px;
  margin: 0px !important;
}

.k-dialog-wrapper.mapped_trans_modal
  .k-window-titlebar.k-dialog-titlebar.k-header
  .k-dialog-title
  kendo-dialog-titlebar
  h4.modal-title {
  margin: 4px;
  padding: 4px 0px 4px 0px;
  font-size: 1rem;
  font-weight: 500;
}

.modal-title {
  margin: 10px;
  padding-top: 7px;
  padding-bottom: 8px;
}

.k-dialog-wrapper.mapped_trans_modal .k-window-content.k-dialog-content {
  padding-top: 0px;
}

.manualpopuptb .k-grid-content {
  overflow-y: inherit;
}

.listofsalarydetailstb .k-grid-content {
  overflow-y: scroll;
}

button.btn.btn-default.default_btnbgclr {
  background-color: #fc7d72;
  color: #fff;
}

//other transactions
.othertransactionmodal .k-dialog-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  height: auto;
  z-index: 11;
  margin: 0px 0px 0px 4rem;
}

span.k-icon.k-i-more-vertical {
  font-size: 12px;
}

.modal.other_trans_modal.show .modal-dialog {
  margin: 2rem auto 0rem auto;
  max-width: 97%;

  & .modal-header.Mismatch_report_header {
    background-color: #eee;
    padding: 0px 15px;

    & h4.modal-title {
      font-size: 1rem;
      font-weight: 500;
      padding: 4px 0px 4px 0px;
    }

    & button.close {
      top: 4px;
      position: relative;
    }

    & button:focus {
      outline: 1px dotted;
      outline: 0px auto -webkit-focus-ring-color;
    }
  }
}

.k-window-title {
  padding: 0.5em -7px;
  margin: -1.5em 0;
  font-size: 16px;
  line-height: 2.5;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
  flex: 1 1;
  display: flex;
  flex-flow: row nowrap;
}

.k-dialog-wrapper.other_trans_modal
  .k-window-titlebar.k-dialog-titlebar.k-header {
  padding: 7px 15px;
  border-bottom: 0px;
  background-color: #eee;
  color: #000000 !important;
}

.k-dialog-wrapper.other_trans_modal .k-window-content.k-dialog-content {
  padding: 10px 0px;
}

.manualpopuptb .k-grid-content {
  overflow-y: inherit;
}

.k-widget.k-grid.table.table-striped.listofsalarydetailstb.deducteewisetrns_heightfixed {
  & .k-grid-header .k-grid-header-wrap table {
    margin-bottom: -19px !important;
  }

  & .sorting_disabled .k-link {
    cursor: default !important;
  }
}

.k-widget.k-grid.table.table-striped.listofsalarydetailstb.deducteewisetrns_heightfixed {
  & thead tr th {
    background-color: #fff;
  }
}

.form-group label,
.form-group select {
  font-size: 11px;
  color: #636363;
}

.form-group select option {
  font-size: 12px;
  color: #333;
  font-weight: 500;
}

span.k-widget.k-datepicker.listifchllan_dt_wbox {
  width: 5rem;
}

span.k-widget.k-datepicker.listifchllan_dt_wbox
  span.k-picker-wrap
  span.k-widget.k-dateinput
  input.k-input {
  padding: 0px 11px;
}

.showallunconbox {
  button.btn.btn-default.challanlist_showalluncon {
    font-size: 12px;
    font-weight: 500;
    border: 1px solid #ddd;
    padding: 2px 10px 4px 10px;
    border-radius: 40px;
  }
  button.btn.btn-default.challanlist_showalluncon.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
}

  & .btntext_blue {
    color: #31719b;
  }
}

.dash_totalverchall_trth2romamt.k-datepicker {
  width: auto !important;
}

// .table.table-striped.challanListV2 thead tr th {
//   padding-top: 4px !important;
//   padding-bottom: 0px !important;
// }

// .table.table-striped.listofsalarydetailstb thead tr th {
//   padding-top: 4px !important;
//   padding-bottom: 0px !important;
// }

.k-animation-container.k-animation-container-relative {
  z-index: 100000 !important;
}


.stsicon {
  /* color: #33729c; */
      font-size: 13px;
      top: 0.1rem;
      position: relative;
  }
  
  .onhoverboxshow {
    position: relative;
    top: 0px;
    left: 0rem;
  }
  .table.table-striped.listofsalarydetailstb thead tr th.k-header.thdisplaycontent span.k-icon.k-i-more-vertical {
    top: 0rem;
}

  .onhoverboxshow .tooltiptext {
    visibility: hidden;
    background-color: #fff;
    color: #fff;
    width: 246px;
    padding: 5px 7px;
    line-height: 1.3rem;
    position: absolute;
    z-index: 1;
    text-align: left;
    left: 6.5rem;
    transition: opacity .3s;
    border: 1px solid #33729c;
}
  .onhoverboxshow .tooltiptext::after {
      content: "";
      position: absolute;
      top: 0%;
      left: -1.3rem;
      transform: rotate(90deg);
      border-width: 10px;
      border-style: solid;
      border-color: #33729c transparent transparent transparent;
  }
  
  .onhoverboxshow:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    position: fixed;
    margin: 0px 1px;
    z-index: 9;
  }

/*----- MuiSlider ---*/

.challanListv2 .MuiSlider-root {
  color: #95d564;
}

.challanListv2 > span > span > span > span {
  background-color: #0e5f59 !important;
}

.challanListv2 span.MuiSlider-thumb[data-index="1"] {
  rotate: 180deg;
}

.challanListv2 span.MuiSlider-thumb[data-index="1"] > span > span > span {
  rotate: 180deg;
}

.challanListv2 .MuiSlider-valueLabel {
  top: -11px !important;
}

.challanListv2 .MuiSlider-valueLabel > span {
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0e5f59 !important;
  min-width: 32px;
  padding: 0 2px;
  border-radius: 10px;
  transform: rotate(0deg);
}

.challanListv2 .MuiSlider-valueLabel > span > span {
  transform: rotate(0deg) !important;
  font-size: 10px;
}

.challanListv2 .MuiSlider-valueLabel > span:before {
  position: absolute;
  width: 8px;
  height: 8px;
  -webkit-transform: translate(-50%, 50%) rotate(45deg);
  -moz-transform: translate(-50%, 50%) rotate(45deg);
  -ms-transform: translate(-50%, 50%) rotate(45deg);
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #0e5f59 !important;
  bottom: 0;
  left: 50%;
}

td.data_padding_right {
  // margin-right: 2.7rem;
  padding-right: calc(1.42857em + 14px) !important;
}

.table td.data_padding-left {
  padding: 0.25rem 0.1rem;
}
/*----- MuiSlider ---*/

.listofsalarydetailstb .k-grid-header {
  margin-right: 13px;
}

/*----- rang slider  ---*/
.rangesliderbox {
  & p.alluncon_textfs {
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    padding-left: 50px;
  }

  .double_range_slider_box {
    position: relative;
    width: 73%;
    height: auto;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-radius: 20px;
  }

  .double_range_slider {
    width: 83%;
    height: 2px;
    position: relative;
    background-color: #dddddd;
    // border-radius: 20px;
  }

  .range_track {
    height: 100%;
    position: absolute;
    border-radius: 20px;
    background-color: #95d564;
  }

  input {
    position: absolute;
    width: 100%;
    height: 5px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    top: 50%;
    transform: translateY(-50%);
  }

  input::-webkit-slider-thumb {
    height: 12px;
    width: 13px;
    border-radius: 50%;
    border: 3px solid #cbffa3;
    background-color: #95d564;
    pointer-events: auto;
    -webkit-appearance: none;
    cursor: pointer;
    margin-bottom: 1px;
  }

  input::-moz-range-thumb {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 3px solid #cbffa3;
    background-color: #95d564;
    pointer-events: auto;
    -moz-appearance: none;
    cursor: pointer;
    margin-top: 30%;
  }

  .minvalue {
    position: relative;
    padding: 0px 5px;
    background: #0e5f59;
    border-radius: 1rem;
    color: white;
    top: -8px;
    bottom: 0;
    width: auto;
    min-width: auto;
    max-width: fit-content;
    transform: translate(0, -100%);
    left: 0;
    font-size: 12px;
    transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: left, transform;
  }

  .maxvalue {
    position: absolute;
    padding: 0px 5px;
    background: #0e5f59;
    border-radius: 1rem;
    color: white;
    width: auto;
    min-width: auto;
    max-width: fit-content;
    top: -9px;
    transform: translate(0, 100%);
    right: 0;
    font-size: 12px;
    transition: right 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: right, transform;
    z-index: 2;
  }
}

.fl {
  float: left;
  top: -14px;
  position: relative;
  font-size: 12px;
  left: 0px;
}

.fr {
  float: right;
  top: -14px;
  position: relative;
  font-size: 12px;
  left: 0px;
}

/*----- rang slider  ---*/

.k-widget.k-window.windowWrapper .k-window-titlebar.k-dialog-titlebar {
  padding: 7px 11px;
  background-color: #d3d3d3;
}

.k-dialog-titlebar {
  border-color: inherit;
  color: #000000;
  background-color: #d3d3d3;
}



.k-widget.k-window.windowWrapper .k-window-content,
.k-prompt-container {
  padding: 5px 5px;
  overflow-x: hidden;
}

.k-widget.k-window.windowWrapper {
  border-color: rgb(0 0 0 / 22%);
  color: #656565;
  background-color: #ffffff;
  box-shadow: 0px 4px 15px 6px rgb(0 0 0 / 18%);
}
.k-widget.k-window.windowWrapper .k-window-title {
  font-size: 14px;
  color: #000000;
}
.k-widget.k-window.windowWrapper button.k-button-icon.k-button {
  color: #000000 !important;
}

.k-widget.k-window.windowWrapper .k-icon-button,
.k-button.k-button-icon {
  width: calc(1.25em + 10px);
  // height: calc(1em + 10px);
  padding: 4px;
}

.excltionicon{
  width: 12px;
  margin-right: 3px;
}

.dt_dnameinputbox.input-error1{
  border: 1px solid #fc6a6a !important;
  box-shadow: none!important;
}
.input-error1{
  .react-select__control.css-yk16xz-control{
      border: 1px solid #fc6a6a !important;
      box-shadow: none!important;
  }
}

.k-widget.k-window.windowWrapper.salarary_detail_popup.overlaynew .k-window-content {
  padding: 5px 15px;
  overflow-x: auto;
  max-height: 540px;
}

.k-widget.k-window.windowWrapper.salarary_detail_popup.viewonlysalarydetail .k-window-content {
  padding: 5px 15px;
  overflow-x: auto;
  max-height: 540px;
}


/* start media code for window */
@media (max-width: 767px) {
  .k-widget.k-window.windowWrapper .k-window-content {
    padding: 5px 5px;
    overflow-x: scroll;
  }
  .k-widget.k-window.windowWrapper {
    width: 94% !important;
  }
}
@media (min-width: 768px) and (max-width: 940px) {
  .k-widget.k-window.windowWrapper .k-window-content {
    padding: 5px 5px;
    overflow-x: scroll;
  }
  .k-widget.k-window.windowWrapper {
    width: 96% !important;
  }
}
@media (min-width: 941px) and (max-width: 1024px) {
  .k-widget.k-window.windowWrapper .k-window-content {
    padding: 5px 5px;
    overflow-x: scroll;
  }
  .k-widget.k-window.windowWrapper {
    width: 97% !important;
    z-index: 9999;
  }
}
@media (min-width: 1170px) and (max-width: 1439px) {
  .listofchallan_rangsliderbox {
    position: relative;
    left: 1rem;
  }
  .colxxlscreen_widthfixed {
    max-width: 57% !important;
    min-width: 57% !important;
    width: 57% !important;
  }
}

@media (min-width: 1650px) and (max-width: 1950px) {
  .listofchallan_rangsliderbox {
    position: relative;
    left: 0rem;
    max-width: 70%;
  }
  .colxxlscreen_widthfixed {
    max-width: 43% !important;
  }
}
@media (min-width: 1951px) and (max-width: 2350px) {
  .listofchallan_rangsliderbox {
    position: relative;
    left: 0rem;
    max-width: 70%;
  }
  .colxxlscreen_widthfixed {
    max-width: 45% !important;
  }
}

@media (min-width: 2351px) and (max-width: 2560px) {
  .listofchallan_rangsliderbox {
    position: relative;
    left: -5rem;
    max-width: 70%;
  }
  .colxxlscreen_widthfixed {
    max-width: 44% !important;
  }
}

@media (min-width: 2561px) and (max-width: 3000px) {
  .listofchallan_rangsliderbox {
    position: relative;
    left: -5rem;
    max-width: 70%;
  }
  .colxxlscreen_widthfixed {
    max-width: 44% !important;
  }
}
@media (min-width: 3001px) {
  .listofchallan_rangsliderbox {
    position: relative;
    left: -5rem;
    max-width: 70%;
  }
  .colxxlscreen_widthfixed {
    max-width: 44% !important;
  }
}

form.challanlist_verfiedbox input.form-check-input {
  vertical-align: sub;
}

@media (max-width: 767px) {
}

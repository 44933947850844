body {
  background-color: #ffffff !important;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

svg:not(:host).svg-inline--fa,
svg:not(:root).svg-inline--fa {
  overflow: visible;
  box-sizing: initial;
}

.returnfilingTdsMappingscss .svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em;
}

.svg-inline--fa.fa-w-10 {
  width: 0.625em;
}

.userexcel_tdsmapping_modal {




    .borderright_sidegrey {
      border-right: 1px solid #ddd;
      padding-right: 12px;

      & .card.userexcel_tdsmapping_leftheadercard {
        padding: 3px 5px 4px 5px;
        background-color: #31719b;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: #fff;
      }


      & .userexcel_tdsmapping_leftbox_innerbtnbox {
        display: ruby-text;

        & button.btn.btn-default.leftbox_abcbtn {
          font-size: 10px;
          font-weight: 500;
          border: 1px solid #ddd;
          padding: 3px 5px 5px 5px;
          width: 150px;
          overflow: hidden;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;



          & span.sixdot_iconclrpadd svg.svg-inline--fa.fa-grip-vertical.fa-w-10 {
            text-align: left;
            float: left;
            position: relative;
            top: 4px;
            color: #9a9a9a;
            cursor: pointer;
          }

        }


        & button.btn.btn-default.leftbox_abcbtn:focus {
          outline: 0;
          box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
        }
        & button.btn.btn-default.leftbox_abcbtn:hover {
          background-color: #f2f2f2;
        }

        & button.btn.btn-default.leftbox_abcbtn.selected {
          background-color: #f2f2f2;
        }

        & span.sixdot_iconclrpadd {
          padding: 0px 3px 0px 1px;
        }

        & button.btn.btn-default.rightbtnbox_sm_basic {
          font-size: 10px;
          font-weight: 500;
          border: 1px solid #ddd;
          padding: 0px 5px 2px 5px;
          background-color: #add580;
          color: #fff;
          position: relative;
          left: -8px;
          top: 2px;
          line-height: 15px;
          width: calc(100% - 150px);
                    overflow: hidden;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
        }

        & button.btn.btn-default.rightbtnbox_sm_domaesticser {
          font-size: 10px;
          font-weight: 500;
          border: 1px solid #ddd;
          padding: 0px 5px 2px 5px;
          background-color: #fd786c;
          color: #fff;
          position: relative;
          left: -8px;
          top: 2px;
          line-height: 15px;
          width: 92px;
          overflow: hidden;
          white-space: nowrap;
          display: block;
        }

        & button.btn.btn-default.employee_dt {
          font-size: 10px;
          font-weight: 500;
          border: 1px solid #ddd;
          padding: 0px 5px 2px 5px;
          background-color: #64b5f7;
          color: #fff;
          position: relative;
          left: -8px;
          top: 2px;
          line-height: 15px;
          width: calc(100% - 150px);
                    overflow: hidden;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
        }
        & button.btn.btn-default.salary17_1 {
          font-size: 10px;
          font-weight: 500;
          border: 1px solid #ddd;
          padding: 0px 5px 2px 5px;
          background-color: #add580;
          color: #fff;
          position: relative;
          left: -8px;
          top: 2px;
          line-height: 15px;
          width: calc(100% - 150px);
                    overflow: hidden;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
        }

        & button.btn.btn-default.perquisites17_2 {
          font-size: 10px;
          font-weight: 500;
          border: 1px solid #ddd;
          padding: 0px 5px 2px 5px;
          background-color: #fd786c;
          color: #fff;
          position: relative;
          left: -8px;
          top: 2px;
          line-height: 15px;
          width: calc(100% - 150px);
                    overflow: hidden;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
        }

        & button.btn.btn-default.prft {
          font-size: 10px;
          font-weight: 500;
          border: 1px solid #ddd;
          padding: 0px 5px 2px 5px;
          background-color: #ffb74e;
          color: #fff;
          position: relative;
          left: -8px;
          top: 2px;
          line-height: 15px;
          width: calc(100% - 150px);
                    overflow: hidden;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
        }

        & button.btn.btn-default.rb {
          font-size: 10px;
          font-weight: 500;
          border: 1px solid #ddd;
          padding: 0px 5px 2px 5px;
          background-color: #fdd735;
          color: #fff;
          position: relative;
          left: -8px;
          top: 2px;
          line-height: 15px;
          width: calc(100% - 150px);
                    overflow: hidden;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
        }
        & button.btn.btn-default.via_ded {
          font-size: 10px;
          font-weight: 500;
          border: 1px solid #ddd;
          padding: 0px 5px 2px 5px;
          background-color: #009588;
          color: #fff;
          position: relative;
          left: -8px;
          top: 2px;
          line-height: 15px;
          width: calc(100% - 150px);
                    overflow: hidden;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
        }
        & button.btn.btn-default.alw {
          font-size: 10px;
          font-weight: 500;
          border: 1px solid #ddd;
          padding: 0px 5px 2px 5px;
          background-color: #64b5f7;
          color: #fff;
          position: relative;
          left: -8px;
          top: 2px;
          line-height: 15px;
          width: calc(100% - 150px);          overflow: hidden;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
        }



      }

    }



    & .card.userexcel_tdsmapping_salalry_headercard {
      padding: 3px 5px 4px 5px;
      background-color: #add580;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
    }


    & .card.userexcel_tdsmapping_middle_leftheadercard {
      padding: 3px 5px 4px 5px;
      background-color: #fd786c;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
    }

    & .card.userexcel_tdsmapping_middle_rightheadercard {
      padding: 3px 5px 4px 5px;
      background-color: #ffb74e;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
    }

    & .card.userexcel_tdsmapping_right_rightheadercard {
      padding: 3px 5px 4px 5px;
      background-color: #64b5f7;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
    }
  

  .userexcel_tdsmapping_leftbox_rightbtnbox {

    & button.btn.btn-default.rightbox_basicbtn {
      font-size: 10px;
      font-weight: 500;
      border: 1px solid #ddd;
      padding: 3px 5px 5px 5px;
      width: 153px;
      overflow: hidden;
      white-space: nowrap;
      // display: block;
      text-overflow: ellipsis;
    }

    & button.btn.btn-default.rightbox_basicbtn:focus {
      outline: 0;
      box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
    }

    & button.btn.btn-default.rightbox_basicbtn.rightbox_basicbtn_greybgclr {
      background-color: #bcbcbc;
      color: #fff;
    }
  }

  & button.btn.btn-default.rightbox_Salaryfullwidth {
    font-size: 10px;
    font-weight: 500;
    border: 1px solid #ddd;
    padding: 3px 5px 5px 5px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
  }

  & button.btn.btn-default.rightbox_Salaryfullwidth_emp_bgclr {
    background-color: #fd786c;
    color: #fff;
  }

  & button.btn.btn-default.rightbox_Salaryfullwidth_regime_bgclr {
    background-color: #add580;
    color: #fff;
  }



  .userexcel_tdsmapping_middle_inner_leftbtnbox {


    span.middle_innerbox_leftside_btn {
      display: inline-flex;
      float: left;
      position: relative;
      top: 1rem;

      & button.btn.btn-default.midboxleft_btnbox {
        font-size: 10px;
        font-weight: 500;
        border: 1px solid #ddd;
        padding: 3px 5px 5px 5px;
        width: 120px;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
      }


      & button.btn.btn-default.midboxleft_btnbox:focus {
        outline: 0;
        box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
      }

      & span.midboxleft_borderbott_right {
        border-bottom: 1px solid #ddd;
        position: relative;
        width: 15px;
        left: 0rem;
        top: -0.6rem;
      }

      span.midboxleft_borderbott_left {
        border: 1px solid #ddd;
        position: relative;
        width: 16px;
        left: 0rem;
        top: 0rem;
        border-right-width: 0px !important;
      }

    }

    span.middle_innerbox_rightside_btn {
      display: inline-block;
      padding-right: 25px;
      position: relative;
      & button.btn.btn-default.middle_innerbox_rightbtn {
        font-size: 10px;
        font-weight: 500;
        border: 1px solid #ddd;
        padding: 3px 5px 5px 5px;
        min-width: 110px;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
        margin-bottom: 6px;
      }

      & button.btn.btn-default.middle_innerbox_rightbtn:focus {
        outline: 0;
        box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
      }


    }

    & button.btn.btn-default.midboxleftbtn_activebgclr {
      background-color: #bcbcbc;
      color: #fff;
    }
  }

  & button.btn.btn-default.profitsalalry_04_btnbgclr_yell {
    font-size: 10px;
    font-weight: 500;
    border: 1px solid #ddd;
    padding: 3px 5px 5px 5px;
    min-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    background-color: #fdd735;
    color: #fff;
  }

  & button.btn.btn-default.profitsalalry_04_btnbgclr_draggrey {
    font-size: 10px;
    font-weight: 500;
    border: 1px solid #ddd;
    padding: 3px 5px 5px 5px;
    min-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    background-color: #009588;
    color: #fff;
  }

  & button.btn.btn-default.profitsalalry_04_btnbgclr_draggrey.btn.focus,
  .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
  }

  & button.btn.btn-default.rightbtnbox_sm_refntbgclr_yell {
    background-color: #fdd735 !important;
  }

  & button.btn.btn-default.rightbtnbox_sm_refntbgclr_blue {
    background-color: #3564fd !important;
  }

  & button.btn.btn-default.rightbtnbox_sm_refntbgclr_red {
    background-color: red !important;
  }


}


h4.modal-title.userexcel_tdsmapping_titlebox {
  width: 100%;
  margin-bottom: 0px;
}

h4.userexcel_tdsmapping_titlefs {
  font-size: 1rem;
  font-weight: 500;
  padding: 20px 0px 7px 0px;
  margin-bottom: 0px;
}


.userexcel_tdsmapping_rightbtnbox {
  position: relative;
  float: right;

  & button.btn.btn-default.reset_btnbgclr {
    background-color: #31719b !important;
    color: #fff;
    border: 0px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px !important;
    padding: 3px 20px 5px 20px;
    top: 0px;
    position: relative;
  }

  & button.btn.btn-default.lockmapping_btnbgclr {
    background-color: #31719b !important;
    color: #fff;
    border: 0px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
    padding: 3px 10px 5px 10px;
    top: 0px;
    position: relative;
  }

  & button.btn.btn-default.unlockmapping_btnbgclr {
    background-color: #31719b !important;
    color: #fff;
    border: 0px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
    padding: 3px 10px 5px 10px;
    top: 0px;
    position: relative;
  }
}

#drag-element-tooltip-main {
  position: absolute;
  left: -999em;
  opacity: 1;
}

#drag-element-tooltip {
  width: 200px;
  height: 45px;
  border: 1px solid #333333b8;
  box-shadow: 1px #333333b8;
  background-color: #fff;
  color: #212121;
  font-size: 12px;
  text-align: center;
  padding: 5px;
  border-radius: 19px 20px 20px 19px;
  position: fixed;
  z-index: 9999999999;
  opacity: 1;
}

span.drogged_element {
  font-weight: bold;
}

div.section_scrollbar {
  max-height: 720px;
  overflow: hidden;
}

div.section_scrollbar ul.right-menu {

     border: 1px solid #ccc;

    & li {
      font-size: 12px;
      font-weight: 500;
      color: #000;
      padding: 5px 5px 5px 5px;
      color: #000;
      // cursor: pointer;
      background-color: #fff;
      border-bottom: 1px solid #ccc;


      & .expand {
        font-size: 16px !important;
        float: right;
        margin-right: 0px;
        padding: 0px 2px 0px 10px;
        position: relative;
        top: -3px;
    }

    ul.child {
      max-height: 350px;
      overflow-x: auto;
      scroll-behavior: smooth;
  }

      ul.child li {
        padding: 5px 0px 5px 10px !important;
    border: 1px solid #ccc !important;
    }
    
  }
  



}
div.section_scrollbar ul.right-menu li:hover {
  background-color: #f2f2f2 !important;
}

// div.section_scrollbar:hover {
//   overflow-y: scroll;
// }

div.section_scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

div.section_scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

.MenuContainer {
  position: absolute;
  z-index: 999;
  background: white;
  width: 100%;
  // cursor: move;  
}

.right-menu {
  background-color: #fff;
  border-radius: 2px;
  padding-left: 0;
  margin: 0;
  position: absolute;
  list-style: none;
  z-index: 1;
  width: 100%;
}

// .menu li {
//   padding: 0.2em 1em;
//   color: #000;
//   cursor: pointer;
//   background-color: #fff;
//   border: 1px solid #ccc;
// }

.right-menu li:hover {
  background-color: #f2f2f2;
}
span.link:hover{
  text-decoration: underline;
}

// .menu li>span {
//    cursor: pointer;
// } 

// .child{
//   position: absolute;
//   top: 0px;
//   left: 99%;
//   display: inline-table;
//   width: 100%;
// }
// .child li {
//   margin: 0px 3px;
//   position: relative;
// }
// .sub_child{
//   position: absolute;
//   top: 0;
//   left: 99%;
//   display: inline-table;
//   width: 100%;
//   float: right;
// }
// .sub_child li{
//   top: 0;
//   position: relative
// }

.expand {
  font-size: 12px;
  float: right;
  margin-right: 5px;
}

p.drop_textfs {
  font-size: 12px;
  font-weight: 500;
}

/* start media code userexcel TDS mapping */

@media (min-width: 1200px) and (max-width: 1500px) {

  .userexcel_tdsmapping_innercolbox_01 {
    min-width: 35% !important;
    width: 35% !important;
    max-width: 35% !important;
  }

  .userexcel_tdsmapping_innercolbox_02 {
    min-width: 65% !important;
    width: 65% !important;
    max-width: 65% !important;
  }

  .userexcel_tdsmapping_innercolbox_03 {
    min-width: 40% !important;
    width: 40% !important;
    max-width: 40% !important;
  }
}

@media (min-width: 1501px) and (max-width: 1600px) {
  .userexcel_tdsmapping_innercolbox_01 {
    min-width: 35% !important;
    max-width: 35% !important;
  }

  .userexcel_tdsmapping_innercolbox_02 {
    min-width: 42% !important;
    max-width: 42% !important;
  }

  .userexcel_tdsmapping_innercolbox_03 {
    min-width: 23% !important;
    max-width: 23% !important;
  }
}

.user_mapping_proceed{
  justify-content: flex-end;
  display: flex;
  align-items: center;
  float: right;
  span{
    color: red;
    font-size: 11px;
  }
  input{
    width: 150px;
    margin:0 10px
  }
                 
}


.userexcel_tdsmapping_leftbtnSM_overflowtext{
  padding-right: 20px !important;
  cursor: pointer;
}

.mapping_unmap_btn{
  position: absolute;
    color: white;
    margin-left: 3px;
    width: 20px;
    text-align: center;
    font-size: 14px;
    right: 0
}


/*===============left side import data popup ================*/
.salary_left_sidepopup {
  position: fixed;
  padding: 0 0.4rem 0.1rem 0.2rem !important;
  background-color: #31719b;
  color: #fff;
  z-index: 20;
  top: 49%;
  transform: rotate(270deg);
  border-radius: 0 0 5px 5px;
  left: -6.6rem;
  overflow: hidden;


  .challans_AnnouncementsIcon {
      position: absolute;
      top: 0;
      right: -15px;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      cursor: pointer;
  }

  .challans_Announcements {
      background-color: #fc7d72;
      width: 55px;
      position: relative;
      padding-left: 26px;
      padding-top: 2px;
  }

  .Dropdown.challans_Announcements-wrapper .challans_Announcements-bubble {
      display: none;
      width: 24px;
      height: 26px;
  }

  .Dropdown.challans_Announcements-wrapper.challans_has-unseen-items .challans_Announcements-bubble {
      box-sizing: border-box;
      overflow: hidden;
      -webkit-transform: rotate(15deg);
      transform: rotate(15deg);
      border-radius: unset;
      display: block;
      position: absolute;
      padding: 0;
      top: -10px;
      background-color: transparent;
      left: 40px;
  }

  .Dropdown.challans_Announcements-wrapper.challans_has-unseen-items .challans_Announcements-bubble:before {
      content: "";
      border: 2px solid #fff;
      border-radius: 50%;
      position: absolute;
      width: 10px;
      height: 10px;
      -webkit-animation: fadeInOut 1s ease .2s infinite normal none running;
      animation: fadeInOut 1s ease .2s infinite normal none running;
      left: -4px;
      bottom: -4px;
  }

  .Dropdown.challans_Announcements-wrapper.challans_has-unseen-items .challans_Announcements-bubble:after {
      content: "";
      border: 2px solid #fff;
      border-radius: 50%;
      position: absolute;
      width: 16px;
      height: 16px;
      -webkit-animation: fadeInOut 7s ease .7s infinite normal none running;
      animation: fadeInOut 7s ease .7s infinite normal none running;
      left: -6px;
      bottom: -6px;
  }

  a.challans_textfs {
      color: rgb(255, 255, 255);
      font-size: 15px;
      font-weight: 500;
      padding-right: 42px;
      padding-left: 22px;
  }

  a.challans_textfs:hover {
      color: rgb(255, 255, 255);
  }

  span.challans_textfs {
      color: rgb(255, 255, 255);
      font-size: 15px;
      font-weight: 600;
      padding-right: 46px;
      padding-left: 22px;
  }

  span.challans_textfs:hover {
      color: rgb(255, 255, 255);
  }

  .svg-inline--fa.fa-w-18 {
      width: 1.125em;
  }


  .svg-inline--fa {
      display: inline-block;
      font-size: inherit;
      height: 1em;
      overflow: visible;
      vertical-align: -0.125em;
  }

  svg:not(:root).svg-inline--fa {
      overflow: visible;
  }

  svg:not(:root).svg-inline--fa,
  svg:not(:host).svg-inline--fa {
      overflow: visible;
      box-sizing: content-box;
  }

  @-webkit-keyframes fadeInOut {
      0% {
          Opacity: 0;
      }

      100% {
          Opacity: 1;
      }
  }

  @keyframes fadeInOut {
      0% {
          Opacity: 0;
      }

      100% {
          Opacity: 1;
      }
  }
}

/*=============== end left side import data popup ================*/
.salary_detail_annexure {
    .head_btn_pos {
        .k-column-title {
            &>span {
                cursor: pointer;
            }

            & div.column_heading>span {
                cursor: pointer;
            }
        }
    }

    .salary_detail_grid {
        .row_action_btn {
            display: flex;
            justify-content: space-evenly;
            color: #fc7d72;
            font-size: 12px;

            & .view_action:hover,
            .edit_action:hover {
                text-decoration: underline;
            }
        }

        .deductee_link {
            color: #31719B;
            cursor: pointer;
        }

        .deductee_link:hover {
            text-decoration: underline;
        }
    }

}

button.btn.btn-default.salary_taxreg_downloaderrrecord {
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    padding: 3px 10px 5px 10px;
    background-color: #31719b;
    color: #fff;
    border-radius: 40px;
    float: right;
    margin-bottom: 5px;
    margin-right: 18px;
}

/*===============left side import data popup ================*/
.salaryannexure_leftsidepopupbox {
    position: fixed;
    padding: 0 0.4rem 0.1rem 0.2rem !important;
    background-color: #31719b;
    color: #fff;
    z-index: 20;
    top: 49%;
    transform: rotate(270deg);
    border-radius: 0 0 5px 5px;
    left: -5.8rem;
    overflow: hidden;

    .challans_AnnouncementsIcon {
        position: absolute;
        top: 0;
        right: -15px;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        cursor: pointer;
    }

    .challans_Announcements {
        background-color: #fc7d72;
        width: 55px;
        position: relative;
        padding-left: 26px;
        padding-top: 2px;
    }

    .Dropdown.challans_Announcements-wrapper .challans_Announcements-bubble {
        display: none;
        width: 24px;
        height: 26px;
    }

    .Dropdown.challans_Announcements-wrapper.challans_has-unseen-items .challans_Announcements-bubble {
        box-sizing: border-box;
        overflow: hidden;
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
        border-radius: unset;
        display: block;
        position: absolute;
        padding: 0;
        top: -10px;
        background-color: transparent;
        left: 40px;
    }

    .Dropdown.challans_Announcements-wrapper.challans_has-unseen-items .challans_Announcements-bubble:before {
        content: "";
        border: 2px solid #fff;
        border-radius: 50%;
        position: absolute;
        width: 10px;
        height: 10px;
        -webkit-animation: fadeInOut 1s ease 0.2s infinite normal none running;
        animation: fadeInOut 1s ease 0.2s infinite normal none running;
        left: -4px;
        bottom: -4px;
    }

    .Dropdown.challans_Announcements-wrapper.challans_has-unseen-items .challans_Announcements-bubble:after {
        content: "";
        border: 2px solid #fff;
        border-radius: 50%;
        position: absolute;
        width: 16px;
        height: 16px;
        -webkit-animation: fadeInOut 7s ease 0.7s infinite normal none running;
        animation: fadeInOut 7s ease 0.7s infinite normal none running;
        left: -6px;
        bottom: -6px;
    }

    div.challans_textfs {
        color: rgb(255, 255, 255);
        font-size: 15px;
        font-weight: 600;
        padding-right: 40px;
        padding-left: 25px;
    }

    div.challans_textfs:hover {
        color: rgb(255, 255, 255);
    }

    .svg-inline--fa.fa-w-18 {
        width: 1.125em;
    }

    .svg-inline--fa {
        display: inline-block;
        font-size: inherit;
        height: 1em;
        overflow: visible;
        vertical-align: -0.125em;
    }

    svg:not(:root).svg-inline--fa {
        overflow: visible;
    }

    svg:not(:root).svg-inline--fa,
    svg:not(:host).svg-inline--fa {
        overflow: visible;
        box-sizing: content-box;
    }

    @-webkit-keyframes fadeInOut {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fadeInOut {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}

.viewonlysalarydetail {
    h4.viewmodetitltextfs_only {
        font-size: 14px;
        padding: 10px 0px 0px 0px;
        position: relative;
        top: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: inline-block;
        width: 250px;
    }

    span.taxregim_textfs_view {
        font-size: 13px;
        font-weight: 500;
    }

    span.input-group.salarytaxreg_daterangegrp_view {
        font-size: 11px;
        color: rgb(147, 151, 155);
        position: relative;
        margin-bottom: 2px;
        display: inline-flex;
        justify-content: flex-end;

        &.daterangecss{
          .k-daterangepicker.k-state-disabled {
            opacity: 1;
            .k-textbox-container.k-text-disabled{
                opacity: 1;
              }
          }
        }
        .k-textbox-container {
            padding-top: 0px;
            width: 9em;

            .k-label {
                display: none;
            }
        }

        & span.salarytaxreg_daterangegrp_textfs {
            top: 0.1rem;
            position: relative;
            &.clr_blc{
                color: black
            }
        }

        & input.salarytaxreg_daterangegrp_input {
            padding: 1px 2px 1px 5px !important;
            border: 1px solid #CFD8DC;
            border-right: 0px !important;
            border-radius: 4px 0px 0px 4px !important;
            box-sizing: border-box;
            background-color: #fff !important;
            color: #fc7d72 !important;
            font-size: 12px !important;
            font-weight: 500;
        }

        & span.input-group-text.salarytaxreg_daterangegrp_calicon {
            background-color: white;
            padding: 2px 5px 2px 1px;
            border-left: 0px !important;
        }

        span.textred {
            color: red;
        }
    }
}
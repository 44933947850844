fieldset {
  display: block;
  margin-left: 2px;
  margin-right: 2px;
  background-color: #fff;
  padding: .35em .75em .625em;
  border: 2px groove (internal value);
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0 5px;
  padding: 0;
  margin-bottom: -.4rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

.bortoplg {
  border-top: 1px solid #333;
  display: flex;
  position: relative;
  margin-top: 13px;
}

fieldset.fieldcretchall.usermanagepro {
  border: 1px solid #fc7d72;
  width: 100%;
}
legend.lengentcretchall.usermanagepro {
  width: 100%;
  padding: 0px;
}
.fieldcretchallinndev.usermanagepro {
  display: block;
}
.challanlefts.usermanagepro {
  float: left;
  padding: 0px 5px;
}
.challanlefts.usermanagepro h4 {
  font-size: 1.2rem;
  font-weight: 500;
  position: relative;
  top: -2px;
}
.challaninner.usermanagepro {
  height: 1px;
  width: 100%;
  position: relative;
}
.newcchallanright.usermanagepro {
  float: right;
  padding: 0px 5px;
}
.newcchallanright.usermanagepro h4 {
  font-size: 1rem;
    font-weight: 500;
    position: relative;
    top: -1px;
}
.bortoplg.bgcorlclrbrch.usermanagepro {
  border-top: 1px solid #fc7d72;
}


table.table.table-striped.usermanagetb thead tr th {
  font-size: 13.5px !important;
  text-align: center;
  border-top: 0px;
  border-bottom: 0px solid #dee2e6;

}



table.table.table-striped.usermanagetb tr td {
  font-size: 12px;
  vertical-align: middle;
  padding: .25rem .75rem;
}

/*
td.action_btn.editdelebtnmr {
  margin-bottom: 0px;
  padding-bottom: 0px;
  float: left;
  position: relative;
  right: -2rem;
}*/

img.create_new_return {
  margin-right: 5px;
}
.backtoclr {
  color: #fc7d72;
  font-size: 13px;
  padding: 4px 0;
  font-weight: 500;
}
label.switch.listofdempswitch.usermanageswbtn {
  display: inline-block;
  width: 32px;
  height: 17px;
  left: 0.2rem;
}

span.slider.round.listofdempswround:before {
  border-radius: 50%;
  width: 12px;
  position: absolute;
  top: 0px;
  left: 0px;
  
}
span.slider.listofdempswround:before {
  content: "";
  height: 12px;
  width: 6px;
  left: 5px;
  bottom: 3px;
  background-color: #fff;
}
input:checked+.slider.listofdempswround:before{
  left: -7px;
}
.flag_icon {object-fit: scale-down;}
/*start media code====================*/
@media only screen and (min-width: 320px) and (max-width: 767px){
  .challanlefts.usermanagepro h4 {
      font-size: 0.9rem;
  }
  .newcchallanright.usermanagepro h4 {
      font-size: 0.7rem;
  }

  label.switch.listofdempswitch.usermanageswbtn {
      margin-left: 0rem;
  }

}
@media only screen and (min-width: 768px) and (max-width: 1024px){
  

  label.switch.listofdempswitch.usermanageswbtn {
      margin-left: 0rem;
  }

}

table.table.table-striped.usermanagetb tr td span img {
  width: 16px!important;
  margin: 0 4px;
}

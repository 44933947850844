.reqraisebtnsect button.btn.btn-default.raisenewrebtn {
    background-color: #fc7d72;
    color: #fff;
    border: 1px solid #fc7d72;
    padding: 4px 15px 6px 15px!important;
    border-radius: 40px!important;
    font-size: 12px;
    font-weight: 500;
}
.reqraisebtnsect button.btn.btn-default.upstraiserebtn {
    border: 1px solid #31719b;
    padding: 4px 15px 6px 15px!important;
    border-radius: 40px!important;
    font-size: 12px !important;
    margin-left: 12px !important;
    background-color: #31719b;
    font-weight: 500;
}
fieldset.reqraisedtranfields {
    border: 1px solid #fc7d72;
}
legend.reqraisedtranlegent {
    width: 11rem;
}
legend.reqraisedtranlegent h4 {
    font-size: 1.2rem;
}
legend.reqraisedtranlegent.reqriasetrcelegn {
    width: 18rem;
}
img.reqraiseserchboxicon {
    position: absolute;
    z-index: 1;
    top: 9px;
    left: 80%;
    width: 15px;
}
img.reqraiseserchboxicon img {
    float: right;
    left: 6.6rem !important;
}
table.table.table-striped.table-responsive.reqraisetracestb thead tr th {
    font-size: 0.7rem;
}
table.table.table-striped.table-responsive.reqraisetracestb tr td {
    font-size: 0.7rem;
}


.deductee-transaction-multi-select .chip{display: none;}
.selctwid .clear-filter {
    left: 87px !important;
    right: unset !important;
    position: relative;
    top: -25px;
    z-index: 2;
}

.clear-filter.listchaltbpdsboxicon03 {
    margin-left: -7px;
}

/*css for table select box width*/
.selctwid {
    width: 75px;
}

.kdk_data_table tr td .selctwid .form-inline input.form-control.boxwisecrh {
    width: 129px;
}
.kdk_data_table tr td .selctwid .form-inline input.form-control.boxwidth01 {
    width: 90px;
}

.selctwid .filter-box .form-group .MyDatePicker .mdp-container {
    z-index: 1999;
    position: absolute;
    display: block;
    top: -8rem;
    left: -20rem;
}

.selctwid form.form-inline {
    left: 0px;
    top: 0px;
    padding: 0px 0px;
    position: relative;
    height: 32px;
}
.selctwid.mapchwidth {
    width: 100px;
    max-width: 100px !important;
}
.selctwid.mappchallwidth form.form-inline {
    max-width: 100px !important;
}

.selctwid.boxwidth .form-inline .form-control {
    width: 136px;
}


.selctwid.boxwpan .form-inline .form-control {
    z-index: 1;
    position: relative;
    padding: 4px 5px;
    width: 92px !important;
}

.raiseDatelist #mdp-calendar-div{top: 2rem; left: -2rem;}

a.backtordlclr {
    font-size: 12px;
    padding: 10px 0;
    color: #fc7d72;
}

.reqraisedoutbor {
border: 1px solid #eee;
   padding: 0px 10px 15px 10px;
    border-radius: 3px;
}
table.table.table-striped.reqraisetb thead tr th {
    border-top: 0px;
    font-size: 13.5px!important;
    font-weight: 500;
    text-align: center;
	 padding: 6px 0px;
}
table.table.table-striped.reqraisetb tr td {
    font-size: 12px;
    padding: .25rem .75rem;
}

img.reqnosearchicon {
    position: absolute;
    z-index: 1;
    top: auto;
    left: 18%;
    width: 12px;
}
td.borrightside {
    border-right: 1px solid #dee2e6;
}
input.form-control.searchinput {
    padding: .37rem .75rem;
    height: calc(1.2rem + .75rem) !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
input.thsearchboxw {
    padding: .37rem .75rem;
    height: calc(1.2rem + .75rem) !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
tr.toptrthbornon th {
    border-bottom: 0px solid #dee2e6 !important;
}
td.blueclrtext {
    color: #31719b;
}
.raisenewreqbtn {
    background-color: #fc7d72 !important;
    color: #fff;
    border: 1px solid #fc7d72 !important;
    padding: 5px 15px!important;
    border-radius: 40px!important;
    font-size: 14px;
}
.updatestfrtrancsbtn {
    border: 1px solid #eee;
    padding: 5px 16px!important;
    border-radius: 40px!important;
    font-size: 14px;
    margin-left: 1rem;
}
.raisereqheader.modal-header {
    padding: 5px 27px 5px 15px;
    background-color: #efecec;
}
.raisereqheader.modal-header .modal-title.h4 {
    padding: 2px 0;
    color: #333;
    font-size: 1.2rem;
}
button.btn.btn-default.proceedbtn {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    margin: 4px 4px 4px 1px;
}
button.btn.btn-default.cancelbtn {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.form-group label, .form-group select {
    font-size: 11px;
    color: #777171;
    font-weight: 500;
}
.form-check-inline.paddright {
    padding-right: 20px;
}
.downfr16btn {
    background-color: #31719b!important;
    color: #fff !important;
    border: 1px solid #31719b!important;
    padding: 5px 15px!important;
    border-radius: 40px!important;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
}
.downfr16btn:hover {
    color: #fff !important;
}
.form-group .form-check-inline.downloaddescr label {
    font-size: 14px !important;
    color: #777171;
}
.modal-header.downloadfr16h {
    padding: 5px 27px 5px 15px;
    background-color: #efecec;
}
.modal-header.downloadfr16h h4.modal-title {
    padding: 2px 0;
    color: #333;
    font-size: 1.2rem;
}
p.clickheretext {
    font-size: 14px;
    padding-top: 2rem;
    line-height: 22px;
}
button.btn.btn-default.updatestfrtrancsbtn {
    color: #fff;
    background-color: #31719b;
    border-color: #31719b;
    padding: 5px 15px!important;
    border-radius: 40px!important;
    font-size: 14px;
}
.download-form-16-popup .modal-dialog {max-width: 530px !important;}
/* .k-animation-container-shown {z-index: 99999 !important;} */
.kdk_data_table tr td .selctwid .form-inline input.form-control.err-tr-srch {
  padding: 0 5px;
}
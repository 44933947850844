* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", ;
}

p.panverific_textfs {
    font-size: 10px;
    width: 100%;
    font-weight: 500;
}

.deductordashboadscss {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

.dash_panstatus_comp_rightboxleft{
    display: flex;
    justify-content: space-evenly;
}

    fieldset {
        display: block;
        margin: 9px 2px 2px !important;
        background-color: #fff;
        padding: 0.35em 0.75em 0.625em !important;
        border: 2px groove (internal value);
    }

    legend {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        line-height: inherit;
        color: inherit;
        white-space: normal;
    }

    fieldset.Deductor_dashboard_fs {
        border: 1px solid #31719b;

        & .Deductor_dashboard_inndev {
            display: block;

            & .Deductor_dashboard_lefts {
                float: left;

                & h4 {
                    font-size: 1.2rem;
                    font-weight: 500;
                    padding: 0px 5px;
                    position: relative;
                    top: 3px;
                }
            }

            & .Deductor_dashboard_inner {
                height: 1px;
                width: 100%;
                position: relative;
            }

            & .Deductor_dashboard_right {
                float: right;

                & .Deductor_dashboard_startfilingbtn {
                    background-color: #31719b;
                    border-color: #31719b;
                    border-radius: 40px;
                    color: #fff;
                    padding: 2px 10px 5px 10px;
                    font-size: 12px;
                    font-weight: 500;
                    border: 0px;
                    position: relative;
                    top: 3px;
                    cursor: pointer;
                    float: right;
                }
            }

            & .Deductor_dashboard_middleborder {
                display: flex;
                position: relative;
                margin-top: 14px;
                border-top: 1px solid #31719b;
                justify-content: center;
            }
        }

        span.Deductor_dashboard_qbox {
            position: absolute;
            top: -25px;
        }


        table.table.table-striped.Deductor_dashboard_tb {
            thead tr th {
                font-size: 12px;
                font-weight: 500;
                border-top: 0px;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
            }

            & tr td {
                padding: 0.25rem 0.75rem;
                font-weight: 500;
                font-size: 11px;


            }

            & .Processingst_clrgreen {
                color: green;
            }

            & .Processingst_clrred {
                color: red;
            }

        }

        & .Deductor_dashboard_qbox {

            & span.Quarterbox_border_greyclr {
                border: 2px solid #fecf8a;
                font-size: 12px;
                font-weight: 500;
                padding: 2px 2px 2px 4px;
                background-color: #e7e7e7;
                text-align: center;
            }

            & span.Quarterbox_border_coralclr {
                border: 2px solid #fecf8a;
                font-size: 12px;
                font-weight: 500;
                padding: 2px 2px 2px 4px;
                background-color: #ffb548;
            }

            & span.Quarterbox_border_greenclr {
                border: 2px solid #7fcac3;
                font-size: 12px;
                font-weight: 500;
                padding: 2px 7.1px 2px 6px;
                background-color: #e7e7e7;
            }

            span.Quarterbox_border_greenlclr {
                border: 2px solid #7fcac3;
                font-size: 12px;
                font-weight: 500;
                padding: 2px 9px 2px 9px;
                background-color: #7fcac3;
            }
        }

    }
    

    fieldset.dash_panstatus_comp_fs {
        border: 1px solid #fc7d72;
        // background-color: #fffdea;
        &.singlepanverfication{
            margin-bottom: 0.15rem !important;
            padding-bottom: 0 !important;
        }
        &.panstatusfielset {
            padding-bottom: 3px !important;
            margin-bottom: 0px !important;
        }

        & legend.dash_panstatus_comp_leg {
            width: auto;

            & h4 {
                font-size: 1rem;
                font-weight: 500;
                position: relative;
                top: 3px;
                padding: 0px 5px;
            }
        }

        & .dash_panstatus_comp_border {
            background-image: url('../../images/rightsideshape.png');
            background-size: cover;
            position: absolute;
            display: block;
            height: 30px;
            width: 30px;
            top: auto;
            margin-top: -21px;
            right: 17px;
        }

        & p.dash_panstatus_comp_textfs {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #31719b;
        }

        & button.btn.btn-default.dash_panstatus_lefsibgl_btnbgclr {
            background-color: #31719b;
            border-color: #31719b;
            border-radius: 40px;
            color: #fff;
            padding: 3px 15px 6px 15px;
            font-size: 12px;
            font-weight: 500;
        }

        & button.btn.btn-default.dash_panstatus_lefsibgl_btnbgclrbluk {
            background-color: #31719b;
            border-color: #31719b;
            border-radius: 40px;
            color: #fff;
            padding: 3px 20px 6px 20px;
            font-size: 12px;
            font-weight: 500;
        }

        & button.btn.btn-default.backbtn_iconfs {
            cursor: pointer;
            vertical-align: baseline;
        }

        & .bluksinglbtntoppadd {
            bottom: -3px;
            position: absolute;
            margin: auto 2rem;
        }

        & .form-group.dash_single_rightsidebox_grp {
            margin-bottom: 0.2rem;

            & input {
                height: calc(1.2em + 0.75rem + 2px);
                padding: 0.375rem 0.3rem;
                font-size: 12px;
                font-weight: 500;
            }

            & span.panst_error_message {
                color: red;
                font-size: 9px;
                padding-left: 5px;
            }
        }

        & .form-group.dash_single_rightsidebox_grp input:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0rem rgba(0, 123, 255, .25);
        }

        & .dash_Captchaimgbox img {
            max-width: 60%;
        }

        & .dash_captchincrimage img{
            max-width: 95%;
        }

        & .panstatus_verifybox_dashborder {
            border: 1px dashed #ded3ba;
            background-color: #fffbda;
            border-width: 4px;
            padding: 0px 15px;

            & p.panstatus_verifybox_txetfs {
                font-size: 11px;
                font-weight: 700;
                text-align: left;
                line-height: 16px;
                margin-bottom: 0.2rem;


            }

            & p.panverify_textgreen {
                color: #689e39;
            }

            & p.panverify_textred {
                color: #e65100;
            }

            & .panstatus_verifybox_checkinsightport_textfs {
                color: #31719b;
                font-size: 12px;
                font-weight: 700;
                text-align: left;
                text-decoration: underline;
            }
        }

        @media only screen and (max-width: 1550px) {
            & .dash_Captchaimgbox img {
                max-width: 108%;
            }
            & .dash_captchincrimage img{
                max-width: 110%;
            }
            & .bluksinglbtntoppadd {
                margin: auto 3rem;
            }
          }


    }

    /*------ right side certifbox css-----------*/
    .dash_rightside_certifbox {
        position: fixed;
        padding: 0 0.4rem 0.1rem 0rem !important;
        background-color: #31719b;
        z-index: 2;
        top: 30%;
        transform: rotate(270deg);
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
        left: -14rem;
        cursor: pointer;
        -webkit-animation: oldrightsummanimat;
        animation: oldrightsummanimat;
        -webkit-animation-duration: 3s;
        animation-duration: 3s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        animation-direction: alternate-reverse;

        & a.mb-0.btn.dash_rightside_certifbox_textfs {
            color: #fff;
        }

        &:hover {
            left: -10.2rem !important;
            transition: all 1.25s ease-in-out;
            -webkit-animation-play-state: paused;
            animation-play-state: paused;
            cursor: pointer;
        }

        @keyframes oldrightsummanimat {
            0% {
                left: -10.2rem;
            }
        }

    }

    .dash_rightside_calcbox {
        position: fixed;
        padding: 0 0.4rem 0.1rem 0rem !important;
        background-color: #31719b;
        z-index: 2;
        top: 68%;
        transform: rotate(270deg);
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
        left: -14rem;
        cursor: pointer;
        -webkit-animation: oldrightsummanimat;
        animation: oldrightsummanimat;
        -webkit-animation-duration: 3s;
        animation-duration: 3s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        animation-direction: alternate-reverse;

        & div.mb-0.btn.dash_rightside_calcbox_textfs {
            color: #fff;
        }

        &:hover {
            left: -10.2rem !important;
            transition: all 1.25s ease-in-out;
            -webkit-animation-play-state: paused;
            animation-play-state: paused;
            cursor: pointer;
        }

        @keyframes oldrightsummanimat {
            0% {
                left: -10.2rem;
            }
        }

    }

    img.cursor_pointer {
        cursor: pointer;
    }

    /*------ right side certifbox css-----------*/

    /* strick notes box css*/
    .card.strickcard_borderclr {
        padding: 0px;
        border-radius: 0px;
        box-shadow: -1px 0px 3px #777;
        &.correctionsticky{
             border: 1px solid #bcf0ec;

            .deductdash_striknote_paper{
                background: linear-gradient(357deg, #bcf0ec 50%, #ffffff 100%);
            }
        }
        &.regularsticky{
            border: 1px solid #fefda9;

            .deductdash_striknote_paper{
                background: linear-gradient(357deg, #fefda9 50%, #ffffff 100%);
            }
        } 
    }


    /*==================================================
 * Effect 2
 * ===============================================*/
    .deductdash_striknote_paper {

        cursor: pointer;
        padding: 10px 0px 10px 0px;
    }

    .deductdash_striknote_paper:before,
    .deductdash_striknote_paper:after {
        z-index: -1;
        position: absolute;
        content: "";
        bottom: 15px;
        left: 10px;
        width: 50%;
        top: 80%;
        max-width: 300px;
        background: #777;
        -webkit-box-shadow: 0 15px 10px #777;
        -moz-box-shadow: 0 15px 10px #777;
        box-shadow: 0 15px 10px #777;
        -webkit-transform: rotate(-3deg);
        -moz-transform: rotate(-3deg);
        -o-transform: rotate(-3deg);
        -ms-transform: rotate(-3deg);
        transform: rotate(-3deg);
    }

    .deductdash_striknote_paper:after {
        -webkit-transform: rotate(3deg);
        -moz-transform: rotate(3deg);
        -o-transform: rotate(3deg);
        -ms-transform: rotate(3deg);
        transform: rotate(3deg);
        right: 10px;
        left: auto;
    }


    .deductdash_striknote_pin {
        --pin-color: #d02627;
        --pin-dark: #9e0608;
        --pin-light: #fc7e7d;
        position: absolute;
        width: 60px;
        height: 50px;
        right: 50%;
        top: -0.7rem;
        left: 50%;
    }

    .deductdash_striknote_metal {
        position: absolute;
        width: 4px;
        height: 16px;
        background: linear-gradient(to right, #808080, 40%, #eae8e8, 50%, #808080);
        border-radius: 0 0 30% 30%;
        transform: rotate(50deg);
        transform-origin: bottom left;
        top: 18px;
        right: 3.3rem;
        border-bottom: 1px solid #808080;
    }

    .deductdash_striknote_bottom-circle {
        position: absolute;
        right: 34px;
        width: 15px;
        top: 1rem;
        height: 15px;
        border-radius: 50%;
        background-color: var(--pin-color);
        background: radial-gradient(circle at bottom right, var(--pin-light), 25%, var(--pin-dark), 90%, var(--pin-color));
    }

    .deductdash_striknote_bottom-circle::before {
        content: "";
        position: absolute;
        top: 3px;
        left: 3px;
        width: 5px;
        height: 10px;
        transform: rotate(55deg);
        transform-origin: 100% 100%;
        border-radius: 0 0 40% 40%;
        background: linear-gradient(to right, var(--pin-dark), 30%, var(--pin-color), 90%, var(--pin-light));
    }

    .deductdash_striknote_bottom-circle::after {
        content: "";
        position: absolute;
        right: -4px;
        top: -2px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: radial-gradient(circle at right, var(--pin-light), 30%, var(--pin-color), var(--pin-dark) 80%);
    }

    p.clickheretextfs {
        font-size: 12px;
        font-weight: 500;
        line-height: 19px;
        padding: 0px 2px;
        text-align: left;
    }

    // p.clickheretextfs.textcolor_blue {
    //     color: #31719b;
    // }

    /* end strick notes box css*/

    /* start filing css*/
    .k-window-content{
        padding: 0rem 1rem 0.5rem 1rem;
    }

    &.StartFiling_modalbox.show .modal-dialog {
        margin: 3rem auto 0rem auto;
        max-width: 650px !important;

        & .modal-header.StartFiling_modalbox_header {
            background-color: #eee;
            padding: 0px 15px;

            .gst_verifyyremailid_title {

                & .StartFiling_title {
                    width: auto;
                    text-align: right;
                    margin-left: auto;
                    float: right;

                    & button.btn.btn-default.StartFiling_btn {
                        background-color: #31719b;
                        border-color: #31719b;
                        border-radius: 40px;
                        color: #fff;
                        padding: 1px 10px 3px 10px;
                        font-size: 12px;
                        font-weight: 500;
                        border: 0px;
                        position: relative;
                        top: 0px;
                    }
                }

                .modal-title.h4 {
                    font-size: 1rem;
                    font-weight: 500;
                    padding: 4px 0px 4px 0px;
                }
            }

            & .gst_verifyyremailid_title.modal-title.h4 span {
                position: relative;
                top: -3px;
            }


            & button.close {
                position: relative;
                top: 2px;
            }
        }

        .startfillinglist.modal-body {
            padding: 0rem 1rem 0.5rem 1rem;
        }
       

        p.selectcell_fs {
            font-size: 12px;
            font-weight: 500;
        }

        table.table.table-striped.stratfiling_tb {

            & thead tr th {
                border-top: 0;
                font-size: 12px;
                text-align: center;
                font-weight: 500;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 0px;
            }

            & tr td {
                font-size: 11px;
                padding: 0.25rem 0.75rem;
                font-weight: 500;

                & .stratfiling_textclr_coral {
                    color: #fc7d72;
                }

                & .stratfiling_textclr_blue {
                    color: #31719b;
                }
            }

            & span.startfiling_subtextfs {
                color: #689e39;
                font-size: 8px;
                font-weight: 500;
                display: block;
                text-align: right;
            }

        }
    }



    &.StartFiling_modalbox.Createnewreturn_modalwidth.show {
        background-color: #00000057;
    }

    &.Createnewreturn_modalwidth.show .modal-dialog {
        max-width: 340px !important;
    }

    div.StartFiling_craetenewret_frmbox {
        & label {
            font-size: 12px;
            color: #777171;
        }

        & select.form-control.createnewret_selbox {
            font-size: 12px;
            font-weight: 500;
        }

        & span.createnewret_radioalign {
            vertical-align: text-bottom;
        }

        & button.btn.btn-default.createnewret_cancelbtn {
            background-color: #f2f2f2 !important;
            color: #636363;
            border: 1px solid #ddd;
            padding: 7px 20px 7px 20px;
            border-radius: 40px;
            font-size: 12px;
        }

        & button.btn.btn-default.createnewret_submitbtn {
            background-color: #31719b;
            color: #fff;
            padding: 4px 15px 6px 15px;
            border-radius: 40px;
            font-size: 12px;
            font-weight: 500;
            border: 0px;
        }

        & .crret_type,
        .crretquarter {
            padding-left: 0px;
            margin-top: 3px;
        }


    }


    /*======= Return Highlights ===========*/

    fieldset.Deductor_dashboard_return_HL_fs {
        border: 1px solid #fc7d72;

        & legend.Deductor_dashboard_return_HL_leg {
            width: auto;

            & h4 {
                font-size: 1.2rem;
                font-weight: 500;
                padding: 0px 5px;
                position: relative;
                top: 2px;
            }
        }

        & table.table.table-striped.Deductor_dashboard_return_HL_tb {

            & thead tr th {
                font-size: 12px;
                font-weight: 500;
                border-top: 0px;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
            }

            & tr td {
                padding: 0.25rem 0.75rem;
                font-weight: 500;
                font-size: 11px;



            }

            & .returnhl_textclrblue_undline {
                color: #31719b;
                text-decoration: underline;
            }
        }

    }

    fieldset.todedcutee_borderclr_coral {
        border: 1px solid #fc7d72 !important;
    }
    /*========= Outstanding Demand ===========*/
    fieldset.Deductor_dashboard_outstdemand_fs {
        border: 1px solid #fc7d72;

        // height: 320px;
        &.Deductor_dashboard_noticeorder_fs {
            height: 260px;
        }

        & legend.Deductor_dashboaroutstandingdemand {
            width: auto;

            & h4 {
                font-size: 1.2rem;
                font-weight: 500;
                padding: 0px 5px;
                position: relative;
                top: 2px;
            }
        }

        & legend.Deductor_dashboard_outstdemand_leg {
            display: block;
            width: 100%;
            max-width: 100%;
            padding: 0;
            margin-bottom: 0.5rem;
            font-size: 1.5rem;
            line-height: inherit;
            color: inherit;
            white-space: normal;

            & .Deductor_dashboard_inndev {
                display: block;

                & .Deductor_dashboard_lefts {
                    float: left;

                    & h4 {
                        font-size: 1.2rem;
                        font-weight: 500;
                        padding: 0px 5px;
                        position: relative;
                        top: 5px;
                    }
                }

                & .Deductor_dashboard_inner {
                    height: 1px;
                    width: 100%;
                    position: relative;
                }

                & .Deductor_dashboard_right {
                    float: right;

                    & button.Deductor_dashboard_startfilingbtn {
                        background-color: #31719b;
                        border-color: #31719b;
                        border-radius: 40px;
                        color: #fff;
                        padding: 2px 10px 5px 10px;
                        font-size: 12px;
                        font-weight: 500;
                        border: 0px;
                        position: relative;
                        top: -6px;
                        cursor: pointer;
                    }
                }

                & .Deductor_dashboard_middleborder {
                    display: flex;
                    position: relative;
                    margin-top: 17px;
                    border-top: 1px solid #fc7d72;
                }
            }


        }


        table.table.table-striped.Deductor_dashboard_outstdemand_tb {

            & thead tr th {
                font-size: 12px;
                font-weight: 500;
                border-top: 0px;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
                vertical-align: baseline;
                position: sticky;
            }

            & tr td {
                padding: 0.25rem 0.75rem;
                font-weight: 500;
                font-size: 11px;
                vertical-align: inherit;
            }

            & tr.Deductor_dashboard_outstdemand_toprow th {
                border-bottom: 0px;
                padding-top: 5px;
                padding-bottom: 5px;
            }

            tr.Deductor_dashboard_outstdemand_2row th {
                border-bottom: 0px;
                padding-top: 5px;
                padding-bottom: 5px;
            }

            & .Deductor_dashboard_outstdemand_rowbgclr_yell {
                background-color: #fffce1;
            }

        }

    }

    fieldset.dash_quarterliability_fs {
        border: 1px solid #31719b;

        & legend.dash_quarterliability_lg {
            width: auto;

            & h4.dash_quarterliability_tital {
                font-size: 1.2rem;
                font-weight: 500;
                padding: 0px 5px;
                position: relative;
                top: 2px;
            }
        }
    }


    table thead tr.Deductor_dashboard_outstdemand_toprow th
    {
    position: sticky;
    top: 0px;
    background-color: #ffffff;
    }



    .notice_order_textclr_blue {
        color: #31719b;

        &.hyperlink:hover {
            text-decoration: underline;
        }

        &.pointercur {
            cursor: pointer;
        }
    }

    .downloadicon_pointer {
        cursor: pointer;
    }

    fieldset.dash_createdeductee_fs {
        border: 1px solid #fc7d72;

        & legend.dash_createdeductee_lg {
            width: 100%;
            text-align: right;

            & button.btn.btn-default.dash_createdeductee_btnbgclr {
                background-color: #31719b;
                color: #fff;
                border: 0px;
                border-radius: 40px;
                padding: 2px 10px 4px 10px;
                font-size: 12px;
                font-weight: 500;
                position: relative;
                top: -2px;
            }
        }

        & h4.totaldeductees_titlefs {
            font-size: 1rem;
            font-weight: 500;
            color: #31719b;
            margin-bottom: 0;
            padding-top: 0;
        }

        /****----- *****/
        & .Deductor_dashboard_inndev {
            display: block;

            & .Deductor_dashboard_lefts {
                float: left;

                & h4 {
                    font-size: 1.2rem;
                    font-weight: 500;
                    padding: 0px 5px;
                    position: relative;
                    top: 5px;
                }
            }

            & .Deductor_dashboard_inner {
                height: 1px;
                width: 100%;
                position: relative;
            }

            & .Deductor_dashboard_right {
                float: right;

                & button.Deductor_dashboard_startfilingbtn {
                    background-color: #fc7d72;
                    border-color: #fc7d72;
                    border-radius: 40px;
                    color: #fff;
                    padding: 2px 10px 5px 10px;
                    font-size: 12px;
                    font-weight: 500;
                    border: 0px;
                    position: relative;
                    top: -6px;
                    cursor: pointer;
                }
            }

            & .Deductor_dashboard_middleborder {
                display: flex;
                position: relative;
                margin-top: 17px;
                border-top: 1px solid #fc7d72;
            }
        }



        /****----- *****/


    }

    button.btn.btn-default.Deductor_dashboard_infoiconbox {
        border-radius: 40px;
        color: #fff;
        padding: 1px 1px 1px 1px;
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        position: relative;
        top: 1px;
        cursor: pointer;
        float: right;
    
        position: relative;
        animation: demoanimate 2s linear;
        animation-iteration-count: infinite;
        background-color: #31719b;
    
    }
    
    
    @keyframes demoanimate {
        0% {
            box-shadow: 0 0 0 0 rgb(213 213 213 / 30%), 0 0 0 0 rgb(241 232 234 / 70%);
        }
        40% {
            box-shadow: 0 0 0 0 rgb(213 213 213 / 30%), 0 0 0 0 rgb(241 232 234 / 70%);
        }
        80% {
            box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 30px rgba(255, 0, 64, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 30px rgba(255, 0, 64, 0);
        }
    }

    


    fieldset.Deductor_dashboard_raised_req_fs {
        border: 1px solid #fc7d72;

        & legend.Deductor_dashboard_raised_req_leg {
            width: auto;

            & h4 {
                font-size: 1.2rem;
                font-weight: 500;
                padding: 0px 5px;
                position: relative;
                top: 2px;
                margin-bottom: 0px;
            }
        }

        & table.table.table-striped.Deductor_dashboard_raised_req_tb {

            thead tr th {
                font-size: 12px;
                font-weight: 500;
                border-top: 0px;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
                vertical-align: baseline;
            }

            & tr td {
                padding: 0.25rem 0.75rem;
                font-weight: 500;
                font-size: 11px;
                vertical-align: inherit;
            }
        }
    }


    fieldset.Deductor_dashboard_allchallan_fs {
        border: 1px solid #31719b;
        max-height: 260px !important;
        height: 260px;

        & legend.Deductor_dashboard_allchallan_leg {
            width: auto;

            & h4 {
                font-size: 1.2rem;
                font-weight: 500;
                padding: 0px 5px;
                position: relative;
                top: 2px;
            }
        }

      

    }

    .mt-2p {
        margin-top: 5% !important;
    }


    /* inconsum Amt css */
    span.unconsum_amtcalcul_box {
        font-size: 12px;
        font-weight: 500;
        color: #31719b;
    }

    .card.allchallan_cardbox {
        border-color: #31719b;
        padding: 0px;
        text-align: center;
        border-radius: 0px;
        border-left: 10px solid #31719b;

        & .totleverifychallan_middbox {
            padding: 0px 0px;
            border-right: 4px solid #31719b;
            background-color: #ffcdd2;
        }

        & .Deductor_dashboard_allchallan_textfs {
            font-size: 12px;
            font-weight: 500;

            & a {
                color: #31719b;
                font-size: 12px;
                font-weight: 700;

            }
            .dasboard_challantext{
                display:block;
                text-align: left;
            }
        }

        & h4.Deductor_dashboard_allchallan_amttextclrblue {
            font-weight: 700;
            font-size: 12px;
            // color: #31719b;
            text-align: right;
            margin-right: 0.8rem;
            vertical-align: middle;
            padding-top: 16px;
        }
    }

    /* hover box css*/

    .btn-container01:nth-child(1) {
        --btn-bg: #ffcdd2;
    }

    .btn-container02:nth-child(1) {
        --btn-bg: #bbdefb;
    }

    .btn-container03:nth-child(1) {
        --btn-bg: #fff59d;
    }

    button.btnhoverbox {
        --width: 100%;
        border: 0;
        position: relative;
        min-width: var(--width);
        min-height: var(--height);
        border-radius: var(--height);
        // color: #31719b;
        font-size: 12px;
        font-weight: bold;
        background: rgb(219, 250, 212);
        // cursor: pointer;
        overflow: hidden;
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 5px 15px;
    }

    button.btnhoverbox .Deductor_dashboard_allchallan_textfs,
    button.btnhoverbox .icon-container {
        position: relative;
        z-index: 2;
    }

    button.btnhoverbox .icon-container {
        --icon-size: 25px;
        position: relative;
        width: var(--icon-size);
        height: var(--icon-size);
        margin-left: 15px;
        transition: transform 500ms ease;
    }

    button.btnhoverbox .icon-container .icon {
        position: absolute;
        left: 0;
        top: 0;
        width: var(--icon-size);
        height: var(--icon-size);
        transition: transform 500ms ease, opacity 250ms ease;
    }

    button.btnhoverbox .icon-container .icon--left {
        transform: translateX(-200%);
        opacity: 0;
    }

    button.btnhoverbox .icon-container .icon svg {
        width: 100%;
        height: 100%;
        fill: #fff;
    }

    button.btnhoverbox::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--btn-bg);
        border-radius: var(--height);
        z-index: 1;
        transition: transform 500ms ease;
    }

    button.btnhoverbox:hover::after {
        transform: translateX(95%);
    }

    button.btnhoverbox:hover .icon-container {
        transform: translateX(125%);
    }

    button.btnhoverbox:hover .icon-container .icon--left {
        transform: translateX(0);
        opacity: 1;
    }

    button.btnhoverbox:hover .icon-container .icon--right {
        transform: translateX(200%);
        opacity: 0;
    }

    /* end hover box css*/

    .viewhelth_btnbox {
        display: inline-flex;

        & button.btn.btn-default.viewhelthbtnbgclr {
            background-color: #fff3c1;
            border-color: #fff3c1;
            border-radius: 40px;
            color: #fff;
            padding: 2px 8px 2px 8px;
            font-size: 12px;
            font-weight: 500;
            border: 0px;
        }
    }

    .showmorebox span {
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        color: #31719b;
    }

    .table-responsive.table-responsive-xl.table-responsive-lg {
        &.outstandingdemandscroll {
            height: 265px;

            table.table.table-striped.Deductor_dashboard_outstdemand_tb {
                display: flex;
                flex-flow: column;
                height: 100%;
                width: 100%;

                thead {
                    /* head takes the height it requires, 
                and it's not scaled when table is resized */
                    flex: 0 0 auto;
                    width: calc(100% - 0.9em);
                }

                tbody {
                    /* body takes all the remaining available space */
                    flex: 1 1 auto;
                    display: block;
                    overflow-y: auto;
                }

                tbody tr {
                    width: 100%;
                }

                thead,
                tbody tr {
                    display: table;
                    table-layout: fixed;
                }
            }
        }

        &.deductor_dashboardout {
            height: 335px;

            table.table.table-striped.dashboard_scrollbar {
                display: flex;
                flex-flow: column;
                height: 100%;
                width: 100%;

                thead {
                    /* head takes the height it requires, 
                and it's not scaled when table is resized */
                    flex: 0 0 auto;
                    width: calc(100% - 0.9em);
                }

                tbody {
                    /* body takes all the remaining available space */
                    flex: 1 1 auto;
                    display: block;
                    overflow-y: auto;
                }

                tbody tr {
                    width: 100%;
                }

                thead,
                tbody tr {
                    display: table;
                    table-layout: fixed;
                }
            }
        }

        &.raised_requestlistform {
            .table.table-striped.Deductor_dashboard_raised_req_tb {
                .k-grid-container .k-grid-content {
                    overflow-y: auto;
                    max-height: 130px;
                }
            }
        }

        &.containerheight {
            .table.table-striped.Deductor_dashboard_raised_req_tb {
                .k-grid-container .k-grid-content {
                    max-height: 130px;
                    overflow: visible;
                }
            }
        }

        &.noticeorderlist {
            .k-widget.k-grid.table.table-striped.notices_orders_tb.listofdedtb.scrollvisible {
                .k-grid-container {
                    .k-grid-content.k-virtual-content {
                        max-height: 150px;
                        overflow: auto;
                    }
                }
            }
        }
    }

    /* ret summ neww css add*/

    .returnsumm_mid_border {
        margin-top: 17px;
        margin-left: 3px;
        width: 117px;
    }

    .returnsumm_mid_border,
    .inner_middleborder {
        border-top: 1px solid #31719b;
        display: flex;
        position: relative;
        float: right;
    }





    /* stricky slider css */

    .strickysliderbox {

        * .carousel-control {
            width: 5%;
        }

        .fieldset.returnin_inprogress_fs {
            border: 1px solid #31719b;
            &.notes_mrgintop{
                margin-top: 6px !important;
            }
            legend.returnin_inprogress_leg {
                width: auto;

                h4 {
                    font-size: 1.2rem;
                    font-weight: 500;
                    padding: 0px 5px;
                    top: 2px;
                    position: relative;
                }
            }

        }


        // .indicatcol_width {
        //     max-width: 1%;
        // }
        // .sliderphoto_width {
        //     max-width: 98%;
        //     min-width: 98%;
        //     width: 98%;
        // }

        .arrowleft {
            cursor: pointer;
            height: 15px;
            position: absolute;
            top: 35%;
            transform: translateX(-50%) translateY(-50%);
            transition: transform 0.1s;
            width: 15px;
            left: 1%;
            z-index: 8;

            .arrow-top,
            .arrow-bottom {
                background-color: #666;
                height: 4px;
                left: -5px;
                position: absolute;
                top: 50%;
                width: 100%;
            }

            .arrow-top:after,
            .arrow-bottom:after {
                background-color: #fff;
                content: '';
                height: 100%;
                position: absolute;
                top: 0;
                transition: all 0.15s;
            }

            .arrow-top {
                transform: rotate(219deg);
                transform-origin: bottom right;
                top: 15px;
            }

            .arrow-top:after {
                left: 100%;
                right: 0;
                transition-delay: 0s;
            }

            .arrow-bottom {
                transform: rotate(138deg);
                transform-origin: top right;
                top: 24px;
            }

            .arrow-bottom:after {
                left: 0;
                right: 100%;
                transition-delay: 0.15s;
            }

        }

        .arrowleft:hover .arrow-top:after {
            left: 0;
            transition-delay: 0.15s;
        }

        .arrowleft:hover .arrow-bottom:after {
            right: 0;
            transition-delay: 0s;
        }

        .arrowleft:active {
            transform: translateX(-50%) translateY(-50%) scale(0.9);
        }

        /* right side errow*/
        .arrowright {
            cursor: pointer;
            height: 15px;
            position: absolute;
            top: 39%;
            transform: translateX(-50%) translateY(-50%);
            transition: transform 0.1s;
            width: 15px;
            z-index: 8;
            right: 0.9%;

            & .arrow-top,
            .arrow-bottom {
                background-color: #666;
                height: 4px;
                left: -5px;
                position: absolute;
                top: 50%;
                width: 100%;
            }

            & .arrow-top:after,
            .arrow-bottom:after {
                background-color: #fff;
                content: '';
                height: 100%;
                position: absolute;
                top: 0;
                transition: all 0.15s;
            }

            & .arrow-top {
                transform: rotate(41deg);
                transform-origin: bottom right;
                top: 13px;
            }

            & .arrow-top:after {
                left: 100%;
                right: 0;
                transition-delay: 0s;
            }

            & .arrow-bottom {
                transform: rotate(319deg);
                transform-origin: top right;
                top: 12px;
            }

            & .arrow-bottom:after {
                left: 0;
                right: 100%;
                transition-delay: 0.15s;
            }


        }

        .arrowright:hover .arrow-top:after {
            left: 0;
            transition-delay: 0.15s;
        }

        .arrowright:hover .arrow-bottom:after {
            right: 0;
            transition-delay: 0s;
        }

        .arrowright:active {
            transform: translateX(-50%) translateY(-50%) scale(0.9);
        }

        .stickynotestwodesign {
            display: flex;
            width: 100%;
            justify-content: space-evenly;

            .flexrow_slider_innerbox {
                width: 300px;
                position: sticky;

            }


        }

        .notesnotfound {
            font-size: 16px;
            text-align: center;
            color: #5271a8;
            height: 110px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            min-height: 136px;
        }
    }

    .carousel.slide.sliderphoto_width {
        max-width: 100%;
        width: 97%;
        min-width: 97%;
        left: 6px;
    }

    .flex.flexrow_slider {
        width: 100%;
        display: flex;

        .flexrow_slider_innerbox {
            padding: 0px 4px;
        }
    }

    .strickysliderbox .carousel-item {
        min-height: 150px;
        max-height: 100%;
    }

    .strickysliderbox .carousel-item-next,
    .carousel-item-prev,
    .carousel-item.active {
        display: block;
        z-index: 0;
    }


    .btn-group.pan_compstatus_btngp {
        border: 1px solid #ffecec;
        border-radius: 40px;
        background-color: #ffecec;
        width: 100%;

        & button.btn.btn-default.pan_compstatus_panbtnbgclr {
            font-size: 12px;
            font-weight: 500;
            padding: 4px 10px 4px 10px;
            min-width: 100px;
            background-color: #ffecec;
            border-radius: 40px 0px 0px 40px;
        }

        & button.btn.btn.btn-default.pan_compstatus_panamtbtnbgclr {
            font-size: 12px;
            font-weight: 500;
            background-color: #e57373;
            border: 0px;
            border-radius: 40px;
            color: #fff;
            padding: 4px 10px 4px 10px;
            border-color: #f2baba;
        }
    }

    .dash_panstatus_comp_rightboxright {
        .captchamsgbtn{
            margin: 0px 0px;
            vertical-align: inherit;
            font-size: 12px;
            font-weight: 700;
            padding: 0px 5px;
          }
          .dash_single_rightsidebox_grp .form-control.panst_catchinputbox{
            font-size: 10px ;
          }
    }


    /*========= circle box css ============*/


    .panstatus_box {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        padding: 10px;
        justify-content: center;
        display: flex;
        align-items: center;
        & h4 {
            font-size: 13px;
            font-weight: 700;
            padding-top: 0px;
        }
        p {
            font-size: 12px;
            font-weight: 500;
            color: #9f9f9f;
        }
    }

   


    .compcheck_titlefs {
        & p {
            text-align: left;
            font-size: 12px;
            font-weight: 500;
        }
    }

    p.panst_backerrowbox {
        text-align: right;
        color: #ff6455;
        
        top: 0px;
        cursor: pointer;
        right: 32px;
        z-index: 1;
    }



    /*========== start media code ================*/

    @media (max-width: 767px) {
        h4.compliancestcheck_titlefs {
            left: 0rem;
        }
    }

    @media (min-width: 1025px) and (max-width: 1441px) {
        .btn-group.pan_compstatus_btngp button.btn.btn-default.pan_compstatus_panbtnbgclr {
            font-size: 11px;
            padding: 4px 5px 4px 5px;
        }

        .btn-group.pan_compstatus_btngp button.btn.btn.btn-default.pan_compstatus_panamtbtnbgclr {
            font-size: 11px;
            padding: 4px 5px 4px 5px;
        }
    }

    @media (min-width: 914px) and (max-width: 1550px){
            fieldset.dash_panstatus_comp_fs button.btn.btn-default.dash_panstatus_lefsibgl_btnbgclrbluk.panstatus_verify {
                background-color: #31719b;
                border-color: #31719b;
                border-radius: 40px;
                color: #fff;
                padding: 0px 20px 0px 20px;
                font-size: 10px;
                font-weight: 500;
                & .dash_Captchaimgbox img{
                    max-width: 108%;
                }
                & .dash_captchincrimage img{
                    max-width: 110%;
                }
            }
            .dash_panstatus_comp_rightboxright {
                .captchamsgbtn{
                  margin: 0px 0px;
                  vertical-align: inherit;
                  font-size: 9px;
                  font-weight: 700;
                  padding: 0px 5px;
                }
             }
             
    
        
        
    }

    &.Createnewreturn_ContinueonSameReturn_modal {
        &.show {
            background-color: rgb(34 34 34 / 98%);
        }

        .modal-dialog {
            max-width: 500px !important;

            p.contonsame_textfs {
                font-size: 12px;
                font-weight: 500;
            }

            button.btn.btn-default.ContinueonSameReturn_btnbgclr {
                text-align: right;
                font-size: 12px;
                font-weight: 500;
                background-color: #31719b;
                color: #fff;
                border-radius: 40px;
                padding: 4px 10px 6px 10px;
            }

            .ifalready_textfs {
                font-size: 12px;
                font-weight: 500;
                text-decoration: underline;
                background-color: #c7e6ff;
                padding: 3px 5px 7px 5px;
                color: #000 !important;
                cursor: pointer;
            }
        }

        .Createnewreturn_ContinueonSameReturn_header.modal-header {
            background-color: #ddd;
            padding: 0px 15px;

            & h4.modal-title {
                font-size: 1rem;
                font-weight: 500;
                padding: 4px 0px 4px 0px;
            }
        }
    }

    &.Createnewreturn_Q2browser_popup {
        &.show {
            background-color: rgb(34 34 34 / 98%);
            font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans",
                sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
                "Segoe UI Symbol", "Noto Color Emoji";

            .modal-dialog {
                max-width: 384px !important;

                .Createnewreturn_Q2browser_header.modal-header {
                    padding: 0px 15px;
                    background-color: #ddd;

                    h4.modal-title {
                        font-size: 1rem;
                        font-weight: 500;
                        padding: 4px 0px 4px 0px;
                    }
                }

                label.form-check-label.labletextfs {
                    font-size: 12px !important;
                    font-weight: 600;
                    color: rgb(33, 37, 41)
                }

                .consofileimport_return.modal-body {
                    padding: 1rem;
                }

                section.Q2selct_importdropzone {
                    flex: 1 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 20px;
                    border-radius: 2px;
                    border: 2px dashed #eee;
                    background-color: #fafafa;
                    color: #bdbdbd;
                    outline: none;
                    transition: border 0.24s ease-in-out;
                    height: 100px;
                    justify-content: center;
                    cursor: pointer;
                    text-align: center;
                    font-size: 12px;

                    p.clickhere_fs {
                        font-size: 12px;
                    }
                }

                button.file_submitbtn {
                    font-weight: 500;
                    font-size: 12px;
                    padding: 3px 10px 6px 10px;
                    border: 0px;
                    border-radius: 40px;
                    background-color: #31719b;
                    color: #fff;
                }

                p.fileselectedtextfs {
                    font-size: 12px;
                    font-weight: 500;
                }

                p.alreadyfiled_textfs {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
    }

    .slidestwo {
        .slick-slider.slick-initialized {
            .slick-track {
                display: flex;
                justify-content: center;
            }

            .slick-slide.slick-active {
                width: 270px !important;

                .clickheretextfs {
                    font-size: 15px !important;
                }
            }
        }
    }

    .topdeductheight {
        height: 349px;
    }

    .viewIconreturnsummary {
        background-color: #31719b;
        padding: 2px 0px;
        border-radius: 14px;
        color: white;
        cursor: pointer;
        font-size: 12px;
        display: block;
        font-weight: 500;
    }

    .qtrgraphnorecord {
        height: 300px;
        margin: auto;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #31719B;
    }

    // button.slick-disabled {
    //     display: none !important;
    // }

    .tds_returnsumm_createret_textwarp {
        writing-mode: vertical-rl;
        text-align: center;
        transform: rotate(180deg);
        background-color: #ecd8d8;
        position: absolute;
        font-size: 11px;
        font-weight: 600;
        color: #ff4131;
    }

    .tds_returnsumm_createret_textwarp.createret_toptbwrap {
        margin-top: 28px;
        padding: 35px 10px 28px 10px;
        left: 24px;
    }

    .tds_returnsumm_createret_textwarp.createret_2tbwrap {
        margin-top: 0px;
        padding: 3px 2px 3px 2px;
        left: 24px;
        max-height: 148px;
    }

    .slick-prev, .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 50%;
        // display: block;
        width: 19px;
        height: 31px;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
        z-index: 1;
        
    }
    .slick-prev{
        left: -13px;
        &:before{
            color: white;
        }
    }
    .slick-next {
        right: -13px;
        &:before{
            color: white;
        }
    }
  
    .slick-arrow {
        opacity: 0.7;
        background: #000 !important;
        color: white
    }

    @media only screen and (min-width: 1400px){
        .panInfobtncomp{
            min-height: 100px;
        }
    }
    button.slick-disabled {
        display: none !important;
    }

    .cursor-default{
        .k-link{
            cursor: default;
        }
    }

    .cursor_pointer{
        cursor: pointer;
    }

    .apexcharts-legend.apexcharts-align-center.apx-legend-position-right{
        text-align: left !important;
    }

}



.slderddddddd {
    .multi-item-carousel {
        .carousel-inner {
            >.item {
                transition: 500ms ease-in-out left;
            }

            .active {
                &.left {
                    left: -33%;
                }

                &.right {
                    left: 33%;
                }
            }

            .next {
                left: 33%;
            }

            .prev {
                left: -33%;
            }

            @media all and (transform-3d),
            (-webkit-transform-3d) {
                >.item {
                    // use your favourite prefixer here
                    transition: 500ms ease-in-out left;
                    transition: 500ms ease-in-out all;
                    backface-visibility: visible;
                    transform: none !important;
                }
            }
        }

        .carouse-control {

            &.left,
            &.right {
                background-image: none;
            }
        }

    }

    // non-related styling:
    body {
        background: #333;
        color: #ddd;
    }

    h1 {
        color: white;
        font-size: 2.25em;
        text-align: center;
        margin-top: 1em;
        margin-bottom: 2em;
        text-shadow: 0px 2px 0px rgba(0, 0, 0, 1);
    }
}

#chart {
    text-align-last: justify;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    position: relative;
    top: 40px;
}

.arrow_box {
    position: relative;
    background: #555;
    border: 2px solid #555;
    ;
    padding: 10px;
}


.browserbutton_box {
    text-align: center;


}
p.No_Records_to_Display_textfs {
    font-size: 18px;
    font-weight: 500;
    text-shadow: 0px 0px 1px #000;
}

.browser_sectionwise_summ_chartbgimg {
    
    padding: 0px;
    background-image: url('../../images/liability_graphsbgimg.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    min-height: 240px;
    height: auto;
    background-position: center;
}


.browser_liability_chartbgimg {
    
    padding: 0px;
    background-image: url('../../images/liability_graphsbgimg.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    min-height: 240px;
    height: auto;
    background-position: center;
    }

    .browser_totaldedcutee_chartbgimg {
    
        padding: 0px;
        background-image: url('../../images/deductee_graphbgimg.PNG');
        background-repeat: no-repeat;
        background-size: 100%;
        width: 100%;
        min-height: 240px;
        height: auto;
        background-position: center;
        }


        .browser_sectionwisemonth_chartbgimg {
    
            padding: 0px;
            background-image: url('../../images/liability_graphsbgimg.png');
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            min-height: 240px;
            height: auto;
            background-position: center;
            text-align: right;
        }

        span.clickablebtn {
            cursor: pointer;
        }

/* slider hover css box*/

.strickysliderbox .slick-prev, .slick-next{
    display: none;
}
.strickysliderbox:hover .slick-prev{
    display: block;
}
.strickysliderbox:hover .slick-next{
    display: block;
}

.switch-panstatus {
    position: relative;
    width: 100%;
    // height: 155px;
}

.switch-panstatus .front-panstatus, .switch-panstatus .back-panstatus {
    right: 0;
    top: 0;
    transition: transform 0.5s;
    backface-visibility: hidden;
}
.switch-panstatus .back-panstatus {
    position: relative;
    transform: rotateY(-180deg);
    right: 0;
    top: -11.5rem;
    max-height: 100%;
    min-height: 25px;
    height: 25px;
}

.k-widget.k-window.windowWrapper.fillinglistwindowrapper {
    p.selectcell_fs {
        font-size: 12px;
        font-weight: 500;
        color: #212529;
    }
    .k-window-titlebar.k-dialog-titlebar {
        padding: 12px 10px 15px 10px;
        background-color: #eee;
    }

        .StartFiling_title {
            width: auto;
            text-align: right;
            margin-left: auto;
            float: right;
    
            button.btn.btn-default.StartFiling_btn {
                background-color: #31719b;
                border-radius: 40px;
                color: #fff;
                padding: 1px 10px 3px;
                font-size: 12px;
                font-weight: 500;
                border: 0;
                position: relative;
                top: 0;
            }
        }

    table.table.table-striped.stratfiling_tb {

        thead tr th {
            border-top: 0;
            font-size: 12px;
            text-align: center;
            font-weight: 500;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 0;
        }

        tr td {
            font-size: 11px;
            padding: 0.25rem 0.75rem;
            font-weight: 500;
            .link {
                color: #31719b!important;
                cursor: pointer;
            }
            span.startfiling_subtextfs{
                color: #689e39;
                font-size: 8px;
                font-weight: 500;
                display: block;
                text-align: right;
            }
        }

    }

}


  /*    ----------- Dashboard Impr css     ----------   */
  ul.allchallan_listbox {
    position: relative;
    margin: 4rem auto auto;

    &  li {
        line-height: 26px;
        font-size: 14px;
        font-weight: 500;

        & span.allchallan_listbox_bullticon_blue {
            color: #008000;
            padding: 0px 11px 0px 0px;
        }

        & span.allchallan_listbox_textfs {
            font-size: 12px;
            font-weight: 500;
        }
        & span.allchallan_listbox_bullticon_red {
            color: #ff0000;
            padding: 0px 11px 0px 0px;
        }
        & span.allchallan_listbox_bullticon_amber {
            color: #ff9802;
            padding: 0px 11px 0px 0px;
        }
    }
}



/*----- total deductee no card css -----*/
fieldset.gen_emailtdscreate_fs {
    border: 1px solid #fc7d72;
    &.tds_tcs_ceritfacte{
        border: 1px solid #31719b;
    }

    & legend.gen_emailtdscreate_leg {
        width: auto;

        & h4.gen_emailtdscreate_titlefs {
            font-size: 1rem;
            font-weight: 500;
            position: relative;
            top: 1px;
        }
    }

    & .dash_panstatus_comp_bordertdscert {
        background-image: url('../../images/rightsideshape.png');
        background-size: cover;
        position: absolute;
        display: block;
        height: 30px;
        width: 30px;
        top: auto;
        margin-top: -21px;
        right: 10px;
    }
}


.totaldeductee_chartbox img {
    max-width: 100%;
    min-width: 80%;
    width: 80%;
}

span.pansearchgif_box img {
   
    position: relative;
}

.card.panst_panverification_cardbox {
    min-width: 60%;
    width: 60%;
    max-width: 100%;
    padding: 5px 5px;
    color: #31719b;
    box-shadow: 0px 2px 2px #1c1c1c;
    background: linear-gradient(357deg, rgb(239 255 18 / 52%) 33%, rgb(255, 255, 255));

    & h4.panst_panverification_totalnofs {
        font-size: 1rem;
        font-weight: 700;
    }

    & p.panst_panverification_total_textfs {
        font-weight: 500;
        font-size: 12px;
    }
    
}


p.panst_panverification_totalnofs {
    font-weight: 500;
    font-size: 12px;
}

.card.panst_panverification_cardbox:hover {
    color: #333;
    background: linear-gradient(357deg, #f6ffde 33%, rgb(255, 255, 255));
    box-shadow: 0 6px 3px 0 rgba(75, 62, 62, 0.7098039216);
    border: 1px solid #59bdff;
}


.quterliabilitypaid_cardtext {
   margin: auto;
   right:3rem;

    & span.quterliabilitypaid_cardtitlefs {
        font-size: 14px;
        font-weight: 500;
    }
}
.quterliabilitypaid_toppadd {
    position: relative;
    top: 6px;
}
span.quterliabilitypaid_rightpadd {
    position: relative;
    right: 3rem;
}

.form_12ba_heading {
    width: 100%;
    font-size: 12px;
}


/* media code --------*/

@media (max-width: 430px){
    
    p.noredords_datainner {
        padding-left: 2rem !important;
    }
    p.noredords_databottom {
        padding-left: 0rem !important;
    }

    span.quterliabilitypaid_rightpadd {
        position: relative;
        right: 0rem;
    }
    .quterliabilitypaid_cardtext {
        margin: auto;
        right: 0rem;
    }
    .quarter_chartbox.returndash_liability_chartbox .returndashboard_liabilitychartbox_width {
        min-width: auto;
        width: auto;
        max-width: 100%;
        overflow-y: auto;
    }
    .totaldeductee_chartbox .totaldeductee_chartbox_width {
        min-width: 300px;
        width: 300px;
        max-width: 100%;
    }
    .returndashboard_sectionwisem_chartbox_width {
        min-width: auto;
        width: auto;
        max-width: 100%;
        overflow-y: auto;
    }

    .deductordashboadscss fieldset.Deductor_dashboard_fs span.Deductor_dashboard_qbox {
        position: relative;
        top: -25px;
    }
    .deductordashboadscss fieldset.dash_createdeductee_fs {
        border: 1px solid #fc7d72;
        height: 100% !important;
    }
    .deductordashboadscss fieldset.dash_quarterliability_fs legend.dash_quarterliability_lg h4.dash_quarterliability_tital {
        font-size: 1rem;
    }

}

@media (min-width: 431px) and (max-width: 540px){
    
    p.noredords_datainner {
        padding-left: 2rem !important;
    }
    p.noredords_databottom {
        padding-left: 0rem !important;
    }

    span.quterliabilitypaid_rightpadd {
        position: relative;
        right: 0rem;
    }
    .quterliabilitypaid_cardtext {
        margin: auto;
        right: 0rem;
    }
    .quarter_chartbox.returndash_liability_chartbox .returndashboard_liabilitychartbox_width {
        min-width: auto;
        width: auto;
        max-width: 100%;
        overflow-y: auto;
    }
    .totaldeductee_chartbox .totaldeductee_chartbox_width {
        min-width: 300px;
        width: 300px;
        max-width: 100%;
    }
    .returndashboard_sectionwisem_chartbox_width {
        min-width: auto;
        width: auto;
        max-width: 100%;
        overflow-y: auto;
    }

    .deductordashboadscss fieldset.Deductor_dashboard_fs span.Deductor_dashboard_qbox {
        position: relative;
        top: -25px;
    }
    .deductordashboadscss fieldset.dash_createdeductee_fs {
        border: 1px solid #fc7d72;
        height: 100% !important;
    }
    .deductordashboadscss fieldset.dash_quarterliability_fs legend.dash_quarterliability_lg h4.dash_quarterliability_tital {
        font-size: 1rem;
    }

}
@media (min-width: 541px) and (max-width: 767px){
    
    p.noredords_datainner {
        padding-left: 2rem !important;
    }
    p.noredords_databottom {
        padding-left: 0rem !important;
    }

    span.quterliabilitypaid_rightpadd {
        position: relative;
        right: 0rem;
    }
    .quterliabilitypaid_cardtext {
        margin: auto;
        right: 0rem;
    }
    .quarter_chartbox.returndash_liability_chartbox .returndashboard_liabilitychartbox_width {
        min-width: auto;
        width: auto;
        max-width: 100%;
        overflow-y: auto;
    }
    .totaldeductee_chartbox .totaldeductee_chartbox_width {
        min-width: 300px;
        width: 300px;
        max-width: 100%;
    }
    .returndashboard_sectionwisem_chartbox_width {
        min-width: auto;
        width: auto;
        max-width: 100%;
        overflow-y: auto;
    }

    .deductordashboadscss fieldset.Deductor_dashboard_fs span.Deductor_dashboard_qbox {
        position: relative;
        top: -25px;
    }
    .deductordashboadscss fieldset.dash_createdeductee_fs {
        border: 1px solid #fc7d72;
        height: 100% !important;
    }
    .deductordashboadscss fieldset.dash_quarterliability_fs legend.dash_quarterliability_lg h4.dash_quarterliability_tital {
        font-size: 1rem;
    }

}

@media (min-width: 768px) and (max-width: 913px){
   
    p.noredords_datainner {
        padding-left: 2rem !important;
    }
    p.noredords_databottom {
        padding-left: 0rem !important;
    }

    span.quterliabilitypaid_rightpadd {
        position: relative;
        right: 0rem;
    }
    .quterliabilitypaid_cardtext {
        margin: auto;
        right: 0rem;
    }

    .quarter_chartbox.returndash_liability_chartbox .returndashboard_liabilitychartbox_width {
        min-width: auto;
        width: auto;
        max-width: 100%;
        overflow-y: auto;
    }
    .totaldeductee_chartbox .totaldeductee_chartbox_width {
        min-width: 300px;
        width: 300px;
        max-width: 100%;
    }
    .returndashboard_sectionwisem_chartbox_width {
        min-width: auto;
        width: auto;
        max-width: 100%;
        overflow-y: auto;
    }

}
@media (min-width: 914px) and (max-width: 1025px){
    p.noredords_datainner {
        padding-left: 2rem !important;
    }
    p.noredords_databottom {
        padding-left: 0rem !important;
    }

    span.quterliabilitypaid_rightpadd {
        position: relative;
        right: 0rem;
    }
    .quterliabilitypaid_cardtext {
        margin: auto;
        right: 0rem;
    }

    .quarter_chartbox.returndash_liability_chartbox .returndashboard_liabilitychartbox_width {
        min-width: auto;
        width: auto;
        max-width: 100%;
        overflow-y: auto;
    }
    .totaldeductee_chartbox .totaldeductee_chartbox_width {
        min-width: 200px;
        width: 200px;
        max-width: 100%;
        overflow: auto;
    }
    .returndashboard_sectionwisem_chartbox_width {
        min-width: auto;
        width: auto;
        max-width: 100%;
        overflow-y: auto;
    }

}
@media (min-width: 1026px) and (max-width: 1171px){
   
    p.noredords_datainner {
        padding-left: 2rem !important;
    }
    p.noredords_databottom {
        padding-left: 0rem !important;
    }

    span.quterliabilitypaid_rightpadd {
        position: relative;
        right: 0rem;
    }
    .quterliabilitypaid_cardtext {
        margin: auto;
        right: 0rem;
    }

    .quarter_chartbox.returndash_liability_chartbox .returndashboard_liabilitychartbox_width {
        min-width: auto;
        width: auto;
        max-width: 100%;
        overflow-y: auto;
    }
    .totaldeductee_chartbox .totaldeductee_chartbox_width {
        min-width: 200px;
        width: 200px;
        max-width: 100%;
        overflow: auto;
    }
    .returndashboard_sectionwisem_chartbox_width {
        min-width: auto;
        width: auto;
        max-width: 100%;
        overflow-y: auto;
    }

}
@media (min-width: 1172px) and (max-width: 1450px){
    
    p.noredords_datainner {
        padding-left: 4rem !important;
    }
    p.noredords_databottom {
        padding-left: 2rem !important;
    }

    h4.pancomp_titlefixed_screens {
        font-size: 12.5px !important;
    }
    .deductordashboadscss fieldset.dash_panstatus_comp_fs button.btn.btn-default.dash_panstatus_lefsibgl_btnbgclr {
       
        margin-right: 2px !important;
    }
    fieldset.gen_emailtdscreate_fs legend.gen_emailtdscreate_leg h4.gen_emailtdscreate_titlefs {
        font-size: 13.5px;
        font-weight: 500;
        position: relative;
        top: 1px;
    }

    span.quterliabilitypaid_rightpadd {
        position: relative;
        right: 1rem;
    }
    .quterliabilitypaid_cardtext {
        margin: auto;
        right: 1rem;
    }
    
}
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #ffffff !important;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.deductorinfo_box {
  display: inline-block;
  width: 100%;
}

.card.comp_health_revistbox {

  background-color: #f29d95;
  font-size: 12px;
  font-weight: 500;
  padding: 3px 5px 3px 5px;
  display: inline-block;
  color: black;
}

span.emailrighticon {
  position: relative;
  top: 4px;
  cursor: pointer;
}

.card.insertcardbox {
  padding: 3px 3px;
  margin-top: 4px;
}

.card.insertcardboxright {
  padding: 3px 3px;
  margin-top: 4px;

}

.interestcardbox_heightfixed {
  overflow-y: auto;
  max-height: 540px;
  overflow-x: hidden;
}


.attendgifbox {
  position: absolute;
  top: -37px;
}

.comphealth_editicon {
  background-color: #f2f2f2;
  padding: 0px 3px 3px 5px;
  border-radius: 40px;
  cursor: pointer;
  position: relative;
  margin-left: 10px;
  top: -2px;

}

.comphealth_editicon img {
  width: 12px;
  height: 11px;

}

.card.retunoverview_cardbox {
  display: block;
  padding: 10px 5px 10px 5px;
  font-size: 12px;
  font-weight: 500;
}

span.returnview_textfs {
  background-color: #31719b;
  color: #fff;
  padding: 4px 5px 5px 5px;
}

span.deducteetrns_textfsbgclr {
  font-size: 12px;
  font-weight: 500;
  padding: 4px 5px 5px 5px;
}

span.retunbox_bgclrgrey {
  background-color: #fff4f5;
}

span.deducteetrns_text {
  padding: 4px 12px 5px 5px;
  margin-right: 30px;
  line-height: 36px;
}

span.deducteetrns_amt {
  padding: 4px 5px 5px 5px;
}

span.deducteetrns_vertline {
  padding: 4px 5px 5px 5px;
  margin-right: 25px;
  margin-left: 25px;
  text-align: center;
}


fieldset {
  display: block;
  margin: 9px 2px 2px !important;
  background-color: #fff;
  padding: 0.35em 0.75em 0.625em !important;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

fieldset.comp_health_analysisreport_fs {
  border: 1px solid #31719b;
}

fieldset.comp_health_analysisreport_fs legend.comp_health_analysisreport_leg {
  width: auto;
}

fieldset.comp_health_analysisreport_fs legend.comp_health_analysisreport_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0px 5px;
}

fieldset.comp_health_pansumm_fs {
  border: 2px dashed #a4a4a4;
  background-color: #ecf6fe;
}

fieldset.comp_health_pansumm_fs legend.comp_health_pansumm_leg {
  width: auto;
}

fieldset.comp_health_pansumm_fs legend.comp_health_pansumm_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0px 5px;
  top: 1px;
  position: relative;
}

fieldset.comp_health_pansumm_fs .rip::before,
fieldset.comp_health_pansumm_fs .rip::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 10px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 50%;
  top: 29%;
}

fieldset.comp_health_pansumm_fs .rip::before {
  left: 14px;
}

fieldset.comp_health_pansumm_fs .rip::after {
  right: -4px;
  transform: translate(-50%, -50%) rotate(225deg);
}

table.table.table-borderless.comp_health_pansumm_tb tr td {
  font-size: 11px;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
  vertical-align: middle;
}

table.table.table-borderless.comp_health_pansumm_tb tr td a.textclr_blue {
  color: #31719b;
  font-weight: 700;
}

table.table.table-borderless.comp_health_pansumm_tb tr td .textdisable {
  color: #7d7d7d;
}

.Unverified_rip::before,
.Unverified_rip::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 10px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 50%;
  top: 73%;
}

.Unverified_rip::before {
  left: 14px;
}

.Unverified_rip::after {
  right: -4px;
  transform: translate(-50%, -50%) rotate(225deg);
}

.feesint_rip::before,
.feesint_rip::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 10px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 50%;
  top: 20%;
}

.feesint_rip::before {
  left: 14px;
}

.feesint_rip::after {
  right: -4px;
  transform: translate(-50%, -50%) rotate(225deg);
}

.unmaptrans_rip::before,
.unmaptrans_rip::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 10px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 50%;
  top: 46%;
}

.unmaptrans_rip::before {
  left: 14px;
}

.unmaptrans_rip::after {
  right: -4px;
  transform: translate(-50%, -50%) rotate(225deg);
}

.deductee_rip::before,
.deductee_rip::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 10px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 50%;
  top: 54%;
}

.deductee_rip::before {
  left: 14px;
}

.deductee_rip::after {
  right: -4px;
  transform: translate(-50%, -50%) rotate(225deg);
}

.verifytrancesuncom_rip::before,
.verifytrancesuncom_rip::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 10px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 50%;
  top: 74%;
}

.verifytrancesuncom_rip::before {
  left: 14px;
}

.verifytrancesuncom_rip::after {
  right: -4px;
  transform: translate(-50%, -50%) rotate(225deg);
}

.Unverifiederrorscr_rip::before,
.Unverifiederrorscr_rip::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 10px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 50%;
  top: 68%;
}

.Unverifiederrorscr_rip::before {
  left: 14px;
}

.Unverifiederrorscr_rip::after {
  right: -4px;
  transform: translate(-50%, -50%) rotate(225deg);
}

td.txtclr_red {
  color: red;
}

fieldset.comp_health_reviewcheck_fs {
  border: 1px solid #fc7d72;
}

fieldset.comp_health_reviewcheck_fs .comp_health_reviewcheck_inndev {
  display: block;
}

fieldset.comp_health_reviewcheck_fs .comp_health_reviewcheck_leftside {
  float: left;
}

fieldset.comp_health_reviewcheck_fs .comp_health_reviewcheck_leftside h4 {
  font-size: 14px;
  font-weight: 500;
  top: 5px;
  padding: 0px 5px;
  position: relative;
}

fieldset.comp_health_reviewcheck_fs .comp_health_reviewcheck_innerhieghtdiv {
  height: 1px;
  width: 100%;
  position: relative;
}

fieldset.comp_health_reviewcheck_fs .comp_health_reviewcheck_rightsidediv {
  float: right;
}

fieldset.comp_health_reviewcheck_fs .comp_health_reviewcheck_topbormiddle {
  border-top: 1px solid #fc7d72;
  display: flex;
  position: relative;
  margin-top: 17px;
}

fieldset.comp_health_reviewcheck_fs button.btn.btn-default.revalidbtnbgclr {
  background-color: #31719b;
  color: #fff;
  border: 0px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 40px;
  padding: 2px 6px 4px 6px;
  top: -5px;
  position: relative;
}

td.text_red {
  color: red;
}

fieldset.comp_health_reviewcheck_fs.borderblue {
  border: 1px solid #31719b;
}

legend.comp_health_reviewcheck_leg {
  width: auto;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

legend.comp_health_reviewcheck_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
  top: 1px;
  position: relative;
}



.card.tdscomp_unmappedchallan {
  background-color: #fff9fa;
  border: 1px solid #c8c8c8;
  padding: 8px 10px;
  cursor: pointer;
}


.card.tdscomp_unmappedchallan:hover,
.card.tdscomp_unmappedchallan:focus {
  /* box-shadow: 0 0.5em 0.5em -0.4em var(--hover); */
  transform: translateY(-0.25em);
  box-shadow: inset 0 -3.25em 0 0 var(--hover);
  transition: 0s;
}


.card.tdscomp_unmappedchallan {
  color: var(--color);
  transition: 0.25s;

  &:hover,
  &:focus {
    border-color: var(--hover);
    color: #333;
    background-color: #f2ffd3c9;
    box-shadow: 0px 3px 4px 0px #4b3e3eb5;
    border: 1px solid #59bdff;
  }
}

p.tdscomp_liabilitt_imgbox {
  text-align: center;
  cursor: pointer;
  margin-bottom: 5px;
}

h4.tdscomp_liabilitt_textfs {
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
  padding-bottom: 5px;
}

p.tdscomp_unmappedchallan_textfs {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0px;
  line-height: 22px;
}

span.tdscomp_trans_textclr {
  color: #987474;
}



.comp_health_reviewcheck_topbormiddle.borderblue {
  border-top: 1px solid #31719b !important;
}

.k-widget.k-grid.table.table-striped.tdscomp_tb thead tr th {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 5px;
  border-top: 0px;
}

.k-widget.k-grid.table.table-striped.tdscomp_tb.mb-0 tr td {
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  font-size: 11px;
  vertical-align: middle;
}

button.btn.btn-default.allocateint_btnbgclr {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 40px;
  color: #fff;
  border: 0px;
  padding: 3px 5px 5px;
  font-size: 12px;
  font-weight: 500;
  width: 88px;
}

tr.tdscomp_tbtoprow th {
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
}

tr.tdscomp_tb2row th {
  padding-top: 0px !important;
  font-size: 9px !important;
  font-weight: 500;
}


table.table.table-striped.tdscomp_tb tr.tdscomp_rowshadow {
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 0 6px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 0 rgba(60, 64, 67, 0.15);
  border-bottom: 1px inset rgba(60, 64, 67, 0.15);
  position: relative;
}

table.table.table-striped.tdscomp_tb tr.tdscomp_rowshadow td {
  font-weight: 700 !important;
  font-size: 12px !important;
  color: #31719b;
  background-color: #ebf5e0 !important;
}

fieldset.sectionwise_fs {
  border: 1px solid #fc7d72;
}

fieldset.sectionwise_fs legend.sectionwise_leg {
  width: auto;
}

fieldset.sectionwise_fs legend.sectionwise_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0px 5px;
  top: 3px;
  position: relative;
}

fieldset.deductee_bgclrlightyell {
  background-color: #fff8ee;
}

fieldset.challanwise_summ_fs {
  border: 1px solid #31719b;
}

fieldset.challanwise_summ_fs legend.challanwise_summ_leg {
  width: auto;
}

fieldset.challanwise_summ_fs legend.challanwise_summ_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0px 5px;
  top: 2px;
  position: relative;
}

p.verifytrancesuncom_textfs {
  font-weight: 500;
  font-size: 12px;
  color: #31719b;
  cursor: pointer;
}

/* new css add 30/10/23*/
td.text_blueunderline {
  color: #31719b;
  text-decoration: underline;
  cursor: pointer;
}

.modal.comp_health_tdscomp_modalbox.show .modal-dialog {
  margin: 2rem auto 0rem auto;
  max-width: 99%;
}

.modal-header.comp_health_tdscomp_header {
  padding: 0px 15px;
  background-color: #eee;
}

.modal-header.comp_health_tdscomp_header h4.modal-title {
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  padding: 13px 15px 21px 15px;
}

.Interest_feescard span.Interest_fees_titlefs {
  font-size: 14px;
  font-weight: 500;
}

tr.comphealth_deducttrans_rowgradienteffect {
  background-color: #d7faae;
  background-image: linear-gradient(180deg, #fff, #d7faae);
}

/*---------Duble errow collapse css ------------*/


.expand-button.Interest_feescollapse_icon {
  position: relative;
}

.accordion-toggle.expand-button.Interest_feescollapse_icon:after {
  position: absolute;
  left: 0.2rem;
  top: 60%;
  transform: translate(0, -50%);
  content: url(../../images/dangleup_icon.png);
  cursor: pointer;
}

.accordion-toggle.collapsed.expand-button.Interest_feescollapse_icon:after {
  content: url(../../images/dangledown_icon.png);
}

/*---------Duble errow collapse css ------------*/


.expand-button.latededintinner_collapseicon {
  position: relative;
}

.accordion-toggle.expand-button.latededintinner_collapseicon:after {
  position: absolute;
  left: 0.2rem;
  top: 60%;
  transform: translate(0, -50%);
  content: url(../../images/angldownicon.png);
  cursor: pointer;
}

.accordion-toggle.collapsed.expand-button.latededintinner_collapseicon:after {
  content: url(../../images/anglupicon.png);
}

/*----plus collpase icon -----*/

.expand-button.latededintinner3_collapseicon {
  position: relative;
}

.accordion-toggle.expand-button.latededintinner3_collapseicon:after {
  position: absolute;
  left: 0.2rem;
  top: 60%;
  transform: translate(0, -50%);
  content: url(../../images/accor_mins_icon.png);
  cursor: pointer;
}

.accordion-toggle.collapsed.expand-button.latededintinner3_collapseicon:after {
  content: url(../../images/accor_plus_icon.png);
}

/*----plus collpase icon -----*/



.card.latdedint_card {

  background-color: #fff;
  padding: 0px 0px;
  border-radius: 0px;
}

.card.latdedint_card tr.lateded_toprowtextfs td {
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  font-size: 13px;
}

.card.latdedint_innercard {
  border-radius: 0px;
}

table.table.table-striped.Deducteetrans_deducteewise_tb thead tr th {
  font-size: 12px;
  font-weight: 500;
  border-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  vertical-align: baseline;
}

table.table.table-striped.Deducteetrans_deducteewise_tb tr td {
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  font-size: 11px;
}

tr.tdscomp_rowbgclr_white {
  background-color: #fff !important;
}

tr.tdscomp_rowbgclr_whiteb {
  background-color: #fff !important;
}



/*----- rang slider  ---*/
.comphealth_rangesliderbox {
  text-align: left;
  top: -1px;
  position: relative;
}

p.comphealth_alluncon_textfs {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  padding-left: 30px;
}

.comphealth_rangesliderbox .double_range_slider_box {
  position: relative;
  width: 100%;
  height: auto;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.comphealth_rangesliderbox .double_range_slider {
  width: 100%;
  height: 2px;
  position: relative;
  background-color: #dddddd;
  border-radius: 20px;
}

.comphealth_rangesliderbox .range_track {
  height: 100%;
  position: absolute;
  border-radius: 20px;
  background-color: #95d564;
}

.comphealth_rangesliderbox input {
  position: absolute;
  width: 100%;
  height: 5px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  top: 50%;
  transform: translateY(-50%);
}

.comphealth_rangesliderbox input::-webkit-slider-thumb {
  height: 12px;
  width: 13px;
  border-radius: 50%;
  border: 3px solid #cbffa3;
  background-color: #95d564;
  pointer-events: auto;
  -webkit-appearance: none;
  cursor: pointer;
  margin-bottom: 1px;
}

.comphealth_rangesliderbox input::-moz-range-thumb {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 3px solid #cbffa3;
  background-color: #95d564;
  pointer-events: auto;
  -moz-appearance: none;
  cursor: pointer;
  margin-top: 30%;
}

.comphealth_rangesliderbox .minvalue {
  position: relative;
  padding: 0px 5px;
  background: #0e5f59;
  border-radius: 1rem;
  color: white;
  top: -8px;
  bottom: 0;
  width: auto;
  min-width: auto;
  max-width: fit-content;
  transform: translate(0, -100%);
  left: 0;
  font-size: 12px;
  transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: left, transform;
}

.comphealth_rangesliderbox .maxvalue {
  position: absolute;
  padding: 0px 5px;
  background: #0e5f59;
  border-radius: 1rem;
  color: white;
  width: auto;
  min-width: auto;
  max-width: -moz-fit-content;
  max-width: fit-content;
  top: -11px;
  transform: translate(0, 100%);
  right: 0;
  font-size: 12px;
  transition: right 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: right, transform;
  z-index: 2;
}


/*----- rang slider  ---*/

/* Inconsum card box css */
.card.comphealth_uncons_card {
  border: 1px solid #786e6e;
  border-radius: 0px;
}

.card.comphealth_uncons_card .card-header.comphealth_uncons_cardheader {
  background-color: #31719b;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 0px 5px;
  border-radius: 0px;
}

span.comphealth_uncons_leftside_text {
  padding: 4px 1px;
}

span.comphealth_uncons_amt {
  padding: 5px 5px;
}

span.editiconbgclr {
  padding: 0px 1px;
  top: 1px;
  position: relative;
  cursor: pointer;
}


.card.comphealth_uncons_card .card-header.comphealth_uncons_cardheader span.comphealth_uncons_rightamt {
  font-weight: 700;
  top: 3px;
  position: relative;
  padding-top: 8px;
}

.card.comphealth_uncons_card .card-body.comphealth_uncons_body {
  padding: 5px 5px 5px 5px;
}

.card.comphealth_uncons_card .card-body.comphealth_uncons_body .comphealth_uncons_innertextfs {
  font-size: 12px;
  font-weight: 500;
}

.card.comphealth_uncons_card .card-body.comphealth_uncons_body .comphealth_uncons_innertextfs span.rightside_amtfs {
  font-weight: 700;
}

.card.comphealth_uncons_card .card-body.comphealth_uncons_body .comphealth_uncons_innertextfs span.rightside_textbold {
  font-weight: 700;
}

.card.comphealth_uncons_card .card-body.comphealth_uncons_bodybgxlr_lightblue {
  background-color: #bbdefb;
}

table.comphealth_deducttrans_trtd_innertablefixed {
  width: 100%;
}

table.comphealth_deducttrans_trtd_innertablefixed tbody {
  display: block;
  max-height: 128px;
  overflow-x: auto;
}

table.comphealth_deducttrans_trtd_innertablefixed tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table.comphealth_deducttrans_trtd_innertablefixed tbody tr.comphealth_deducttrans_trtd_bgclr_yell {
  background-color: #fff !important;
}


.modal.adjustable_amt_against_modalbox.show {
  background-color: #22222294;
}

.modal.adjustable_amt_against_modalbox.show .modal-dialog {
  max-width: 750px;
  margin: 3rem auto auto auto;
}

.modal-header.adjustable_amt_against_header {
  background-color: #eee;
  padding: 0px 15px;
}

.modal-header.adjustable_amt_against_header h4.modal-title {
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 0px 4px 0px;
  width: 100%;
}

.modal-header.adjustable_amt_against_header button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.row.adjustableamt_frmrow label {
  font-size: 12px;
  color: #777171;
  font-weight: 500;
}

.row.adjustableamt_frmrow input.form-control {

  font-size: 12px;
  font-weight: 500;

}

.row.adjustableamt_frmrow input.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0, 123, 255, .25);
}

button.btn.btn-default.adjustableamt_donebtnbgclr {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 40px;
  color: #fff;
  border: 0px;
  padding: 5px 22px 6px 22px;
  font-size: 12px;
  font-weight: 500;
}


.modal.latedeductint_modalbox.show .modal-dialog {
  margin: 2rem auto auto auto;
  max-width: 840px;
}

.modal.latefeesmodalwidth.show .modal-dialog {
  max-width: 610px;
}

.modal-header.latedeductint_header {
  background-color: #eee;
  padding: 0px 15px;
}

h4.modal-title.latedeductint_titlefs {
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 0px 4px 0px;
  width: 100%;
}

span.latededuct_amtfs {
  float: right;
  /* color: #31719b; */
}

legend.shortdeduction_leg {
  padding-bottom: 0px !important;
  margin-bottom: 0px;
}

fieldset.pan_sumamry_fs {
  border: 1px solid #ddd;
  background-color: #ffffef;
}

fieldset.pan_compliance_fs {
  border: 1px solid #ddd;
  background-color: #ffffef;
}

legend.pan_sumamry_leg {
  width: auto;
  margin-bottom: 0px;
}

legend.pan_sumamry_leg h4 {
  font-size: 1rem;
  font-weight: 500;
  top: 2px;
  position: relative;
}

table.table.table-borderless.pan_sumamry_table tr td {
  padding: 0.25rem 0.2rem;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
}

table.table-borderless.pan_sumamry_table {
  background-color: #ffffef;
}

span.textnofsclr {
  font-size: 14px;
  font-weight: 500;
}

span.invlid_textfsclr {
  color: #f54337;
  font-weight: 700;
  padding-right: 2px;
}

span.operative_textfsclr {
  color: #8bc34a;
  font-weight: 700;
  padding-right: 8px;
}

.fixednowbox {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 40px;
  color: #fff;
  border: 0px;
  padding: 0px 5px 1px;
  font-size: 9px;
  font-weight: 500;
  width: 45px;
  text-align: center;
}

span.pannot_textfsclr {
  color: #32729b;
}

fieldset.shortdeduction_fs {
  border: 1px solid #ddd;
  margin-top: 4px !important;
  padding: 5px 5px !important;
  background-color: #ffffef;

}

legend.shortdeduction_leg .shortdeduction_inndev {
  display: block;
}

.shortdeduction_leftside {
  float: left;
}

.shortdeduction_leftside h4 {
  font-size: 1rem;
  font-weight: 500;
  top: 7px;
  position: relative;
}

.shortdeduction_innerhieghtdiv {
  height: 1px;
  width: 100%;
  position: relative;
}

.shortdeduction_rightsidediv {
  float: right;
  position: relative;
  top: -7px;
}

.shortdeduction_middborder {
  border-top: 1px solid #ddd;
  display: flex;
  position: relative;
  margin-top: 17px;
}

span.shortdeduction_amttextclr_red {
  color: #f54337;
  font-size: 14px;
  font-weight: 500;
}

span.shortdeduction_transtextfs {
  font-size: 14px;
  font-weight: 500;
  color: #666b70;
}



fieldset.complincheck_fs {
  border: 1px solid #31719b;
}

legend.complincheck_leg .complincheck_inndev {
  display: block;
}

.complincheck_leftside {
  float: left;
}

.complincheck_leftside h4 {
  font-size: 1rem;
  font-weight: 500;
  top: 8px;
  position: relative;
}

.complincheck_innerhieghtdiv {
  height: 1px;
  width: 100%;
  position: relative;
}

.complincheck_rightsidediv {
  float: right;
  position: relative;
  top: -5px;
}

.complincheck_topbormiddle {
  border-top: 1px solid #31719b;
  display: flex;
  position: relative;
  margin-top: 19px;
}

button.btn.btn-default.complincheck_viewbtn {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 40px;
  color: #fff;
  border: 0px;
  padding: 3px 10px 5px;
  font-size: 12px;
  font-weight: 500;
}

td.textblueclr {
  color: #31719b;
  cursor: pointer;
}

fieldset.sectionwisechalla_fs {
  border: 1px solid #31719b;
}

legend.sectionwisechalla_leg {
  width: auto;
}

legend.sectionwisechalla_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
  top: 2px;
  position: relative;
}

.cardbox_contentborder {
  border: 1px dashed #31719b;
  background-color: #ffffff;
  border-radius: 40px;
  padding: 2px 2px;
  cursor: pointer;
}


.cardbox_contentborder:hover,
.cardbox_contentborder:focus {
  /* box-shadow: 0 0.5em 0.5em -0.4em var(--hover); */
  transform: translateY(-0.25em);
  box-shadow: inset 0 -3.25em 0 0 var(--hover);
  transition: 0s;
}


.cardbox_contentborder {
  color: var(--color);
  transition: 0.25s;

  &:hover,
  &:focus {
    border-color: var(--hover);
    color: #333;
    background-color: #f2ffd3c9;
    box-shadow: 0px 3px 4px 0px #4b3e3eb5;
    border: 1px solid #59bdff;
  }
}

.cardbox_contentborder:hover span.pannot_textfsclr {
  color: #fff;
}

span.invlid_textfsclr_yell {
  color: #ffb600;
  font-weight: 700;
  padding-right: 2px;
}


.modal.Validate_lower_ded_certif_modalbox.show .modal-dialog {
  margin: 3rem auto auto auto;
  max-width: 1150px;
}

.modal-header.Validate_lower_ded_certif_header {
  background-color: #eee;
  padding: 0px 15px;
}

.modal-header.Validate_lower_ded_certif_header h4.modal-title {
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 0px 4px 0px;
}

table.table.table-striped.Validate_lower_ded_certif_tb thead tr th {
  font-size: 12px;
  font-weight: 500;
  border-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  vertical-align: baseline;
}

table.table.table-striped.Validate_lower_ded_certif_tb tr td {
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  font-size: 11px;
  vertical-align: baseline;
}

textarea.form-control.Validate_lowerdedcertif_terxtarea {
  height: calc(1.2em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 500;
}

td.noneditable_cellbox {
  background-color: #dee2e6;
  border-bottom: 1px solid #f2f2f2;
}

td.validtextclr_red {
  color: #fc7d72;
}

td.Expiredtextclr_green {
  color: green;
}

tr.Validate_lower_ded_certif_rowbgclr_grey {
  background-color: #f2f2f2;
}
td.Validatelower_tdbox_bgclr_coral {
  background-color: #fff;
}
td.validatelower_boderright {
  border-right: 1px solid #ddd !important;
}

td.textclrblur_underline {
  color: #31719b;
  text-decoration: underline;
  cursor: pointer;
}

td.Expiredtextclr_invalid {
  color: red;
}

tr.Validate_lower_ded_certif_rowbgclr_white {
  background-color: #fff !important;
}

button.btn.btn-default.ReverifyTransbtnbgclr {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 40px;
  color: #fff;
  border: 0px;
  padding: 4px 10px 6px;
  font-size: 12px;
  font-weight: 500;
}

button.btn.btn-default.ReverifyTransbtnbgclrdone {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 40px;
  color: #fff;
  border: 0px;
  padding: 4px 20px 6px;
  font-size: 12px;
  font-weight: 500;
}

span.trans_textfs {
  font-size: 10px;
  font-weight: 500;
}

p.starsave_amttextfsclr {
  color: #fc7d72;
  font-size: 12px;
  font-weight: 500;
}

button.btn.btn-default.Interestoffset_btnauto {
  background-color: #f8857d;
  border-color: #f8857d;
  border-radius: 40px;
  color: #fff;
  border: 0px;
  padding: 5px 10px 7px;
  font-size: 12px;
  font-weight: 500;
}

button.btn.btn-default.Interestoffset_btnmanual {
  background-color: #f8857d;
  border-color: #f8857d;
  border-radius: 40px;
  color: #fff;
  border: 0px;
  padding: 5px 10px 7px;
  font-size: 12px;
  font-weight: 500;
}

button.btn.btn-default.Interestoffset_btnpay {
  background-color: #f8857d;
  border-color: #f8857d;
  border-radius: 40px;
  color: #fff;
  border: 0px;
  padding: 5px 30px 7px;
  font-size: 12px;
  font-weight: 500;
}

sup.starfs {
  font-size: 12px;
  font-weight: 500;
  vertical-align: middle;
}


.modal.Interest_Offset_auto_modalbox.show .modal-dialog {
  margin: 3rem auto auto auto;
  max-width: 950px;
}

.modal-header.Interest_Offset_auto_header {
  background-color: #eee;
  padding: 0px 15px;
}

.modal-header.Interest_Offset_auto_header h4.modal-title {
  font-size: 1rem;
  width: 100%;
  padding: 4px 0px 4px 0px;
}

.modal-header.Interest_Offset_auto_header h4.modal-title h4.offsetingamt_textfs {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0px;
  padding-top: 4px;
}

.modal-header.Interest_Offset_auto_header button.close {
  width: 6%;
  top: 10px;
  position: relative;
}

.modal-header.Interest_Offset_auto_header button.close:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

button.btn.btn-default.offsetbtnbgclr {
  background-color: #31719b;
  border-radius: 40px;
  padding: 3px 10px 5px 10px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  vertical-align: sub;
  top: 3px;
  position: relative;
}

button.btn.btn-default.offsetbtnbgclr:hover {
  background-color: #ed7a72;
}

table.table.table-striped.offsetingamt_available_tb thead tr th {
  font-size: 12px;
  font-weight: 500;
  border-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  vertical-align: baseline;
}

table.table.table-striped.offsetingamt_available_tb tr td {
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  font-size: 11px;
}

.offsetdone_tdscol_bgclr_yell {
  background-color: #fff9ca;
}

.offsetdone_tdscol_bgclr_green {
  background-color: #9eff9e;
}

.Transferror_head_textclr {
  font-size: 12px;
  font-weight: 500;
}

.Transferror_head_textclr span.dotclrerror {
  color: #fff9ca;
}


.Transfereehead_textclr {
  font-size: 12px;
  font-weight: 500;

}

.Transfereehead_textclr span.dotclrfree {
  color: #9eff9e;
}


.card.offsetdone_succbox {
  padding: 6px 16px;
  background-color: #86efa9;
  border-radius: 5;
  bottom: 0;
  position: absolute;
  z-index: 1030;
  border-top: 1px solid rgba(0, 0, 0, .125) !important;
  right: 15px;
  display: none;
}

.offsetdonesuc_content {
  font-size: 16px;
  font-weight: 500;
}

span.offset_iconfs {
  cursor: pointer;
}



fieldset.comp_health_returnsummary_fs {
  border: 1px solid #31719b;
}

legend.comp_health_returnsummary_inndev {
  display: block;
}

.comp_health_returnsummary_leftside {
  float: left;
}

.comp_health_returnsummary_leftside h4 {
  font-size: 1rem;
  font-weight: 500;
  top: 8px;
  position: relative;
}

.comp_health_returnsummary_innerhieghtdiv {
  height: 1px;
  width: 100%;
  position: relative;
}

.comp_health_returnsummary_rightsidediv {
  float: right;
  position: relative;
  top: -5px;
}

.comp_health_returnsummary_topbormiddle {
  border-top: 1px solid #31719b;
  display: flex;
  position: relative;
  margin-top: 19px;
}

button.btn.btn-default.comp_health_returnsummary_paybtn {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 40px;
  color: #fff;
  border: 0px;
  padding: 3px 20px 5px;
  font-size: 12px;
  font-weight: 500;
}

span.shortageinterest_textfs {
  background-color: #31719a;
  border: 1px solid #ddd;
  padding: 1px 2px 6px 1px;
  border-radius: 40px;
}

span.shortageinterest_textfs a {
  font-size: 12px;
  font-weight: 500;
  color: white;
  padding: 0px 10px;
  vertical-align: middle;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.Interest_feescard {
  display: flex;
}

input.form-control.interestmapping_searchtext {
  font-weight: 500;
  padding: 9px 0 9px 3px !important;
  font-size: 11px;
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: 0 solid #ced4da;
  min-width: 209px;
}

span.input-group-text.interestmapping_search_icon {
  background-color: #fff;
  color: #31719b;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-left-width: 0;
  cursor: pointer;
  padding: 0.375rem 0.2rem;
  border-radius: 0rem 0.25rem 0.25rem 0rem;
}

::placeholder {
  font-size: 11px !important;
  font-weight: 500;
  color: #ddd !important;
}

input.form-control.interestmapping_searchtext:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0, 123, 255, .25);
}

table.table.table-striped.interestmapping_Deducteetrans_deducteewise_tb thead tr th {
  font-size: 12px;
  font-weight: 500;
  border-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  vertical-align: baseline;
  background-color: #31719b;
  color: #fff;
}

table.table.table-striped.interestmapping_Deducteetrans_deducteewise_tb tr td {
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  font-size: 11px;
}



table.table.table-striped.Deducteetrans_deducteewise_innertb03 thead tr th {
  font-size: 12px;
  font-weight: 500;
  border-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  vertical-align: baseline;
  background-color: #fff9c5;
  color: #222;
}



table.table.table-striped.Deducteetrans_deducteewise_innertb03 tr td {
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  font-size: 11px;
  background-color: #e8e4e4;
}

td.tdscomp_rowbgclr_innertb_whitebg {
  background-color: #fff !important;
}

td.tdscomp_rowbgclr_innertb_whitebg.borderleftside {
  border-left: 1px solid #ddd;
}





table.table.table-striped.interestmapping_Deducteetrans_deducteewise_tb tbody.interestmap_tbody {
  display: block;
  max-height: 135px;
  overflow-y: auto;
}

table.table.table-striped.interestmapping_Deducteetrans_deducteewise_tb thead,
tbody.interestmap_tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table.table.table-striped.interestmapping_Deducteetrans_deducteewise_tb thead {
  padding: 0px 10px 0px 0px;
}


span.unlinked_img {
  cursor: pointer;
  vertical-align: text-bottom;
}

.spborder_aftertenrow {
  border-bottom: 4px dashed #d35146;
  position: relative;
  padding: 6px 0px 10px 0px;
  margin-bottom: 17px;
}

.cardbox_modeview_active:hover {
  box-shadow: 1px 7px 13px 0px #4b3e3eb5;
}

.cardbox_modeview_active:hover .card.comphealth_uncons_card {
  box-shadow: 1px 16px 15px -7px rgba(0, 0, 0, 0.46);
  outline-color: #e6db55;
  border: 1px solid #e6db55;
  transition: all 0.3s ease 0s;
  border-width: 1.9px;
  background-color: #ffffe0;
  border-color: #e6db55;
}


.cardbox_modeview_disabledcard .card-header.comphealth_uncons_cardheader {
  background-color: #d2d2d2 !important;
  cursor: grabbing;
}

.cardbox_modeview_disabledcard .card-body.comphealth_uncons_body {
  background-color: #d2d2d2 !important;
  cursor: grabbing;
}

.cardbox_modeview_disabledcard span.editiconbgclr {
  cursor: none;
}

.cardbox_modeview_disabledcard span.unlinked_img {
  cursor: none;
}



.cardbox_modeview_deniededboximg {
  width: 100%;
  height: 100%;
  background-position: center;
  position: absolute;
  top: 0px;
  right: 5px;
  background-size: contain;
  background-color: rgba(0, 0, 0, 0.32);
  cursor: pointer;
}

/* .cardbox_modeview_deniededimg {
  background-image: url(./../../images/denided.png);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left 50% bottom 50%;
  background-position-x: center;
  background-position-y: center;
  cursor: pointer;
} */

.cardbox_modeview_deniededimg {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  top: 37%;
  transform: rotate(327deg);
  cursor: pointer;
}

.modal.consume_modalbox.show {
  background-color: #000000a8;
}

.modal.consume_modalbox.show .modal-dialog {
  max-width: 391px;
  margin: 15rem auto 0rem auto;
}

p.consume_textfs {
  font-size: 12px;
  font-weight: 500;
}

button.btn.btn-default.consum_yesbtnbgclr {
  background-color: #31719b;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 40px;
  border: 0px;
  padding: 4px 20px 6px 20px;
}

button.btn.btn-default.consum_nobtnbgclr {
  background-color: #31719b;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 40px;
  border: 0px;
  padding: 4px 20px 6px 20px;
}


/*--------left side modal comp summ --------*/


/*------ right side certifbox css-----------*/
.Computation_summary_leftsidemodal {
  position: fixed;
  padding: 5px 12px 5px 12px;
  background-color: #31719b;
  z-index: 2;
  top: 44%;
  transform: rotate(270deg);
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
  left: -4.7rem;
  cursor: pointer;
  -webkit-animation: instmappsummanimat;
  animation: instmappsummanimat;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  animation-direction: alternate-reverse;


  font-size: 15px;
  font-weight: 500;
  color: #fff;

}


.Computation_summary_leftsidemodal:hover {
  left: -4.8rem !important;
  transition: all 1.25s ease-in-out;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  cursor: pointer;
}

@keyframes instmappsummanimat {
  0% {
    left: -6.0rem;
  }
}


.modal.comput_summmodal.show .modal-dialog {
  margin: 3rem auto auto 0rem;
  max-width: 400px;
}

.modal-header.comput_summmodal_header {
  background-color: #eee;
  padding: 0px 15px;
}

.modal-header.comput_summmodal_header h4.modal-title {
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 0px 4px 0px;
}

table.table.table-striped.ComputationSummary_tb thead tr th {
  font-size: 12px;
  font-weight: 500;
  border-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  vertical-align: baseline;
}

table.table.table-striped.ComputationSummary_tb tr td {
  font-weight: 500;
  font-size: 11px;
  padding: 0.25rem 0.3rem;
}

tr.totlerow_shadoweffect {
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 0 6px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 0 rgba(60, 64, 67, 0.15);
  border-bottom: 1px inset rgba(60, 64, 67, 0.15);
  position: relative;
}

tr.totlerow_shadoweffect td {
  font-weight: 700 !important;
  color: #31719b;
  background-color: #ebf5e0;
}

button.btn.btn-default.Paybtnbgclr {
  background-color: #31719b;
  color: #fff;
  padding: 4px 20px 6px 20px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 40px;
  cursor: pointer;
  position: relative;
  border: 0px;
}

.compsummary_cellbgclr_yell {
  background-color: #fffce1 !important;
}

.compsummary_cellbgclr_lightgrey {
  background-color: #ffe8e8;
}

td.textclr_starred {
  color: red;
}



/* checked box animation css*/

.interestmapp_check {
  display: block;
  position: relative;
  padding: 0em 0em 0em 16%;
  margin: 0 auto;
  cursor: pointer;
  border-bottom: solid 1px #ddd;
}

.interestmapp_check:last-child {
  border-bottom: none;
}

.interestmapp_check__state {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.interestmapp_check__text {
  color: #135156;
  transition: all 0.4s linear 0.4s;
}

.interestmapp_check__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 130px;
  height: auto;
  margin: auto;
  fill: none;
  stroke: #27FDC7;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.interestmapp_check__line,
.interestmapp_check__box,
.interestmapp_check__check {
  transition: stroke-dashoffset 0.8s cubic-bezier(0.9, 0, 0.5, 1);
}

.interestmapp_check__circle {
  stroke: #27FDC7;
  stroke-dasharray: 1 6;
  stroke-width: 0;
  transform-origin: 13.5px 12.5px;
  transform: scale(0.4) rotate(0deg);
  -webkit-animation: none 0.8s linear;
  animation: none 0.8s linear;
}

.link {
  color: #31719B !important;
  cursor: pointer;
}

@-webkit-keyframes explode {
  30% {
    stroke-width: 3;
    stroke-opacity: 1;
    transform: scale(0.8) rotate(40deg);
  }

  100% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(1.1) rotate(60deg);
  }
}

@keyframes explode {
  30% {
    stroke-width: 3;
    stroke-opacity: 1;
    transform: scale(0.8) rotate(40deg);
  }

  100% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(1.1) rotate(60deg);
  }
}

.interestmapp_check__box {
  stroke-dasharray: 56.1053, 56.1053;
  stroke-dashoffset: 0;
  transition-delay: 0.16s;
}

.interestmapp_check__check {
  stroke: #27FDC7;
  stroke-dasharray: 9.8995, 9.8995;
  stroke-dashoffset: 9.8995;
  transition-duration: 0.32s;
}

/* .interestmapp_check__line {
  stroke-dasharray: 168, 1684;
  stroke-dashoffset: 168;
}  */
.interestmapp_check__circle {
  -webkit-animation-delay: 0.56s;
  animation-delay: 0.56s;
  -webkit-animation-duration: 0.56s;
  animation-duration: 0.56s;
}

.interestmapp_check__state+.interestmapp_check__text {
  transition-delay: 0s;
  color: #5EBEC1;
  opacity: 0.6;
}

.interestmapp_check__state:checked+.interestmapp_check__icon .interestmapp_check__box {
  /*! stroke-dashoffset: 56.1053; */
  transition-delay: 0s;
}

.interestmapp_check__state:checked>.interestmapp_check__icon .interestmapp_check__line {
  stroke-dashoffset: -8;
}

.interestmapp_check__state:checked+.interestmapp_check__icon .interestmapp_check__check {
  stroke-dashoffset: 0;
  transition-delay: 0.48s;
}

.interestmapp_check__state:checked+.interestmapp_check__icon .interestmapp_check__circle {
  -webkit-animation-name: explode;
  animation-name: explode;
}



/*  --------- icon close -------*/

/* end  checked box animation css*/

/*------ right side certifbox css-----------*/


.comput_health_verifid_btnbox {
  padding: 0px 2px 0px 2px;
  font-size: 10px;
  font-weight: 500;
  position: relative;
  vertical-align: middle;
  height: 17px;
  top: 9px;
  background-color: #31719b;
  color: #fff;
  border-radius: 3px;
}

.cardbox_contentborder_withouthoverbox {
  border: 1px dashed #31719b;
  background-color: #ffffff;
  border-radius: 40px;
  padding: 2px 2px;
  /* cursor: pointer; */
}

.comput_health_review_btnbox {
  padding: 0px 2px 1px 2px;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  background-color: #31719b;
  margin: 0px !important;
  border-radius: 3px;
}

span.comp_health_reviewtop3box {
  padding: 0px 2px 1px 2px;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  background-color: #31719b;
  margin: 0px !important;
  border-radius: 3px;
}

.hide {
  display: none;
}

.black_txt {
  color: #333333
}

.row-strick-1 {
  text-decoration: none;
}

.row-strick-2 {
  text-decoration: red line-through;
  text-decoration-thickness: 2px;
}

.k-overlay {
  z-index: 100
}


fieldset.Overutilization_of_challans_fs {
  border: 1px solid #fc7d72;
}
legend.Overutilization_of_challans_leg {
  width: auto;
}
legend.Overutilization_of_challans_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0px 5px;
  top: 2px;
  position: relative;
}
.card.overutofchallan_card {
  border: 1px dashed #31719b;
  background-color: #ffffff;
  border-radius: 40px;
  padding: 5px 10px;
}
p.overutofchallan_textfs {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: green;
}
p.overutofchallan_textfs_v02 {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}
span.overutofchallan_textfs_v02_dtclr {
  color: #ffb600;
}
span.overofchalla_lineclr {
  color: #f54337;
  padding: 0px 7px;
}
p.overutofchallan_textfs0v3 {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: red;
}
span.date_labeltextfs {
  font-size: 12px;
  font-weight: 500;
  color: #987474;
}

.comp_health_late_deduc_popup .k-grid-content {
  overflow-y: auto;
}

.compu-health-late-deduction-interest-data.k-widget.k-window.windowWrapper .k-window-content,
.k-prompt-container {
    padding: 5px 0px;
    padding-left: 20px;
}

.comphealth_textblink {
  animation: compblinker 1s linear infinite;
  color: red;
  font-size: 11px;
  font-weight: 500;
  transition: all 1s ease;
}

td.comphealth_textclr_coral {
  color: #fc7d72;
}

span.tocapital {
  text-transform: capitalize;
}


@keyframes compblinker {
  50% {
    opacity: 0;
  }
}


/* ------------  comphealth LDC cardbox css  start  ----------------*/

fieldset.comphealth_LDCbox_fs {
    border: 1px solid #ddd;
    background-color: #fff3f3;
    /* background: radial-gradient(closest-side, #ebf8e1, #fff3f3, #ebf8e1); */
    background-color: #ffffef;
}
fieldset.comphealth_LDCbox_fs legend.comphealth_LDCbox_leg .comphealth_LDCbox_inndev {
  display: block;
}
fieldset.comphealth_LDCbox_fs legend.comphealth_LDCbox_leg .comphealth_LDCbox_inndev .comphealth_LDCbox_leftside {
  float: left;
}
fieldset.comphealth_LDCbox_fs legend.comphealth_LDCbox_leg .comphealth_LDCbox_inndev .comphealth_LDCbox_leftside h4 {
  font-size: 1rem;
  font-weight: 500;
  top: 7px;
  position: relative;
}
fieldset.comphealth_LDCbox_fs legend.comphealth_LDCbox_leg .comphealth_LDCbox_inndev .comphealth_LDCbox_innerhieghtdiv {
  height: 1px;
  width: 100%;
  position: relative;
}
fieldset.comphealth_LDCbox_fs legend.comphealth_LDCbox_leg .comphealth_LDCbox_inndev .comphealth_LDCbox_rightsidediv {
  float: right;
}
fieldset.comphealth_LDCbox_fs legend.comphealth_LDCbox_leg .comphealth_LDCbox_inndev .comphealth_LDCbox_rightsidediv button.btn.btn-default.comphealth_LDCbox_verybtn {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 40px;
  color: #fff;
  border: 0px;
  padding: 1px 10px 3px;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  top: -6px;
}
fieldset.comphealth_LDCbox_fs legend.comphealth_LDCbox_leg .comphealth_LDCbox_inndev .comphealth_LDCbox_middborder {
  border-top: 1px solid #31719b;
  display: flex;
  position: relative;
  margin-top: 17px;
}
fieldset.comphealth_LDCbox_fs .comphealth_LDCbox_innercontentbox {
  font-size: 12px;
  font-weight: 500;
}
fieldset.comphealth_LDCbox_fs .comphealth_LDCbox_innercontentbox p.comphealth_LDCbox_textfs.textclr_coral {
  color: #fc7d72;
}
fieldset.comphealth_LDCbox_fs .comphealth_LDCbox_innercontentbox p.textclr_green {
  color: green;
}
fieldset.comphealth_LDCbox_fs .comphealth_LDCbox_innercontentbox span.textclr_coral_bold {
  color: #fc7d72;
  font-weight: 700;
}
fieldset.comphealth_LDCbox_fs .comphealth_LDCbox_innercontentbox img.eyeicon_pointer {
  cursor: pointer;
}

.coral_link {
  color: #fc7d72 !important;
  cursor: pointer;
  font-weight: 600;
}
.coral_link:hover {
  text-decoration: none;
}

/* ------------  comphealth LDC cardbox css  end  ----------------*/


/* ------------  LDC Popup switch button  ----------------*/


label.switch.listofldcswitch {
  display: inline-block;
  width: 26px;
  height: 11px;
  top: 0.21rem;
  margin: 0rem;
}
span.slider.listofdempswround {
  border-radius: 7px;
  background-color: #28a745;
}

span.slider.listofldcswround {
  border-radius: 7px;
  background-color: #28a745;
}

span.slider.listofdempswround:before {
  content: "";
  left: 5px;
  height: 7.3px;
  width: 3px;
  top: 2px;
  bottom: 2.1px;
  background-color: #fff;
}
span.slider.round.listofdempswround:before {
  border-radius: 18%;
}
input:checked + .slider.listofdempswround:before {
  left: -8px;
}

.k-link, .k-link:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
  cursor: default;
}

/* .k-column-title {
  max-width: 90;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
  overflow: hidden;
  cursor: pointer;
} */

.k-widget.k-grid.table.table-striped thead tr th.action .k-cell-inner > .k-link .k-column-title {
  cursor: default!important;
}

span.toCapital {
  text-transform: capitalize !important;
}
.health_reportbtndwn{
  display: flex; 
  justify-content: flex-end; 
}
.btn.btn-default.comp_health_returnsummary_paybtn.health_spinner{
  padding: 4px 8px 5px;
}
/* ------------  LDC Popup switch button  ----------------*/




/*--------- media code  --------*/
@media (min-width: 228px) and (max-width: 767px) {

  .rip::before,
  .rip::after {
    top: 54% !important;
  }

  .feesint_rip::before,
  .feesint_rip::after {
    top: 20% !important;
  }

  .unmaptrans_rip::before,
  .unmaptrans_rip::after {
    top: 52% !important;
  }

  .Unverified_rip::before,
  .Unverified_rip::after {
    top: 90%;
  }

  .deductee_rip::before,
  .deductee_rip::after {
    top: 48%;
  }

  .Unverifiederrorscr_rip::before,
  .Unverifiederrorscr_rip::after {
    top: 86%;
  }

  .verifytrancesuncom_rip::before,
  .verifytrancesuncom_rip::after {
    top: 86%;
  }

  .card.comp_health_revistbox {
    display: block;
  }
  fieldset.challanwise_summ_fs legend.challanwise_summ_leg h4 {
    font-size: 1rem;
  }
  h4.tdscomp_liabilitt_textfs {
      font-size: 11px;
      font-weight: 700;
  }

  p.tdscomp_unmappedchallan_textfs {
      font-size: 11px;
      line-height: 15px;
  }
}

@media (min-width: 768px) and (max-width: 821px) {

  .rip::before,
  .rip::after {
    top: 30% !important;
  }

  .feesint_rip::before,
  .feesint_rip::after {
    top: 20% !important;
  }

  .unmaptrans_rip::before,
  .unmaptrans_rip::after {
    top: 52% !important;
  }

  .Unverified_rip::before,
  .Unverified_rip::after {
    top: 87%;
  }

  .deductee_rip::before,
  .deductee_rip::after {
    top: 48%;
  }

  .Unverifiederrorscr_rip::before,
  .Unverifiederrorscr_rip::after {
    top: 72%;
  }

  .verifytrancesuncom_rip::before,
  .verifytrancesuncom_rip::after {
    top: 86%;
  }
  fieldset.challanwise_summ_fs legend.challanwise_summ_leg h4 {
    font-size: 1rem;
  }
  legend.sectionwisechalla_leg h4 {
    font-size: 1rem;
  }
  .card.tdscomp_unmappedchallan {
      padding: 8px 5px;
  }
  h4.tdscomp_liabilitt_textfs {
      font-size: 11px;
      font-weight: 700;
  }
  p.tdscomp_unmappedchallan_textfs {
      font-size: 11px;
      line-height: 18px;
  }

}

@media (min-width: 822px) and (max-width: 913px) {

  .rip::before,
  .rip::after {
    top: 30% !important;
  }

  .feesint_rip::before,
  .feesint_rip::after {
    top: 20% !important;
  }

  .unmaptrans_rip::before,
  .unmaptrans_rip::after {
    top: 52% !important;
  }

  .Unverified_rip::before,
  .Unverified_rip::after {
    top: 87%;
  }

  .deductee_rip::before,
  .deductee_rip::after {
    top: 48%;
  }

  .Unverifiederrorscr_rip::before,
  .Unverifiederrorscr_rip::after {
    top: 72%;
  }

  .verifytrancesuncom_rip::before,
  .verifytrancesuncom_rip::after {
    top: 86%;
  }
  fieldset.challanwise_summ_fs legend.challanwise_summ_leg h4 {
    font-size: 1rem;
  }
  legend.sectionwisechalla_leg h4 {
    font-size: 1rem;
  }

  .card.tdscomp_unmappedchallan {
      padding: 8px 5px;
  }
  h4.tdscomp_liabilitt_textfs {
      font-size: 11px;
      font-weight: 700;
  }
  p.tdscomp_unmappedchallan_textfs {
      font-size: 11px;
      line-height: 18px;
  }
}

@media (min-width: 914px) and (max-width: 1024px) {

  .rip::before,
  .rip::after {
    top: 30% !important;
  }

  .feesint_rip::before,
  .feesint_rip::after {
    top: 20% !important;
  }

  .unmaptrans_rip::before,
  .unmaptrans_rip::after {
    top: 56% !important;
  }

  .Unverified_rip::before,
  .Unverified_rip::after {
    top: 87%;
  }

  .deductee_rip::before,
  .deductee_rip::after {
    top: 48%;
  }

  .Unverifiederrorscr_rip::before,
  .Unverifiederrorscr_rip::after {
    top: 79%;
  }

  .verifytrancesuncom_rip::before,
  .verifytrancesuncom_rip::after {
    top: 86%;
  }

  h4.tdscomp_liabilitt_textfs {
    font-size: 13px;
}

}

@media (min-width: 1025px) and (max-width: 1171px) {

  .rip::before,
  .rip::after {
    top: 30% !important;
  }

  .feesint_rip::before,
  .feesint_rip::after {
    top: 20% !important;
  }

  .unmaptrans_rip::before,
  .unmaptrans_rip::after {
    top: 56% !important;
  }

  .Unverified_rip::before,
  .Unverified_rip::after {
    top: 87%;
  }

  .deductee_rip::before,
  .deductee_rip::after {
    top: 48%;
  }

  .Unverifiederrorscr_rip::before,
  .Unverifiederrorscr_rip::after {
    top: 79%;
  }

  .verifytrancesuncom_rip::before,
  .verifytrancesuncom_rip::after {
    top: 86%;
  }

  .inerestmapping_left_colwidth {
    max-width: 50% !important;
  }

  .inerestmapping_right_colwidth {
    max-width: 50% !important;
    min-width: 50% !important;
  }

  h4.tdscomp_liabilitt_textfs {
    font-size: 14px;
}

}

@media (min-width: 1172px) and (max-width: 1282px) {

  .rip::before,
  .rip::after {
    top: 17% !important;
  }

  .feesint_rip::before,
  .feesint_rip::after {
    top: 10% !important;
  }

  .unmaptrans_rip::before,
  .unmaptrans_rip::after {
    top: 29% !important;
  }

  .Unverified_rip::before,
  .Unverified_rip::after {
    top: 44%;
  }

  .deductee_rip::before,
  .deductee_rip::after {
    top: 48%;
  }

  .Unverifiederrorscr_rip::before,
  .Unverifiederrorscr_rip::after {
    top: 79%;
  }

  .verifytrancesuncom_rip::before,
  .verifytrancesuncom_rip::after {
    top: 52%;
  }

  .inerestmapping_left_colwidth {
    max-width: 50% !important;
  }

  .inerestmapping_right_colwidth {
    max-width: 50% !important;
    min-width: 50% !important;
  }
}

@media (min-width: 1283px) and (max-width: 1369px) {

  .rip::before,
  .rip::after {
    top: 17% !important;
  }

  .feesint_rip::before,
  .feesint_rip::after {
    top: 10% !important;
  }

  .unmaptrans_rip::before,
  .unmaptrans_rip::after {
    top: 29% !important;
  }

  .Unverified_rip::before,
  .Unverified_rip::after {
    top: 44%;
  }

  .deductee_rip::before,
  .deductee_rip::after {
    top: 48%;
  }

  .Unverifiederrorscr_rip::before,
  .Unverifiederrorscr_rip::after {
    top: 79%;
  }

  .verifytrancesuncom_rip::before,
  .verifytrancesuncom_rip::after {
    top: 52%;
  }

  .inerestmapping_left_colwidth {
    max-width: 59% !important;
  }

  .inerestmapping_right_colwidth {
    max-width: 40% !important;
    min-width: 40% !important;
  }

  input.form-control.interestmapping_searchtext {
    min-width: 170px;
  }
}

@media (min-width: 1370px) and (max-width: 1441px) {

  .rip::before,
  .rip::after {
    top: 17% !important;
  }

  .feesint_rip::before,
  .feesint_rip::after {
    top: 10% !important;
  }

  .unmaptrans_rip::before,
  .unmaptrans_rip::after {
    top: 29% !important;
  }

  .Unverified_rip::before,
  .Unverified_rip::after {
    top: 44%;
  }

  .deductee_rip::before,
  .deductee_rip::after {
    top: 48%;
  }

  .Unverifiederrorscr_rip::before,
  .Unverifiederrorscr_rip::after {
    top: 46%;
  }

  .verifytrancesuncom_rip::before,
  .verifytrancesuncom_rip::after {
    top: 47%;
  }

  .inerestmapping_left_colwidth {
    max-width: 59% !important;
  }

  .inerestmapping_right_colwidth {
    max-width: 40% !important;
    min-width: 40% !important;
  }

  input.form-control.interestmapping_searchtext {
    min-width: 170px;
  }


}

@media (min-width: 1442px) and (max-width: 1520px) {

  .inerestmapping_left_colwidth {
    max-width: 58% !important;
  }

  .inerestmapping_right_colwidth {
    max-width: 42% !important;
    min-width: 42% !important;
  }


}

/*# sourceMappingURL=Computation_health.css.map */


@media (min-width: 1025px) and (max-width: 1400px) {

  .card.comphealth_uncons_card .card-header.comphealth_uncons_cardheader {
    font-size: 11px;
  }

  table.table.table-striped.Deducteetrans_deducteewise_tb thead tr th {
    font-size: 11px;
    padding-left: 3px;
    padding-right: 3px;
  }

}
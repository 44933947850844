.multi-select {
  width: 100%;
  min-width: 150px;
  z-index: 9;
}
.listoftransactionsboxgp .item-selected {
  background-color: #fc7d72 !important;
  & span.dropdown-heading-value span{
    color: #ffffff !important;
  }
}


.listoftransactionsboxgp .dropdown-content {
  min-width: 100%;
  width: auto!important;
  max-width: auto!important;
}
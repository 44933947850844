/*======= confirm popup box css======*/

.fade.areusuredelmbox.modal.show .modal-dialog {
    width: 35%;
}
.areusuredelmboxF.modal-footer {
    padding: 5px 15px;
}
button.btnfscof {
    font-size: 14px;
    border-radius: 40px;
}
button.btnfscof.cancelbtnbgclr.btn.btn-secondary {
    background-color: #f2f2f2;
    color: #636363;
    border: 1px solid #ddd;
    padding: 7px 20px!important;
    border-radius: 40px;
    font-size: 12px!important;
}
button.cancelbtnbgclr_coral {
    background-color: #fc7d72 !important;
    color: #fff !important;
    border: 1px solid #fc7d72 !important;
    padding: 7px 20px!important;
    border-radius: 40px;
    font-size: 12px!important;
}

.btnclrcssyesno{
    width: 70px !important;
    padding: 0 !important;
    background: #31719b !important;
    border: 1px solid #31719b !important;
}

@media only screen and (max-width: 767px){
    .fade.areusuredelmbox.modal.show .modal-dialog {
        width: 95%;
    }
}

.planlimitexceeededpopup{
    .modal-header {
        padding: 5px 8px;
        background: #dee2e6;
    }
    button.close{
        margin-top: -20px
    }

    .areusuredelmboxF.modal-footer{
        display: flex;
    justify-content: center;
    }

}
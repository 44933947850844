h2.corportplanfs {
  font-size: 1.2rem;
  color: #31719b;
  font-weight: 700;
}
h2.corportplanfs_padded{
  font-size: 1.2rem;
  color: #31719b;
  font-weight: 700;
  margin-left: 20%;
}
.corp3colcolumns {
  float: left;
  width: 25%;
  padding: 8px 0px;
}
.prof4colcolumns {
  float: left;
  width: 20%;
  /* padding: 8px 0px; */
}
li.typepricecol img {
  max-width: 100%;
}
ul.price {
  box-shadow: -2px 1px 26px -5px rgb(0 0 0 / 20%);
}

.price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.price:hover {
  box-shadow: 0 15px 25px 0 rgb(0 0 0 / 20%) !important;
}

li.typepricecol {
  padding: 23px 0px 0px !important;
  top: 0px;
  position: relative;
}
.price .headertopbox {
  color: white;
  font-size: 34px;
  padding: 7px 5px !important;
}
li.headertopbox.goldbox {
  background-color: #b7afd5 !important;
}
li.headertopbox.plantinumbox {
  background-color: #92d5dc;
}
li.headertopbox.diamondbox {
  background-color: #92d5dd;
}
.headerbottonborderbox {
  border-top: 0.5em solid #b7afd5;
  border-right: 0.6em solid #92d5dd00;
  border-bottom: 0;
  border-left: 0.6em solid #92d5dd00;
  width: 29px;
  margin: auto;
  position: relative;
  top: 19px;
}
.headerbottonborderpaltbox {
  border-top: 0.5em solid #92d5dc;
  border-right: 0.6em solid #92d5dd00;
  border-bottom: 0;
  border-left: 0.6em solid #92d5dd00;
  width: 29px;
  margin: auto;
  position: relative;
  top: 19px;
}

li.firstparam {
  margin-top: 14.3px !important;
}
li.sliver_platbgclr {
  background-color: #92d5dc;
  color: #fff;
}
li.gold_dainmbgclr {
  background-color: #b7afd5;
  color: #fff;
}
li.planparam {
  color: #31719b;
  font-weight: 500;
  position: relative;
}
li.planpricetextclr {
  color: #31719b;
  font-weight: 500;
}

.plantable_leftimage_listtexfs ul.price li {
  font-size: 13px;
  font-weight: 600;
  padding-top: 10px;
}


.price li {
  border-bottom: 1px solid #eee;
  padding: 6px 2px;
  text-align: center;
  font-weight: 600;
}
.price li:last-child {
  border-bottom: 0px solid #eee;
  padding: 6px 2px 1px 2px;

}
span.distextclr {
  color: #8edcdc;
  font-size: 17px;
  font-weight: 700;
}
span.pricetextfs {
  font-size: 18px;
  color: #335a5f;
  font-weight: 700;
  padding: 0px 3px;
}
button.btn.btn-default.pricetypgoldbtn {
  background-color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: 1px 1px 8px #b6b6b6;
  margin-bottom: 2px;
  min-height: 55px;
  width: 100%;
}
button.btn.btn-default.Proceedbtntextclr {
  background-color: #31719b;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
}

button.btn.btn-default.Proceedbtntextclr_coral {
  background-color: #fc7d72;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  border-radius: 40px;
  padding: 6px 20px 7px 20px;
}
p.pricecontfs {
  font-size: 12px;
  text-align: right;
}
p.texesextraappfs {
  font-size: 12px;
  font-weight: 500;
  text-align: right;
}

p.formoreinforfs {
  font-size: 14px;
  font-weight: 500;
}
p.salesmailtextfs {
  font-size: 12px;
  font-weight: 500;
  color: #31719b !important;
}
p.tremcondtextfs {
  font-size: 12px;
  color: #7b7b7b;
}
button.btn.btn-default.pricetypgoldbtn.silvertextclr {
  color: #56b0b0;
  font-size: 12px;
  font-weight: 700;
}

.modal-header.selctconth {
  padding: 4px 15px;
  background-color: #eee;
}
.selctconttitle {
  font-size: 1.1rem;
  padding: 2px 0px 0px;
}
.modal-header.selctconth button.close {
  display: none;
}
.cotegtitle {
  font-size: 12px;
  font-weight: 500;
}
.modal.userselectplanpopup.show .modal-dialog {
  margin: 100px auto 0px;
}

.talkpopup .modal-content {
  width: 29rem;
}
.plantalksubjectInput {
  width: 100%;
  font-size: 12px;
  border: 1px solid lightgray;
}
.plantalk_body_textarea {
  width: 100%;
  height: 121px;
  border-color: lightgray;
  font-size: 12px;
}

.paymentmode_button {
  border: 1px solid #31719b;
  width: 117px;
  background-color: #fc7d72;
  color: white;
  padding: 2px 0px 3px 0px;
  border-radius: 0;
}
.paymentmode_button:hover {
  color: white;
}
.paymentmode_button .active {
  background-color: #31719b;
  color: white;
}

.paymentmode_heading .heading {
  width: 100%;
  display: block;
  font-size: 12px;
  color: #777171;
}

.full-width .k-input {
  font-size: 12px;
}

.payment_active_Button {
  background-color: #31719b !important;
  color: white;
}

/* .tabsPlan_tables { */

.tabsPlan_tables nav.gstprofessional_plan_navtab {
  padding-top: 4px;
  padding-bottom: 5px;
}

.tabsPlan_tables nav.gstprofessional_plan_navtab .nav.nav-tabs {
  border-bottom: 0px solid #dee2e6 !important;
}

a.nav-item.nav-link.gstprofessional_plan_tab.active {
  color: #ffffff;
  background-color: #fc7d72;
  font-weight: 500;
}

.tabsPlan_tables a.nav-item.nav-link.plantabborder_rightside0 {
  border-right: 0px solid #31719b !important;
}

a.nav-item.nav-link.gstprofessional_plan_tab {
  color: #31719b;
  background-color: #ffffff;
  border-color: #31719b;
  outline-color: #31719b !important;
  border-radius: 0px;
  padding: 2px 25px 3px 25px !important;
  font-size: 14px;
  border: 0px solid #31719b;
  font-weight: 500;
  border-width: 1.5px;
  border-right: 1px solid #31719b;
  text-align: center;
  width: 150px;
}

.gstplan_migratplan_widthbox {
  padding-left: 0px;
}
.gstplan_migratplan_widthbox .gstplan_migratplan_box {
  border: 4px solid #adabab;
  border-radius: 40px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 0px;
  position: relative;
}

/* .currentplan_mainbox
  .gstplan_migratplan_widthbox*/


.gstplan_migratplan_innermaindiv {
    display: flex;
}
.gstplan_migratplan_innermaindiv .gstplan_migratplan_leftcolwidth_box {
  width: auto;
}
 .gstplan_migratplan_innermaindiv .gstplan_migratplan_rightcolwidth_box {
  width: auto;
}
.gstplan_migratplan_leftwidthbox {
  padding-top: 4px;
}

.gstplan_migratplan_box .gstplan_migratplan_fs {
  padding: 0px;
  top: 1rem;
  position: relative;
}

/**-------card box col width ----*/
.currentplan_mainbox .prof4colcolumns {
  /* float: left;
  width: 20%;
  padding: 2px 0px; */
  float: left;
  width: 195px !important;
  min-width: 195px;
  max-width: 30%;
  padding: 2px 0px;
  margin-bottom: 0.8rem;
}
.currentplan_mainbox ul.price {
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  padding: 15px 3px;
}
.currentplan_mainbox ul.price li.headertopbox.plantinumbox {
  background-color: #ffffff;
}
.currentplan_mainbox ul.price li.sliver_platbgclr {
  background-color: #ffffff;
  color: #222;
}
.currentplan_mainbox .price .headertopbox {
  color: white;
  font-size: 25px;
  padding: 7px 5px !important;
}
.currentplan_mainbox ul.price.gstcurentplan_boxborder_greyclr {
  border: 1px solid #9d9d9d;
  padding: 15px 5px;
  border-radius: 10px;
  box-shadow: -2px 14px 32px -5px rgba(0, 0, 0, 0.33);
}
.currentplan_mainbox ul.price.gstcurentplan_boxborder_greyclr li.currentplan_topbocx_bgclr_blue {
  background-color: #b7afd6 !important;
  color: #fff !important;
  position: relative;
  padding-bottom: 11px !important;
}

.currentplan_mainbox button.btn.btn-default.currentplan_btnbgclr {
  background-color: #fc7d72;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  border-radius: 40px;
  padding: 6px 20px 7px 20px;
  border: 1px solid #e6e6e6;
}
.currentplan_mainbox button.btn.btn-default.Proceedbtntextclr {
  background-color: #31719b;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  border-radius: 40px;
  padding: 6px 20px 7px 20px;
}
.currentplan_mainbox button.btn.btn-default.pricetypgoldbtn {
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 5px;
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: 1px 1px 8px #b6b6b6;
  margin-bottom: 2px;
  width: 100%;
}
.currentplan_mainbox button.btn.btn-default.pricetypgoldbtn.profgoldtextclr {
  color: #56b0b0;
  font-size: 14px;
  font-weight: 700;
}
.currentplan_mainbox button.btn.btn-default.pricetypgoldbtn.profplanttextclr {
  color: #56b0b0;
  font-size: 14px;
  font-weight: 700;
}
.currentplan_mainbox button.btn.btn-default.pricetypgoldbtn.profdiamtextclr {
  color: #56b0b0;
  font-size: 14px;
  font-weight: 700;
}
.currentplan_mainbox .gstplan_migratplan_widthbox {
  padding-left: 0px;
}
.currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box {
  border: 4px solid #adabab;
  border-radius: 40px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 0px;
  position: relative;
}
.currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box .gstplan_migratplan_fs {
  padding: 0px;
  top: -1rem;
  position: relative;
}
.currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box .gstplan_migratplan_fs h4 {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #31719b;
  background-color: #ffffff;
  display: inline-block;
  padding: 1px 7px;
  margin-bottom: 0px;
}
.currentplan_mainbox .gstplan_migratplan_leftbox {
  border: 4px solid #fc7d72;
  border-radius: 40px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 0px;
  position: relative;
  width: 81%;
  margin-left: 2rem;
}

/*  start media code for col migrat*/
p.texesextraappfs {
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  margin-bottom: 0px;
}

/**-------card box col width ----*/
/*------------ current plan active card css ------------------*/

ul.price.user_currentplan_cardbox.active:hover button.btn.btn-default.user_currentplan_name {
  background-color: #31719b;
  color: #333333;
  /* margin: 23px -10px 0; */
  width: 100%;
  border-radius: 0px;
  padding: 11px 0 11px;
  position: relative;
  transition: all 0.3s ease-in-out 0s;
  transform: scale(1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.01);
  -ms-transform: scale(1.01);
  z-index: 3;
}
ul.price.user_currentplan_cardbox.active:hover button.btn.btn-default.user_currentplan_name span.shape-left {
  border-color: transparent #31719b #31719b transparent;
  border-style: solid;
  border-width: 14px 4px 11px;
  display: block;
  height: 15px;
  position: absolute;
  top: -26px;
  left: -1px;
  width: 5px;
  transition: all 0.3s ease-in-out 0s;
}
ul.price.user_currentplan_cardbox.active:hover button.btn.btn-default.user_currentplan_name span.shape-right {
  border-color: transparent transparent #31719b #31719b;
  border-style: solid;
  border-width: 14px 4px 11px;
  display: block;
  height: 15px;
  position: absolute;
  top: -26px;
  right: -1.5px;
  width: 5px;
  transition: all 0.3s ease-in-out 0s;
}
ul.price.user_currentplan_cardbox.active:hover button.btn.btn-default.user_currentplan_name span.user_currentplan_nametitle {
  font-weight: 600;
  color: #fff;
  text-shadow: 0px 1px 3px #0f1f3c;
  font-size: 14px;
}

/* current plan 02*/

ul.price.user_currentplan_cardbox_activev02 li.sliver_platbgclr {
  background-color: #ffffff;
  color: #222;
  border-top: 1px solid #eee;
}
ul.price.user_currentplan_cardbox_activev02 li.headertopbox.plantinumbox {
  background-color: #ffffff;
}

/* current plan 03*/
ul.price.user_currentplan_cardbox_activev03 {
  transform: scale(1.06);
  -webkit-transform: scale(1.06);
  -moz-transform: scale(1.06);
  -o-transform: scale(1.06);
  -ms-transform: scale(1.06);
  z-index: 3;
  border: 4px solid #bd1550;
  box-shadow: 0px 2px 12px 0px rgba(39, 31, 31, 0.75) !important;
}

ul.price.user_currentplan_cardbox_activev03 .user_currentplan_v03_ribbon {
  font-size: 13px;
  font-weight: 700;
  display: block;
  --f: .5em; /* control the folded part */
  position: absolute;
  top: -4px;
  right: 0;
  line-height: 1.8;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  -o-border-image: conic-gradient(rgba(0, 0, 0, 0.5333333333) 0 0) 51%/var(--f);
     border-image: conic-gradient(rgba(0, 0, 0, 0.5333333333) 0 0) 51%/var(--f);
  -webkit-clip-path: polygon(100% calc(100% - var(--f)), 100% 100%, calc(100% - var(--f)) calc(100% - var(--f)), var(--f) calc(100% - var(--f)), 0 100%, 0 calc(100% - var(--f)), 999px calc(100% - var(--f) - 999px), calc(100% - 999px) calc(100% - var(--f) - 999px));
          clip-path: polygon(100% calc(100% - var(--f)), 100% 100%, calc(100% - var(--f)) calc(100% - var(--f)), var(--f) calc(100% - var(--f)), 0 100%, 0 calc(100% - var(--f)), 999px calc(100% - var(--f) - 999px), calc(100% - 999px) calc(100% - var(--f) - 999px));
  transform: translate(29.2893218813%, -100%) rotate(45deg);
  transform-origin: 0% 100%;
  background-color: #BD1550; /* the main color  */
}

@keyframes zoominzoomout {
  0% {
    scale: 100%;
  }
  50% {
    scale: 120%;
  }
  100% {
    scale: 100%;
  }
}

/*--------- renwal plan --------*/

@keyframes zoominzoomout {
  0% {
    scale: 100%;
  }
  50% {
    scale: 110%;
  }
  100% {
    scale: 100%;
  }
}

ul.price.user_renwalplan_cardbox_04 button.btn.btn-default.pricetypgoldbtn.profplanttextclr {
  background-color: #31719b;
  color: #ffffff;
  width: 100%;
  border-radius: 0px;
  text-shadow: 0px 1px 3px #222;
}
ul.price.user_renwalplan_cardbox_04 span.pricetextfs {
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  padding: 0px 3px;
}
ul.price.user_renwalplan_cardbox_04 span.distextclr {
  color: #fc7d72;
  font-size: 22px;
  font-weight: 700;
}/*# sourceMappingURL=Current_plan.css.map */

/* } */

@media (max-width: 425px) {
  .gstplan_migratplan_innermaindiv {
    display: block;
  }
  .currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box {
    top: -19px;
  }
  .currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box .gstplan_migratplan_fs {
      top: -24.5rem;
  }
}
@media (max-width: 426px) and (max-width: 767px) {
  .gstplan_migratplan_innermaindiv {
    display: block;
}
  .currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box {
    top: -19px;
  }
  .currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box .gstplan_migratplan_fs {
      top: -1.5rem;
  }
}

@media (max-width: 520px) {
  .gstplanbox_disp {
    display: block !important;
  }
  .prof4colcolumns {
    width: 100% !important;
    max-width: 100% !important;
  }


}
@media (min-width: 521px) and (max-width: 767px) {
  .gstplanbox_disp {
    display: block !important;
  }
  .prof4colcolumns {
    width: 30% !important;
    max-width: 100% !important;
  }
}
/* start media code  */
@media (min-width: 320px) and (max-width: 767px) {
  .corp3colcolumns {
    width: 100%;
  }

  button.btn.btn-default.pricetypgoldbtn.silvertextclr {
    font-size: 9px;
  }
  span.distextclr {
    font-size: 12px;
  }
  span.pricetextfs {
    font-size: 12px;
  }
  button.btn.btn-default.Proceedbtntextclr {
    font-size: 9px;
  }
}
@media (min-width: 768px) and (max-width: 940px) {
  .price li {
    font-size: 12px;
  }
  button.btn.btn-default.pricetypgoldbtn.silvertextclr {
    font-size: 12px;
  }
  span.distextclr {
    font-size: 16px;
  }
  span.pricetextfs {
    font-size: 16px;
  }
  button.btn.btn-default.Proceedbtntextclr {
    font-size: 12px;
  }
}
@media (min-width: 941px) and (max-width: 1024px) {
  .price li {
    font-size: 14px;
  }
  button.btn.btn-default.pricetypgoldbtn.silvertextclr {
    font-size: 12px;
  }
  span.distextclr {
    font-size: 18px;
  }
  span.pricetextfs {
    font-size: 18px;
  }
  button.btn.btn-default.Proceedbtntextclr {
    font-size: 12px;
  }
}

@media (max-width: 367px) {
  .gstplan_migratplan_innermaindiv {
    display: block;
  }
  .currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box {
    top: -19px;
  }
  .currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box .gstplan_migratplan_fs {
      top: -25.5rem;
  }
}
@media (min-width: 368px) and (max-width: 519px) {
  .gstplan_migratplan_innermaindiv {
    display: block;
  }
  .currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box {
    top: -19px;
  }
  .currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box .gstplan_migratplan_fs {
      top: -1rem;
  }
}
@media (min-width: 520px) and (max-width: 767px) {
  .gstplan_migratplan_innermaindiv {
    display: block;
}
  .currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box {
    top: -19px;
  }
  .currentplan_mainbox .gstplan_migratplan_widthbox .gstplan_migratplan_box .gstplan_migratplan_fs {
      top: -25.5rem;
  }
}

.panwise_deductorlist {
    a.btn.btn-default.tds_panwise_deductmaster_backbtn {
        color: rgb(252, 125, 114);
        font-size: 12px;
        font-weight: 500;
    }

    .cursor_pointer{
        cursor: pointer;
    }
    .dark_color_text {
        color: #31719B;
    }

    h4.tds_panwise_deductmaster_backbtn {
        font-size: 1.2rem;
        font-weight: 500;
    }

    .panwiseded_master_SPwrapper {
        max-width: 55px;
        top: 8rem;
        margin: auto auto;
        position: absolute;
        left: 4.0rem;

        & .panwiseded_master_innerwrapper {
            background-color: #f6fcff;
            color: #333;
            font-size: 8px;
            line-height: 1.7;
            padding: .5px 3px;
            cursor: default;
            text-align: left;
            margin-bottom: 0;
            border: 1px solid #fc7d72;

            & p.panwiseded_master_textfs {
                margin-bottom: 0px;
            }
        }

        & .panwiseded_master_innerwrapper:nth-child(odd) {
            border-left: 2px solid;
            border-color: #fbb6b0;

        }

        & .panwiseded_master_innerwrapper:nth-child(odd):after {
            content: "";
            border-left: 2px solid;
            border-color: #fbb6b0;
            margin-top: 0;
            position: absolute;
            bottom: 22.5px;
            padding-top: 1px;
            transform: rotate(316deg);
            left: 47px;
            border-color: #ddd transparent;
            display: block;
            width: 0;
            border-color: rgba(252, 125, 114, 0) #fbb6b0 #fbb6b0 rgba(251, 182, 176, 0);
            border-style: solid;
            border-width: 0 0 14px 14px;
        }
    }

    .table.table-striped.tds_panwisedmaster_tb {

        thead tr th {
            border-top: 0;
            font-size: 12px;
            text-align: center;
            font-weight: 500;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 0px;
            &.headingwiseclass{
                .k-cell-inner .k-link .k-column-title{
                    cursor: pointer;
                }
            }
        }

        tr td {
            font-size: 11px;
            padding: 0.25rem 0.75rem;
            font-weight: 500;
            vertical-align: baseline;
            border-top: 1px solid #dee2e6;
        }

        td.Panwisedeductor_master_panclrblue {
            color: #33729b;
            cursor: pointer;
        }

        td.Panwisedeductor_master_textoverflow {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            white-space: nowrap;
        }

        td.Panwisedeductor_master_emailtextoverflow {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            white-space: nowrap;
        }

        td.Panwiseded_master_panbgclr_w {
            background-color: #fff;
        }

        td.Panwiseded_master_panbordrright {
            border-right: 1px solid #ddd;
            vertical-align: middle;
        }
    }

    /*speechbubble media ================*/

    @media (max-width: 639px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 10rem;
            left: 0.5rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 640px) and (max-width: 767px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 3.5rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 821px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 0.9rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 822px) and (max-width: 991px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 2.9rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }


    @media only screen and (min-width: 992px) and (max-width: 1025px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 0.2rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 1026px) and (max-width: 1170px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 3.5rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 1171px) and (max-width: 1349px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 5rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 1350px) and (max-width: 1440px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 4rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 1681px) and (max-width: 1791px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 3.5rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 1792px) and (max-width: 1991px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 3.5rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 1992px) and (max-width: 2191px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 3.5rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 2893px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 3.5rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }


    @media only screen and (min-width: 1441px) and (max-width: 1550px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 8.0rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 1551px) and (max-width: 1680px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 11.0rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 1681px) and (max-width: 1791px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 14.5rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 1792px) and (max-width: 1991px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 21.0rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 1992px) and (max-width: 2191px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 27rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 2192px) and (max-width: 2300px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 30.2rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 2301px) and (max-width: 2791px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 36.2rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 2792px) and (min-width: 2892px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 3.5rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 2893px) {
        .panwiseded_master_SPwrapper {
            max-width: 55px;
            width: 55px;
            top: 8rem;
            left: 51.8rem;
        }

        .panwiseded_master_SPwrapper .panwiseded_master_innerwrapper:nth-child(2n+1):after {
            top: 0.5rem;
        }

        p.panwiseded_master_textfs {
            font-size: 7px;
            margin-bottom: 0px;
        }
    }
}
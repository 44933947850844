
/* telerik grid css start  */
table{ background-color: #fff;}
.k-grid tr.k-alt {background-color: #fff;}
.k-grid{border-width: 0px;}
.k-grid th {border-width: 0px;}
.k-grid td {border-width: 0px;}
.k-grid-pager {border-width: 0px;}
.k-grid-header, .k-grouping-header, .k-grid-add-row, .k-grid-footer, .k-pager-wrap{background-color: 'rgb(37, 16, 16)'}
col.k-sorted, th.k-sorted { background-color: transparent;}
.k-grid-header .k-grid-header-wrap {
    background-color: #fff;
}
th.active .k-icon {
    color: #fc7d72;
}
.k-i-more-vertical::before {
    content: "\e129";
}
.remove_sortclass .k-i-sort-asc-sm {display: none;}
.remove_sortclass .k-i-sort-desc-sm {display: none;}
.remove_sortclass .active_sort {display: inline;}
/* telerik grid css end  */

.table.table-striped.listofsalarydetailstb thead tr th {
    font-size: 12px !important;
    text-align: center !important;
    font-weight: 500;
    vertical-align: baseline;
    padding-top: 5px;
}
.table.table-striped.listofsalarydetailstb tr td {
    font-size: 11px !important;
    font-weight: 500;
    vertical-align: middle;
    padding: 0.25rem 0.75rem;
}
form.form-inline.salarysearchbox img {
    position: absolute;
    top: 10px;
    width: 15px;
    display: flex;
    z-index: 333;
    right: 10px !important;
    left:auto !important;
}
input.form-control.salaryinboxwidth {
    width: 85px;
}
table.table.table-striped.listofsalarydetailstb tr td span img {
    width: 14px!important;
    margin: 0 4px;
}
.table td {
    padding: .25rem .75rem;
}

.align-right {text-align: right !important;}
.align-center {text-align: center !important;}
 .filter_ui_select {font-size: 12px;}
 .filter_ui_option .k-list > .k-item {
    color: #656565;
    font-size: 12px;
  }
  .filter_ui_option .k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected
  {
    color: #fff !important;
  }

  span.importSexcelbtn {
    color: rgb(252, 125, 114);
    color: #ffffff;
    border: none;
    padding: 7px 15px;
    border-radius: 40px;
    font-size: 12px;
    background-color: rgb(252, 125, 114);
    cursor: pointer;
    vertical-align: bottom;
}
.table.table-striped.listofsalarydetailstb thead tr th span.k-icon {
    font-size: 12px;
    margin: 0px -4px;
}
.table.table-striped.listofsalarydetailstb thead tr th span.k-column-title {
    min-width: 0;
    text-overflow: ellipsis;
    white-space: break-spaces;
}
.saldtddeleditsect img {width: 16px !important; cursor: pointer;}
.table-striped.listofsalarydetailstb tr td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: revert;
}

.k-widget.k-grid.table.table-striped.listofsalarydetailstb.table-responsive .k-grid-header {
    padding: 0px 0px 0px 0px !important;
}

.deductee_link {color: #31719B; cursor: pointer;}
.salarytitlerpadd h4 {
    padding-right: 13rem;
}
input.form-check-input.verticalaligtext {
    vertical-align: middle;
}


* {
	margin: 0;
	padding: 0;
}

body {
	background-color: #ffffff !important;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	-webkit-font-smoothing: antialiased;
	font-weight: 500 !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", ;
}

nav.Mismatch_report_nav {
	& .nav.nav-tabs {
		border-bottom: 0px solid #dee2e6 !important;

		& a.nav-item.nav-link.Mismatch_report_ratemist {
			color: #31719b;
			background-color: #ffffff;
			border-color: #31719b;
			outline-color: #31719b !important;
			border-radius: 0px;
			padding: 2px 25px 3px 25px !important;
			font-size: 12px;
			font-weight: 500;
			border: 0px solid #31719b;
			border-width: 1.5px;
			border-right: 1px solid #31719b;
			text-align: center;
		}

		& a.nav-item.nav-link.Mismatch_report_ratemist.active {
			color: #ffffff;
			background-color: #fc7d72;
		}

		& a.nav-item.nav-link.Mismatch_report_borderrightnon {
			border-right: 0px solid !important;
		}
	}
}

table.table.table-striped.Mismatch_report_tb {
	& thead tr th {
		font-size: 12px;
		font-weight: 500;
		border-top: 0px;
		padding-top: 5px;
		padding-bottom: 5px;
		text-align: center;
		vertical-align: baseline;
	}

	& tr td {
		padding: 0.25rem 0.75rem;
		font-weight: 500;
		font-size: 11px;


	}

	& th.active .k-icon {
		color: #fc7d72;
	}
	& .textclr_blue {
		color: #31719b;
	}

	& td.mist_redtext {
		color: red;
	}

}

.mismatchmodal .k-dialog-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 90%;
    height: auto;
    z-index: 11;
    margin: 0px 0px 0px 4rem;
}

span.k-icon.k-i-more-vertical {
    font-size: 12px;
}

.modal.Mismatch_report_modal.show .modal-dialog {
	margin: 2rem auto 0rem auto;
	max-width: 97%;

	& .modal-header.Mismatch_report_header {
		background-color: #eee;
		padding: 0px 15px;

		& h4.modal-title {
			font-size: 1rem;
			font-weight: 500;
			padding: 4px 0px 4px 0px;
		}

		& button.close {
			top: 4px;
			position: relative;
		}

		& button:focus {
			outline: 1px dotted;
			outline: 0px auto -webkit-focus-ring-color;
		}
	}
}


.k-dialog-wrapper.Mismatch_report_modal .k-window-titlebar.k-dialog-titlebar.k-header {
	padding: 7px 15px;
	border-bottom: 0px;
	background-color: #eee;
	color: #000000 !important;
}

.k-dialog-wrapper.Mismatch_report_modal .k-window-content.k-dialog-content {
	padding: 10px 0px;
}
.manualpopuptb .k-grid-content{
	overflow-y: inherit;
}


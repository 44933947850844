header {
  position: sticky;
  top: 0px;
  z-index: 10;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  background-color: #fff;
}

header nav {
  margin-bottom: 0px !important;
  border: 0px !important;
  background-color: #fff;
}

header .navbar-brand img {
  width: 130px;
}

header ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
}

header ul li span {
  border-radius: 50%;
  padding: 10px 7px 6px 11px;
  margin-top: 15px;
}
span.syn_reloadicon svg {
  color: #31719b;
  vertical-align: middle;
}
.header-sticky {
  position: fixed;
  top: 78px;
  left: 25%;
  z-Index: 100;
  width: 50%;
  text-align: center;
}

.userprodropd .dropdown-menu.show {

  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(111, 119, 130, .15), 0 5px 20px 0 rgba(21, 27, 38, .08);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 1px;
  font-size: 14px;
  margin-top: 6px;
  border-radius: 0px;
  max-width: 100%;
  min-width: 13rem

}
.dropdown-menu.dropdown-menu-right.show button.dropdown-item.btn.btn-primary {
  font-weight: 500;
}

.userprodropd ul li {
  margin-right: 0px;
}

li.nav-item.dropdown.userprodropdlist a#navbardrop {
  padding: 0.5rem 0.1rem !important;
}

span.checkiconusr {
  font-size: 12px !important;
  padding-left: 0px;
  color: #333;
}


span.logicntopm {
  margin-top: -9px;
  max-width: 100%;
}

span.logicntopm img {
  width: 15px;
  height: 15px;
  padding: 0px;
  float: right;
}

a.dropdown-item.profileusr span {
  font-size: 0.8rem;
}

.userprodropd a.dropdown-item.profileusr {
  padding: 0.1rem 0.6rem !important;
}

span.black_color_text.usertextoverflow {
  max-width: 105px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  cursor: pointer;
  display: inline-block;
}

.userprodropd a.dropdown-item {
  font-size: 0.8rem;
  padding: .1rem 0rem !important;
}

.lipaddmanage span.formdetail {
  padding: 10px 7px 6px 3px;
}

span.float-right.rightagliconmore img {
  width: 15px;
  height: 15px;
  padding: 0px;
  margin: 0px;
  color: #333;
}

span.float-right.rightagliconmore {
  margin-top: -12px;
}


.dropdown-item.profileusr {
  padding: 0.2rem 0.5rem;
  font-weight: 500;
}

span.activeusrp {

  color: #4caf50 !important;
  font-size: 9px !important;
  float: right;
  margin-top: -2px;
  
}

button.dropdown-item.logdisinline.btn.btn-primary {
  padding: 0.2rem 0.5rem;
}



li.nav-item.dropdown.dropleft.header_callmemainbox {
  top: 0.3rem;
}

span.callicon img {
  width: 15px;
}

.callmebgclr {
  background-color: #31719b;
  color: #fff;
  padding: 1px 4px 3px 4px !important;
  position: relative;
  /* right: -12rem; */
  border-radius: 4px;
  font-size: 13px;
  top: 3px;
  cursor: pointer;
}

.callmebgclr span {
  padding: 2px;
}

.callmebgclr:hover {
  color: #fff;
}

.nav-link.dropdown-toggle.callmebgclr::after {
  display: none;
}


.dropdown-menu.header_cllmeinnerdown.show {
  left: -7.6rem;
  top: 2.7rem;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(111, 119, 130, .15), 0 5px 20px 0 rgba(21, 27, 38, .08);
  box-sizing: border-box;
  font-size: 14px;
  max-width: 100%;
  padding: 0 1px;
  min-width: 14rem;

}

span.closeiconright {
  float: right;
  color: #31719b;
}

.dropdown-item.headerscetbborder {
  font-size: 12px;
  padding: 0.4rem 0.5rem;
  border-bottom: 1px solid #e8e3e3;
  font-weight: 500;
  border-radius: 0px;
}

.textboxinnersect {
  padding: 0.5rem 0.5rem;
}

p.contentarea {
  font-size: 10.5px;
  color: #909090;
  font-weight: 500;
}

.inputboxarea input.form-control.inputcallme {
  font-size: 12px;
  padding: .37rem .75rem;
  height: calc(1.2em + .75rem + 2px);
  border: 1px solid #c6c2c2;
  outline-color: transparent;
}

.callmebtnbox {
  padding: 0.5rem 0.1rem 0rem 0.1rem;
  text-align: center;
}

.callmebtnbox .callmedonebtn {
  background-color: #33729c;
  color: #fff;
  outline-color: transparent;
  padding: 0.2rem 0.9rem;
  font-size: 14px;
}

.callmebtnbox .callmedonebtn:hover {
  color: #fff;
}

input.form-control.inputcallme:focus {
  color: #495057;
  background-color: #fff;
  border-color: #33729c;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

a.btn.btn-default.callmedonebtn:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

.dropdown-item.headerscetbborder:active {
  color: #16181b;
  background-color: #cccccc00;
}

header ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 5px !important;
}
.rotate_icon {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.disableRefrshSync {
  color: #93979b;
}

.refrsh_sync {
  margin: 0px 8px;
  cursor: pointer;
  color: #31719b !important;
}

/* start media code -------*/
@media only screen and (max-width: 767px) {
  header ul li span.callicon {
    padding: 5px 0px 6px 0px !important;
    margin-top: 4px;
  }

  header ul li span.callicon img {
    margin-left: 0px !important;
    width: 15px !important;
  }

  .dropdown-menu.header_cllmeinnerdown.show {
    left: -8.6rem;
    top: 1.7rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 941px) {
  li.nav-item.dropdown.gst2arecprouser {
    top: 7px;
    right: 2rem;
  }
}

@media only screen and (min-width: 942px) and (max-width: 1024px) {
  li.nav-item.dropdown.gst2arecprouser {
    top: 7px;
    right: 2rem;
  }

}

@media only screen and (max-width: 767px) {
  .col-8.col-md-8.col-sm-8.col-lg-8.col-xl-8.mt-3.smpaddhead {
    padding: 0px 1px;
  }

  .navbar-header.col-4.col-md-4.col-sm-4.col-lg-4.col-xl-4.smpaddhead {
    padding: 0px 1px;
  }

  .profilesmfsize {
    font-size: 11px !important;
  }

  li.nav-item.dropdown.userprodropdlist a#navbardrop {
    float: right;
  }

}

@media (max-width: 319px) {
  a.navbar-brand.mainexplogo img {
    width: 76px !important;
    left: -21px;
    position: relative;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  a.navbar-brand.mainexplogo img {
    width: 92px !important;
  }
  .header_compname_textoverflow {
      max-width: 154px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1025px) {
  ul.float-right.headernavdropmd {

    margin-right: 2rem;
  }

  .header_compname_textoverflow {
      max-width: 154px;
  }

}

@media only screen and (min-width: 1026px) and (max-width: 1349px) {
  ul.float-right.headernavdropmd {
    margin-right: 2rem;
  }

  li.nav-item.dropdown.dropleft.header_callmemainbox.show {
    top: 0.3rem;
    /* right: 2rem; */
  }

}

.dispinlinerightnav {
  float: right;
  display: inline-flex;
}

.processSync {
  position: fixed;
  z-index: 999;
  background: white;
  width: 50%;
  bottom: 60px;
  padding: 10px;
  left: 25%;
  box-shadow: 0px 0px 2px 3px #20202033;
  border-radius: 12px;
}

.client-status-show {
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  line-height: 2rem;
  font-size: 0.7rem;
}
/*  */

.tds_dashboard_headerleftsidebox {
  margin: 0.3125rem 0rem;
}
span.expit_infoiconheader {
  position: relative;
  top: -7px;
  font-size: 10px;
}

header .newHeadercss ul.tds_dashboard_headerleftsidebox li span.tds_dashboard_headerleft_insight {
  padding: 0rem !important;
}

header .newHeadercss ul.tds_dashboard_headerleftsidebox li span.tds_dashboard_headerleft_insight span {
  padding: 0rem !important;
}

header .newHeadercss ul.tds_dashboard_headerleftsidebox li span.cursorpoit img.editicon {
  width: 16px;
}

header .newHeadercss ul.tds_dashboard_headerleftsidebox li span.cursorpoit img.govticon {
  width: 18px;
}

.newHeadercss .k-widget.k-dropdown.header_select.headerselect.drpdwn_width {
  width: 80px;
  cursor: pointer;
  padding: 0;
  margin-top: 0;
  height: calc(0.9em + 5px)!important;
  line-height: 0;
}

.newHeadercss .k-widget.k-dropdown.header_select.headerselect.drpdwn_width span {
  margin-top: 0;
  padding: 0px;
  padding-left: 5px;
  padding-right: 3px;
}

.newHeadercss .k-widget.k-dropdown.header_select.headerselect.drpdwn_width span.k-input {
  height: 1.4em;
  font-weight: 500;
}

.newHeadercss span.tds_boxalign {
  right: 0;
  left: 0rem;
  position: relative;
  padding: 0
}
.newHeadercss span.tds_sync_status {
  text-align: right;
  color: rgb(252, 125, 114);
  font-size: 12px;
  font-weight: 500;
  margin-top: 0px;
  padding: 0;
}
.newHeadercss .drodwnsynstatus{
  text-align: right;
  color: rgb(252, 125, 114);
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  padding:0;
}

.newHeadercss .k-widget.k-dropdown.header_select.headerselect.drpdwn_width span.k-select {
    display: block;
    border: 0;
    background-color: transparent!important;
    top: -4px;
    cursor: pointer;
    padding: 1px 11px 0 0!important;
    width: 15px;
}

/* span.k-widget.k-dropdown.header_select.headerselect.drpdwn_width span#yearDropDownPopup {
  margin-top: -9px;
  border-color: #f2f2f2;
  color: #656565;
  background-color: #f2f2f2!important;
  background-image: linear-gradient(#f2f2f2,#f2f2f2)!important;
  height: calc(0.9em + 5px)!important;
  border-radius: 0;
  width: 80px!important;
  padding: 0;
  min-width: 80px;
  max-width: 100px;
} */

.dispinlinerightnav .headernavdropmd .drodwnsynstatus span.dropdwonfy{
  padding: 0;
  color: lightgray;
  vertical-align: middle;
}
.dispinlinerightnav .headernavdropmd .drodwnsynstatus span.dropdwonyearlist{
  top: -1px;
  position: relative;
}

.newHeadercss .rotate_icon {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
.newHeadercss .tds_dashboard_headerleft_insight .tds_dashboard_headerleft_infoiconheader{
  position: relative;
  top: -5px;
  font-size: 10px;
}

.sync_stripchallanfooter .exprit_expriebox_btnbox {
  padding: 2px 5px 2px 1px;
  font-weight: 500;
  white-space: initial;
  max-width: initial;
  display: inline-block;
  margin: 0px 1px;
  font-size: 14px;
  width: 100%;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  /* color: #ff4131; */
  /* cursor: pointer;*/
  border-radius: 40px;
}

.sync_stripchallanfooter .updatebtn{
  margin-left: 1rem;
  background-color: #31719b;
  border: none;
  padding: 3px 10px 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: white;
  font-weight: 500;
}

li.nav-item.dropdown.userprodropdlist.show .dropdown-divider {
  margin: 0.3rem 0;
}
li.nav-item.dropdown.userprodropdlist.show button.dropdown-item.btn.btn-primary:focus {
  border-color: #0062cc;
  box-shadow: 0 0 0 0rem rgba(38, 143, 255, 0.5);
  border-radius: 0px !important;
}
li.nav-item.dropdown.userprodropdlist.show button.dropdown-item.btn.btn-primary:hover {
 border-radius: 0px !important;
  background-color: #e9ecef;
}
li.nav-item.dropdown.userprodropdlist.show button.dropdown-item.btn.btn-primary:active {
  border-radius: 0px !important;
  background-color: #e9ecef;
  color: #222;
}
.header_compname_textoverflow {
  max-width: 290px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}





/*========== antibble  ==============*/



@-webkit-keyframes snake {
  to {
    stroke-dashoffset: -300%;
  }
}

@keyframes snake {
  to {
    stroke-dashoffset: -300%;
  }
}

.drc-border-ani {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
  --thickness: 0.20rem !important;
  --roundness: 1.1rem !important;
  --color: #0f6297;
  --opacity: 1;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  background: #f2f2f2;
  box-shadow: 0 2px 4px 0 #eee;
  border: none;
  border-radius: var(--roundness);
  cursor: pointer;
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 4px 14px;
  border: 1px solid #5a839d;
 
}


  .drc-border-ani:after {
    border: 0 solid;
    border: 0 solid;
    box-sizing: border-box;
    box-sizing: border-box;
  }

  .drc-border-ani:before {
    border: 0 solid;
    border: 0 solid;
    box-sizing: border-box;
    box-sizing: border-box;
  }

  .drc-border-ani:disabled {
    cursor: default;
  }

  .drc-border-ani:-moz-focusring {
    outline: auto;
  }

  .drc-border-ani svg {
    vertical-align: middle;
    border-radius: var(--roundness);
    display: block;
    filter: url(#glow);
    height: calc(100% + 2px);
    left: -1px;
    position: absolute;
    top: -1px;
    width: calc(100% + 2px);
    z-index: -100;
  }

  [hidden] {
    display: none;
  }

  rect {
    -webkit-animation-play-state: running;
    animation-play-state: running;
    opacity: var(--opacity);
  }

  rect:hover {
    filter: brightness(1.2);

  }

  rect:active {
    --opacity: 0;
  }

  rect.react_attribute {
    fill: none;
    stroke: var(--color);
    stroke-width: var(--thickness);
    rx: var(--roundness);
    stroke-linejoin: round;
    stroke-dasharray: 190%;
    stroke-dashoffset: 80;
    -webkit-animation: snake 5s linear infinite;
    animation: snake 5s linear infinite;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s;
    width: 100%;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    opacity: var(--opacity);
  }

   span.headerconsorequestconfig {
    position: relative;
    top: 3px;
    cursor: pointer;
}

span.headerconsorequestconfig:after {
  cursor: pointer;
  content: url("../../images/anglupicon.png");
}

span.headerconsorequestconfig.collapsed:after {
  cursor: pointer;
  content: url("../../images/angldownicon.png");
}

.raisereqretprocesswithdef{
  white-space: initial;
    font-size: 12px;
    padding-left: 0px;
}

label.switch.listofreqconfigswitch {
    width: 26px;
    height: 14px;
    top: 5px;
    margin: 0rem;
    float: right;
}

span.slider.listofreqconfiground {
  border-radius: 7px;
  height: 12px;
  margin-top: 0px;
  background-color: #aaa5a5;

}

span.slider.listofreqconfiground:before {
  content: "";
  left: 5px;
  height: 7.3px;
  width: 3px;
  top: 2px;
  bottom: 2.1px;
  background-color: #fff;
}

span.slider.round.listofreqconfiground:before {
  border-radius: 50%;
  width: 10px;
  position: absolute;
  top: 2px;
  left: 2px;
}
span.slider.round.listofreqconfiground:before {
  border-radius: 50%;
}
input:checked + .slider.listofreqconfiground:before {
  left: -13px;
}
input:checked + span.slider.listofreqconfiground {
  background-color: #28a745;
}
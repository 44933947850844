body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
}

.backbtntext {
    color: #fc7d72;
    font-size: 12px;
}

h4.mysubcribed-plantextfs {
    font-size: 1.2rem;
    font-weight: 500;
}

button.btn.btn-dedauld.renewpaln-btnbgclr {
    background-color: #31719b;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
    padding: 3px 20px 5px 20px;
}

h4.mysubgstplan-fs {
    font-size: 1.2rem;
    font-weight: 500;
}

span.form-check-inline.mysub-plan-Subscribed-box {
    font-size: 12px;
    font-weight: 500;
}

label.form-check-label.mysub-plan-Subtextfsbold.subplantxtclr-coral {
    color: #fc7d72;
    font-size: 12px;
    font-weight: 700;
}

label.form-check-label.mysub-plan-Subtextfs {
    font-size: 12px;
    font-weight: 500;
    padding: 0px 5px;
}

label.form-check-label.mysub-plan-Subtextfs.textboldsize {
    font-weight: 700;
    color: #31719b;
}

label.form-check-label.Subtext-borderclr {
    padding-left: 7px;
    color: rgb(177, 171, 171);
    font-size: 13px;
    top: 0px;
    position: relative;
}

table.table.table-striped.mysubplan-table thead tr th {
    border-top: 0;
    font-size: 12px !important;
    text-align: center;
    padding: 5px 0;
    font-weight: 500;
    vertical-align: bottom;
}

table.table.table-striped.mysubplan-table tr td {
    font-size: 12px;
    padding: 0.25rem 0.75rem;
}

span.mysubplan-tdtextbold {
    font-weight: 700;
}

td.mysubplan-overflow td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 150px;
}

h4.mysubplan-histfs {
    font-size: 1.2rem;
    font-weight: 500;
}

/* =================== start Select Addon Details css ===================*/
.modal-body {
    padding: 0.5rem;
}
.modal-title {
    margin: 4px;
    line-height: 1.5;
}


.modal.fade.mysubplan_addon_modalboxpopup.show .modal-dialog {
    margin: 2rem auto auto auto;
}

.modal-header.mysubplan_addon_modalboxpopup_header {
    padding: 0px 15px;
    background-color: #eee;
}

.modal-header.mysubplan_addon_modalboxpopup_header .modal-title.h4 {
    font-size: 1rem;
    padding: 1px 0px 2px 0px;
    font-weight: 500;
}

.modal-header.mysubplan_addon_modalboxpopup_header button.close {
    position: relative;
    top: 4px;
}

.modal-header.mysubplan_addon_modalboxpopup_header button:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

table.table.table-striped.mysubplan_addondetailstb thead tr th {
    border-top: 0px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

table.table.table-striped.mysubplan_addondetailstb tr td {
    font-size: 11px;
    font-weight: 500;
    padding: 0.25rem 0.75rem;
}

tr.mysubplan_addon_whitebgclrrow td {
    background-color: #fff;
}

tr.mysubplan_addon_whitebgclrrow td {
    background-color: #fff;
}

.mysubplan_addon_rightsidegrayborder {
    border-right: 1px solid #dee2e6 !important;
}

.mysubplan_addon_rightsidegrayborder.textgrayclr {
    color: #dbcaca;
}

span.iconfs {
    color: #fc7d72;
    font-size: 10px;
}

tr.mysubplan_addon_totalamtbgclr td {
    background-color: #f1edf8;
    padding: 0.75rem 0.75rem !important;
}

button.btn.btn-dedauld.mysub-plan_subplan_proccedbtnbgclr {
    background-color: #31719b;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
    padding: 3px 20px 5px 20px;
}

label.mysub_planSub_mymirg_name {
    font-size: 12px;
    color: #777171;
    font-weight: 500;
}

label.my_subplan_palntextbold {
    font-size: 12px;
    font-weight: 500;
}

select.mysupplan_migration_selbox {
    height: calc(1em + 1.1rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    font-weight: 500;
}

label.mysub-plan-Subscribed-mymirg_planname_overflowtext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    max-width: 250px;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1pxsolidrgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
}

/* =================== end Select Addon Details css ===================*/

/* =================== start media code ===================*/

@media (max-width: 767px) {
    h4.mysubcribed-plantextfs {
        right: 0rem;
    }
}

@media (min-width: 768px) and (max-width: 940px) {}

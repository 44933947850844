$color_1: #212529;
$color_2: #fc7d72;
$color_3: #31719b;
$color_4: inherit;
$color_5: #b5b5b5;
$color_6: #fff;
$color_7: #31719b;
$color_8: #777171;
$color_9: #636363;
$color_10: #495057;
$color_11: #a87f7f;
$color_12: #0093ff;
$font-family_1: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",;
$background-color_1: #ffffff;
$background-color_2: #f9f3d7;
$background-color_3: #fff;
$background-color_4: #eee;
$background-color_5: #31719b;
$background-color_6: #2f709a;
$background-color_7: green;
$background-color_8: #f2f2f2;
$border-color_1: #d4d9df;
$border-color_2: #31719b;
$border-color_3: #80bdff;

/*
.tds_form16_step-borboxtime ul.tds_form16_steptimeline li.tds_form16_step04 {
    margin-bottom: 0rem !important;
}
*/
/* ========== mins animation===*/
@keyframes colorchange {
	0% {
		color: #93979b;
	}
	50% {
		color: #31719b;
	}
	100% {
		color: #FFD700;
	}
}
/* ========== end mins animation===*/
/* ============= modal box css ========*/
/* ============= end  modal box css ========*/
/* ====== media code start ===========*/
body {
	background-color: $background-color_1 !important;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.5;
	color: $color_1;
	text-align: left;
	-webkit-font-smoothing: antialiased;
	font-weight: 500 !important;
	font-family: $font-family_1;
}

.tdstcs_certificate_parent{
	.card.tdstcs_certificates_cardbox {
		box-shadow: 0px 3px 10px #ddd;
		border-radius: 10px;
	}


span.issuescon_backbtn {
	font-size: 12px;
	padding: 4px 0;
	// color: $color_2;
	font-weight: 500;
	color:#fc7d72;
}

h4.issuestdstcscert_titlefs {
	text-align: left;
    font-size: 1.2rem;
    position: relative;
    padding-left: 5.6rem;
	margin-right: 20px;
}
span.tds_form16_connectionsuccesstextfs-fs {
	text-align: center;
	font-size: 16px;
	font-weight: 500;
}
span.gstr-trying-textfsbgclr {
	background-color: $background-color_2;
	padding: 3px 40px 5px 40px;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
}
span.tdsits-mendatory-textfs {
	font-size: 1rem;
	color: $color_3;
	font-weight: 500;
}
span.tds_form16_infoicon-clr {
	color: $color_2;
	font-size: 27px;
	vertical-align: text-top;
	color: $color_2;
	font-size: 27px;
	vertical-align: text-top;
}
.leftside-border {
	border-left: 1px solid #ddd;
	border-width: 2.2px;
	padding: 0px 1px 0px 18px;
	border-left: 1px solid #ddd;
	border-width: 2.2px;
	padding: 0px 1px 0px 18px;
}
p.down-alraedytextfs {
	font-size: 14px;
	font-weight: 500;
	font-size: 14px;
	font-weight: 500;
	font-size: 14px;
	font-weight: 500;
}
a.clickhererefer-fs {
	color: $color_3;
	color: $color_3;
}
h4.infoicon-left {
	position: relative;
	left: -1rem;
	position: relative;
	left: -1rem;
}
fieldset {
	display: block;
	margin: 9px 2px 2px !important;
	background-color: $background-color_3;
	padding: 0.35em 0.75em 0.625em !important;
	border: 2px groove (internal value);
}
legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: $color_4;
	white-space: normal;
}
select.tds_issuesconnection_selboxfs_yr {
	font-size: 12px;
	padding: 1px 5px;
	font-weight: 500;
	background-color: $background-color_4;
}
select.tds_issuesconnection_selboxfs_26qno {
	font-size: 12px;
	padding: 1px 5px;
	font-weight: 500;
	background-color: $background-color_4;
}
select.tds_issuesconnection_selboxfs_q01 {
	font-size: 12px;
	padding: 1px 5px;
	font-weight: 500;
	background-color: $background-color_4;
}
p.issuesconn_wetextfs {
    font-size: 12px;
    font-weight: 500;
    position: relative;
    top: -5px;
}
.tds_form16_step-borboxtime {
	ul.tds_form16_steptimeline {
		li {
			list-style: none;
			font-size: 12px;
			margin-bottom: 3rem;
			font-weight: 500;
			&.tds_form16_step06 {
				margin-bottom: 4rem;
			}
		}
	}
}
.leftside_contentbox.stp04 {
	margin-bottom: 0rem;
	top: 1.6rem;
}
ul.tds_form16_steptimeline {
	position: relative;
	padding: 0px 0px;
	li {
		color: $color_5;
		font-weight: 500;
	}
}
span.tds_form16_verticallinebor {
	content: " ";
	background: #d4d9df;
	position: absolute;
	width: 1.5px;
	border-color: $border-color_1;
	height: 19%;
	left: 0.9rem;
	&.tds_form16_bg_greyclr{
		background-color: #fc7d72;
		border-color: #fc7d72;
	}
}
span.tds_form16_verticallinebor.stp01 {background-color: #fc7d72;
    border-color: #fc7d72;
	top: 3%;
}
span.tds_form16_verticallinebor.stp02 {
	top: 40%;
}
span.tds_form16_verticallinebor.stp03 {
	top: 22%;
}


button.btn.btn-default.tds_issuesconnection_raisenew_btnbgclr {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 15px 5px;
    background-color: #ffffff;
    border-radius: 40px;
    color: #31719b;
    margin-right: 5px;
    border: 1px solid #fc7d72;
}
button.btn.btn-default.tds_issuesconnection_raisenew_btnbgclr:hover {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 15px 5px;
    background-color: #fc7d72;
    border-radius: 40px;
    color: #fff;
    margin-right: 5px;
    border: 1px solid #fc7d72;
}

.leftside_contentbox {
	position: relative;
}
p.textbox_checkst_fsclr {
    font-size: 12px;
    font-weight: 500;
    padding-top: 2px;
}

p.textbox_checkst_fsclr.tds_form16_conntext_greyclr {
	color: $color_5;
}
.tds_issuesconnection_rightbox_heightfixed{
	.stp01box, .stp02box, .stp03box, .stp04box, .stp05box{
	height: 60px;
	padding-top: 4px;
  }
  .stp06box{
	height: 75px;
  }
  .stp07box{
	height: 60px;
  }
}

span.mins_sincereq_textfs {
	font-size: 12px;
	position: relative;
	top: 4px;
	font-weight: 500;
}
span.reqsuccess_textclrgreen {
	color: $color_7;
}
p.reqpending_textgrey, span.reqpending_textgrey {
	color: $color_5;
}
.progressbox_width {
	display: inline-flex;
}
.progress.tds_issuesconnection_progressbox {
	.progress-bar {
		padding: 0px 5px;
		background-color: $background-color_6;
	}
}
button.btn.btn-default.tds_issuesconnection_unsignpdf_btnbgclr {
	font-size: 12px;
	font-weight: 500;
	padding: 3px 15px 5px 15px;
	background-color: #fff;
	border-radius: 40px;
	color: #31719b;
	margin-right: 5px;
	border: 1px solid #fc7d72;
	cursor: default;
}

button.btn.btn-default.tds_issuesconnection_resignpdf_textclr {
	font-size: 12px;
	font-weight: 500;
	padding: 3px 15px 5px 15px;
	border-radius: 40px;
	color: $color_3;
	text-decoration: underline;
}
span.tds_connectionsuccesstextfs_addanimation {
	animation: colorchange 5s infinite alternate;
	-webkit-animation: colorchange 5s infinite alternate;
}
.modal.expressconnectorcon_modalbox.show {
	.modal-dialog {
		margin: 4rem auto;
		max-width: 815px;
	}
}
.modal-header.expressconnectorcon_modal_header {
	padding: 0px 15px;
	background-color: $background-color_4;
	h4.modal-title {
		font-size: 1.2rem;
		padding: 3px 0px 5px 0px;
		font-weight: 500;
	}
	button.close {
		top: 6px;
		position: relative;
	}
	button {
		&:focus {
			outline: 1px dotted;
			outline: 0px auto -webkit-focus-ring-color;
		}
	}
}
span.gstr1its-mendatory-textfs {
	font-size: 1rem;
	color: $color_3;
	font-weight: 500;
}
button.btn.btn-default.download-btnbgclr {
	background-color: $background-color_1;
	padding: 0px 5px 5px 5px;
	border-color: $border-color_2;
	font-size: 12px;
	border-radius: 40px;
	color: $color_3;
	font-weight: 500;
}
.modal.Certificates_modalbox.show {
	.modal-dialog {
		margin: 4rem auto 0rem auto;
	}
}
.modal-header.Certificates_modal_header {
	padding: 0px 15px;
	background-color: $background-color_4;
	h4.modal-title {
		font-size: 1.2rem;
		padding: 3px 0px 5px 0px;
		font-weight: 500;
	}
	button.close {
		top: 6px;
		position: relative;
	}
	button {
		&:focus {
			outline: 1px dotted;
			outline: 0px auto -webkit-focus-ring-color;
		}
	}
}
p.certificates_textfs {
	font-size: 12px;
	font-weight: 500;
}
button.btn.btn-default.certificates_okaybtnbgclr {
	font-size: 12px;
	font-weight: 500;
	padding: 3px 25px 5px 25px;
	background-color: $background-color_5;
	border-radius: 40px;
	color: $color_6;
}
label.form-check-label {
	font-size: 12px;
	color: $color_8;
}
span.radiovertical_align {
	vertical-align: super;
	top: 3px;
	position: relative;
}
button.btn.btn-default.certificates_calcelbtnbgclr {
	background-color: $background-color_8;
	color: $color_9;
	font-size: 12px;
	border-radius: 40px;
	padding: 3px 20px 5px 20px;
	font-weight: 500;
}
.modal-header.raisedreqsuccessfully_header {
	padding: 0px 15px;
	background-color: $background-color_4;
	h4.modal-title {
		font-size: 1.2rem;
		padding: 3px 0px 5px 0px;
		font-weight: 500;
	}
	button.close {
		top: 6px;
		position: relative;
	}
	button {
		&:focus {
			outline: 1px dotted;
			outline: 0px auto -webkit-focus-ring-color;
		}
	}
}
button.btn.btn-default.editmobileno_okaybtnbgclr {
	font-size: 12px;
	font-weight: 500;
	padding: 3px 15px 5px 15px;
	background-color: $background-color_5;
	border-radius: 40px;
	color: $color_6;
}
button.btn.btn-default.certificates_donebtnbgclr {
	font-size: 12px;
	font-weight: 500;
	padding: 3px 20px 5px 20px;
	background-color: $background-color_5;
	border-radius: 40px;
	color: $color_6;
}
.input-group-prepend.mobileedit_inputgrp {
	margin-right: -1px;
	padding: 6px 4px;
	border: 1px solid #ced4da;
	border-radius: 0.25rem 0 0 0.25rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.flag_icon {
	object-fit: scale-down;
}
.modal.editmobileno_modalbox.show {
	.modal-dialog {
		margin: 4rem auto 0rem auto;
		max-width: 345px;
	}
}
input.form-control.editmobileno_inputboxfs {
	font-size: 12px;
	&:focus {
		color: $color_10;
		background-color: $background-color_3;
		border-color: $border-color_3;
		outline: 0;
		box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
	}
}
img.imgtop_paddstp01 {
	top: -7px;
	position: relative;
}
img.imgtop_padd {
	top: -3px;
	position: relative;
}
.tds_issuesconnection_rightbox_step02imgbox {
	top: 5rem;
	position: relative;
	text-align: center;
}
.tds_issuesconnection_rightbox_step03imgbox {
	top: 9.8rem;
	position: relative;
	text-align: center;
}
.tds_issuesconnection_rightbox_step04imgbox {
	top: 13.2rem;
	position: relative;
	text-align: center;
}
p.tds_issuesconnection_singedby_textfs {
	font-size: 11px;
	color: $color_11;
	padding-top: 1px;
}
.form-group.zipunsignedpdfgen_btnbox_colwidth {
	max-width: 26.3%;
}
.resignparent {
	position: relative;
}
.tcsresigncertifacte {
	font-size: 12px;
	color: $background-color_5;
	position: absolute;
	width: 100px;
	top: 5px;
	left: 0;
	cursor: pointer;
    font-weight: 500;
    border-radius: 40px;
    color: #31719b;
    text-decoration: underline;
	text-align: left;
}

.digitalsignator_detail {
	position: absolute;
	right: 0;
	top: 36px;
    font-size: 11px;
    color: #a87f7f;
}

.margin_inherit{
	margin-right: inherit;
	margin-left: inherit;
}

}
@media (max-width: 281px) {
	.form16_certif_colwidth_leftside {
		max-width: 22%;
	}
	.issuesconnection_colwidth_rightside {
		max-width: 52%;
	}
	.issuesconnection_colwidth_lastrightside {
		max-width: 22%;
	}
	.tds_issuesconnection_rightbox_heightfixed.stp03box {
		overflow-x: auto;
		height: 7.1rem;
	}
	.tds_issuesconnection_rightbox_step01imgbox {
		img {
			width: 40px;
		}
	}
	.tds_issuesconnection_rightbox_step02imgbox {
		img {
			width: 40px;
		}
		top: 5.4rem;
	}
	.tds_issuesconnection_rightbox_step03imgbox {
		img {
			width: 40px;
		}
		top: 11rem;
	}
	.tds_issuesconnection_rightbox_step04imgbox {
		img {
			width: 40px;
		}
		top: 16rem;
	}
	button.btn.btn-default.tds_issuesconnection_raisenew_btnbgclr {
		font-size: 9px;
		padding: 3px 5px 5px 5px;
	}
	.leftside_contentbox.stp04 {
		margin-bottom: 2rem;
	}
	button.btn.btn-default.tds_issuesconnection_unsignpdf_btnbgclr {
		font-size: 9px;
		padding: 3px 7px 5px 5px;
	}
	img.imgtop_padd {
		top: 6px;
		position: relative;
		width: 10%;
	}
	.form-group.zipunsignedpdfgen_btnbox_colwidth {
		max-width: 100%;
	}
}
@media (min-width: 281px) and (max-width: 767px) {
	.form16_certif_colwidth_leftside {
		max-width: 22%;
	}
	.issuesconnection_colwidth_rightside {
		max-width: 56%;
	}
	.issuesconnection_colwidth_lastrightside {
		max-width: 22%;
	}
	.tds_issuesconnection_rightbox_heightfixed.stp03box {
		overflow-x: auto;
		height: 7.1rem;
	}
	.tds_issuesconnection_rightbox_step01imgbox {
		img {
			width: 40px;
		}
	}
	.tds_issuesconnection_rightbox_step02imgbox {
		img {
			width: 40px;
		}
		top: 7.4rem;
	}
	.tds_issuesconnection_rightbox_step03imgbox {
		img {
			width: 40px;
		}
		top: 14.5rem;
	}
	.tds_issuesconnection_rightbox_step04imgbox {
		img {
			width: 40px;
		}
		top: 19.5rem;
	}
	button.btn.btn-default.tds_issuesconnection_raisenew_btnbgclr {
		font-size: 9px;
		padding: 3px 5px 5px 5px;
	}
	.leftside_contentbox.stp04 {
		margin-bottom: 2rem;
	}
	button.btn.btn-default.tds_issuesconnection_unsignpdf_btnbgclr {
		font-size: 9px;
		padding: 3px 7px 5px 5px;
	}
	img.imgtop_padd {
		top: 6px;
		position: relative;
		width: 10%;
	}
}
@media (max-width: 767px) {
	h4.issuestdstcscert_titlefs {
		padding-right: 0rem;
	}
}
@media (min-width: 768px) and (max-width: 821px) {
	button.btn.btn-default.tds_issuesconnection_unsignpdf_btnbgclr {
		font-size: 8px;
		padding: 3px 15px 5px 15px;
	}
	button.btn.btn-default.tds_issuesconnection_raisenew_btnbgclr {
		font-size: 8px;
	}
	img.imgtop_padd {
		top: 6px;
		position: relative;
	}
	.leftside_contentbox.stp04 {
		top: 1.2rem;
	}
	.form-group.zipunsignedpdfgen_btnbox_colwidth {
		max-width: 33.3%;
	}
	.tds_issuesconnection_rightbox_heightfixed.stp03box {
		height: 8.9rem;
	}
}
@media (min-width: 822px) and (max-width: 913px) {
	.form-group.zipunsignedpdfgen_btnbox_colwidth {
		max-width: 33.3%;
	}
	button.btn.btn-default.tds_issuesconnection_unsignpdf_btnbgclr {
		font-size: 8px;
	}
	button.btn.btn-default.tds_issuesconnection_raisenew_btnbgclr {
		font-size: 9px;
	}
	img.imgtop_padd {
		top: 6px;
		position: relative;
	}
	.leftside_contentbox.stp04 {
		top: 1.2rem;
	}
	.tds_issuesconnection_rightbox_heightfixed.stp03box {
		height: 8.9rem;
	}
}
@media (min-width: 914px) and (max-width: 1025px) {
	.form-group.zipunsignedpdfgen_btnbox_colwidth {
		max-width: 32.3%;
	}
	img.imgtop_padd {
		top: 0px;
	}
}

.topsizepopup{
	margin-top: 150px
}

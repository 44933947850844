.deductee-popup {
  z-index: 999999;
  background-color: #ffffff;
}

/*Drgable */

.deductee-popup-vertical {
  position: fixed;
  /*resize: both; !*enable this to css resize*! */
  overflow: auto;
  top: 0px;
  right: 0px;
  width: 43%;
  height: 100%;
  opacity: 0;
  min-width: 580px;
  max-width: 80%;
  /* z-index: 12; */
}

.deductee-popup-vertical-left {
  position: fixed;
  /*resize: both; !*enable this to css resize*! */
  overflow: auto;
  top: 0px;
  left: 0px;
  width: 43%;
  height: 100%;
  opacity: 1;
  min-width: 580px;
  max-width: 80%;
}

.deductee-popup-horizontal {
  position: fixed;
  /*resize: both; !*enable this to css resize*! */
  overflow: auto;
  bottom: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  min-height: 200px;
  min-width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  max-height: 80%;
}

.deductee-popup-header {
  padding: 0px;
  /* cursor: move; */
  z-index: 10;
}

/*Resizeable*/

.deductee-popup .resizer-left {
  width: 5px;
  height: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: e-resize;
}

.deductee-popup .resizer-up {
  width: 100%;
  height: 5px;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  cursor: n-resize;
  z-index: 101;
}

.w-600
{
	max-width: 600px;
}
.zero_margin_bottom
{
	margin-bottom: 0px!important;
}

.deductee-popup .resizer-both {
  width: 5px;
  height: 5px;
  background: transparent;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: nw-resize;
}
.MyDatePicker
{
  width: 100%;
}

/*NOSELECT*/

.deductee-popup * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.view_btn.focus,.view_btn:focus
{
  outline: 0!important;
  box-shadow: none!important;
}
.deductee-popup-header
{
  border-bottom: 1px solid #dddddd;
}
.border-right
{
  border-right: 1px solid #dddddd;
}

.deductee-popup-head {
  width: 100%;
}

.deductee-overlay
{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .3;
}
.sticky_top{
position: sticky;
top: 0px;
z-index: 2;
background: #fff;
padding-top: 15px;

}
.sticky_bottom_create_deductee
{
position: sticky;
bottom: 0px;
background: #fff;
text-align: center;
padding: 15px;
}
.form-group label,.form-group select
{
  font-size: 11px;
  color:#636363;
}
.form-group select option
{
  font-size: 12px;
  color:#333333;
  font-weight: 500;
}
.form-control:focus {
    box-shadow: none;
}
.view_btn img
{
  width: 40px;
}

.cdf input,label{
  font-size: 11px !important;
  font-weight: 500;
}
.float_card{
    background-color: #fff;
    width: 100%;
    float: left;
    padding: 0 15px;
}

label.switch.listofdempswitch {
  display: inline-block;
  width: 26px;
  height: 11px;
  top: 0.21rem;
  margin: 0rem;
}
span.slider.listofldcswround {
  border-radius: 7px;
  background-color: #000;
}

span.slider.listofldcswround:before {
  content: "";
  left: 5px;
  height: 7.3px;
  width: 3px;
  top: 2px;
  bottom: 2.1px;
  background-color: #fff;
}
span.slider.round.listofldcswround:before {
  border-radius: 18%;
}
input:checked + .slider.listofldcswround:before {
  left: -8px;
}

label.switch.taxregimeswitch {
  display: inline-block;
  width: 29px;
  height: 13px;
  top: 0.21rem;
  margin: 0rem;
}
span.slider.taxregimeswround {
  border-radius: 7px;
  background-color: #28a745;
}

span.slider.taxregimeswround:before {
  content: "";
  left: 1px;
  height: 11px;
  width: 10px;
  top: 1px;
  bottom: 2.1px;
  background-color: #fff;
}
span.slider.round.taxregimeswround:before {
  border-radius: 50%;
}
input:checked + .slider.taxregimeswround:before {
  left: -8px;
}

.switch.btn.swactinacbtnlistd {
  margin-top: -1px;
  min-width: 26px;
  min-height: 11px ;
  width: 26px !important;
  height: 11px;
  border-radius: 34px;
  padding: 0.3rem 0.75rem;
}

.switch.btn.swactinacbtnlistd span.switch-handle.btn.btn-light {
  padding: 13px 1px;
  border-radius: 0px !important;
  background-color: #fff;
  border-color: #fff;
  right: 6px;
}
.switch.btn.off.btn-outline-secondary.swactinacbtnlistdoutlin.swactinacbtnlistd span.switch-handle.btn.btn-light {
  left: 6px;
}

.updatededuct_togglebtnbox {
  position: relative;
  top: -3px;
}

.switch.btn.on.btn-outline-success.w-100.mx-3.f-12.swactinacbtnlistd {
  margin-top: -3px;
  min-height: calc(1.2em + .75rem + 2px);
}
.switch.btn.off.btn-outline-secondary.w-100.mx-3.f-12.swactinacbtnlistd {
  margin-top: -3px;
  min-height: calc(1.2em + .75rem + 2px);
}
span.switch-on.btn.btn-outline-success.swactinacbtnlistdoutlin {
  top: -3px;
  border-color: transparent;
  background-color: #28a745;
  padding-right: 1rem;
}
span.switch-off.btn.btn-outline-secondary.swactinacbtnlistdoutlin {
  top: -3px;
  border-color: transparent;
}

span.updatededuct_deactivetextclr {
  font-size: 12px;
  font-weight: 500;
}

span.updatededuct_activetextclr {
  font-size: 12px;
  font-weight: 500;
  color: green;
  padding-left: 10px;
}


.deductee-popup-horizontal .form-group.col-md-3.pl-0.smmboxml.veticalview {
  display: none;
}

.deductee-popup-vertical .form-group.col-md-3.pl-0.smmboxml.horizontview {
  display: none;
}

.deductee-popup-vertical-left .form-group.col-md-3.pl-0.smmboxml.horizontview {
  display: none;
}

.deductee-popup.deductee-popup-horizontal.creatededcandemp .form-group.col-md-3.veticalview {
  display: none;
}

.deductee-popup-vertical .form-group.col-md-3.pl-0.horizontview {
  display: none;
}

.deductee-popup-vertical-left .form-group.col-md-3.pl-0.horizontview {
  display: none;
}

.deductee-popup-horizontal .form-group.col-md-3.pl-0.veticalview {
  display: none;
}


button.light_color_bg.listdupdeempbtn {
  background-color: #fc7d72;
  color: #ffffff;
  border: 1px solid #fc7d72;
  padding: 7px 43px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
}
button.light-btn.listdupdeempbtngry {
  background-color: #f2f2f2;
  color: #636363;
  border: 1px solid #dddddd;
  padding: 7px 40px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
}
button.dark_color_bg.listdupdeempbtnblu {
  background-color: #31719B;
  color: #ffffff;
  border: none;
  padding: 7px 15px !important;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
}
/*pan verify css*/

.fade.panvermpopu.modal.show .modal-dialog {
  width: 30%;
}
.panvermpopuh.modal-header {
  padding: 7px 17px;
  background-color: #eee;
}
.modal-title.h4.panvermpopuhtit {
  padding: 0px;
  font-size: 1.0rem;
}
.panvermpopufoot.modal-footer {
  padding: 2px 25px;
}
.panvermpopufoot.modal-footer button.btn.btn-secondary {
  padding: 7px 20px;
  background-color: #eee;
  color: #333;
  border: 0px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
}
.panvermpopufoot.modal-footer button.btn.btn-primary {
  padding: 7px 20px;
  background-color: #31719b;
  color: #fff;
  border: 0px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
}
.modal-body.panvermpopubody {
  padding: 2px 12px;
}
.deductee-popup.deductee-popup-horizontal.creatededcandemp .sticky_top {
  top: 0px !important;
}
.deductee-popup.deductee-popup-horizontal.creatededcandemp .css-11unzgr {
  max-height: 60px !important;
}

h6.createDempfs {
  font-size: 0.9rem;
}
.dark_green_color_text{
  color: #20d720;
}
.dark_red_color_text{
  color: red;
}

.k-widget.k-window.windowWrapper .k-window-titlebar.k-dialog-titlebar {
  padding: 7px 11px;
  background-color: #d3d3d3;
}

.k-dialog-titlebar {
  border-color: inherit;
  color: #000000;
  background-color: #d3d3d3;
}

.k-widget.k-window.windowWrapper .k-window-title {
  font-size: 14px;
  color: #000000;
}
.k-widget.k-window.windowWrapper button.k-button-icon.k-button {
  color: #000000 !important;
}

span.k-widget.k-maskedtextbox.form-control.DM_mobileboxborder input.k-textbox {
  border: 0px;
  padding: 0px !important;
  height: calc(0em + 10px);
  vertical-align: initial;
  width: 100%;
}
span.k-widget.k-maskedtextbox.form-control.DM_mobileboxborder input.k-textbox:focus{
  border-color: rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.08);
}
 
/*==================start media code-==================*/

@media  (min-width: 320px) and (max-width: 767px){
	
	.deductee-popup-vertical {
	  min-width: 300px;
	  max-width: 80%;
	}
	.deductee-popup-vertical .form-group.col-md-3.pl-0.zero_margin_bottom.smmboxml {
		margin-left: 15px;
	}
  .deductee-popup-vertical .form-group.col-md-3.pl-0.smmboxml {
    margin-left: 15px;
  }
  .deductee-popup-vertical-left {
	  min-width: 300px;
	  max-width: 80%;
	}
	.deductee-popup-vertical-left .form-group.col-md-3.pl-0.zero_margin_bottom.smmboxml {
		margin-left: 15px;
	}
  .deductee-popup-vertical-left.form-group.col-md-3.pl-0.smmboxml {
    margin-left: 15px;
  }
  .deductee-popup-horizontal .form-group.col-md-3.pl-0.zero_margin_bottom.smmboxml {
    margin-left: 15px;
  }
  .deductee-popup-horizontal .form-group.col-md-3.pl-0.smmboxml {
    margin-left: 15px;
  } 
  .deductee-popup-horizontal .form-group.col-md-2.px-0.smmboxml {
    margin-left: 15px;
    margin-right: 15px;
  } 

  .deductee-popup-horizontal .MyDatePicker {
    width: 100%;
    z-index: 0;
  }
  .fade.panvermpopu.modal.show .modal-dialog {
        width: 95%;
    }


}


@media  (min-width: 768px) and (max-width: 1024px){
    h6.col-md-6.col-sm-6.col-lg-6.col-xl-6.tlfsize {
      font-size: 9px;
  }
  .fade.panvermpopu.modal.show .modal-dialog {
    width: 50%;
  }

}
.txt-ovrflow{
white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;width: 100%;
    display: inline-block;}
    .opacity-1{
      opacity: 1;
    }
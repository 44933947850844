.chanllans_status_list {

  .challanstitle_notformat{
    position: absolute;
    color: #31719b;
    & > span {
      font-size: 11px;
    }
  }
  .challanstitlepos {
    position: absolute;
    right: 12px;
    color: #31719b;

    & > span {
      font-size: 11px;
    }

    &.mapedtrans {
      right: 57%;
    }

    &.challanpost {
      right: 50%;
    }
  }

  .table th {
    padding-bottom: 1.2rem;
    font-size: 12px;
    font-weight: 500;
  }

  a.dash_totalverchall_backtextfs {
    color: #fc7d72;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }

  .table-responsive.table-responsive-xl.table-responsive-lg.table-responsive-md.table-responsive-sm {
    .k-widget.k-grid.table.table-striped.blukimport_tb.scrollvisible {
      .k-grid-content {
        max-height: 480px;

        table.k-grid-table {
          tbody {
            tr td {
              &.challanno_textclr_blurundline {
                color: #31719b;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1450px) {
    .challanstitlepos {
      &.mapedtrans {
        right: 55%;
      }
    }
  }

  .activeverify_colbgclr_lyell {
    background-color: rgb(255 252 219 / 75%) !important;
  }

  .headerscrollscss {
    .k-grid-header {
      background-color: #fff;
      padding-right: 15px !important;
    }
    .k-grid-header-wrap {
      border-color: #fff;
    }
  }

  .k-grid-content.k-virtual-content {
    overflow: auto;
  }
  .header_padenable{
    .k-grid-header{
      padding-right: 17px !important;
      background-color: white;
      .k-grid-header-wrap{
        border-right-width: 0px
      }
    }
  }
}

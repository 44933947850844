.align-center-pagination
{
	width: 100%;
	margin: auto;
	text-align: center;
}
.showmorebox span {
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    color: #31719b;
}
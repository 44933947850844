/*returns*/
.grey_bg
{
	background-color: #fafafa;
	padding:15px;
	width: 100%;
}
.more_btn img
{
	width: 7px!important;
	height: 20px;
}
.white_bg
{
	background-color: #ffffff;
}
.info_img
{
	width: 20px;
}
h4.pbpaddre {
    font-size: 1rem;
}
h4.bigsiez {
    font-size: 1.4rem;
}
h4.pbpaddre.bgclrsect {
    border-bottom: 0px solid #dcdcdc;
    box-shadow: 0px 1px 0px #dcdcdc;
    padding-bottom: 5px;
    background-color: #f1f1f1;
    padding: 8px 6px;
}
.kdk_data_table.challan.tbreturn.kdkreturntb tr td span img {
    width: 5px !important;
    margin: 0px 0px;
}
.kdk_data_table.challan.tbreturn.kdkreturntb tr td .form-inline .form-control {
    width: 80px;
    border-radius: 0px;
    height: 28px;
    background-color: #fff;
}
.kdk_data_table.challan.challantbsentth.mt-0.pt-0 thead tr th {
    text-align: center;
}
.kdk_data_table.challan.tbreturn.kdkreturntb.returndusercreate thead tr th {
    font-size: 13px !important;
}
td.text-right.alignochallanunmap {
    padding-right: 4.5rem;
}
td.text-right.alignochallan {
    padding-right: 46px;
}
.kdk_data_table.challan.tbreturn thead tr th {
    font-size: 13.5px !important;
    padding-top: 4px;
    padding-bottom: 0px;
    text-align: center;

}
.kdk_data_table.challan.tbreturn.kdkreturntb table thead tr td {
    padding-left: 9px;
}
form.form-inline.sboxwrs .multi-select {
    min-width: 85px !important;
}
.kdk_data_table.challan.tbreturn.kdkreturntb form.form-inline.sboxwrs .select-panel {
    height: 130px !important;
}
.kdk_data_table.challan.tbreturn.kdkreturntb form.form-inline label.select-item {
    padding: 1px 2px !important;
}

.kdk_data_table.challan.tbreturn.kdkreturntb form.form-inline span.item-renderer {
    font-size: 9.9px;
    vertical-align: text-bottom;
    z-index: 333;
    position: relative;
    display: inline-flex;
    padding: 3px 0px 0px 0px !important;
    width: 100%;
}
span.mb-0.tdtextsiz {
    font-size: 12px;
}
span.float-right.right_deductee_name.mb-0.tdtextsiz02 {
    font-size: 9px;
}
span.float-right.right_deductee_name.userr {
    font-size: 9px !important;
    padding-top: 8px !important;
}
.grey_bg.paddtoprem {
    padding: 7px 0px;
    box-shadow: 0px 1px 2px #dcdcdc;
}
.card.paddtopbrem {
    padding: 6px 16px;
    background-color: #f2f2f2;
    border-right: 0px solid;
    border-radius: 0px;
    bottom: 0px;
    position: fixed;
    z-index: 29999;

}
span.textsizeat {
    font-size: 12px;
    font-weight: 500;
}
span.float-right.textsizeat {
    font-size: 13.5px;
    font-weight: 500;
}
fieldset.jobspabgclrb {
    background-color: #fcfbef;
}
fieldset.borfeildclr {
    border: 1px solid #fc7d72;
}
fieldset {
    
    display: block;
    margin-left: 2px;
    margin-right: 2px;
    background-color: #fff;
    padding-top: 0.35em;
    padding-bottom: 0.625em;
    padding-left: 0.75em;
    padding-right: 0.75em;
    border: 2px groove (internal value);
}
.duesecbgclr {
   
    padding: 1px 8px;
}
fieldset.fborblue {
    border: 1px solid #31719b;
}
legend.lengwidth01 {
    width: 6rem;
}


legend.lengwidth {
    width: 10rem;
}
legend.lengwidth02 {
    width: 14rem;
}

legend.lengwidth03 {
    width: 7rem;
}
legend.lengwidth04 {
    width: 16rem;
}
legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: -0.4rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

ul.pagination.justify-content-center.paddbpagi {
    margin-bottom: 0px;
}
.justify-content-center {
    margin-bottom: 0px;
}
/*.bgclrbody {
    background-color: #eaeaea!important;
}*/
.bgclrbody {
    background-color: #fff;
}
td.action_btn.iocncentered {
    margin-left: 3rem;
}
img.create_new_return {
    margin-right: 5px;
}

.bortoplg {
    border-top: 1px solid #333;
    display: flex;
    position: relative;
    margin-top: 19px;
}
.bortoplg.borblue {
    border-top: 1px solid #31719b;
}
fieldset.fieldcretchall {
    border: 1px solid #fc7d72;
    width: 100%;

}
.bortoplg.bgcorlclrbrch {
    border-top: 1px solid #fc7d72;
}
fieldset.fieldcretchall.borbule {
    border: 1px solid #31719b;
}
legend.lengentcretchall {
    width: 100%;
    padding: 0px;
}
.fieldcretchallinndev {
    display: flow-root;
}
.challanlefts {
    float: left;
    padding: 0px 5px;
}
.challaninner {
    height: 1px;
    width: 100%;
    position: relative;
}
.newcchallanright {
    float: right;
    padding: 0px 5px;
}
span.mkpophov:hover {
    background-color: #333;
    color: #dcdcdc;
}


/*====media start====*/
@media only screen  and (min-width: 320px) and (max-width: 767px){
    .textclrordd {
        margin-top: 0.3rem !important
    }

}



@media only screen and (min-width: 280px) and (max-width: 319px){
    .fieldcretchallinndev {
        display: block;
        width: 220px !important;
    }
    
    .fieldcretchallinndev.dmhline {
        display: block;
        width: 222px !important;
    }
    .challanlefts.dmhh4 h4 {
        font-size: 7px !important;
    }
    .kdk_data_table.challan.tbreturn.kdkreturntb tr td span img {
        width: 9px !important;
        margin: 0px 0px;
    }
}



@media only screen and (max-width: 360px) {
    .fieldcretchallinndev {
        display: block;
        width: 253px;
    }

    .newcchallanright h4 {
        font-size: 11px;
    }
   
    .challanlefts h4 {
        float: left;
        padding: 0px 0px;
        font-size: 13px;
        margin-top: 8px;
    }

    .challaninner {
        height: 1px;
        width: 8rem;
        position: relative;
    }
    .bortoplg {
    
    z-index: 0;
    display: flex;
    position: relative;
    margin-top: 15px;
}

.fieldcretchallinndev.dmhline {
    display: block;
    width: 265px;
}
.challanlefts.dmhh4 h4 {
    font-size: 10px;
}
.kdk_data_table.challan.tbreturn.kdkreturntb tr td span img {
    width: 9px !important;
    margin: 0px 0px;
}

}
@media only screen and (min-width: 361px) and (max-width: 411px) {
    .fieldcretchallinndev {
        display: block;
        width: 298px;
    }
 
    .newcchallanright h4 {
        font-size: 11px;
    }
   
    .challanlefts h4 {
        float: left;
        padding: 0px 0px;
        font-size: 15px;
        margin-top: 8px;
    }

    .challaninner {
        height: 1px;
        width: 8rem;
        position: relative;
    }
    .bortoplg {
       
        margin-top: 15px;
    }
    .fieldcretchallinndev.dmhline {
        display: block;
        width: 313px;
    }
    .challanlefts.dmhh4 h4 {
        font-size: 12px;
    }
    .kdk_data_table.challan.tbreturn.kdkreturntb tr td span img {
        width: 9px !important;
        margin: 0px 0px;
    }
}
@media only screen and (min-width: 412px) and (max-width: 520px) {
    .fieldcretchallinndev {
        display: block;
        width: 342px;
    }
   
    .newcchallanright h4 {
        font-size: 11px;
    }
   
    .challanlefts h4 {
        float: left;
        padding: 0px 0px;
        font-size: 15px;
        margin-top: 8px;
    }

    .challaninner {
        height: 1px;
        width: 8rem;
        position: relative;
    }
    .bortoplg {
     
        margin-top: 16px;
    }

    .fieldcretchallinndev.dmhline {
        display: block;
        width: 345px;
    }
    .challanlefts.dmhh4 h4 {
        font-size: 15px;
    }
    .kdk_data_table.challan.tbreturn.kdkreturntb tr td span img {
        width: 9px !important;
        margin: 0px 0px;
    }
    
}
@media only screen and (min-width: 521px) and (max-width: 720px) {
    .fieldcretchallinndev {
        display: block;
        width: 459px;
    }
   
    .newcchallanright h4 {
        font-size: 11px;
    }
   
    .challanlefts h4 {
        float: left;
        padding: 0px 0px;
        font-size: 15px;
        margin-top: 8px;
    }

    .challaninner {
        height: 1px;
        width: 8rem;
        position: relative;
    }
    .bortoplg {
     
        margin-top: 16px;
    }

    .fieldcretchallinndev.dmhline {
        display: block;
        width: 462px;
    }
    .challanlefts.dmhh4 h4 {
        font-size: 15px;
    }
    .kdk_data_table.challan.tbreturn.kdkreturntb tr td span img {
        width: 9px !important;
        margin: 0px 0px;
    }
}
@media only screen and (min-width: 641px) and (max-width: 767px) {

    .fieldcretchallinndev {
        display: block;
        width: 460px;
    }
   
    .newcchallanright h4 {
        font-size: 11px;
    }
   
    .challanlefts h4 {
        float: left;
        padding: 0px 0px;
        font-size: 17px;
        margin-top: 8px;
    }

    .challaninner {
        height: 1px;
        width: 8rem;
        position: relative;
    }
    .bortoplg {
     
        margin-top: 16px;
    }
    .fieldcretchallinndev.dmhline {
        display: block;
        width: 467px;
    }
    .challanlefts.dmhh4 h4 {
        font-size: 17px;
    }
    .kdk_data_table.challan.tbreturn.kdkreturntb tr td span img {
        width: 9px !important;
        margin: 0px 0px;
    }
    
}

@media only screen and (max-width: 942px) and (min-width: 768px){

    .fieldcretchallinndev {
        display: block;
        width: 648px;
    }
   
    .newcchallanright h4 {
        font-size: 11px;
    }
   
    .challanlefts h4 {
        float: left;
        padding: 0px 0px;
        font-size: 17px;
        margin-top: 8px;
    }

    .challaninner {
        height: 1px;
        width: 8rem;
        position: relative;
    }
    .bortoplg {
     
        margin-top: 16px;
    }

    .fieldcretchallinndev.dmhline {
        display: block;
        width: 649px;
    }
    .challanlefts.dmhh4 h4 {
        font-size: 17px;
    }
    .kdk_data_table.challan.tbreturn.kdkreturntb tr td span img {
        width: 9px !important;
        margin: 0px 0px;
    }


}
@media only screen  and (min-width: 943px) and (max-width: 1024px){

    .fieldcretchallinndev {
        display: block;
    width: 860px;
    }
   
    .newcchallanright h4 {
        font-size: 11px;
    }
   
    .challanlefts h4 {
        float: left;
        padding: 0px 0px;
        font-size: 17px;
        margin-top: 8px;
    }
    .challaninner {
        height: 1px;
        width: 8rem;
        position: relative;
    }
    .bortoplg {
     
        margin-top: 16px;
    }

    .fieldcretchallinndev.dmhline {
        display: block;
        width: 872px;
    }
    .challanlefts.dmhh4 h4 {
        font-size: 17px;
    }

    
}


/* .tdsvalidateupload_step_borboxtime ul.tds-steptimeline li.tds-step01::after {
  background-color: green;
  left: 10px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  position: absolute;
  margin-top: 21px;
  border: 2px solid #000;
}
.tdsvalidateupload_step_borboxtime ul.tds-steptimeline li.tds-step02::before {
  background-color: green;
  left: 10px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  position: absolute;
  margin-top: 21px;
  border: 2px solid #000;
}
.tdsvalidateupload_step_borboxtime ul.tds-steptimeline li.tds-step02::after {
  background-color: white;
  left: 10px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  position: absolute;
  border: 2px solid #b5b5b5;
  bottom: 0.5rem;
  margin-bottom: 6px;
}
.tdsvalidateupload_step_borboxtime ul.tds-steptimeline li.tds-step02.efilingstatus_activebox::after {
  background-color: red;
  left: 10px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  position: absolute;
  margin-top: 21px;
  border: 2px solid #000;
  margin-bottom: 6px;
} */

fieldset {
  display: block;
  margin: 9px 2px 2px !important;
  background-color: #fff;
  padding: 0.35em 0.75em 0.625em !important;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

fieldset.comp_health_reviewcheck_fs {
  border: 1px solid #fc7d72;
}
fieldset.comp_health_reviewcheck_fs .comp_health_reviewcheck_inndev {
  display: block;
}
fieldset.comp_health_reviewcheck_fs .comp_health_reviewcheck_leftside {
  float: left;
}
fieldset.comp_health_reviewcheck_fs .comp_health_reviewcheck_leftside h4 {
  font-size: 1.2rem;
  font-weight: 500;
  top: 5px;
  padding: 0px 5px;
  position: relative;
}
fieldset.comp_health_reviewcheck_fs .comp_health_reviewcheck_innerhieghtdiv {
  height: 1px;
  width: 100%;
  position: relative;
}
fieldset.comp_health_reviewcheck_fs .comp_health_reviewcheck_rightsidediv {
  float: right;
}
fieldset.comp_health_reviewcheck_fs .comp_health_reviewcheck_topbormiddle {
  border-top: 1px solid #fc7d72;
  display: flex;
  position: relative;
  margin-top: 17px;
}
fieldset.comp_health_reviewcheck_fs button.btn.btn-default.revalidbtnbgclr {
  background-color: #31719b;
  color: #fff;
  border: 0px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 40px;
  padding: 2px 6px 4px 6px;
  top: -2px;
  position: relative;
}

td.text_red {
  color: red;
}

/* table.table.table-borderless.comp_health_review_checkerrors_tb {
  width: 100%;
}
table.table.table-borderless.comp_health_review_checkerrors_tb tbody {
  display: block;
  max-height: 255px;
  overflow-x: auto;
  height: 255px;
}
table.table.table-borderless.comp_health_review_checkerrors_tb tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
} */
table.table.table-borderless.comp_health_review_checkerrors_tb thead tr th {
  padding-top: 0px;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  text-align: center;
}
table.table.table-borderless.comp_health_review_checkerrors_tb tr td {
  font-size: 11px;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
}
table.table.table-borderless.comp_health_review_checkerrors_tb tr td a.textclr_blue {
  color: #31719b;
  font-weight: 700;
}
table.table.table-borderless.comp_health_review_checkerrors_tb tr td .textdisable {
  color: #7d7d7d;
}

button.btn.btn-default.validup_proforef_btn {
  background-color: #31719b;
  color: #fff;
  padding: 3px 10px 5px 10px;
  font-size: 12px;
  font-weight: 500;
  border: 0px;
  border-radius: 40px;
}

.btn.btn-default.validup_downFVUbtn {
  background-color: #31719b;
  color: #fff;
  padding: 4px 10px 5px 10px;
  font-size: 12px;
  font-weight: 500;
  border: 0px;
  border-radius: 40px;
}

fieldset.authsing_fs {
  border: 1px solid #fc7d72;
}
fieldset.authsing_fs legend.authsing_leg {
  width: auto;
}
fieldset.authsing_fs legend.authsing_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0px 5px;
}
fieldset.authsing_fs .form-check.authsing_radiobox {
  font-size: 12px;
  font-weight: 500;
  padding-left: 0px;
}
fieldset.authsing_fs .form-check.authsing_radiobox a {
  color: #31719b;
  text-decoration: none;
  background-color: transparent;
}

fieldset.whetherreguler_fs {
  border: 1px solid #31719b;
}
fieldset.whetherreguler_fs legend.whetherreguler_leg {
  width: auto;
}
legend.whetherreguler_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
}
table.table.table-borderless.whetherreguler_tb tr td {
  font-size: 11px;
  font-weight: 500;
  padding: 0.25rem 0.3rem;
}
select.whetherreguler_selbox {
  font-size: 11px;
  font-weight: 500;
  border: 1px solid #ced4da;
  padding: 0px 3px !important;
}
fieldset.whetherreguler_fs legend.whetherreguler_leg h4 img {
  text-align: right;
  cursor: pointer;
  top: -3px;
  position: relative;
}
fieldset.whetherreguler_fs span.whetherreguler_textleftfs {
  font-size: 11px;
  font-weight: 500;
  color: #E6477D;
}



fieldset.whetherreguler_fs span.whetherreguler_textright {
  float: right;
  font-size: 12px;
  font-weight: 700;
  top: 3px;
  position: relative;
}
span.whetherreguler_textright select {
  width: 100%;
  padding: 0 0.2rem;
  border-color: #ddd;
  font-size: 11px;
  font-weight: 500;
}
fieldset.whetherreguler_fs span.whetherreguler_textright a {
  color: #31719b;
  text-decoration: none;
  background-color: transparent;
}
fieldset.whetherreguler_fs .entertokenno_fs {
  font-size: 11px;
  font-weight: 500;
}
fieldset.whetherreguler_fs .entertokenno_fs input.entertokenno_inputbox {
  height: calc(1.2em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  max-width: 50%;
}
fieldset.whetherreguler_fs .entertokenno_fs input.entertokenno_inputbox:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
}

fieldset.panverify_fs {
  border: 1px solid #fc7d72;
}
fieldset.panverify_fs .panverify_inndev {
  display: block;
}
fieldset.panverify_fs .panverify_inndev .panverify_leftside {
  float: left;
}
fieldset.panverify_fs .panverify_inndev .panverify_leftside h4 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0px 5px;
  top: 5px;
  position: relative;
}
fieldset.panverify_fs .panverify_inndev .panverify_innerhieghtdiv {
  height: 1px;
  width: 100%;
  position: relative;
}
fieldset.panverify_fs .panverify_inndev .panverify_rightsidediv {
  float: right;
}
fieldset.panverify_fs .panverify_inndev button.btn.btn-default.panverify_procebtnbgclr {
  background-color: #fc7d72;
  outline-color: #fc7d72;
  border: 0px;
  padding: 1px 10px 3px 10px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  top: -5px;
  position: relative;
}
fieldset.panverify_fs .panverify_inndev .panverify_topbormiddle {
  border-top: 1px solid #fc7d72;
  display: flex;
  position: relative;
  margin-top: 17px;
}
fieldset.panverify_fs p.panverify_subtextfs {
  font-size: 12px;
  font-weight: 500;
}
fieldset.panverify_fs span.verified__green {
  color: green;
}
fieldset.panverify_fs span.verified_red {
  color: red;
}
fieldset.panverify_fs p.panverify_textfs {
  font-size: 12px;
  font-weight: 500;
}
fieldset.panverify_fs button.btn.btn-default.panverify_proceedbtn {
  background-color: #fc7d72;
  outline-color: #fc7d72;
  border: 0px;
  padding: 3px 10px 5px 10px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

fieldset.panverify_fs.challanverify_borderblue {
  border: 1px solid #31719b !important;
}

.panverify_topbormiddle.challanverify_borderblue {
  border-top: 1px solid #31719b !important;
}

.modal.downloadfvu_popup.show .modal-dialog {
  margin: 3rem auto 0rem auto;
}
.modal.downloadfvu_popup.show .modal-dialog .modal-header.downloadfvu_popup_header {
  background-color: #eee;
  padding: 0px 15px;
}
.modal.downloadfvu_popup.show .modal-dialog .modal-header.downloadfvu_popup_header h4.modal-title {
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 0px;
}
.modal.downloadfvu_popup.show .modal-dialog .modal-header.downloadfvu_popup_header button.close {
  top: 2px;
  position: relative;
}
.modal.downloadfvu_popup.show .modal-dialog .modal-header.downloadfvu_popup_header button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
.modal.downloadfvu_popup.show .modal-dialog p.downloadfvu_popup_textfs {
  font-size: 12px;
  font-weight: 500;
}
.modal.downloadfvu_popup.show .modal-dialog button.btn.btn-default.downloadanybtn {
  background-color: #31719b;
  color: #fff;
  padding: 3px 10px 5px 10px;
  font-size: 12px;
  font-weight: 500;
  border: 0px;
  border-radius: 40px;
}
.modal.downloadfvu_popup.show .modal-dialog button.btn.btn-default.downloadanyfixnowbtn {
  background-color: #31719b;
  color: #fff;
  padding: 3px 10px 5px 10px;
  font-size: 12px;
  font-weight: 500;
  border: 0px;
  border-radius: 40px;
}

.modal.errorsfoundonfvu_popup.show .modal-dialog {
  margin: 3rem auto 0rem auto;
}
.modal.errorsfoundonfvu_popup.show .modal-dialog .modal-header.errorsfoundonfvu_header {
  background-color: #ffcdd2;
  padding: 0px 15px;
}
.modal.errorsfoundonfvu_popup.show .modal-dialog .modal-header.errorsfoundonfvu_header h4.modal-title {
  font-size: 13px;
  font-weight: 500;
  color: red;
  padding: 8px 0px 8px 0px;
}
.modal.errorsfoundonfvu_popup.show .modal-dialog .modal-header.errorsfoundonfvu_header button.close {
  top: 3px;
  position: relative;
}
.modal.errorsfoundonfvu_popup.show .modal-dialog .modal-header.errorsfoundonfvu_header button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.tdsvalidateupload_step_borboxtime {
  padding-left: 13px;
}

.noerror_foundbox {
  margin-top: 0px;
  min-height: 6rem;

}

fieldset.efilingstatus_fs {
  border: 1px solid #ddd;
}
fieldset.efilingstatus_fs legend.efilingstatus_leg {
  width: auto;
}
fieldset.efilingstatus_fs legend.efilingstatus_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0px 5px;
  top: 2px;
  position: relative;
}
fieldset.efilingstatus_fs p.efilingstatus_textfs {
  font-size: 12px;
  font-weight: 500;
}/*# sourceMappingURL=Validate_upload.css.map */



/*-----------  step generation timeline box    ------------*/
li.tds-step01 {
  display: inline-flex;
}
span.tdsvertical_timeline_innerleftbox {
  display: flex;
  min-width: 60px;
}
span.step01_contentbox {
  display: inline-block;
  margin-left: 9px;
  min-height: 4rem;
  max-height: 4rem;

  padding: 5px 0px 7px 0px;
}

.tdsvalidateupload_step_borboxtime ul.tds-steptimeline {
  position: relative;
  padding: 0px 0px;
}
.tdsvalidateupload_step_borboxtime ul.tds-steptimeline li {
  color: #b5b5b5;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  display: flex;
}
.tdsvalidateupload_step_borboxtime span.tds-verticallinebornew {
  content: " ";
  background: #d4d9df;
  position: absolute;
  width: 1.5px;
  border-color: #d4d9df;
  height: 18%;
  min-height: 18%;
  max-height: 100%;
  left: 0.9rem;
  margin-top: 33px;
}

.tdsvalidateupload_step_borboxtime span.tds-verticallinebornew1 {
  content: " ";
  background: #d4d9df;
  position: absolute;
  width: 1.5px;
  border-color: #d4d9df;
  height: 66%;
  min-height: 66%;
  max-height: 100%;
  left: 0.9rem;
  margin-top: 33px;
}

.tdsvalidateupload_step_borboxtime li.tds-step03 {
  position: relative;
  margin-bottom: 0rem !important;
  top: 0rem;
}
.tdsvalidateupload_step_borboxtime .tdsvalidateupload_step_borboxtime span.tds-verticallinebornew.stp02.noerrorfound_timebox02 {
  min-height: 34% !important;
  margin-top: 32px;
  height: 34% !important;
}
.tdsvalidateupload_step_borboxtime li.tds-step03.noerrorfound_timebox03 {
  padding-top: 0.1rem;
  position: relative;
  margin-bottom: 2rem !important;
}
.tdsvalidateupload_step_borboxtime span.tds-verticallinebornew.noerrorfound_timebox02 {
  min-height: 15% !important;
}

.validup_generatefvu_btn {
  background-color: #31719b;
  color: #fff;
  padding: 3px 10px 5px 10px;
  font-size: 12px;
  font-weight: 500;
  border: 0px;
  border-radius: 40px;
  position: relative;
}




span.tds_verttimeline_dotbox {
  left: 10px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  position: absolute;
  margin-top: 21px;
}
span.tds_verttimeline_dotbox.dotbox_activebgclr_green {
  background-color: green;
  border: 2px solid #000;
}
span.tds_verttimeline_dotbox.dotbox_activebgclr_red {
  background-color: red;
  border: 2px solid #000;
}
span.tds_verttimeline_dotbox.dotbox_activebgclr_white {
  background-color: #fff;
  border: 2px solid #ddd;
}
span.tds_verttimeline_dotboxafter.dotbox_activebgclr_white {
  left: 10px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  position: absolute;
  bottom: 4px;
}

span.step01_contentbox.review_error_contentbox {
  min-height: 315px;
  height: 315px;
  max-height: 315px;
  width: 100%;
}
span.tds-verticallinebornew.review_error_vline.stp02 {
  height: 58%;
  min-height: 58%;
  max-height: 100%;
}

span.contentbox_step03 {
  display: inline-block;
  margin-left: 9px;
  max-width: 90%;
  min-width: 90%;
  width: 90%;
  padding-top: 3px;
}

span.tds-verticallinebornew.review_error_vline.stp01 {
  height: 27% !important;
  min-height: 27% !important;
  max-height: 100%;
}
span.tds_verttimeline_dotbox.review_error_afterdotbox.dotbox_activebgclr_white {
  bottom: 11px;
}

span.review_error_vlinestep01 {
  height: 8% !important;
  min-height: 8% !important;
}



/*----------- end  step generation timeline box    ------------*/



/*----- fvu gen. challan verifid   -------*/
/* 
.challanverifparent .dash_panstatus_comp_border {
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  top: auto;
  margin-top: -23px;
  right: 17px;
}



.challanverifparent .challanverifiedbox .front-panstatus, .back-panstatus {
  right: 0;
  top: 0;
  transition: transform 1s;
  backface-visibility: hidden;
}
.challanverifiedbox.back-panstatus {
  position: absolute;
  transform: rotateY(-180deg);
  overflow: hidden;
  right: 0;
  top: 0;
  left: 5px;
} */

fieldset.likeydefault_found_fs {
  border: 1px solid #fc7d72;
}
legend.likeydefault_found_leg {
  width: auto;
}
legend.likeydefault_found_leg h4 {
  font-size: 1.2rem;
  font-weight: 500;
  position: relative;
  top: 1px;
}

/* .challanverify_contentbox {
  position: relative;
  height: 300px;
  min-height: 300px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
} */

.challanverify_mediabox {
  cursor: pointer;
}

.challanverify_roundborderbox {
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 5px 5px 5px 5px;
  max-width: 100%;
  width: auto;
  vertical-align: middle;
  height: auto;
  min-height: 64px;
  max-height: 100%;
  min-width: 67px;
  width: 67px;
  max-width: 100%;
  text-align: center;
  box-shadow: 0px 1px 3px #ddd;
}
.smallno_textfs {
  font-size: 13px;
  font-weight: 700;
  position: relative;
  width: 57px;
  white-space: inherit;
  overflow: hidden;
  word-wrap: break-word;
  vertical-align: middle !important;
  top: 1rem;
}
.challanverify_textclr_red {
  color: #512da8;
}
.challanverify_textclr_coral {
  color: #2296f3;
}
.challanverify_textclr_blue {
  color: #22f333;
}
.challanverify_textclr_yellow {
  color: #009588;
}
.challanverify_textclr_orange {
  color: #fc7d72;
}
.challanverify_mediabox:last-child {
  border-bottom: 0px !important;
}
.backface_panstatus_card.card {
  padding: 10px 10px;
  cursor: pointer;
}

.likelydefault_textbox h4.likely_titlefs {
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
}

.likelydefault_textbox p.likely_textfs {
  font-size: 11px;
  font-weight: 500;
  color: #31719b;
}



p.challanverify_pan_unverufy_textfs {
  font-size: 12px;
  font-weight: 500;
  color: red;
}

p.challanverify_comp_unverufy_textfs {
  font-size: 12px;
  font-weight: 500;
  color: green;
}

p.challanverify_challan_textfs {
  font-size: 12px;
  font-weight: 500;
 color: #31719b;
}

p.challanverify_latefeesdef_textfs {
  font-size: 12px;
  font-weight: 500;
  color: green;
}

/*----- fvu gen. challan verifid   -------*/


fieldset.comp_health_reviewcheck_fs table.table.table-borderless.ReviewFixErrors_tbheight {
  width: 100%;
}
fieldset.comp_health_reviewcheck_fs table.table.table-borderless.ReviewFixErrors_tbheight thead {
  display: block;
  max-height: 232px;
  overflow-x: auto;
}
fieldset.comp_health_reviewcheck_fs table.table.table-borderless.ReviewFixErrors_tbheight tbody {
  display: block;
  max-height: 232px;
  overflow-x: auto;
}

fieldset.comp_health_reviewcheck_fs table.table.table-borderless.ReviewFixErrors_tbheight tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}


   
/* 100 show stoppers right side drawer*/

/*------ right side certifbox css-----------*/
.Computationsumm_validup_rightsidemodal {
  position: fixed;
  padding: 5px 12px 5px 12px;
  background-color: #ffffff;
  z-index: 2;
  top: 33%;
  transform: rotate(270deg);
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
  right: -3rem;
  cursor: pointer;
  /* animation: instmappsummanimat; */
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-direction: alternate-reverse;
  font-size: 15px;
  font-weight: 500;
  color: red;
  border-color: red;
  border: 1px solid red;
  border-width: 2px;

}

/*------ end right side certifbox css-----------*/

@media  (max-width: 767px){

  fieldset.comp_health_reviewcheck_fs table.table.table-borderless.ReviewFixErrors_tbheight thead, tbody {
    display: block;
    max-height: 120px;
    overflow-x: auto;
  }

}



fieldset.validupload_reviewcheck_fs {
  border: 1px solid #fc7d72;
}
.validupload_reviewcheck_inndev {
  display: block;
}
.validupload_reviewcheck_leftside {
  float: left;
}
.validupload_reviewcheck_leftside h4 {
  font-size: 1.2rem;
  font-weight: 500;
  top: 5px;
  padding: 0px 5px;
  position: relative;
  color: #000;
}
.validupload_reviewcheck_innerhieghtdiv {
  height: 1px;
  width: 100%;
  position: relative;
}
.validupload_reviewcheck_rightsidediv {
  float: right;
}
.validupload_reviewcheck_rightsidediv button.btn.btn-default.revalidbtnbgclr {
  background-color: #31719b;
  color: #fff;
  border: 0;
  font-size: 12px;
  font-weight: 500;
  border-radius: 40px;
  padding: 2px 6px 4px;
  top: -5px;
  position: relative;
}
.validupload_reviewcheck_topbormiddle {
  border-top: 1px solid #fc7d72;
  display: flex;
  position: relative;
  margin-top: 17px;
}

td.validup_Errortable_textoverlfow {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

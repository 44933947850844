* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", ;
}

.Interest_allocation_maindiv {

    & table.table.table-striped.Interest_fees_amt_tb tr th {
        font-size: 12px;
        text-align: center;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        border: 0px;
        vertical-align: baseline;
    }

    & table.table.table-striped.Interest_fees_amt_tb tr td {
        font-size: 11px;
        padding: 0.25rem 0.75rem;
        font-weight: 500;
        vertical-align: baseline;
    }

    & .Interest_fees_rightborder {
        border-right: 1px solid #ddd !important;
    }

    & .Interest_fees_textclr_red {
        color: red;
    }

    & .Interest_fees_textclr_green {
        color: green;
    }

    & td.Interest_fees_amt_listofch_textalign {
        vertical-align: middle !important;
        font-weight: 700;
        background-color: rgba(253, 255, 242, 0.9294117647);
    }



    & span.unconsumed_textclr_blue {
        color: #2f709a;
        font-size: 17px;
        font-weight: 700 !important;
    }


    & button.btn.btn-defualt.Interest_fees_amt_autoall {
        background-color: #2f709a;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        border-radius: 40px;
        padding: 3px 10px 5px 10px;
    }

    & button.btn.btn-defualt.Interest_fees_amt_savebtn {
        background-color: #fc7d72;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        border-radius: 40px;
        padding: 3px 20px 5px 20px;
    }

    & button.btn.btn-defualt.Interest_fees_amt_paybtn {
        background-color: #2f709a;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        border-radius: 40px;
        padding: 3px 20px 5px 20px;
        position: relative;
        top: 0px;
    }

    & .Interest_allocated_celbgclr_lightyell {
        background-color: #fff8dc;
    }

    & .Interest_allocated_celbgclr_liggtgrey {
        background-color: #fdf4e7;
    }

    & .Interest_allocated_celbgclr_lightblue {
        background-color: rgb(238 246 255);

    }

    & .insfees_border_bottomblue {
        border-bottom: 1px solid #0465ff !important;
    }

    .textclr_bluelink {
        color: rgb(0, 0, 255);
        cursor: pointer;
    }

    & button.btn.btn-default.inerestall_offsetbtn {
        background-color: #2f709a;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        border-radius: 40px;
        padding: 0px 5px 2px 5px;
    }

    .textclr_bluelink {
        color: rgb(0, 0, 255);
        cursor: pointer;
    }

    .form-control-interests-mapping {
        display: block;
        width: 100%;
        height: calc(1rem + 0.3rem + 1px);
        padding: 0.375rem 0.75rem;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &.error {
            border: 1px solid #fc7d72;
            border-radius: 0.25rem;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        }

    }

    .form-control-interests-mapping:disabled,
    .form-control-interests-mapping[readonly] {
        background-color: #e9ecef !important;
    }

    .superscript {
        position: relative;
        top: -0.3em;
        font-weight: bold;
        color: #f31700;
    }

    .superscript-pd {
        padding-left: 25px;
        ;
    }

    .dropdown.tds_returnflow_challan_hover_link {
        display: none;
    }

    td.tds_returnflow_challan_hoverbox:hover .dropdown.tds_returnflow_challan_hover_link {
        z-index: 999;
        position: absolute;
        display: block;

        & span.dropdown-content.tds_returnflow_challan_dropcont {
            position: absolute;
            background-color: #f9f9f9;
            max-width: 500px;
            min-width: 170px;
            width: 170px;
            box-shadow: 0 4px 9px 0px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            padding: 0px 8px;

            &.positionclass {
                bottom: 1.7rem;
            }
        }

        & ul.tds_returnflow_challan_hoverlist {
            list-style: none;
            padding: 7px 4px 7px 4px;
            margin-bottom: 0px;

            & li {
                font-size: 11px;
                font-weight: 500;
                line-height: 19px;

                & span.challan_hoverlist_cellwidthleftbox {
                    text-align: left;
                }
            }
        }
    }

    // .interestsmappings .k-grid-content {
    //     overflow-y: auto;
    // }

}

.k-widget.k-grid.table.table-striped.interestsmappings tr td{
    font-size: 11px!important;
    font-weight: 500;
    vertical-align: middle;
    padding: 0.25rem 0.75rem;
}

.dividerboder_middle hr.hrborderclr {
    border-top: 3px dashed rgba(0, 0, 0, .1);
}


.late_deductee_deposit_scrollingfixed thead,
tbody.late_deductee_deposit_innertbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.late_deductee_deposit_scrollingfixed tbody.late_deductee_deposit_innertbody {

    display: block;
    // max-height: 200px;
    // overflow-x: auto;
}


button.btn.btn-defualt.Interest_fees_amt_proceedbtnbgclr {
    background-color: #2f709a;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    border-radius: 40px;
    padding: 3px 10px 5px 10px;
}

button.btn.btn-defualt.Interest_fees_amt_relookbtn {
    background-color: #fc7d72;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    border-radius: 40px;
    padding: 3px 10px 5px 10px;
}

.intallocaT_hrlinebox hr.intallocaT_hrlineclr {
    border-top: 2px dashed rgba(0, 0, 0, 0.1);
    margin: 8px 0px;
}

td.int_textclr_blueundline {
    color: #31719b;
    text-decoration: underline;
    cursor: pointer;
}


.modal.int_totalamt_modalbox.show .modal-dialog {
    margin: 2rem auto;

    & .modal-header.int_totalamt_header {
        background-color: #eee;
        padding: 0px 15px;

        & h4.modal-title {
            font-size: 1rem;
            font-weight: 500;
            padding: 4px 0px 4px 0px;
        }

        & button.close {
            top: 3px;
            position: relative;
        }
    }


    form.form.form-row.adjamt_againsttds_frmmodal {
        & .form-group {
            margin-bottom: 2px;
        }

        & label {
            font-size: 12px;
            color: #777171;


        }

        & input.form-control.text-right.adjamt_against_input {
            height: calc(1.2em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 12px;
            font-weight: 500;
        }

        & input.form-control.text-right.adjamt_against_input:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.0rem rgba(0, 123, 255, .25);
        }


    }

    & button.btn.btn-default.\35 othermappedchallbtn {
        background-color: #31719b;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        border-radius: 40px;
        border: 0;
        padding: 4px 20px 7px 20px;
    }



}

.interestMapping.k-widget.k-window.windowWrapper .k-window-content,
.k-prompt-container {
    padding: 5px 0px;
    padding-left: 20px;
}

.interestsmappings .k-filterable>.k-cell-inner .k-link {
    cursor: default;
}

.interestsmappings .k-cell-inner .k-link {
    cursor: default;
}

.k-numerictextbox .k-select {
    display: none;
}

.lateInterestLiabilityPopup .k-grid-content {
    overflow-y: auto;
}

td.Interest_fees_toprow_rightborder {
    border-right: 1px solid #fefda9 !important;
}

table.table.table-borderless.Interest_fees_amttop_tb tr  {
    td{ 
        font-size: 11px;
        padding: 0.25rem 0.75rem;
        font-weight: 500;
        vertical-align: baseline;
    }
}

.intressallocationboxleft {
    vertical-align: middle !important;
    background-color: rgba(253, 255, 242, 0.9294117647);
    padding: 2px 5px 9px 5px;
    border: 1px solid #ddd;

    p.unconsumed_textclr_box {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0px;
    }
   span.unconsumed_textclr_fs {
    font-style: italic;
    color: #fc7d72;
    font-size: 13px;
    font-weight: 500;
    }
    span.autoall_btnbox_paddtop {
        position: relative;
        top: 5px;
    }
}


.interstallo_striknotebox {
    box-shadow: 1px 1px 6px 1px #333;

    table.interstall_innertable_width {
      
        background: linear-gradient(357deg, #fefda9 25%, #fff);
        box-shadow: 0px 2px 9px #333;

        & 
        .interstall_innertable_striknote_pin {
            --pin-color: #d02627;
            --pin-dark: #9e0608;
            --pin-light: #fc7e7d;
            position: absolute;
            width: 60px;
            height: 50px;
            right: 50%;
            top: -1.3rem;
            right: -0%;
        }
        
        .interstall_innertable_striknote_metal {
            position: absolute;
            width: 4px;
            height: 16px;
            background: linear-gradient(to right, #808080, 40%, #eae8e8, 50%, #808080);
            border-radius: 0 0 30% 30%;
            transform: rotate(50deg);
            transform-origin: bottom left;
            top: 18px;
            right: 3.3rem;
            border-bottom: 1px solid #808080;
        }
        
        .interstall_innertable_striknote_bottom_circle{
            position: absolute;
            right: 34px;
            width: 15px;
            top: 1rem;
            height: 15px;
            border-radius: 50%;
            background-color: var(--pin-color);
            background: radial-gradient(circle at bottom right, var(--pin-light), 25%, var(--pin-dark), 90%, var(--pin-color));
        }
        
        .interstall_innertable_striknote_bottom_circle::before {
            content: "";
            position: absolute;
            top: 3px;
            left: 3px;
            width: 5px;
            height: 10px;
            transform: rotate(55deg);
            transform-origin: 100% 100%;
            border-radius: 0 0 40% 40%;
            background: linear-gradient(to right, var(--pin-dark), 30%, var(--pin-color), 90%, var(--pin-light));
        }
        
        .interstall_innertable_striknote_bottom_circle::after {
            content: "";
            position: absolute;
            right: -4px;
            top: -2px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: radial-gradient(circle at right, var(--pin-light), 30%, var(--pin-color), var(--pin-dark) 80%);
        }
    }
}

/*--------- media code   ----------*/
@media (max-width: 767px) {
    .intressallocationboxleft span.autoall_btnbox_paddtop {
        display: block;
        position: relative;
        top: 5px;
    }
}

@media (min-width: 768px) and (max-width: 821px) {
    .intressallocationboxleft span.autoall_btnbox_paddtop {
        display: block;
        position: relative;
        top: 5px;
    }
    .Interest_allocation_maindiv span.unconsumed_textclr_blue {
        font-size: 11px;
    }
    .intressallocationboxleft span.unconsumed_textclr_fs {
        font-size: 9px;
    }
    .intressallocationboxleft {
        padding: 4px 5px 7px 5px;
    }
    .intressallocationboxleft p.unconsumed_textclr_box {
        font-size: 12px;
    }
}

@media (min-width: 822px) and (max-width: 913px) {
    .intressallocationboxleft span.autoall_btnbox_paddtop {
        display: block;
        position: relative;
        top: 5px;
    }
    .Interest_allocation_maindiv span.unconsumed_textclr_blue {
        font-size: 11px;
    }
    .intressallocationboxleft span.unconsumed_textclr_fs {
        font-size: 9px;
    }
    .intressallocationboxleft {
        padding: 14px 5px 14px 5px;
    }
    .intressallocationboxleft p.unconsumed_textclr_box {
        font-size: 12px;
    }
}

@media (min-width: 914px) and (max-width: 1023px) {
    .intressallocationboxleft span.autoall_btnbox_paddtop {
        display: block;
        position: relative;
        top: 5px;
    }
    .Interest_allocation_maindiv span.unconsumed_textclr_blue {
        font-size: 11px;
    }
    .intressallocationboxleft span.unconsumed_textclr_fs {
        font-size: 9px;
    }
    .intressallocationboxleft {
        padding: 14px 5px 14px 5px;
    }
    .intressallocationboxleft p.unconsumed_textclr_box {
        font-size: 12px;
    }
}
@media (min-width: 1024px) and (max-width: 1170px) {
    .Interest_allocation_maindiv span.unconsumed_textclr_blue {
        font-size: 11px;
    }
    .intressallocationboxleft span.unconsumed_textclr_fs {
        font-size: 9px;
    }
    .intressallocationboxleft {
        padding: 14px 5px 14px 5px;
    }
}
@media (min-width: 1172px) and (max-width: 1281px) {
    .Interest_allocation_maindiv span.unconsumed_textclr_blue {
        font-size: 14px;
    }
    .intressallocationboxleft span.unconsumed_textclr_fs {
        font-size: 11px;
    }
    .intressallocationboxleft {
        padding: 13px 5px 14px 5px;
    }
}
@media (min-width: 1282px) and (max-width: 1401px) {
    .Interest_allocation_maindiv span.unconsumed_textclr_blue {
        font-size: 14px;
    }
    .intressallocationboxleft span.unconsumed_textclr_fs {
        font-size: 12px;
    }
    .intressallocationboxleft {
        padding: 13px 5px 14px 5px;
    }

}
@media (min-width: 1402px) and (max-width: 1450px) {
    .Interest_allocation_maindiv span.unconsumed_textclr_blue {
        font-size: 14px;
    }
    .intressallocationboxleft span.unconsumed_textclr_fs {
        font-size: 12px;
    }
    .intressallocationboxleft {
        padding: 14px 5px 14px 5px;
    }

}
@media (min-width: 1451px) and (max-width: 1500px) {
    .Interest_allocation_maindiv span.unconsumed_textclr_blue {
        font-size: 14px;
    }
    .intressallocationboxleft span.unconsumed_textclr_fs {
        font-size: 12px;
    }
    .intressallocationboxleft {
        padding: 6px 5px 9px 5px;
    }

}

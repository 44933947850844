body{   
    background-color: #ffffff!important;
     font-size: 1rem;
     font-weight: normal ;
     line-height: 1.5;
     color: #212529;
     text-align: left;
     -webkit-font-smoothing: antialiased;
     font-weight: 500 !important;
     font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",;
 }
 .btn.btn-default.gstrretregbtnclr {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 28px;
    color: #fff;
    padding: 4px 15px 5px 15px;
    font-size: 12px;
    font-weight: 500;
}
 .backbtntext {
     color: #fc7d72;
     font-size: 12px;
 }
 /* button.mysubplan_backbtn-toppadd {
    top: -6px;
    position: relative;
} */
 
h4.mysubcribed-plantextfs {
    font-size: 1.2rem;
    font-weight: 500;
    position: relative;
    top:0px;
}
 button.btn.btn-dedauld.renewpaln-btnbgclr {
     background-color: #31719b;
     color: #fff;
     font-size: 12px;
     font-weight: 500;
     border-radius: 40px;
     padding: 5px 20px 7px 20px;
 }
 h4.mysubgstplan-fs {
     font-size: 1.2rem;
     font-weight: 500;
 }
 span.form-check-inline.mysub-plan-Subscribed-box {
     font-size: 12px;
     font-weight: 500;
 }
 label.form-check-label.mysub-plan-Subtextfsbold.subplantxtclr-coral {
     color: #fc7d72;
     font-size: 12px;
     font-weight: 700;
 }
 label.form-check-label.mysub-plan-Subtextfs {
     font-size: 12px;
     font-weight: 500;
     padding: 0px 5px;
 }
 label.form-check-label.mysub-plan-Subtextfs.textboldsize {
     font-weight: 700;
     color: #31719b;
 }
 label.form-check-label.Subtext-borderclr {
     padding-left: 7px;
     color: rgb(177, 171, 171);
     font-size: 13px;
     top: 0px;
     position: relative;
 }
 
 table.table.table-striped.mysubplan-table thead tr th {
     border-top: 0;
     font-size: 12px!important;
     text-align: center;
     padding: 5px 0;
     font-weight: 500;
     vertical-align: bottom;
 }
 
 
 table.table.table-striped.mysubplan-table tr td {
     font-size: 12px;
     padding: 0.25rem 0.75rem;
 }
 span.mysubplan-tdtextbold {
     font-weight: 700;
 }
 
 td.mysubplan-overflow td {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     word-wrap: break-word;
     max-width: 150px;
 }
 h4.mysubplan-histfs {
     font-size: 1.2rem;
     font-weight: 500;
 }
 
 /* =================== start media code ===================*/
 
 @media (max-width: 767px){
     h4.mysubcribed-plantextfs {
         right: 0rem;
     }
 }
 
 @media (min-width: 768px) and (max-width: 940px){
     
 }

* {
    margin: 0;
    padding: 0;
}

body{   
   background-color: #ffffff!important;
    font-size: 1rem;
    font-weight: normal ;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",;
}

.modal .createdeductee_trans_modal.show .modal-dialog {
    margin: 0rem -1rem 0rem auto;
    cursor: auto;
    position: absolute;
    overflow: auto;
    top: 0;
    right: 0;
    width: 43%;
    height: 100%;
    min-width: 43%;
    max-width: 80%;
    background-color: #fff;

        & .modal-content.creatededucteetrans_contentbox {
            border-radius: 0px;
            max-height: 100%;
            height: 100vh;
            min-height: 100%;
            background-color: #fff;
            border: 0px solid rgba(0,0,0,.2);
            overflow-x: hidden;

            & .modal-header.createdeductee_trans_modal_header {
                padding: 0px 15px;
                background-color: #eee;

                & h4.modal-title {
                    font-size: 1rem;
                    padding: 4px 0px 4px 0px;
                    font-weight: 500;
                }

                & button.close {
                    position: relative;
                    top: 3px;
                    right: 8px;
                }

                & button:focus {
                    outline: 1px dotted;
                    outline: 0px auto -webkit-focus-ring-color;
                }
                
                
            }
        }


        /* form css*/
        & form.createdeductee_trans_modal_form {
            padding-right: 5px;

            & .form-group {
                margin-bottom: 0.3rem;
            }

            & label {
                font-size: 12px;
                color: #777171;
            }
            & input.form-control {
                font-size: 11px;
                font-weight: 500;
                height: calc(1.2em + 0.75rem + 2px);
                border: 1px solid #ced4da;
                color: #e0e0e0;
            }

            

            & select.createdeductee_trans_selbox {
                font-size: 11px;
                font-weight: 500;
                height: calc(1.2em + 0.75rem + 2px);
                border: 1px solid #ced4da;
                min-width: 100%;
                width: 100%;
            }

            & span.interesttextfs {
                font-size: 9px;
                font-weight: 500;
                position: relative;
                top: -5px;
                color: #848484;
            }

            & button.btn.btn-default.creaatededucteetrans_savebtn {
                font-size: 12px;
                padding: 2px 20px 5px 20px;
                background-color: #31719b;
                border-radius: 40px;
                font-weight: 500;
                color: #fff;
            }
            button.btn.btn-default.creaatededucteetrans_savenowbtn {
                font-size: 12px;
                padding: 2px 10px 5px 10px;
                background-color: #fc7d72;
                border-radius: 40px;
                font-weight: 500;
                color: #fff;
            }
            button.btn.btn-default.creaatededucteetrans_cancel {
                font-size: 12px;
                font-weight: 500;
                text-decoration: underline;
                color: #31719b;
            }

        }


}

.modal.mappedchallantb_popup26q_modal.show {
    background-color: #22222282;

    & .modal-dialog {
        margin: 4rem auto 0rem auto;
        max-width: 700px;

        & .modal-header.mappedchallantb_popup26q_modal_header {
            background-color: #eee;
            padding: 0px 15px;

            & h4.modal-title {
                font-size: 1rem;
                font-weight: 500;
                padding: 4px 0px 4px 0px;
            }
           & button.close {
                top: 3px;
                position: relative;
            }
            & button:focus {
                outline: 1px dotted;
                outline: 0px auto -webkit-focus-ring-color;
            }
        }

        & table.table.table-striped.mappedchallantb_popup26q_modal_tb {
            & thead tr th {
                font-size: 12px;
                text-align: center;
                border-top: 0;
                font-weight: 500;
                padding-top: 5px;
                padding-bottom: 5px;
            }

            & tr td{
                font-size: 11px;
                font-weight: 500;
                padding: 0.25rem 0.75rem;
            }
        }

    }
}


.modal.Reason_popup26q_modalpopup.show {
    background-color: #22222282;

    & .modal-dialog {
        margin: 4rem auto 0rem auto;

        & .modal-header.Reason_modal_header {
            background-color: #eee;
            padding: 0px 15px;

            & h4.modal-title {
                font-size: 1rem;
                font-weight: 500;
                padding: 4px 0px 4px 0px;
            }
           & button.close {
                top: 3px;
                position: relative;
            }
            & button:focus {
                outline: 1px dotted;
                outline: 0px auto -webkit-focus-ring-color;
            }
        }

        & .show-more-height {
            height: 65px; 
            overflow:hidden; 
        }
       & ul.ullistbox li {
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            list-style: none;
         }

         .showmore_box a {
            font-size: 12px;
            color: #2e729b;
            cursor: pointer;
        }

    }
}

::placeholder{
    font-size: 12px;
    color: #e0e0e0;
}

p.altsave_textfsclr {
    font-size: 11px;
    font-weight: 500;
    color: #777171;
}
span.createdeductee_trans_subtextfs {
    font-size: 9px;
    font-weight: 500;
    color: #31719b;
    padding-top: 2px;
    font-style: italic;
}
span.createdeductee_trans_rupsign {
    position: absolute;
    left: 10px;
    top: 34px;
    font-size: 14px;
    background-color: #fff;
    padding: 0px 3px 0px 0px;
}

[aria-expanded="false"] > .expanded,
[aria-expanded="true"] > .collapsed {
  display: none;
}
.modal.createdeductee_trans_modal.show {
    z-index: 1049;
}
span.k-widget.k-autocomplete.form-control.createdt_inputtext input.k-input {
    padding: 0.5rem 0.75rem;
    height: 1.2rem;
}

button.btn.btn-default.creaatededucteetrans_savebtn {
    font-size: 12px;
    padding: 2px 20px 5px 20px;
    background-color: #31719b;
    border-radius: 40px;
    font-weight: 500;
    color: #fff;
}
button.btn.btn-default.creaatededucteetrans_savenowbtn {
    font-size: 12px;
    padding: 2px 10px 5px 10px;
    background-color: #fc7d72;
    border-radius: 40px;
    font-weight: 500;
    color: #fff;
}

li:has( span.talrik-auto-select){
    background-color: #ffd8d5
}
.mapped_challan_combobox .k-dropdown-wrap .k-icon.k-clear-value.k-i-close{
    // display: none;
    right: 1.8rem
} 

span.k-widget.k-autocomplete.form-control.dt_dnameinputbox span.k-searchbar input.k-input {
    height: 0px;
    padding: 0.5rem 0rem;
}
span.k-widget.k-combobox.k-combobox-clearable.mapped_challan_combobox span.k-select {
    border-color: #fff;
    color: #424242;
    // background-color: #f5f5f500;
}
.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25) !important;
}
span.k-widget.k-autocomplete.form-control.dt_dnameinputbox :focus-within {
    border-color: rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.08);
}
span.k-widget.k-autocomplete.form-control.dt_dnameinputbox {
    border: 1px solid #ced4da;
}
span.k-widget.k-autocomplete.form-control.dt_dnameinputbox :focus {
    border-color: rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.08);
}
.datepay_errorsmsbox {
    float: left;
}

span.addnewded_textfs {
    color: #31719b;
    font-size: 11px;
    font-weight: 500;
}

select.custom-select.sltWidt1 {
    font-weight: 500 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: 11px;
}
.tdsrateboxdd .dropdown-menu{max-height: 140px; overflow-y: scroll; width: 235px}

.redtextclr{color:#fc7d72}

.warning {
    border-color: #fc7d72!important;
}
.float_card{
    height: 100%;
}
.css-4ljt47-MenuList{
    max-height: 250px;
}

.dt-select-dropdown .react-select__menu-list{
    max-height: 200px!important;
}

.react-select__control--is-disabled {
    min-height: 34px!important;
}
.react-select__single-value{
    color: #777171!important;
}

.popup-vertical.vpopuphight1 {
    height: 93%;
  }
  
.excltionicon{
    width: 12px;
    margin-right: 3px;
}
.dt_dnameinputbox.input-error1{
    border: 1px solid #fc6a6a !important;
    box-shadow: none!important;
}
.input-error1{
    .react-select__control.css-yk16xz-control{
        border: 1px solid #fc6a6a !important;
        box-shadow: none!important;
    }
}
    /* -------- media code  --------*/
    @media  (max-width: 767px) {
        .modal.createdeductee_trans_modal.show .modal-dialog {
            
            width: 100%;
            height: 100%;
            min-width: 100%;
            max-width: 100%;
        }
    }

    @media  (min-width: 768px) and (max-width: 821px) {
        .modal.createdeductee_trans_modal.show .modal-dialog {
            
            width: 80%;       
        }
    }

    .k-table-th, .k-table-td{
        border-color: rgb(231, 231, 231);
    }
